import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageEN from './locate/en/translate.json';
import languageHeb from './locate/heb/translate.json';

const i18next = (language) => {
  i18n
    .use(initReactI18next)
    .init({
      initImmediate: false,
      resources: {
        en: languageEN,
        heb: languageHeb,
      },
      lng: language,
      debug: true,
      ns: ['translations'],
      defaultNS: 'translations',
      keySeparator: '.',
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
      },
    });
};

export default i18next;
