import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Icon } from 'rsuite';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function parseName(name) {
  const pathNames = name.split('_').filter((x) => x).map((namePath) => namePath.charAt(0).toUpperCase() + namePath.substr(1));
  return pathNames.join(' ');
}

function BreadcrumbPath() {
  const pathElements = useSelector((state) => state.catalog.pathElements);

  const { t, i18n } = useTranslation();

  const BreadcrumbItem = (breadcrumbItemProps) => (
    <Breadcrumb.Item componentClass={Link} {...breadcrumbItemProps} />
  );

  return (
    <div className="breadcrumb-list">
      <Link to='/' className='landing_page_link'>{i18n.language === 'heb' ? 'ראשי' : 'Konebone'}</Link>
      <div className='rs-breadcrumb-separator'><Icon icon="angle-right" /></div>

      <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>

        {!!pathElements.length && pathElements.map(({ name, path }, index) => {
          const isLast = index === pathElements.length - 1;
          const newName = parseName(name);
          return isLast ? (
            <BreadcrumbItem key={`${newName}-${index}`} to={path} active>
              <span>{index === 0 ? t('page.product_list.products_catalog') : newName}</span>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={`${newName}-${index}`} to={path}><span>{index === 0 ? t('page.product_list.products_catalog') : newName}</span></BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
}

BreadcrumbPath.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(BreadcrumbPath);
