import React, { useState } from 'react';
import {
  Panel,
  PanelGroup,
  Breadcrumb,
  Icon,
  FlexboxGrid,
  Col,
  Button,
  Modal,
} from 'rsuite';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation, Trans } from 'react-i18next';
import NeedHelpComponent from '../components/NeedHelpComponent';

const products = {
  orderFilling: [
    {
      vendorСod: 401236,
      cn: 365839,
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 10,000',
      priceOfOneProduct: '₪ 100,00/item',
      posterProduct: `${product_1}`,
      quantityOrdered: 100,
      isNotAvailableFull: false,
      isNotInStock: false,
      replacement: [],
      isReadyAlternative: false,
    },
    {
      vendorСod: 402153,
      cn: 365840,
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 3,000',
      priceOfOneProduct: '₪ 150,00/item',
      posterProduct: `${product_2}`,
      quantityOrdered: 20,
      isNotAvailableFull: false,
      isNotInStock: false,
      replacement: [],
      isReadyAlternative: false,
    },
    {
      vendorСod: 401263,
      cn: 365813,
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 1,000',
      priceOfOneProduct: '₪ 50,00/item',
      posterProduct: `${product_1}`,
      quantityOrdered: 20,
      isNotAvailableFull: false,
      isNotInStock: false,
      replacement: [],
      isReadyAlternative: false,
    },
    {
      vendorСod: 124038,
      cn: 123456,
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 1,500',
      priceOfOneProduct: '₪ 0,15/item',
      posterProduct: `${product_3}`,
      quantityOrdered: 10,
      isNotAvailableFull: false,
      isNotInStock: false,
      replacement: [],
      isReadyAlternative: false,
    },
  ],
};

import product_1 from '../assets/img/product_1.png';
import product_2 from '../assets/img/product_2.png';
import product_3 from '../assets/img/product_3.png';

const ListOrdered = ({ orderedProducts }) => (
  <ul>
    {orderedProducts.map((product, index) => (
      <li key={index}>
        <OrderedProduct product={product} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  orderedProducts: PropTypes.instanceOf(Array).isRequired,
};

const OrderedProduct = ({ product }) => {
  const isMobileView = useMediaQuery({ query: '(max-width: 1199px)' });
  const {
    vendorСod,
    description,
    priceOfAllProducts,
    priceOfOneProduct,
    quantityOrdered,
    posterProduct,
    isNotAvailableFull,
    isNotInStock,
    replacement,
    cn,
  } = product;
  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${isNotInStock ? ' opacity' : ''}`}>

        {isMobileView ? (
          <>
            <div>
              <div className="products-img">
                <img src={posterProduct} alt="product_png" />
                {isNotInStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
              <p className="quantity">
                <span>
                  x
                  {quantityOrdered}
                </span>
              </p>
            </div>
            <div>

              <div className="flex_box_p">
                <p><span className="product_number">{vendorСod}</span></p>
                {cn && <p><span className="ltr">{cn} {t('page.supplier_orders.catalog_number')}</span></p>}
              </div>

              <p className="description">{description}</p>
              {!isNotInStock && (
                <>
                  <p className="price-all"><span className="ltr">{priceOfAllProducts}</span></p>
                  <p><span className="ltr">{priceOfOneProduct}</span></p>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="products-img">
                <img src={posterProduct} alt="product_png" />
                {isNotInStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
            </div>
            <div className="initial_padding">
              <div className="flex_box_p">
                <p><span className="product_number">{vendorСod}</span></p>
                {cn && <p><span className="ltr">{cn} {t('page.supplier_orders.catalog_number')}</span></p>}
              </div>

              <p className="description">{description}</p>
            </div>
            <div>
              <p className="quantity">
                <span>
                  x
                  {quantityOrdered}
                </span>
              </p>
            </div>
            <div>
              {!isNotInStock && (
                <>
                  <p className="price-all"><span className="ltr">{priceOfAllProducts}</span></p>
                  <p><span className="ltr">{priceOfOneProduct}</span></p>
                </>
              )}
            </div>
          </>
        )}

      </div>
      {isNotAvailableFull && (
        <div className="warning_box">
          <p>{t('page.order.order_list_item.pay_order.info.this_product_available')}</p>
          <p><span className="red">100</span>/200 {t('page.order.order_list_item.pay_order.info.items_are_available')}</p>
        </div>
      )}
      {replacement.length > 0 && (
        <div className="replacement">
          <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
          <ListOrdered orderedProducts={replacement} />
        </div>
      )}
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

const OrderDelivered = () => {
  const { t } = useTranslation();
  const [orderSummary, setOrderSummary] = useState(false);
  const [showUploadPackingModal, setShowUploadPackingModal] = useState(false);
  return (
    <>
      <div className="checkout offer_confirmation order_list_item">
        <div className="container">

          <div className="breadcrumb-list">
            <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
              <Breadcrumb.Item componentClass={Link} to="/">
                <span>{t('page.order.orders')}</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item componentClass={Link} to="/">
                <span>{t('page.requests_for_proposal.accepted')}</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <span>{t('page.order.order')} #48592</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>


          <div className="checkout-flex-col">

            <FlexboxGrid className="flex-420-col">
              <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

                <Panel bordered className="panel_edit">

                  <div className="header-flex">
                    <div><p className="order">{t('page.order.order')} #48592</p></div>
                    <div><p><span className="orders"><span className="ltr">{t('page.supplier_orders.products', { p: 4 })}</span></span></p></div>
                    <div><p><span className="delivered">{t('page.requests_for_proposal.menu.in_process')}</span></p></div>
                  </div>
                  <p className="upload_info">
                    <Trans
                      i18nKey="page.order.order_list.upload_packing_slip"
                      values={{mail: 'info@konebone.co.il'}}
                      components={{ a: <a href="mailto:info@konebone.co.il"><span/></a>, bold: <strong /> }}
                    />
                  </p>

                  <br/>

                  <div className="header-flex">
                    <div><p className="order">{t('page.order.order')} #48592</p></div>
                    <div><p><span className="orders"><span className="ltr">{t('page.supplier_orders.products', { p: 4 })}</span></span></p></div>
                    <div><p><span className="delivered">{t('page.requests_for_proposal.menu.in_process')}</span></p></div>
                  </div>
                  <p>
                    <Trans
                      i18nKey="page.order.order_list.packing_slip_sent"
                      components={{ br: <br/> }}
                    />
                  </p>

                  <div className="wrapper_download_file">
                    <Link to="/">
                      <span>Packing-slip-48492.pdf</span>
                    </Link>
                  </div>

                  <div className="offers_list">
                    <div className="offer-box">

                      <PanelGroup accordion>
                        <Panel header={<p>{t('page.order.order_filling')} <span className="count">(4)</span></p>} defaultExpanded>

                          <div className="products-list">
                            <ListOrdered orderedProducts={products.orderFilling} />
                          </div>

                        </Panel>
                      </PanelGroup>

                    </div>
                  </div>


                </Panel>

              </FlexboxGrid.Item>
              <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>

                <div className="order-component offers_list">

                  <Panel bordered>

                    <p className="header">{t('page.supplier_orders.order_details')}</p>

                    <div className="grey_box">
                      <p>{t('page.supplier_orders.final_order_price')}</p>
                      <p className="price bold"><span className="ltr">₪ 21,000</span></p>

                      <div className="toggle_hide_view">
                        <Button appearance="link" onClick={() => setOrderSummary(!orderSummary)}>
                          <span className="flex">
                            <span>{t('page.order.order_summary')}</span>
                            <span>
                              <i className={`rs-icon ${orderSummary ? 'open' : 'close'}`} />
                            </span>
                          </span>
                        </Button>
                      </div>

                    </div>

                    <div className="submit_button">
                      <Button appearance='primary' onClick={() => setShowUploadPackingModal(true)}>
                        <span>{t('page.requests_for_proposal.confirm_delivery')}</span>
                      </Button>
                    </div>

                    {orderSummary && (
                      <div className="details_box">
                        <p className="header">{t('page.supplier_orders.summary')}</p>
                        <div className="price_box with_line">
                          <ul>
                            <li><p>{t('page.supplier_orders.make_offer.subtotal')}</p></li>
                            <li><p><span className="ltr">₪ 27,000</span></p></li>
                          </ul>
                          <ul>
                            <li><p>{t('page.offers_list.delivery_cost')}</p></li>
                            <li><p><span className="ltr">₪ 400</span></p></li>
                          </ul>
                          <ul>
                            <li><p>{t('page.offers_list.order_total')}</p></li>
                            <li><p><span className="ltr">₪ 27,400</span></p></li>
                          </ul>
                        </div>
                      </div>
                    )}

                    <div className="details_box">
                      <p className="header">{t('page.order.order_list_item_new.delivery_details')}</p>

                      <div className="ico-item contacts">
                        <p className="sub-header">{t('page.supplier_orders.customer_contacts')}</p>
                        <p>Jenny Wilson, 051 5550110</p>
                      </div>

                      <div className="ico-item delivery">
                        <p className="sub-header">{t('page.supplier_orders.delivery_method')}</p>
                        <p>By Supplier with delay</p>
                      </div>

                      <div className="ico-item clock">
                        <p className="sub-header">{t('page.supplier_orders.delivery_time')}</p>
                        <p>June 20, 2021 at 7:00 AM</p>
                      </div>

                      <div className="ico-item project">
                        <p className="sub-header">{t('page.supplier_orders.delivery_address')}</p>
                        <p>June 20, 2021 at 7:00 AM</p>
                      </div>

                      <div className="ico-item deliveryman">
                        <p className="sub-header">{t('page.supplier_orders.deliveryman')}</p>
                        <p>Ashot Ashotovich, (052) 333-23-11</p>
                      </div>

                      <div className="ico-item services">
                        <p className="sub-header">{t('page.order.additional_services')}</p>
                        <p>{t('page.order.unloading')}</p>
                      </div>

                      <div className="ico-item comment">
                        <p className="sub-header">{t('page.order.comment_supplier')}</p>
                        <p>
                          {t('page.order.order_list_item_new.text')}
                        </p>
                      </div>
                    </div>

                  </Panel>

                  <NeedHelpComponent orderId={1}/>

                </div>

              </FlexboxGrid.Item>
            </FlexboxGrid>

          </div>

        </div>
      </div>

      {showUploadPackingModal && <UploadPackingModal
        showUploadPackingModal={showUploadPackingModal}
        setShowUploadPackingModal={setShowUploadPackingModal}
      />}
    </>
  );
};

function UploadPackingModal({ showUploadPackingModal, setShowUploadPackingModal }) {
  const handlerModalClose = () => {
    setShowUploadPackingModal(!showUploadPackingModal);
  };
  const { t } = useTranslation();
  return (
    <Modal
      className="decline-all-offers upload-packing-modal"
      overflow={false}
      show={showUploadPackingModal}
      onHide={handlerModalClose}
      keyboard={true}
      autoFocus={true}>
      <Modal.Header>
        <p>{t('page.upload_packing.title', { order: 48593})}</p>
      </Modal.Header>
      <Modal.Body >
        <p>
          <Trans
            i18nKey="page.order.order_list.upload_packing_slip"
            values={{mail: 'info@konebone.co.il'}}
            components={{ a: <a href="mailto:info@konebone.co.il"><span/></a>, bold: <strong /> }}
          />
        </p>

        <label htmlFor="file-upload" className="upload_label">
          <span className="title">{t('page.upload_packing.upload_file')}</span>
          <span>{t('page.upload_packing.allowed_extensions')}</span>
          <span className="choose_file">
            <strong>{t('page.upload_packing.choose_file')}</strong>
          </span>
        </label>
        <input
          id="file-upload"
          type="file"
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />

        <div className="wrapper_download_file">
          <Link to="/">
            <span>Packing-slip-48492.pdf</span>
          </Link>
        </div>

        <div className="flex-box">
          <div><Button appearance="ghost" onClick={handlerModalClose}><span>{t('page.upload_packing.cancel')}</span></Button></div>
          <div><Button appearance="primary" onClick={handlerModalClose}><span>{t('page.upload_packing.submit')}</span></Button></div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

UploadPackingModal.propTypes = {
  showUploadPackingModal: PropTypes.bool.isRequired,
  setShowUploadPackingModal: PropTypes.func.isRequired,
};

export default OrderDelivered;
