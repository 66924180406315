import React, {useEffect, useState, useRef, useLayoutEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Nav,
  Button,
  Panel,
  PanelGroup,
  Loader,
} from 'rsuite';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { addToCart as addToCartAction } from '../redux/actions/cart';
import { getProductById } from '../redux/actions/product';
import AlertAddedToCart from '../components/AlertAddedToCart';
import BreadcrumbPath from '../components/BreadcrumbPath';
import PurchaseCounter from '../components/PurchaseCounter';
// import ProductNotFound from './ProductNotFound';
import NoImage from '../assets/img/no_image.png';
import { deleteCartItem } from '../redux/actions/cart';
import { closeAllNotifications } from '../redux/actions/notification';
import { loadAllPath } from '../redux/actions/catalog';
import { useTranslation } from 'react-i18next';
import NotFound from './NotFound';

const navItems = {
  SPECIFICATION: 'specification',
  DESCRIPTION: 'description',
};

function ProductDetails() {
  const [count, setCount] = useState(1);
  const { productId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const descriptionRef = useRef(null);

  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = React.useState(navItems.SPECIFICATION);

  const product = useSelector((state) => state.product.item);
  const cartItems = useSelector((state) => state.cart.items);
  const isFetching = useSelector((state) => state.product.isFetching);
  const user = useSelector((state) => state.auth.user);

  const {
    description,
    imageFileName,
    koneboneProductName,
    manufacturerName,
    manufacturingCountry,
    maxKbPrice,
    maxQuantityOnPallet,
    minKbPrice,
    minQuantityOnPalletl,
    packageType,
    // productNumber,
    catalogNumber,
    videoInstructionAndTips,
    technicalSpecification,
  } = product;

  useEffect(() => {
    dispatch(getProductById(productId));
    dispatch(loadAllPath(location.pathname));
  }, [productId, location.pathname]);


  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if ((!product || !Object.keys(product).length) && !isFetching) {
    return <NotFound/>;
  }
  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const handlePlus = () => {
    setCount(count + 1);
  };

  const handleMinus = () => {
    count > 1 && setCount(count - 1);
  };

  const onCartItemDelete = (id) => {
    dispatch(deleteCartItem(id));
  };

  const closeNotifications = () => dispatch(closeAllNotifications());

  const addToCart = () => dispatch(addToCartAction(
    t('cart.product_added_to_cart'),
    'open',
    <AlertAddedToCart
      history={history}
      onCartItemDelete={() => onCartItemDelete(product?.productId)}
      product={product}
      closeAllNotifications={closeNotifications}
      cartItems={cartItems}
      count={count}
    />,
    product,
    count
  ));

  const productDescription = description ? `${description.substr(0, 157)}${description.length > 157 ? '\u2026' : ''}` : t('page.product_details.description.no_description');

  const readMoreHandler = () => {
    setActiveKey(navItems.DESCRIPTION);
    window.scrollTo({ behavior: 'smooth', top: descriptionRef.current.offsetTop });
  };

  const renderHtml = (text) => <p dangerouslySetInnerHTML={{ __html: text }} />;
  const searchBreak = '/n';
  const replaceBreakWith = '<br />';
  const searchItemStart = '**';
  const replaceItemStartWith = '<li class="disc">';
  const searchItemEnd = '##';
  const replaceItemEndWith = '</li>';
  const processTemplate = (text) => {
    return text.replaceAll(
      searchBreak, replaceBreakWith
    ).replaceAll(
      searchItemStart, replaceItemStartWith
    ).replaceAll(
      searchItemEnd, replaceItemEndWith
    );
  };

  return (
    <>
      {isFetching && <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />}
      {product && Object.keys(product).length && product.productId && (
        <div className="product_details">
          <div className="container">

            <BreadcrumbPath />

            <div className="product_details_flex">
              <div>

                <div className="products-img">
                  <img
                    src={imageFileName ? imageFileName : NoImage}
                    alt={koneboneProductName}
                  />
                </div>

              </div>
              <div>

                <div className="product_info">
                  {/* <p className="code"><span className="product_number">{productNumber}</span></p> */}
                  <p className="code"><span className="product_number">{catalogNumber}</span></p>
                  <p>{koneboneProductName}</p>
                  <p className="price">
                    <span className="ltr"><span className="ltr">₪ {minKbPrice} - ₪ {maxKbPrice}</span></span>
                  </p>
                </div>

                <div className="short_description">
                  <p className="title">{t('page.product_details.description.description')}</p>
                  {renderHtml(processTemplate(productDescription))}
                  <p className="read_more" onClick={readMoreHandler}>{t('page.product_details.read_more')}</p>
                </div>

                {!Object.keys(user).length || user?.entityType === 'CUSTOMER' ? (
                  <div className="add_to_cart">
                    <PurchaseCounter
                      count={count}
                      handlePlus={handlePlus}
                      handleMinus={handleMinus}
                      setCount={setCount}
                    />
                    <div>

                      <Button block type="button" appearance="primary" onClick={addToCart}><span>{t('page.add_to_cart')}</span></Button>

                    </div>
                  </div>) : ''}

              </div>

            </div>

            <div className="tabs_product_info">

              <div className="nav-list" ref={descriptionRef}>
                <Nav activeKey={activeKey} appearance="subtle" onSelect={handleSelect}>
                  <Nav.Item eventKey="description">{t('page.product_details.description.description')}</Nav.Item>
                  <Nav.Item eventKey="specification">{t('page.product_details.specification.specification')}</Nav.Item>
                </Nav>
              </div>

              <div className="tabs_list">
                {activeKey === navItems.SPECIFICATION && (
                  <div className="tabs_item">
                    <PanelGroup accordion>
                      <Panel header={t('page.product_details.specification.general')} defaultExpanded>

                        <ul>
                          <li><p><strong>{t('page.product_details.specification.manufacturer_name')}</strong></p></li>
                          <li><p>{manufacturerName}</p></li>
                        </ul>

                        <ul>
                          <li><p><strong>{t('page.product_details.specification.manufacturing_country')}</strong></p></li>
                          <li><p>{manufacturingCountry}</p></li>
                        </ul>

                        <ul>
                          <li><p><strong>{t('page.product_details.specification.quantity_on_pallet')}</strong></p></li>
                          <li><p>{minQuantityOnPalletl}-{maxQuantityOnPallet}</p></li>
                        </ul>

                      </Panel>
                      <Panel header={t('page.product_details.specification.dimensions_and_weight')} expanded>

                        <ul>
                          <li><p><strong>{t('page.product_details.specification.package_type')}</strong></p></li>
                          <li><p>{packageType}</p></li>
                        </ul>

                      </Panel>
                      <Panel header={t('page.product_details.specification.additional_information')} expanded>

                        <ul>
                          <li><p>{videoInstructionAndTips ? <a href={videoInstructionAndTips} target="_blank" rel="noreferrer">
                                { t('page.product_details.specification.video_instruction_and_tips') } </a> :
                              t('page.product_details.specification.no_video_instructions')}</p></li>
                        </ul>

                      </Panel>
                      <Panel header={t('page.product_details.specification.other')} expanded>

                        <div>{technicalSpecification ? renderHtml(processTemplate(technicalSpecification)) : '-'}</div>

                      </Panel>
                    </PanelGroup>
                  </div>
                )}
                {activeKey === navItems.DESCRIPTION && (
                  <div className="tabs_item">
                    <PanelGroup accordion>
                      <Panel className='title' header={t('page.product_details.description.about_product')} defaultExpanded>
                        <p>{renderHtml(processTemplate(description || t('page.product_details.description.no_description')))}</p>
                      </Panel>
                    </PanelGroup>
                  </div>
                )}
              </div>

            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default ProductDetails;
