import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import IntlProvider from 'rsuite/es/IntlProvider';
import { isIE } from 'react-device-detect';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import UnsupportedBrowser from './pages/UnsupportedBrowser';
import './assets/scss/index.scss';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18next from './i18n/i18n';

// const isRtlDirection = process.env.REACT_APP_DIRECTION === 'rtl';

function Main() {
  const { i18n } = useTranslation();
  const userLanguage = localStorage.getItem('userLanguage') === 'en' ? 'en' : 'heb';

  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute('dir', localStorage.getItem('userLanguage') === 'en' ? 'ltr' : 'rtl');
  }, []);

  return (
    <Provider store={store}>
      {/* <I18nextProvider i18n={isRtlDirection ? i18next('heb') : i18next('en')}> */}
      <I18nextProvider i18n={i18next(userLanguage)}>
        <IntlProvider rtl={i18n.language === 'heb'}>
          {isIE ? <UnsupportedBrowser /> : <App />}
        </IntlProvider>
      </I18nextProvider>
    </Provider>
  );
}

ReactDOM.render(
  <Main />,
  document.getElementById('root'),
);
