import React, { createRef, useState, useEffect } from 'react';
import {
  Panel,
  PanelGroup,
  Breadcrumb,
  Icon,
  FlexboxGrid,
  Col,
  Button,
  Tooltip,
  Whisper,
} from 'rsuite';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import NeedHelpComponent from '../components/NeedHelpComponent';

const InfoTooltip = ({ ...rest }) => {
  const { t } = useTranslation();
  return (
    <Tooltip classPrefix="info_tooltip_checkout rs-tooltip" {...rest}>
      <p>{t('page.add_alternative_modal.indicated_alternative_product')}</p>
    </Tooltip>
  );
};

const InfoButton = () => {
  const triggerRef = createRef();
  const [isOpen, setIsOpen] = useState(false);

  function handleCloseTooltip() {
    triggerRef.current.close();
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('resize', handleCloseTooltip);
    } else {
      window.removeEventListener('resize', handleCloseTooltip);
    }

    return () => {
      window.removeEventListener('resize', handleCloseTooltip);
    };
  });

  return (
    <Whisper
      placement="autoVerticalStart"
      trigger="hover"
      onOpen={() => setIsOpen(true)}
      speaker={<InfoTooltip />}
      triggerRef={triggerRef}
    >
      <span className="icon_refresh"><Icon icon="refresh" /></span>
    </Whisper>
  );
};

const products = {
  inStock: [
    {
      vendorСod: '365839',
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 15,005',
      priceOfOneProduct: '₪ 150,05/item',
      posterProduct: `${product_1}`,
      quantityOrdered: '100',
      isNotAvailableFull: true,
      isNotInStock: false,
      replacement: [],
      isReadyAlternative: true,
    },
    {
      vendorСod: '365839',
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 15,005',
      priceOfOneProduct: '₪ 150,05/item',
      posterProduct: `${product_2}`,
      quantityOrdered: '5',
      isNotAvailableFull: false,
      isNotInStock: false,
      replacement: [],
      isReadyAlternative: true,
    },
  ],
  alternativeProducts: [
    {
      vendorСod: '365839',
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 14,005',
      priceOfOneProduct: '₪ 140,05/item',
      posterProduct: `${product_2}`,
      quantityOrdered: '100',
      isNotAvailableFull: false,
      isNotInStock: true,
      replacement: [
        {
          vendorСod: '365839',
          description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
          priceOfAllProducts: '₪ 14,005',
          priceOfOneProduct: '₪ 140,05/item',
          posterProduct: `${product_2}`,
          quantityOrdered: '100',
          isNotAvailableFull: false,
          isNotInStock: false,
          replacement: [],
        },
      ],
      isReadyAlternative: false,
    },
    {
      vendorСod: '365839',
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 14,005',
      priceOfOneProduct: '₪ 140,05/item',
      posterProduct: `${product_2}`,
      quantityOrdered: '100',
      isNotAvailableFull: false,
      isNotInStock: true,
      replacement: [
        {
          vendorСod: '365839',
          description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
          priceOfAllProducts: '₪ 14,005',
          priceOfOneProduct: '₪ 140,05/item',
          posterProduct: `${product_2}`,
          quantityOrdered: '100',
          isNotAvailableFull: true,
          isNotInStock: false,
          replacement: [],
        },
      ],
      isReadyAlternative: false,
    },
  ],
  outOfStock: [
    {
      vendorСod: '365839',
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 15,005',
      priceOfOneProduct: '₪ 150,05/item',
      posterProduct: `${product_3}`,
      quantityOrdered: '5',
      isNotAvailableFull: false,
      isNotInStock: false,
      replacement: [],
      isReadyAlternative: false,
    },
  ],
};

import product_1 from '../assets/img/product_1.png';
import product_2 from '../assets/img/product_2.png';
import product_3 from '../assets/img/product_3.png';
import { getOrderById } from '../redux/actions/order';

const ListOrdered = ({ orderedProducts }) => (
  <ul>
    {orderedProducts.map((product, index) => (
      <li key={index}>
        <OrderedProduct product={product} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  orderedProducts: PropTypes.instanceOf(Array).isRequired,
};

const OrderedProduct = ({ product }) => {
  const isMobileView = useMediaQuery({ query: '(max-width: 1199px)' });
  const {
    vendorСod,
    description,
    priceOfAllProducts,
    priceOfOneProduct,
    quantityOrdered,
    posterProduct,
    isNotAvailableFull,
    isNotInStock,
    replacement,
    isReadyAlternative,
  } = product;
  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${isNotInStock ? ' opacity' : ''}`}>

        {isMobileView ? (
          <>
            <div>
              <div className="products-img">
                <img src={posterProduct} alt="product_png" />
                {isNotInStock && <p className="out_of_stock"><span>יאלמב אל</span></p>}
              </div>
              <p className="quantity">
                <span>
                  x
                  {quantityOrdered}
                </span>
              </p>
            </div>
            <div>
              {isReadyAlternative ? (
                <div className="flex_box_p">
                  <p><span className="product_number">{vendorСod}</span></p>
                  <p><InfoButton /></p>
                </div>
              ) : (
                <p>
                  <span className="product_number">{vendorСod}</span>
                </p>
              )}
              <p className="description">{description}</p>
              {!isNotInStock && (
                <>
                  <p className="price-all"><span className="ltr">{priceOfAllProducts}</span></p>
                  <p><span className="ltr">{priceOfOneProduct}</span></p>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="products-img">
                <img src={posterProduct} alt="product_png" />
                {isNotInStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
            </div>
            <div className="initial_padding">
              {isReadyAlternative ? (
                <div className="flex_box_p">
                  <p><span className="product_number">{vendorСod}</span></p>
                  <p><InfoButton /></p>
                </div>
              ) : (
                <p>
                  <span className="product_number">{vendorСod}</span>
                </p>
              )}
              <p className="description">{description}</p>
            </div>
            <div>
              <p className="quantity">
                <span>
                  x
                  {quantityOrdered}
                </span>
              </p>
            </div>
            <div>
              {!isNotInStock && (
                <>
                  <p className="price-all"><span className="ltr">{priceOfAllProducts}</span></p>
                  <p><span className="ltr">{priceOfOneProduct}</span></p>
                </>
              )}
            </div>
          </>
        )}

      </div>
      {isNotAvailableFull && (
        <div className="warning_box">
          <p>{t('page.order.order_list_item.pay_order.info.this_product_available')}</p>
          <p><span className="red">100</span>/200 {t('page.order.order_list_item.pay_order.info.items_are_available')}</p>
        </div>
      )}
      {replacement.length > 0 && (
        <div className="replacement">
          <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
          <ListOrdered orderedProducts={replacement} />
        </div>
      )}
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

const OrderListItem = () => {
  const dispatch = useDispatch();
  const [deliveryDetails, setDeliveryDetails] = useState(false);
  const { t } = useTranslation();
  const { orderId } = useParams();

  useEffect(() => {
    dispatch(getOrderById(orderId));
  }, [location.key]);

  return (
    <div className="checkout offer_confirmation order_list_item">
      <div className="container">

        <div className="breadcrumb-list">
          <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
            <Breadcrumb.Item componentClass={Link} to="/">
              <span>{t('page.order.orders')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <span>{t('page.order.order')} #48592</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="checkout-flex-col">

          <FlexboxGrid className="flex-420-col">
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

              <Panel bordered className="panel_edit">

                <div className="header-flex">
                  <div><p className="order">{t('page.order.order')} #48592</p></div>
                  <div><p><span className="in_progress">{t('page.order.in_progress')}</span></p></div>
                </div>
                <p className="date">{t('page.order.order_list_item.delivery_with_delay')}, 13 Jul 2021, 12:00</p>
                <p className="date">{t('page.order.order_list_item.delivery_with_delay')}, 13 Jul 2021, 12:00</p>

                <br />

                <div className="header-flex">
                  <div><p className="order">{t('page.order.order')} #48592</p></div>
                  <div><p><span className="awaiting-delivery">{t('page.order.awaiting_delivery')}</span></p></div>
                </div>
                <p className="date">{t('page.order.order_list_item.delivered_at')} 13 Jul 2021, 12:00</p>
                <p className="date">{t('page.order.order_list_item.delivered_at')} 13 Jul 2021, 12:00</p>

                <br />

                <div className="header-flex">
                  <div><p className="order">{t('page.order.order')} #48592</p></div>
                  <div><p><span className="delivered">{t('page.order.delivered')}</span></p></div>
                </div>
                <p className="date">{t('page.order.order_list_item.delivered_at')} 13 Jul 2021, 12:00</p>
                <p className="date">{t('page.order.order_list_item.delivered_at')} 13 Jul 2021, 12:00</p>

                <br />

                <div className="offers_list">
                  <div className="offer-box">

                    <PanelGroup accordion>
                      <Panel header={<p><span className="count">Delivery - 1 </span> {t('page.order.order_list_item.in_stock')} <span className="count">(2)</span></p>} defaultExpanded>

                        <div className="products-list">
                          <ListOrdered orderedProducts={products.inStock} />
                        </div>

                      </Panel>
                      <Panel header={<p><span className="count">Delivery - 1 </span>{t('page.order.order_list_item.pay_order.alternative_products')} <span className="count">(2)</span></p>} defaultExpanded>

                        <div className="products-list">
                          <ListOrdered orderedProducts={products.alternativeProducts} />
                        </div>

                      </Panel>
                      <Panel header={<p><span className="count">Delivery - 1 </span>{t('page.order.order_list_item.pay_order.out_of_stock')} <span className="count">(1)</span></p>} defaultExpanded>

                        <div className="products-list">
                          <ListOrdered orderedProducts={products.outOfStock} />
                        </div>

                      </Panel>

                      <Panel header={<p><span className="count">Delivery - 2 </span>{t('page.order.order_list_item.in_stock')} <span className="count">(2)</span></p>} defaultExpanded>

                        <div className="products-list">
                          <ListOrdered orderedProducts={products.inStock} />
                        </div>

                      </Panel>
                      <Panel header={<p><span className="count">Delivery - 2 </span>{t('page.order.order_list_item.pay_order.alternative_products')} <span className="count">(2)</span></p>} defaultExpanded>

                        <div className="products-list">
                          <ListOrdered orderedProducts={products.alternativeProducts} />
                        </div>

                      </Panel>
                      <Panel header={<p><span className="count">Delivery - 2 </span>{t('page.order.order_list_item.pay_order.out_of_stock')} <span className="count">(1)</span></p>} defaultExpanded>

                        <div className="products-list">
                          <ListOrdered orderedProducts={products.outOfStock} />
                        </div>

                      </Panel>
                    </PanelGroup>

                  </div>
                </div>


              </Panel>

            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>

              <div className="offers_list">

                <Panel bordered>

                  <div className="supplier_name">
                    <p className="header">{t('page.order.order_summary')}</p>
                  </div>

                  <div className="grey_box">
                    <p>{t('page.order.order_filling')}</p>
                    <p className="price">
                      <span>{t('page.order.of_products', { num1: 3, num2: 5 })}</span>
                    </p>
                    <div className="toggle_hide_view">
                      <Button appearance="link" onClick={() => setDeliveryDetails(!deliveryDetails)}>
                        <span className="flex">
                          <span>{t('page.order.delivery_details')}</span>
                          <span>
                            <i className={`rs-icon ${deliveryDetails ? 'open' : 'close'}`} />
                          </span>
                        </span>
                      </Button>
                    </div>
                  </div>

                  {deliveryDetails && (
                    <div className="details_box">
                      <p className="header">{t('page.order.delivery_details')}</p>

                      <div className="ico-item clock">
                        <p className="sub-header">{t('page.order.order_list.specific_delivery_time')}</p>
                        <p>June 20, 2021 at 7:00 AM</p>
                      </div>

                      <div className="ico-item project">
                        <p className="sub-header">{t('page.order.project')}</p>
                        <p>Garad, Sokolov st. 79, Holon</p>
                      </div>

                      <div className="ico-item contacts">
                        <p className="sub-header">{t('page.order.contacts')}</p>
                        <p>Jenny Wilson, 051 5550110</p>
                      </div>

                      <div className="ico-item services">
                        <p className="sub-header">{t('page.order.additional_services')}</p>
                        <p>{t('page.order.unloading')}</p>
                      </div>

                      <div className="ico-item comment">
                        <p className="sub-header">{t('page.order.comment_supplier')}</p>
                        <p>
                          Delivery can be done with a truck without a crane,
                          unloading of goods will be done by a crane of the construction site.
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="details_box">
                    <p className="header">{t('page.order.contacts')}</p>

                    {/* <div className="ico-item suppliet">
                      <p className="sub-header">{t('page.order.order_list_item.supplier')}</p>
                      <p>Sara Shara, (050) 855-77-44</p>
                    </div> */}

                    <div className="ico-item delivery">
                      <p className="sub-header">{t('page.order.order_list_item.delivery')}</p>
                      <p>Ashot Ashotovich 1, (052) 333-23-11</p>
                    </div>

                    <div className="ico-item delivery">
                      <p className="sub-header">{t('page.order.order_list_item.delivery')}</p>
                      <p>Ashot Ashotovich 2, (052) 333-23-11</p>
                    </div>
                  </div>

                  <div className="details_box payment_box">
                    <div className="flex-container">
                      <div>
                        <p className="header">{t('page.order.order_list_item.payment')}</p>
                      </div>
                      <div>
                        <p className="paid">שוטף+60</p>
                      </div>
                    </div>
                    <p>{t('page.order.order_list_item.until', { d: '30', m: 'Sept', y: '2021' })}</p>
                  </div>


                  <div className="details_box payment_box">
                    <div className="flex-container">
                      <div>
                        <p className="header">{t('page.order.order_list_item.payment')}</p>
                      </div>
                      <div>
                        <p className="paid">{t('page.order.order_list_item.paid')}</p>
                      </div>
                    </div>
                  </div>


                  <div className="details_box payment_box">
                    <div className="flex-container">
                      <div>
                        <p className="header">{t('page.order.order_list_item.payment')}</p>
                      </div>
                      <div>
                        <p className="failed">{t('page.order.order_list_item.failed')}</p>
                      </div>
                    </div>
                  </div>

                  <div className="price_box">
                    <ul>
                      <li><p>{t('page.order.order_list_item.price_offer')}</p></li>
                      <li><p><span className="ltr">₪ 26,500</span></p></li>
                    </ul>

                    <ul className="discount_price">
                      <li><p><span className="flex"><span>{t('page.offer.discount')}</span><span className="percentage">-10%</span></span></p></li>
                      <li><p><span className="ltr">-₪ 2,500</span></p></li>
                    </ul>

                    <ul>
                      <li><p>{t('page.order.order_list_item.delivery_cost')}</p></li>
                      <li><p><span className="ltr">₪ 1000</span></p></li>
                    </ul>

                    <ul>
                      <li><p>{t('page.offer.subtotal_without_VAT')}</p></li>
                      <li><p><span className="ltr">₪ 27,400</span></p></li>
                    </ul>
                    <hr/>

                    <ul>
                      <li><p>{t('page.offer.vat')}</p></li>
                      <li><p><span className="ltr">₪ 4,658</span></p></li>
                    </ul>

                    <ul className="total">
                      <li><p>{t('page.offer.order_total')}</p></li>
                      <li><p><span className="ltr">₪ 27,600</span></p></li>
                    </ul>

                    {/* <ul>
                      <li><p>{t('page.order.order_list_item.unloading_cost')}</p></li>
                      <li><p><span className="ltr">₪ 100</span></p></li>
                    </ul>

                    <ul className="total">
                      <li><p>{t('page.order.order_list_item.order_total')}</p></li>
                      <li><p><span className="ltr">₪ 27,600</span></p></li>
                    </ul> */}
                  </div>

                </Panel>


                {/* <Panel bordered className="documents-box">

                  <p className="header">{t('page.order.order_list_item.documents')}</p>
                  <p>{t('page.order.order_list_item.order_payment_receive')}</p>

                </Panel>

                <Panel bordered className="documents-box">

                  <p className="header">{t('page.order.order_list_item.documents')}</p>
                  <ul className="list_links">
                    <li><Link to="#" download><span>{t('page.order.order_list_item.payment_receive')}</span></Link></li>
                  </ul>

                </Panel>

                <Panel bordered className="documents-box">

                  <p className="header">{t('page.order.order_list_item.documents')}</p>

                  <ul className="list_links">
                    <li><Link to="#" download><span>{t('page.order.order_list_item.payment_receive')}</span></Link></li>
                    <li><Link to="#" download><span>{t('page.order.order_list_item.order_invoice')}Order invoice</span></Link></li>
                  </ul>

                </Panel> */}

                <NeedHelpComponent orderId={orderId}/>

              </div>

            </FlexboxGrid.Item>
          </FlexboxGrid>

        </div>

      </div>
    </div>
  );
};

export default OrderListItem;
