import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FlexboxGrid,
  Col,
  Icon,
  Input,
  InputGroup,
  Badge,
  Modal,
  Button,
  Dropdown,
} from 'rsuite';
// import queryString from 'query-string';
import { SignInContext } from '../context/signInContext';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { logout } from '../redux/actions/auth/logout';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { resetCartData } from '../redux/actions/cart';
import { setActivePage } from '../redux/actions/order';


function SiteHeader() {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const userError = useSelector((state) => state.auth.errorRedirect);
  const cartItems = useSelector((state) => state.cart.items);

  const { handleIsModalOpen } = useContext(SignInContext);

  const userErrorRedirect = () => {
    history.push('/');
    handleIsModalOpen(true);
  };

  useEffect(() => {
    userError && Object.keys(user).length === 0 && userErrorRedirect();
  }, [userError]);

  return (
    <>
      {user?.entityType === 'SUPPLIER' || user?.entityType === 'SUPPLIER_CANDIDATE' ? (
        <div className="site-header">
          {/* <SupplierInfoHeader /> */}
          <InfoHeader />
          <SupplierUserHeader entityType={user?.entityType} />
        </div>
      ) : (
        <div className="site-header">
          <InfoHeader />
          <UserHeader entityType={user?.entityType} cartItems={cartItems} />
        </div>
      )}
    </>
  );
}

function InfoHeader() {
  return (
    <div className="info-header">
      <div className="container">
        <FlexboxGrid>
          <FlexboxGrid.Item>
            <div className="header-logo">
              <Link to='/'>
                <span>logo</span>
              </Link>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item>
            <div className="contact-info">
              <a href="tel:0723970146">
                <span className="flex">
                  <span><Icon icon="mobile" /></span>
                  <span className="title"><span className="ltr">072 397-0146</span></span>
                </span>
              </a>
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    </div>
  );
}

function UserHeader({ entityType, cartItems }) {
  return (
    <div className="user-header">
      <div className="container">
        <FlexboxGrid>
          <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
            <ProductCatalog />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} colspan={24} md={16}>
            <Search />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} colspan={24} md={2}>
            <Authorization entityType={entityType} />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} colspan={24} md={2}>
            <Cart cartItems={cartItems} />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    </div>
  );
}

UserHeader.propTypes = {
  entityType: PropTypes.string,
  cartItems: PropTypes.instanceOf(Array).isRequired,
};
function SupplierUserHeader({ entityType }) {
  return (
    <>
      <div className="user-header user-header_supplier">
        <div className="container">
          <FlexboxGrid>
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={2}>
              <Orders />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={2}>
              <Authorization entityType={entityType} />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
      </div>
    </>
  );
}

SupplierUserHeader.propTypes = {
  entityType: PropTypes.string,
};

function Cart({ cartItems }) {
  const countOfItems = cartItems?.length;
  return (
    <Link to="/cart" className="link_bg">
      <span className="flex">
        <span className="icon"><Icon icon="shopping-cart" /></span>
        {/* <span className="title"><span>Cart</span></span> */}
        <span className="badge"><Badge content={countOfItems} /></span>
      </span>
    </Link>
  );
}

Cart.propTypes = {
  cartItems: PropTypes.instanceOf(Array).isRequired,
};

function Authorization({ entityType }) {
  const { isModalOpen, handleIsModalOpen } = useContext(SignInContext);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [accountMenuIsOpen, setAccountMenuIsOpen] = useState(false);
  const isMobileView = useMediaQuery({ query: '(max-width: 575px)' });
  const { t } = useTranslation();
  const history = useHistory();

  const user = useSelector((state) => state.auth.user);
  const isAuthorized = user && Object.keys(user).length;

  const dispatch = useDispatch();

  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  const openMenu = () => {
    setMenuIsOpen(true);
  };

  const onLogout = () => {
    dispatch(logout(() => {
      history.push('/');
    }));
    dispatch(resetCartData());
    isMobileView && closeMenu();
  };

  useEffect(() => {
    !isMobileView && setMenuIsOpen(false);
  }, [isMobileView]);

  return (
    isAuthorized ? (
      <>
        {!isMobileView ? (
          <Dropdown
            open={accountMenuIsOpen}
            onToggle={(open) => {
              setAccountMenuIsOpen(open);
            }}
            className="account_menu"
            renderTitle={() => {
              return (
                <Button className="link_bg no_bg">
                  <span className="flex">
                    <span className="icon"><Icon icon="avatar" /></span>
                    <span className="title"><span>{user?.firstName || t('header.account')}</span></span>
                  </span>
                </Button>
              );
            }}
          >
            {!(entityType === 'SUPPLIER' || entityType === 'SUPPLIER_CANDIDATE') && (
              <>
                <Dropdown.Item onSelect={() => {
                  setAccountMenuIsOpen(false);
                  history.push('/customer/orders');
                }}>
                  <span className="flex">
                    <span className="icon"><Icon icon="task" /></span>
                    <span className="title"><span>{t('header.my_orders')}</span></span>
                  </span>
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item onSelect={onLogout}>
              <span className="flex">
                <span className="icon"><Icon icon="sign-out" /></span>
                <span className="title"><span>{t('header.log_out')}</span></span>
              </span>
            </Dropdown.Item>
          </Dropdown>
        ) : (
          <Button className={`link_bg no_bg${menuIsOpen ? ' transparent' : ''}`} onClick={() => {
            menuIsOpen ? closeMenu() : openMenu();
          }}>
            <span className="flex">
              <span className="icon"><Icon icon="avatar" /></span>
              <span className="title"><span>{t('header.account')}</span></span>
            </span>
          </Button>
        )}

        <Modal
          backdrop
          show={menuIsOpen}
          onHide={closeMenu}
          className="supplier_mobile_menu white"
          classPrefix="white-rs-supplier"
          overflow={false}
        >
          <Modal.Body>
            <ul>
              {!(entityType === 'SUPPLIER' || entityType === 'SUPPLIER_CANDIDATE') && (
                <>
                  <li>
                    <Button className="link" onClick={() => {
                      closeMenu();
                      history.push('/customer/orders');
                    }} >
                      <span className="flex">
                        <span className="icon"><Icon icon="task" /></span>
                        <span className="title"><span>{t('header.my_orders')}</span></span>
                      </span>
                    </Button>
                  </li>
                </>
              )}
              <li>
                <Button className="link" onClick={onLogout}>
                  <span className="flex">
                    <span className="icon"><Icon icon="sign-out" /></span>
                    <span className="title"><span>{t('header.log_out')}</span></span>
                  </span>
                </Button>
              </li>
            </ul>
          </Modal.Body>
        </Modal>
      </>
    ) : (
      <Button className="link_bg no_bg" onClick={() => handleIsModalOpen(!isModalOpen)}>
        <span className="flex">
          <span className="icon"><Icon icon="avatar" /></span>
          <span className="title"><span>{t('header.sign_in')}</span></span>
        </span>
      </Button>
    )
  );
}

Authorization.propTypes = {
  entityType: PropTypes.string,
};

function Orders() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Link
      to="/supplier/orders"
      className="link_bg orders"
      onClick={() => dispatch(setActivePage(1))}
    >
      <span className="flex">
        <span className="icon"><Icon icon="order-form" /></span>
        <span className="title">
          <span>{t('header.orders')}</span>
        </span>
      </span>
    </Link>
  );
}

function Search() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isActiveMobileSearch, setIsActiveMobileSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    const path = pathname.split('/');
    if (path[1] === 'search') {
      setSearchValue(path[2] ? path[2] : '');
    }
  }, [pathname]);

  const historyPush = () => {
    if (searchValue.trim() !== '') {
      history.push(`/search/${searchValue}`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      historyPush();
    }
  };

  const onChange = (value) => {
    setSearchValue(value);
  };

  return (
    <div className={`search${isActiveMobileSearch ? ' active' : ''}`}>
      <InputGroup>
        <Input placeholder={t('header.search_by_products_or_categories')} value={searchValue} onKeyDown={handleKeyDown} onChange={onChange} />
        <InputGroup.Addon>
          <Button disabled={!searchValue.trim()} onClick={() => historyPush()}>
            <Icon icon="search" />
          </Button>
        </InputGroup.Addon>
      </InputGroup>
      <Button className="link_bg link_search" onClick={() => setIsActiveMobileSearch(!isActiveMobileSearch)}>
        <span className="flex">
          <span className="icon"><Icon icon="search" /></span>
          <span className="title"><span>{t('header.search')}</span></span>
        </span>
      </Button>
    </div>
  );
}

function ProductCatalog() {
  const { t } = useTranslation();
  return (
    <Link to="/product_catalog" className="link_bg catalog">
      <span className="flex">
        <span className="icon"><Icon icon="th-large" /></span>
        <span className="title">
          <span className="desktop">{t('header.products_catalog')}</span>
          <span className="mobile">{t('header.catalog')}</span>
        </span>
      </span>
    </Link>
  );
}

export default SiteHeader;
