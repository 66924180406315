import { createActions } from 'redux-actions';
import draftApi from '../../api/draft';

const actions = createActions({
  postAddAlternativeDraftProduct: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getAllPackageTypes: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getAllMeasurements: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetDraft: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

export const postAddAlternativeDraftProduct = (data) => async (dispatch) => {
  dispatch(actions.postAddAlternativeDraftProduct.request());
  const query_data = {
    catalogNumber: data.catalogNumber,
    manufacturerName: data.manufacturerName,
    measurementTitle: data.measurementTitle,
    name: data.name,
    packageTypeTitle: data.packageTypeTitle,
    pricePerUnit: data.pricePerUnit,
    quantity: data.quantity,
  };
  try {
    const response = await draftApi.postAddAlternativeDraftProduct(query_data);
    if (response.statusCode !== 200) throw new Error(JSON.stringify(response));
    dispatch(actions.postAddAlternativeDraftProduct.success({ item: response.data, result: 'Success' }));
  } catch (e) {
    const response = JSON.parse(e.message);
    dispatch(actions.postAddAlternativeDraftProduct.error({
      error: response?.message?.message,
      validationErrors: response?.data?.validationErrors,
    }));
  }
};

export const getAllPackageTypes = () => async (dispatch) => {
  dispatch(actions.getAllPackageTypes.request());
  try {
    const response = await draftApi.getAllPackageTypes();
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.getAllPackageTypes.success({ packages: response.data}));
  } catch (e) {
    dispatch(actions.getAllPackageTypes.error({ error: e }));
  }
};

export const getAllMeasurements = () => async (dispatch) => {
  dispatch(actions.getAllMeasurements.request());
  try {
    const response = await draftApi.getAllMeasurements();
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.getAllMeasurements.success({ measurements: response.data }));
  } catch (e) {
    dispatch(actions.getAllMeasurements.error({ error: e }));
  }
};

export const resetDraft = () => async (dispatch) => {
  dispatch(actions.resetDraft.request());
  try {
    dispatch(actions.resetDraft.success());
  } catch (e) {
    dispatch(actions.resetDraft.error({ error: e }));
  }
};
