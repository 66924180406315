export const updateDataObject = (sourceItem, item) => ({
  ...sourceItem,
  ...item,
});

export const addToCollection = (items, item) => [...items, item];

export const updateCollection = (items, item) => {
  const clone = items.map((i) => {
    if (i.productId === item.productId) {
      return item;
    }
    return i;
  });
  return clone;
};
