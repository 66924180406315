import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toggleIsTimeConstraintsShown, toggleIsAdditionalServicesShown, toggleIsProjectsShown } from '../../redux/actions/order';
import { FlexboxGrid, Col, Panel } from 'rsuite';
import { useTranslation } from 'react-i18next';


function ConfirmOrder({
  setIsSuccessfulOrderShown,
  setIsConfirmOrderShown,
}) {
  const timeConstraints = useSelector((state) => state.checkout.timeConstraints);
  const additionalServices = useSelector((state) => state.checkout.additionalServices);
  const project = useSelector((state) => state.checkout.projectData);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const timeConstraintsDate = timeConstraints?.date?.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' });

  return (
    <>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>
        <Panel bordered className="panel_edit">
          <div className="flex-container">
            <div className="flex-item">
              <p className="header">
                {t('page.checkout.time_constraints.time_constraints')} <span className="light">{t('page.checkout.step_1')}</span>
              </p>
              <p>{t('page.checkout.time_constraints.specific_delivery_time')}:{' '}
                {timeConstraints?.urgentStatus ?
                  t('page.checkout.time_constraints.time_of_order_confirmation') :
                  // eslint-disable-next-line max-len
                  timeConstraints?.date && timeConstraints?.time &&
                  // eslint-disable-next-line max-len
                    `${timeConstraintsDate}, ${timeConstraints?.time?.label}`
                }
              </p>
            </div>
            <div className="flex-item item-edit">
              <p className="edit"><a href="#" onClick={() => {
                dispatch(toggleIsTimeConstraintsShown(true));
                dispatch(toggleIsProjectsShown(false));
                dispatch(toggleIsAdditionalServicesShown(false));
                setIsSuccessfulOrderShown(false);
                setIsConfirmOrderShown(false);
              }}><span>{t('page.checkout.edit')}</span></a></p>
            </div>
          </div>
        </Panel>

        <Panel bordered className="panel_edit">
          <div className="flex-container">
            <div className="flex-item">
              <p className="header">
                {t('page.checkout.project.project')} <span className="light">{t('page.checkout.step_2')}</span>
              </p>
              <p>
                {project?.name}
                {project?.street && `, ${project?.street} `} {project?.apt && project?.apt}
                {!!project?.city && `, ${project?.city}`}
              </p>
            </div>
            <div className="flex-item item-edit">
              <p className="edit"><a href="#" onClick={() => {
                dispatch(toggleIsTimeConstraintsShown(false));
                dispatch(toggleIsProjectsShown(true));
                dispatch(toggleIsAdditionalServicesShown(false));
                setIsSuccessfulOrderShown(false);
                setIsConfirmOrderShown(false);
              }}><span>{t('page.checkout.edit')}</span></a></p>
            </div>
          </div>
        </Panel>

        <Panel bordered className="panel_edit">
          <div className="flex-container">
            <div className="flex-item">
              <p className="header">{t('page.checkout.additional_services.additional_services')} <span className="light">{t('page.checkout.step_3')}</span></p>
              <p>
                {additionalServices?.serviceUnloading && t('page.order.unloading')}
                {additionalServices?.serviceUnloading && additionalServices?.customerComment && ', '}
                {additionalServices?.customerComment && additionalServices?.customerComment}</p>
            </div>
            <div className="flex-item item-edit">
              <p className="edit"><a href="#" onClick={() => {
                dispatch(toggleIsTimeConstraintsShown(false));
                dispatch(toggleIsProjectsShown(false));
                dispatch(toggleIsAdditionalServicesShown(true));
                setIsSuccessfulOrderShown(false);
                setIsConfirmOrderShown(false);
              }}><span>{t('page.checkout.edit')}</span></a></p>
            </div>
          </div>
        </Panel>
      </FlexboxGrid.Item>
    </>
  );
}

ConfirmOrder.propTypes = {
  setIsConfirmOrderShown: PropTypes.func.isRequired,
  setIsSuccessfulOrderShown: PropTypes.func.isRequired,
};

export default ConfirmOrder;
