import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FlexboxGrid, Col } from 'rsuite';
import { Link } from 'react-router-dom';
import environmentApi from '../api/environment';
import { useTranslation } from 'react-i18next';

function SiteFooter() {
  const [environment, setEnvironment] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    environmentApi.getEnvironment().then((items) => {
      if (items.data) {
        setEnvironment(items.data?.commitTimeStamp);
      }
    });
  }, []);

  const user = useSelector((state) => state.auth.user);
  const checkSupplier = user?.entityType === 'SUPPLIER' || user?.entityType === 'SUPPLIER_CANDIDATE';

  return (
    !(location.pathname.indexOf('offer_confirmation') > -1) && (
      <div className="site-footer">
        <div className="before-copyright">
          <div className="container">

            <div className="flex-container">
              <FlexboxGrid>
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>

                  <div className="footer-logo">
                    <Link to={checkSupplier ? '/supplier/orders/new' : '/'}>
                      <span>logo</span>
                    </Link>
                  </div>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
                  <h3>{t('footer.contacts')}</h3>
                  <ul>
                    <li>
                      <p>
                        <a href="tel:0723970146">
                          <span className="ltr">{t('company_phone_number')}</span>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="mailto:info@konebone.co.il">
                          <span>info@konebone.co.il</span>
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>{t('footer.company_address')}</p>
                    </li>
                    <li>
                      <p>
                        <a href="https://www.facebook.com/" target="_blank" className="facebook" rel="noreferrer">
                          <span>facebook</span>
                        </a>
                        <a href="https://www.linkedin.com/company/konebone/" target="_blank" className="linkedin" rel="noreferrer">
                          <span>linkedin</span>
                        </a>
                      </p>
                    </li>
                  </ul>

                </FlexboxGrid.Item>
              </FlexboxGrid>
            </div>

          </div>
        </div>
        <div className="copyright">
          <div className="container">

            <div className="flex-container">
              <FlexboxGrid>
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
                  <p>{t('footer.copyright')}</p>
                </FlexboxGrid.Item>
                <>
                  <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                    <p><Link to="/privacy-policy">{t('footer.privacy_policy')}</Link></p>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                    <p><Link to="/terms-and-conditions-for-suppliers">{t('footer.terms_and_conditions_for_suppliers')}</Link></p>
                    <p><Link to="/terms-and-conditions-for-customers">{t('footer.terms_and_conditions_for_customers')}</Link></p>
                  </FlexboxGrid.Item>
                </>
              </FlexboxGrid>
            </div>

            <p className="environment">{environment && `Build version: ${environment}`}</p>
            {/*
              <p className="environment">{process.env.REACT_APP_COMMIT_AUTHOR}</p>
              <p className="environment">{process.env.REACT_APP_COMMIT_BRANCH}</p>
              <p className="environment">{process.env.REACT_APP_COMMIT_REF_NAME}</p>
              <p className="environment">{process.env.REACT_APP_COMMIT_SHA}</p>
              <p className="environment">{process.env.REACT_APP_COMMIT_TIMESTAMP}</p>
            */}

          </div>
        </div>
      </div>
    )
  );
}

export default SiteFooter;
