import { createActions } from 'redux-actions';
// import { stringify } from 'query-string';
import projectApi from '../../api/project';
// import { PRODUCTS_PER_PAGE } from '../constantas/product';

const actions = createActions({
  getProjectList: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

export const getProjects = () => async (dispatch) => {
  // const query_data = { page: 1, perPage: PRODUCTS_PER_PAGE };
  dispatch(actions.getProjectList.request());
  try {
    // const response = await projectApi.getProjects(stringify(query_data));
    const response = await projectApi.getProjects();
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.getProjectList.success({
      items: response.data.projects,
      totalElements: response.data.totalElements,
    }));
  } catch (e) {
    dispatch(actions.getProjectList.error({ error: e }));
  }
};
