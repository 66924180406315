import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'rsuite';
import { useTranslation } from 'react-i18next';

function SuccessfullyPlaced() {
  const { t } = useTranslation();
  const history = useHistory();
  const { orderId } = useParams();
  return (
    <div className="offer_confirmation_info">
      <div className="container">
        <div className="offer_confirmation_info_box">
          <div className="banner successfully_placed">
            <span />
          </div>
          <p className="header">{t('page.successfully_placed.your_order_has_accepted')}</p>
          <p>
            {t('page.successfully_placed.text')}
          </p>
          <div className="button">
            <div className="flex">
              <div>
                <Button block type="button" appearance="ghost" onClick={() => history.push('/product_catalog')}><span>{t('page.successfully_placed.back_to_catalogue')}</span></Button>
              </div>
              <div>
                <Button block type="button" appearance="primary" onClick={() => history.push(`/customer/order-paid/${orderId}`)}><span>{t('page.successfully_placed.order_details')}</span></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessfullyPlaced;
