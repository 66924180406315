import { handleActions } from 'redux-actions';
import actions from '../../actions/auth/constants';
import { addressInfo } from './addressInfo';
import { signIn } from './signIn';
import { smsCodeVerification } from './smsCodeVerification';
import { companyInfoData } from './companyInfoData';
import { managerData } from './managerData';
import { registration } from './registration';
import { logout } from './logout';

export const initialState = {
  isFetching: false,
  error: '',
  registrationData: {},
  signInData: {},
  companyInfo: {},
  companyInfo: {},
  managerInfo: {},
  isManagerCreated: false,
  isCodeConfirmed: false,
  user: {},
  errorRedirect: false,
};

const authReducers = Object.assign(
  {},
  addressInfo(actions),
  signIn(actions),
  smsCodeVerification(actions),
  companyInfoData(actions),
  managerData(actions),
  registration(actions),
  logout(actions),
  {
    // reset goToNextStep
    [actions.resetGoToNextStep]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      goToNextStep: false,
      isCodeConfirmed: false,
    }),
  },
  {
    // reset user without logout
    [actions.resetUserState]: (state) => ({
      ...state,
      user: {},
      errorRedirect: true,
    }),
  }
);

export default handleActions(
  authReducers,
  initialState
);
