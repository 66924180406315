import { createActions } from 'redux-actions';

const actions = createActions({
  setSignInData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetSignInData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  signIn: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  confirmSignInCode: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  confirmRegistrationCode: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  setCompanyInfoData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetCompanyInfoData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  createCompanyInfo: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  setAddressInfoData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetAddressInfoData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  createAddressInfo: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  setManagerData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetManagerData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  createManager: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  setRegistrationData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetRegistrationData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetRegistrationUser: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  registration: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getSignedSupplierCandidate: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getSignedCustomerCandidate: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  setCurrentUser: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  putCartItemToCustomer: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  logout: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  postLoginRestore: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetIsCodeConfirmed: (x) => x,
  resetGoToNextStep: (x) => x,
  resetRegistrationError: (x) => x,
  resetSignInError: (x) => x,
  resetUserState: (x) => x,
});

export default actions;
