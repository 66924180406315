import { handleActions } from 'redux-actions';
import actions from '../actions/order';

export const initialState = {
  isFetching: false,
  error: '',
  items: [],
  item: {},
  pages: null,
  totalItems: null,
  isOrdersUpdated: false,
  isTimeConstraintsShown: true,
  isProjectsShown: false,
  isAdditionalServicesShown: false,
  toggleCheckInfo: false,
  activePage: 1,
  timeRanges: [],
};

export default handleActions(
  // place RFP
  {
    [actions.placeRFP.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),

    [actions.placeRFP.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
    }),

    [actions.placeRFP.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // resetOrder
    [actions.resetOrder.request]: (state) => ({
      ...state,
      isFetching: false,
      error: '',
      items: [],
      item: {},
      pages: null,
      totalItems: null,
      isOrdersUpdated: false,
    }),

    [actions.resetOrder.success]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [actions.resetOrder.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // confirm order
    // [actions.confirmOrder.request]: (state) => ({
    //   ...state,
    //   isFetching: true,
    //   error: '',
    //   item: {},
    // }),

    // [actions.confirmOrder.success]: (state, { payload }) => ({
    //   ...state,
    //   isFetching: false,
    //   item: payload.item,
    // }),

    // [actions.confirmOrder.error]: (state, { payload }) => ({
    //   ...state,
    //   isFetching: false,
    //   error: payload.error,
    // }),

    // get order by customer
    [actions.getOrdersByCustomer.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
      pages: null,
      totalItems: null,
      isOrdersUpdated: false,
    }),

    [actions.getOrdersByCustomer.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
      totalItems: payload.totalItems,
      pages: payload.pages,
    }),

    [actions.getOrdersByCustomer.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    [actions.setActivePage.success]: (state, { payload }) => ({
      ...state,
      activePage: payload.activePage,
    }),

    // get order by customer search
    [actions.getOrdersByCustomerSearch.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
      pages: null,
      totalItems: null,
      isOrdersUpdated: false,
    }),

    [actions.getOrdersByCustomerSearch.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
      totalItems: payload.totalItems,
      pages: payload.pages,
    }),

    [actions.getOrdersByCustomerSearch.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // get draft order by manager.
    [actions.getDraftOrderByManager.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),

    [actions.getDraftOrderByManager.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
    }),

    [actions.getDraftOrderByManager.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // get time range.
    [actions.getTimeRange.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      timeRanges: [],
    }),

    [actions.getTimeRange.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      timeRanges: payload.item,
    }),

    [actions.getTimeRange.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // Confirm order by ID
    [actions.confirmOrderById.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),

    [actions.confirmOrderById.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
    }),

    [actions.confirmOrderById.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // Get order by ID
    [actions.getOrderById.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
    }),

    [actions.getOrderById.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    [actions.getOrderById.request]: (state, { }) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),

    // Get paid order by ID
    [actions.getPaidOrderById.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
    }),

    [actions.getPaidOrderById.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    [actions.getPaidOrderById.request]: (state, { }) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),

    // Decline all offers buy order id
    [actions.declineAllOffersByOrderId.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),

    [actions.declineAllOffersByOrderId.success]: (state, { }) => ({
      ...state,
      isFetching: false,
      isOrdersUpdated: true,
    }),

    [actions.declineAllOffersByOrderId.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    [actions.toggleIsTimeConstraintsShown.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isTimeConstraintsShown: payload.isTimeConstraintsShown,
    }),

    [actions.toggleIsProjectsShown.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isProjectsShown: payload.isProjectsShown,
    }),

    [actions.toggleIsAdditionalServicesShown.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isAdditionalServicesShown: payload.isAdditionalServicesShown,

    }),

  },
  initialState
);
