import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  FormGroup,
  ControlLabel,
  Input,
} from 'rsuite';
import { Link, useHistory } from 'react-router-dom';
import { formatPhoneNumber } from '../../../helpers/phoneNumberHelpers';
import {
  resetSignInError,
  setSignInData,
  signInUser,
} from '../../../redux/actions/auth/signIn';
// import validationMessages from '../../../helpers/validationMessages';
import { Schema } from 'rsuite';
import { Trans, useTranslation } from 'react-i18next';

const { StringType } = Schema.Types;
const model = (t) => Schema.Model({
  phone: StringType().isRequired(t('errors.this_field_is_required')),
});

function errorHandler(t, typeError) {
  const errors = {
    'phone.not.active': t('errors.phoneIsNotActive'),
    'phone.not.found': t('errors.phoneNotFound'),
    'phone.not.confirmed': t('errors.phoneNotConfirmed'),
    'bean.validation.fail': t('errors.phoneInvalid'),
    'exception.auth.manager_status_BLOCKED': t('errors.blockedManagerAccount'),
    'exception.auth.company_status_BLOCKED': t('errors.blockedCompanyAccount'),
    'exception.auth.company_status_DECLINED': t('errors.declinedCompanyAccount'),
    'default': t('errors.defaultErrorMessage'),
  };
  return (errors[typeError] || errors['default']);
}

const SignIn = ({ handleShowConfirmCode, handleShowSignIn, onHideModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [registrationData, setRegistrationData] = useState({});

  const { signInData, error, goToNextStep } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  useEffect(() => {
    if (goToNextStep) {
      handleShowSignIn(false);
      handleShowConfirmCode(true);
    }
  }, [goToNextStep]);

  useEffect(() => {
    if (error === 'company.type.undefined') {
      history.push('/supplier_succeeded_registration');
      onHideModal(true);
    } else if (error === 'company.not.active') {
      history.push('/customer_succeeded_registration');
      onHideModal(true);
    }
  }, [error]);

  useEffect(() => {
    setRegistrationData({ ...signInData, phone: formatPhoneNumber(signInData?.phone) });
  }, [signInData]);

  const onFormSubmit = () => {
    const phone = registrationData?.phone?.split('').filter((item) => item !== '-').join('');
    dispatch(setSignInData({ ...registrationData, phone }));
    delete registrationData.isRememberMeChecked;
    dispatch(signInUser({ ...registrationData, phone }));
  };

  const handlePhoneNumberChange = (value) => {
    if (error) {
      dispatch(resetSignInError());
    }
    const formattedPhoneNumber = formatPhoneNumber(value);
    setRegistrationData({ ...registrationData, phone: formattedPhoneNumber });
  };

  // const handleCheckboxCheck = (evt, val) => {
  //   setRegistrationData({ ...registrationData, isRememberMeChecked: val });
  // };

  const handleClickLostAccessPhoneNum = () => {
    onHideModal();
  };

  return (
    <Form
      fluid
      model={model(t)}
      onChange={(formValue) => setRegistrationData({ ...formValue })}
      checkTrigger="blur"
    >
      <FormGroup>
        <p>
          <Trans
            i18nKey="auth.sign_in.enter_the_phone_number"
            components={{ strong: <strong></strong> }}
          />
        </p>
      </FormGroup>
      <FormGroup>
        <ControlLabel>{t('auth.sign_in.phone_number')}</ControlLabel>
        <Input
          name="phone"
          type="text"
          placeholder="05N-XXXXXXX"
          value={registrationData?.phone || ''}
          errorMessage={null}
          onChange={(v) => handlePhoneNumberChange(v)}
          inputMode="numeric"
          inputType="tel"
        />
      </FormGroup>
      {/* <FormGroup>*/}
      {/*  <Checkbox name="rememberMe" onChange={(evt, val) => handleCheckboxCheck(evt, val)} checked={registrationData.isRememberMeChecked} value={registrationData?.isRememberMeChecked}>*/}
      {/*    <span className="text">{t('auth.sign_in.remember_me')}</span>*/}
      {/*  </Checkbox>*/}
      {/* </FormGroup>*/}

      {error && <p className="registration_error">{errorHandler(t, error)}</p>}

      <FormGroup className="buttons_box">
        <Button block appearance="primary" type="submit" disabled={!Object.keys(registrationData).length || registrationData?.phone?.length <= 10} onClick={onFormSubmit}><span>{t('auth.sign_in.sign_in')}</span></Button>
      </FormGroup>
      <p className="forgot_phone"><Link to="/lost_access_phone_number" onClick={handleClickLostAccessPhoneNum}>{t('auth.sign_in.i_lost_access')}</Link></p>
    </Form>
  );
};

SignIn.propTypes = {
  handleShowConfirmCode: PropTypes.func.isRequired,
  handleShowSignIn: PropTypes.func.isRequired,
  onHideModal: PropTypes.func.isRequired,
};

export default SignIn;


