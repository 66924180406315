import { post, get, put, putWithoutStringifyArgs } from './';

const placeRFP = (data) => post('/order', data);
const getOrdersByCustomer = (query) => get('/orders', query);
const getOrdersByCustomerSearch = (query) => get('/orders', query);
const getDraftOrderByManager = () => get('/order/by_manager');
const confirmOrderById = (orderId, data) => putWithoutStringifyArgs(`/order/${orderId}/confirm`, data);
const getOrderById = (orderId) => get(`/order/${orderId}`);
const getPaidOrderById = (orderId) => get(`/order/paid/${orderId}`);
const declineAllOffers = (orderId) => put(`/order/${orderId}/customer-decline-all-offers`);
const getTimeRange = () => get('/time-range/order/');

const orderApi = {
  placeRFP,
  getOrdersByCustomer,
  getOrdersByCustomerSearch,
  getDraftOrderByManager,
  confirmOrderById,
  getOrderById,
  getPaidOrderById,
  declineAllOffers,
  getTimeRange,
};

export default orderApi;
