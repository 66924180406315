import React from 'react';
import { Button } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PaymentFailed() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="offer_confirmation_info">
      <div className="container">
        <div className="offer_confirmation_info_box">
          <div className="banner payment_failed">
            <span />
          </div>
          <p className="header">{t('page.payment_failed.payment')}</p>
          <p>
            {t('page.payment_failed.text_1')}
            <br />
            {t('page.payment_failed.text_2')}
          </p>
          <div className="button">
            <Button block type="button" appearance="primary" style={{maxWidth: '200px'}} onClick={() => history.push('/customer/orders?status=IN_REVIEW')}>
              <span>{t('page.payment_failed.btn_text')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailed;
