export const dic = {
  main_banner_section_title: {
    en: 'KONEBONE - B2B Marketplace for Construction Market',
    heb: 'קונה-בונה-פלטפורמה דיגיטלית B2B Marketplace לרכש חומרי בניין',
  },
  main_banner_section_content: {
    en: 'The platform connects Builder, Suppliers, and Forwarders in one place.',
    heb: 'פלטפורמה דיגיטלית חדשנית להזמנת חומרי בניין המקשרת בין ספקי חומרי בניין לבין קבלנים ויזמים כך שכולם מרוויחים',
  },
  main_banner_section_contact_us: {
    en: 'Contact Us',
    heb: 'צור קשר',
  },
  tabs_btn_section_builders_preheader: {
    en: 'Save time and money',
    heb: 'הרוויחו לקוחות חדשים',
  },
  tabs_btn_section_builders_header: {
    en: 'Builders',
    heb: 'ספקים',
  },
  tabs_btn_section_builders_content: {
    en: 'Building materials are sourced from multiple suppliers in one place, so with the click of a button, you will get the best price offers for your project and save a lot of time and money.',
    heb: 'קבלו הזמנות מלקוחות חדשים שיגיעו אליכם בלחיצת כפתור, וחשיפה לעוד ועוד בעלי מקצוע מהתחום שיהפכו ללקוחות שלכם',
  },
  tabs_btn_section_suppliers_preheader: {
    en: 'Get new clients',
    heb: 'חסכו זמן וכסף',
  },
  tabs_btn_section_suppliers_header: {
    en: 'Suppliers',
    heb: 'קבלנים',
  },
  tabs_btn_section_suppliers_content: {
    en: 'Be exposed to thousands of new customers and get new orders.',
    heb: 'חומרי הבניין ממגוון ספקים במקום אחד, כך שבלחיצת כפתור תקבלו הצעת מחיר עם המחירים הטובים ביותר עבור הפרויקט שלכם, ותחסכו זמן רב וכסף.',
  },
  about_us_section_title: {
    en: 'Who are we and why should you join the revolution with us?',
    heb: 'מי אנחנו ולמה שתצטרפו למהפכה דווקא איתנו?',
  },
  about_us_section_content_paragraph_1: {
    en: 'Following extensive experience in the building field, both in entrepreneurship and in construction, we developed the KONEBONE platform to provide a response to the needs arising from the field and to optimize the procurement processes in the construction industry.',
    heb: 'בעקבות ניסיון רב בתחום הבניין, הן ביזמות והן בבניה, פיתחנו את פלטפורמת קונהבונה כדי לספק מענה לצרכים העולים מהשטח ועל מנת לייעל את תהליכי הרכש בענף הבניה.',
  },
  about_us_section_content_paragraph_2: {
    en: 'The KONEBONE platform is operated by professionals who see it as a mission and are determined to influence, change and improve the construction industry through innovation, technology, artificial intelligence and e-commerce.',
    heb: '.את פלטפורמת קונהבונה מפעילים אנשי מקצוע שרואים בכך שליחות ונחושים להשפיע, לשנות ולשפר את ענף הבניה באמצעות חדשנות, טכנולוגיה, בינה מלאכותית ומסחר אלקטרוני.',
  },
  about_us_section_content_paragraph_3: {
    en: 'We know for sure that using the platform will increase the economic value of your business.',
    heb: 'אנו יודעים בוודאות שהשימוש בפלטפורמה יעלה את הערך הכלכלי של העסק שלכם.',
  },
  about_us_section_list_item_1: {
    en: 'Reduced operating costs',
    heb: 'צמצום עלויות תפעול',
  },
  about_us_section_list_item_2: {
    en: 'Time saving',
    heb: 'חיסכון בזמן',
  },
  about_us_section_list_item_3: {
    en: 'increasing profitability',
    heb: 'הגדלת רווחיות',
  },
  about_us_section_list_item_4: {
    en: 'Automation',
    heb: 'אוטומציה',
  },
  about_us_section_list_item_5: {
    en: 'Artificial intelligence',
    heb: 'בינה מלאכותית',
  },
  why_we_section_preheader: {
    en: '',
    heb: 'קבלנים',
  },
  why_we_section_title: {
    en: 'Why you should use our platform',
    heb: 'למה כדאי להשתמש בפלטפורמה שלנו',
  },
  why_we_section_content: {
    en: 'Because it optimizes procurement work, save time and increase the profitability by purchasing building materials at the best prices available on the market.',
    heb: 'כי השימוש בה מייעל את העבודה, חוסך בזמן ובכוח אדם ומאפשר לכם להגדיל את הרווחיות באמצעות רכישת חומרי בניין במחירים הטובים ביותר שקיימים בשוק',
  },
  why_we_section_try_it_now: {
    en: 'Try it now',
    heb: 'להתחברות לחצו כאן',
  },
  slider_section_preheader: {
    en: 'How it works',
    heb: 'How it works',
  },
  slider_section_header: {
    en: '5 short and simple steps to order from us',
    heb: '5 צעדים קצרים ופשוטים וההזמנה אצלכם',
  },
  slider_section_contact_us: {
    en: 'Contact Us',
    heb: 'להתחברות לחצו כאן',
  },
  slider_section_slide_1_content: {
    en: 'Builders submit a request for proposal (RFP) with a list of necessary products for their project.',
    heb: 'מתחברים לפלטפורמה',
  },
  slider_section_slide_2_content: {
    en: 'The platform searches via registered suppliers for:',
    heb: 'מגישים בקשה להצעת מחיר',
  },
  slider_section_slide_3_content: {
    en: 'Customer gets three best offers and chooses the most attractive one.',
    heb: ':המערכת סורקת את הבקשה ומאתרת הצעות לפי',
  },
  slider_section_slide_4_content: {
    en: 'The chosen supplier receives a notification that the buyer has chosen their offer. The order moves to the assembly and delivery stage.',
    heb: 'המערכת מציעה לכם עד שלוש הצעות מחיר מספקים שונים בהתאם לבקשתכם, במחירים הטובים ביותר',
  },
  slider_section_slide_5_content: {
    en: 'KoneBone organizes and controls final delivery with commitment for times.',
    heb: 'אתם בוחרים את ההצעה הטובה ביותר עבורכם ומבצעים הזמנה',
  },
  slider_section_slide_1_list: {
    en: [''],
    heb: [''],
  },
  slider_section_slide_2_list: {
    en: ['- inventory availability and prices; - distance and cost of delivery; - trade record and rating of supplier.'],
    heb: [''],
  },
  slider_section_slide_3_list: {
    en: [''],
    heb: ['- אמינות ודירוג איכות הספק ;- ניסיון מסחרי ;- עלות וזמן אספקה משוער ;- מועדי אספקה ;- המחירים התחרותיים ;- ביותר הימצאות המלאי'],
  },
  slider_section_slide_4_list: {
    en: [''],
    heb: [''],
  },
  slider_section_slide_5_list: {
    en: [''],
    heb: [''],
  },
  benefits_section_title: {
    en: 'All the benefits in the palm of your hand',
    heb: 'כל היתרונות בכף היד',
  },
  benefits_section_btns_panel_btn_left: {
    en: 'for Builders',
    heb: 'לקבלנים',
  },
  benefits_section_btns_panel_btn_right: {
    en: 'for Suppliers',
    heb: 'לספקים',
  },
  benefits_section_list_builders_item_1_title: {
    en: 'Digital Unified platform- One Stop Shop',
    heb: 'נקודת מכירה אחת One Stop Shop',
  },
  benefits_section_list_builders_item_1_content: {
    en: '',
    heb: 'נקודת מכירה אחת ואיתור הצעות מחיר הטובות ביותר ממאות ספקים, להשוואה מהירה ופשוטה בלי מאמץ',
  },
  benefits_section_list_builders_item_2_title: {
    en: 'Optimization of materials spending',
    heb: 'אופטימיזציה לעלויות רכש',
  },
  benefits_section_list_builders_item_2_content: {
    en: '',
    heb: 'חיסכון בכוח אדם ובעלויות תפעול, מה שיעלה את שורת הרווח של הפרויקט',
  },
  benefits_section_list_builders_item_3_title: {
    en: 'Suppliers compete for each order',
    heb: 'ללא עלות נוספת',
  },
  benefits_section_list_builders_item_3_content: {
    en: '',
    heb: 'ללא דמי שימוש או רכישת רישיון, כך שאתם רק מרוויחים ללא כל סיכון',
  },
  benefits_section_list_builders_item_4_title: {
    en: 'Optimization of manpower',
    heb: 'הצעות מחיר הטובות ביותר',
  },
  benefits_section_list_builders_item_4_content: {
    en: '',
    heb: 'המחירים הטובים ביותר, להוזלת הפרויקט ומקסום הרווח',
  },
  benefits_section_list_builders_item_5_title: {
    en: 'Real time tracking',
    heb: 'שקיפות',
  },
  benefits_section_list_builders_item_5_content: {
    en: '',
    heb: 'מעקב אחרי ההזמנה ללא הפתעות בזמן אמת ובשקיפות מקסימלית',
  },
  benefits_section_list_builders_item_6_title: {
    en: 'Transparency',
    heb: 'חסכון בזמן',
  },
  benefits_section_list_builders_item_6_content: {
    en: '',
    heb: 'חסון בזמן לכל התהליך הרכש',
  },
  benefits_section_list_builders_item_7_title: {
    en: 'Time saving on procurement',
    heb: '',
  },
  benefits_section_list_builders_item_7_content: {
    en: '',
    heb: '',
  },
  benefits_section_list_suppliers_item_1_title: {
    en: 'Exposure To High Amount Of Customers',
    heb: 'קבלת לקוחות חדשים',
  },
  benefits_section_list_suppliers_item_1_content: {
    en: '',
    heb: 'ניהול הזמנות עתידיות עם מועדי אספקה רחוקים ותזכורות עם הגעת המועד',
  },
  benefits_section_list_suppliers_item_2_title: {
    en: 'No Investments In Delivery Fleet',
    heb: 'קידום בערוצים החדשים',
  },
  benefits_section_list_suppliers_item_2_content: {
    en: '',
    heb: 'אפשרות קידום ופרסום של מוצרים, למיקסום החשיפה',
  },
  benefits_section_list_suppliers_item_3_title: {
    en: 'New Leads',
    heb: 'מעקב בזמן אמת',
  },
  benefits_section_list_suppliers_item_3_content: {
    en: '',
    heb: 'מעקב אחרי ההזמנות בזמן אמת, כך ששום דבר לא מתפספס',
  },
  benefits_section_list_suppliers_item_4_title: {
    en: 'Real Time Tracking',
    heb: 'ניהול הזמנות עתידיות',
  },
  benefits_section_list_suppliers_item_4_content: {
    en: '',
    heb: 'קבלת לקוחות חדשים שלא הייתם מגיעים אליהם אחרת',
  },
  benefits_section_list_suppliers_item_5_title: {
    en: 'Timely Payments',
    heb: 'מסמכים דיגיטלים',
  },
  benefits_section_list_suppliers_item_5_content: {
    en: '',
    heb: 'כל המסמכים דיגיטליים במקום אחד, מה שמייצר סדר',
  },
  benefits_section_list_suppliers_item_6_title: {
    en: 'Digital Documentation',
    heb: 'תשלומים בזמן',
  },
  benefits_section_list_suppliers_item_6_content: {
    en: '',
    heb: 'תשלומים בזמן, ללא עיכובים, מה שישפר את התזרים שלכם',
  },
  benefits_section_list_suppliers_item_7_title: {
    en: 'Promotion in Digital Channel',
    heb: 'הובלה חיצונית',
  },
  benefits_section_list_suppliers_item_7_content: {
    en: '',
    heb: 'הובלת חומרים על ידי קונה-בונה',
  },
  questions_section_title: {
    en: 'Frequently asked questions',
    heb: 'קבלנים שואלים - אנחנו עונים',
  },
  questions_section_accordion_list_item_1_title: {
    en: 'I\'ve been buying from the same supplier for years and I\'m satisfied, why should I change?',
    heb: 'אני קונה אצל אותו הספק שנים ואני מרוצה, למה לי לשנות?',
  },
  questions_section_accordion_list_item_1_content: {
    en: 'The KONEBONE system locates supplipers with the most profitable product mix. With the click of a button, you can get multiple quotes effortlessly, including comparing prices from the best suppliers, which you don\'t usually do when you buy from one supplier. When using the KONEBONE platform, you will be surprised to discover the price differences and the quality of service.',
    heb: 'מערכת קונהבונה מאתרת ספקים עם תמהיל מוצרים הכי משתלם. בלחיצת כפתור ניתן לקבל מספר הצעות מחיר ללא מאמץ, כולל השוואת מחירים מהספקים הטובים ביותר,  מה שאינכם עושים בדרך כלל כאשר אתם קונים מספק אחד. בשימוש בפלטפורמה קונהבונה תופתעו לגלות את ההבדלי המחירים ואיכות השירות.',
  },
  questions_section_accordion_list_item_2_title: {
    en: 'Is it necessary to meet physically in order to place an order and sign documents?',
    heb: 'האם יש צורך להיפגש פיזית לצורך ביצוע הזמנה וחתימת על מסמכים.',
  },
  questions_section_accordion_list_item_2_content: {
    en: 'No need to meet physically. All orders are digital and can be made from your mobile device, which saves a lot of time.',
    heb: 'אין צורך להיפגש פיזית. כל ההזמנות הן דיגיטליות וניתן לעשות אותן מהמכשיר הנייד שלכם, מה שחוסך זמן רב.',
  },
  questions_section_accordion_list_item_3_title: {
    en: 'The platform shows me a price range. At what price do I purchase?',
    heb: 'הפלטפורמה מראה לי טווח מחירים. באיזה מחיר אני רוכש?',
  },
  questions_section_accordion_list_item_3_content: {
    en: 'The price range for each product consists of the high price and the low price - these prices were received from the suppliers who joined the platform. The final price will appear in the price offer approved by the supplier you choose for the final order.',
    heb: 'טווח המחירים לכל מוצר מורכב מהמחיר הגבוה ומהמחיר הנמוך - מחירים אלה התקבלו מהספקים שהצטרפו לפלטפורמה. המחיר הסופי יופיע בהצעת המחיר המאושרת על ידי הספק אותו תבחרו להזמנה הסופית.',
  },
  questions_section_accordion_list_item_4_title: {
    en: 'I\'m a non-tech person and I\'m afraid I won\'t get along with the platform',
    heb: 'אני אדם לא טכנולוגי וחושש שלא אסתדר עם הפלטפורמה',
  },
  questions_section_accordion_list_item_4_content: {
    en: 'For those who have a hard time with technology, we are ready to accept orders in any possible way that is convenient for you via WhatsApp or by phone and a representative on our behalf will guide you so that you too can use the platform easily and comfortably in order to derive all its benefits.',
    heb: 'לאנשים שקשה להם  עם טכנולוגיה, אנחנו ערוכים לקבל הזמנות בכל דרך אפשרית הנוחה עבורכם בוואטסאפ או באמצעות הטלפון  ונציג מטעמנו ידריך אתכם כך שתוכלו  גם אתם להשתמש בפלטפורמה בקלות ובנוחות על מנת להפיק את כל התועלות שבה.',
  },
  questions_section_accordion_list_item_5_title: {
    en: 'Who are we and why should you join the revolution with us?',
    heb: 'מי אנחנו ולמה שתצטרפו למהפכה דווקא איתנו?',
  },
  questions_section_accordion_list_item_5_content: {
    en: 'Following extensive experience in the building field, both in entrepreneurship and in construction, we developed the KONEBONE platform to provide a response to the needs arising from the field and to optimize the procurement processes in the construction industry. The KONEBONE platform is operated by professionals who see it as a mission and are determined to influence, change and improve the construction industry through innovation, technology, artificial intelligence and e-commerce. We know for sure that using the platform will increase the economic value of your business.',
    heb: 'בעקבות ניסיון רב בתחום הבניין, הן ביזמות והן בבניה, פיתחנו את פלטפורמת קונהבונה כדי לספק מענה לצרכים העולים מהשטח ועל מנת לייעל את תהליכי הרכש בענף הבניה. את פלטפורמת קונהבונה מפעילים אנשי מקצוע שרואים בכך שליחות  ונחושים להשפיע, לשנות ולשפר את ענף הבניה באמצעות חדשנות, טכנולוגיה, בינה מלאכותית ומסחר אלקטרוני. אנו יודעים בוודאות שהשימוש בפלטפורמה יעלה את הערך הכלכלי של העסק שלכם.',
  },
  questions_section_list_suppliers_item_1: {
    en: 'Exposure To High Amount Of Customers',
    heb: 'Exposure To High Amount Of Customers',
  },
  questions_section_list_suppliers_item_2: {
    en: 'No Investments In Delivery Fleet',
    heb: 'No Investments In Delivery Fleet',
  },
  questions_section_list_suppliers_item_3: {
    en: 'New Leads',
    heb: 'New Leads',
  },
  questions_section_list_suppliers_item_4: {
    en: 'Real Time Tracking',
    heb: 'Real Time Tracking',
  },
  questions_section_list_suppliers_item_5: {
    en: 'Timely Payments',
    heb: 'Timely Payments',
  },
  questions_section_list_suppliers_item_6: {
    en: 'Digital Documentation',
    heb: 'Digital Documentation',
  },
  questions_section_list_suppliers_item_7: {
    en: 'Promotion in Digital Channel',
    heb: 'Promotion in Digital Channel',
  },
  contact_form_section_title: {
    en: 'Please leave your contact information and field of interest and we will come back to you shortly',
    heb: 'אנא השאירו את פרטי הקשר שלכם ואת תחום העניין, אנו נחזור אליכם בהקדם האפשרי.',
  },
  // contact_form_section_content_paragraph_1: {
  //   en: 'A little patience…',
  //   heb: 'קצת סבלנות',
  // },
  // contact_form_section_content_paragraph_2: {
  //   en: 'KoneBone marketplace is in the last days of construction. We are doing our best to present the website very soon. Please leave your contact information and field of interest and we will come back to you shortly',
  //   heb: 'פלטפורמת קונה-בונה נמצאת בשלבים אחרונים לפני עלייתה לאוויר. אנו עושים את מירב המאמצים להציג בפניכם את הפלטפורמה מוכנה לעבודה ולהתחיל לקבל הזמנות. אנא השאירו את פרטי הקשר שלכם ואת תחום העניין, אנו נחזור אליכם בהקדם האפשרי.',
  // },
  contact_form_section_field_1: {
    en: 'Contact full name',
    heb: 'שם מלא',
  },
  contact_form_section_field_2: {
    en: 'Phone or Email',
    heb: 'טלפון או דואר אלקטרוני',
  },
  contact_form_section_field_3: {
    en: 'Field of interest',
    heb: 'תחום עניין',
  },
  contact_form_section_field_4: {
    en: 'Comment',
    heb: 'הערות',
  },
  contact_form_section_btn_submit: {
    en: 'Contact Me',
    heb: 'שלח פניה',
  },
  validationErrors_not_empty: {
    en: 'this field is required',
    heb: 'שדה חובה',
  },
  validationErrors_phone_email: {
    en: 'A valid phone or email address is required',
    heb: 'שדה חובה',
  },

  request_success_title: {
    en: 'Your request successfully sent',
    heb: 'פנייתך נשלחה בהצלחה',
  },
  request_success_description: {
    en: 'Konebone contact you as soon as possible.',
    heb: 'אנו נחזור אליך בהקדם האפשרי',
  },
  request_error_title: {
    en: 'Something went wrong',
    heb: 'משהו השתבש',
  },
  request_error_description: {
    en: 'Try resubmitting the contact form again',
    heb: 'אנא נסה שוב',
  },
  request_success_button: {
    en: 'Go Back',
    heb: 'חזרה',
  },

};
