import { post, get, putWithoutStringifyArgs } from './';

// login flow
const signInUser = (data) => post('/login/phone', data);
// user verification by code
const confirmLoginVerificationCode = (data) => post('/login/code', data);

// registration flow
const sendPhoneForRegistration = (data) => post('/registration/phone', data);
const getSignedSupplierCandidate = () => get('/supplier/candidate');
const getSignedCustomerCandidate = () => get('/customer');

// user verification by code
const confirmRegistrationVerificationCode = (code) => post('/registration/code', code);

// get company types
const getCompanyTypes = () => get('/company-types');

// post registration details
const createUserPrifile = (data) => post('/registration/details', data);
const createCompanyInfo = (company) => post('/registration/company', company);
const createAddressInfoData = (address) => post('/registration/address', address);
const createManager = (manager) => post('/registration/manager', manager);

// current user info
const getCurrentUser = () => get('/login');
const putCartItemToCustomer = (data) => putWithoutStringifyArgs('/customer/cart', data);

// user logout
const logout = () => post('/logout');

// user login restore
const postLoginRestore = (data) => post('/login/restore', data);

const authApi = {
  signInUser,
  sendPhoneForRegistration,
  getSignedSupplierCandidate,
  getSignedCustomerCandidate,
  confirmRegistrationVerificationCode,
  getCompanyTypes,
  createUserPrifile,
  confirmLoginVerificationCode,
  createCompanyInfo,
  createAddressInfoData,
  createManager,
  logout,
  getCurrentUser,
  putCartItemToCustomer,
  postLoginRestore,
};

export default authApi;
