import { handleActions } from 'redux-actions';
import actions from '../actions/project';

export const initialState = {
  isFetching: false,
  error: '',
  items: [],
  item: {},
  pages: null,
};

export default handleActions(
  {
    // get projects
    [actions.getProjectList.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
    }),

    [actions.getProjectList.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
      pages: payload.pages,
      totalItems: payload.totalItems,
    }),

    [actions.getProjectList.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),
  },
  initialState
);
