import { handleActions } from 'redux-actions';
import actions from '../actions/cart';

export const initialState = {
  isFetching: false,
  error: '',
  items: [],
  customerRequest: '',
};

export default handleActions(
  {
    // add product to cart
    [actions.addToCart.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
    }),

    [actions.addToCart.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
    }),

    [actions.addToCart.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // set cart data
    [actions.setCartData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
    }),

    [actions.setCartData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
    }),

    [actions.setCartData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // reset cart data
    [actions.resetCartData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
    }),

    [actions.resetCartData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
    }),

    [actions.resetCartData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // delete cart itemm
    [actions.deleteCartItem.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
    }),

    [actions.deleteCartItem.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
    }),

    [actions.deleteCartItem.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // get all products from cart by manager.
    [actions.getAllProductsFromCartByManager.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
    }),

    [actions.getAllProductsFromCartByManager.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
    }),

    [actions.getAllProductsFromCartByManager.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // addDataToItems
    [actions.addDataToItems.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
    }),

    [actions.addDataToItems.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
    }),

    [actions.addDataToItems.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // addCustomerRequest
    [actions.addCustomerRequest.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      customerRequest: '',
    }),

    [actions.addCustomerRequest.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      customerRequest: payload,
    }),

    [actions.addCustomerRequest.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),
  },
  initialState
);
