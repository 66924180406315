import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { Breadcrumb, Icon, Button, Panel, Modal, PanelGroup, Loader } from 'rsuite';
import { Link, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getOffersByCustomerId } from '../redux/actions/offer';
/* import { ReactComponent as CheckIcon } from '../assets/svg/check_unloading.svg'; */
import NoImage from '../assets/img/no_image.png';
import { formatPrice } from '../utils';
import { declineAllOffersByOrderId } from '../redux/actions/order';
import NotFound from './NotFound';

function OfferItem({ offer, supplierName, supplierNumber, supplierNameHeight }) {
  const [viewProducts, setViewProducts] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const deliveryDate = offer?.deliveryDetail?.deliveryDate?.split('-');
  const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
  const deliveryTimeRange = offer?.deliveryDetail?.deliveryTimeRange;

  const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
    `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
    t('page.supplier_orders.during_the_working_day');

  const dateTime = `${deliveryDateData} ${deliveryTime}`;
  const deliveryText = offer?.deliveryDetail?.withDelay ? t('page.offers_list.delivery_with_delay.delivery_with_delay') :
    t('page.offers_list.delivery_expected');

  const productsView = viewProducts ? t('page.offer.hide_products') : t('page.offer.view_products');

  const chooseOffer = (orderId) => {
    history.push(`/customer/offer_confirmation/${orderId}?${supplierNumber}`);
  };


  return (
    <Panel bordered className={offer?.isBest ? 'border_red' : ''}>

      {offer?.isBest && (
        <div className="best_offer">
          <span>{t('page.offers_list.best_offer')}</span>
        </div>
      )}

      <div className="supplier_name" style={{minHeight: `${supplierNameHeight}px`}}>
        <p className="header">{supplierName} {supplierNumber} <span>(#{offer.offerId})</span></p>
        <p>{
          deliveryTime ? (
            <>
              <spna>{deliveryText}</spna>
              <br/>
              <spna>{dateTime}</spna>
            </>
          ) :
            `${t('page.offers_list.delivery_time')} ${t('page.offers_list.not_specified')}`
        }</p>
      </div>

      <div className="tag_box">
        {offer?.deliveryDetail?.withDelay && <p><span className="with_delay"> {t('page.offers_list.with_delay')}</span></p>}
        {offer?.isPartial && <p><span className="partial_order">{t('page.offers_list.partial_order')}</span></p>}
      </div>

      <div className="grey_box">
        <p>{t('page.offers_list.order_filling')}</p>
        <p className="price"><span>{t('page.order.of_products',
          {
            num1: offer?.products?.length,
            num2: offer?.products?.length + offer?.outOfStockProducts?.length,
          })}</span></p>
        <div className="toggle_hide_view">
          <Button appearance="link" onClick={() => setViewProducts(!viewProducts)}>
            <span className="flex">
              <span>{productsView}</span>
              <span>
                <i className={`rs-icon ${viewProducts ? 'open' : 'close'}`} />
              </span>
            </span>
          </Button>
        </div>
      </div>

      <div className="price_box">
        {/* <ul>
          <li><p>{t('page.offers_list.unloading')}</p></li>
          <li><p><span className="ltr">{offer.serviceUnloading ? <CheckIcon /> : '-'}</span></p></li>
        </ul> */}

        <ul>
          <li><p>{t('page.offers_list.price_offer')}</p></li>
          <li><p><span className="ltr">₪ {formatPrice(offer?.offerPrice || 0)}</span></p></li>
        </ul>

        <ul>
          <li><p className="relative_for_badge">{t('page.offers_list.discount')} {!!offer?.discountPercentage && <span className="discount_badge"><span className="ltr">-{offer?.discountPercentage}%</span></span>}</p></li>
          <li><p><span className="ltr">{!!offer?.discount && '-'} ₪ {formatPrice(offer?.discount || 0)}</span></p></li>
        </ul>

        <ul>
          <li><p>{t('page.offers_list.delivery_cost')}</p></li>
          <li><p><span className="ltr">₪ {formatPrice(offer?.deliveryDetail?.deliveryCost || 0)}</span></p></li>
        </ul>

        <ul className="total">
          <li><p>{t('page.offers_list.order_total')}</p></li>
          <li><p><span className="ltr">₪ {formatPrice(offer?.orderTotal || 0)}</span></p></li>
        </ul>
      </div>

      {offer?.verifyStatus === 'VERIFIED' ? (
        <Button appearance="primary" onClick={() => chooseOffer(offer?.offerId)}><span>{t('page.offers_list.choose_offer')}</span></Button>
      ) : (
        <Button appearance="primary" disabled><span>{t('page.offers_list.unverified_offer')}</span></Button>
      )}


      {viewProducts && (
        <div className="offer-box">

          <PanelGroup accordion>

            {offer?.inStockProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.in_stock')} <span className="count">({offer?.inStockProducts.length})</span></p>} defaultExpanded>

              <div className="products-list">
                <ListOrdered orderedProducts={offer?.inStockProducts} />
              </div>

            </Panel>)}
            {offer?.alternativeProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.pay_order.alternative_products')} <span className="count">({offer?.alternativeProducts.length})</span></p>} defaultExpanded>

              <div className="products-list">
                <ListOrdered orderedProducts={offer?.alternativeProducts} />
              </div>

            </Panel>)}
            {offer?.outOfStockProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.pay_order.out_of_stock')} <span className="count">({offer?.outOfStockProducts.length})</span></p>} defaultExpanded>

              <div className="products-list">
                <ListOrdered orderedProducts={offer?.outOfStockProducts} />
              </div>
            </Panel>)}

          </PanelGroup>

        </div>
      )}
    </Panel>
  );
}

OfferItem.propTypes = {
  offer: PropTypes.object.isRequired,
  supplierName: PropTypes.string.isRequired,
  supplierNumber: PropTypes.number.isRequired,
  group: PropTypes.object,
  supplierNameHeight: PropTypes.number,
};

const OfferItemGroup = forwardRef(function OfferItemGroup({ offer, supplierName, supplierNumber }, supplierNameRef) {
  const [viewProducts, setViewProducts] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const productsView = viewProducts ? t('page.offer.hide_products') : t('page.offer.view_products');

  const offersIdList = offer?.offers.map(({offerId}) => offerId).join();
  const deliveryList = offer?.offers.map(({deliveryDetail}) => deliveryDetail);
  const deliveryWithDelay = offer?.offers.map(({deliveryDetail}) => deliveryDetail.withDelay);
  const countOfProductsArray = offer?.offers.map((el) => el.products?.length);
  const countOfProducts = countOfProductsArray.reduce(function(a, b) {
    return a + b;
  }, 0);
  const verifyStatusArray = offer?.offers.map(({verifyStatus}) => verifyStatus === 'VERIFIED');
  const isPartial = offer?.offers[0].orderProductQuantity > countOfProducts;

  const chooseOffer = () => {
    history.push(`/customer/offer_confirmation/${offersIdList}?${supplierNumber}`);
  };
  return (
    <Panel bordered className={offer?.offers[0]?.isBest ? 'border_red' : ''}>
      {offer?.offers[0]?.isBest && (
        <div className="best_offer">
          <span>{t('page.offers_list.best_offer')}</span>
        </div>
      )}

      <div className="supplier_name" ref={supplierNameRef}>
        <p className="header">{supplierName} {supplierNumber} <span>(#{offersIdList})</span></p>
        {deliveryList.map((item, index) => {
          const deliveryDate = item?.deliveryDate?.split('-');
          const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
          const deliveryTimeRange = item?.deliveryTimeRange;

          const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
            `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
            t('page.supplier_orders.during_the_working_day');

          const dateTime = `${deliveryDateData} ${deliveryTime}`;
          const deliveryText = item?.withDelay ? t('page.offers_list.delivery_with_delay.delivery_with_delay') :
            t('page.offers_list.delivery_expected');

          return (
            <p key={index}>{t('page.order.order_list_item.delivery')} {index + 1}: {
              deliveryTime ? (
                <>
                  <spna>{deliveryText}</spna>
                  <br/>
                  <spna>{dateTime}</spna>
                </>
              ) :
                `${t('page.offers_list.delivery_time')} ${t('page.offers_list.not_specified')}`
            }</p>
          );
        })}
      </div>

      <div className="tag_box">
        {deliveryWithDelay.find((e) => e === true) && <p><span className="with_delay">{t('page.offers_list.with_delay')}</span></p>}
        {isPartial && <p><span className="partial_order">{t('page.offers_list.partial_order')}</span></p>}
      </div>

      <div className="grey_box">
        <p>{t('page.offers_list.order_filling')}</p>
        <p className="price"><span>{t('page.order.of_products',
          {
            num1: countOfProducts,
            num2: offer?.offers[0]?.products?.length + offer?.offers[0]?.outOfStockProducts?.length,
          })}</span></p>
        <div className="toggle_hide_view">
          <Button appearance="link" onClick={() => setViewProducts(!viewProducts)}>
            <span className="flex">
              <span>{productsView}</span>
              <span>
                <i className={`rs-icon ${viewProducts ? 'open' : 'close'}`} />
              </span>
            </span>
          </Button>
        </div>
      </div>

      <div className="price_box">

        <ul>
          <li><p>{t('page.offers_list.price_offer')}</p></li>
          <li><p><span className="ltr">₪ {formatPrice(offer?.offerPrice || 0)}</span></p></li>
        </ul>

        <ul>
          <li><p className="relative_for_badge">{t('page.offers_list.discount')} {!!offer?.discountPercentage && <span className="discount_badge"><span className="ltr">-{offer?.discountPercentage}%</span></span>}</p></li>
          <li><p><span className="ltr">{!!offer?.discount && '-'} ₪ {formatPrice(offer?.discount || 0)}</span></p></li>
        </ul>

        <ul>
          <li><p>{t('page.offers_list.delivery_cost')}</p></li>
          <li><p><span className="ltr">₪ {formatPrice(offer?.deliveryCost || 0)}</span></p></li>
        </ul>

        <ul className="total">
          <li><p>{t('page.offers_list.order_total')}</p></li>
          <li><p><span className="ltr">₪ {formatPrice(offer?.orderTotal || 0)}</span></p></li>
        </ul>
      </div>

      {verifyStatusArray.every((e) => e === true) ? (
        <Button appearance="primary" onClick={() => chooseOffer()}><span>{t('page.offers_list.choose_offer')}</span></Button>
      ) : (
        <Button appearance="primary" disabled><span>{t('page.offers_list.unverified_offer')}</span></Button>
      )}


      {viewProducts && (
        <div className="offer-box">

          <PanelGroup accordion>

            {offer?.offers.map((item, index) => {
              const date = item.deliveryDetail.deliveryDate?.split('-');
              const deliveryTime = !item.deliveryDetail.deliveryTimeRange?.full ?
                <span>
                  {item.deliveryDetail.deliveryTimeRange?.start?.slice(0, 5)}-
                  {item.deliveryDetail.deliveryTimeRange?.end?.slice(0, 5)}</span> :
                'during the working day';

              return (
                <React.Fragment key={index}>
                  <p>{t('page.order.order_list_item.delivery')} {index + 1}: {''}
                    <span>{date[2]}/{date[1]}/{date[0]}, {deliveryTime}</span>
                  </p>
                  <br />

                  {item?.inStockProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.in_stock')} <span className="count">({item?.inStockProducts.length})</span></p>} defaultExpanded>

                    <div className="products-list">
                      <ListOrdered orderedProducts={item?.inStockProducts} />
                    </div>

                  </Panel>)}
                  {item?.alternativeProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.pay_order.alternative_products')} <span className="count">({item?.alternativeProducts.length})</span></p>} defaultExpanded>

                    <div className="products-list">
                      <ListOrdered orderedProducts={item?.alternativeProducts} />
                    </div>

                  </Panel>)}
                  {item?.outOfStockProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.pay_order.out_of_stock')} <span className="count">({item?.outOfStockProducts.length})</span></p>} defaultExpanded>

                    <div className="products-list">
                      <ListOrdered orderedProducts={item?.outOfStockProducts} />
                    </div>
                  </Panel>)}
                </React.Fragment>
              );
            })}

          </PanelGroup>

        </div>
      )}
    </Panel>
  );
});


OfferItemGroup.propTypes = {
  offer: PropTypes.object.isRequired,
  supplierName: PropTypes.string.isRequired,
  supplierNumber: PropTypes.number.isRequired,
};

function OffersList() {
  const [showDeclineAllOffers, setShowDeclineAllOffers] = useState(false);
  const [supplierNameHeight, setSupplierNameHeight] = useState(0);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { items, group, isFetching, error } = useSelector((state) => state.offer);
  const { isOrdersUpdated } = useSelector((state) => state.order);
  let numberOfSupplierName = 1;
  const mounted = useRef(true);
  const supplierNameRef = useRef(null);

  useEffect(() => {
    setSupplierNameHeight(supplierNameRef?.current?.clientHeight ? supplierNameRef?.current?.clientHeight : 0);
  }, [isFetching]);

  useEffect(() => {
    dispatch(getOffersByCustomerId(orderId));
  }, []);

  useEffect(() => {
    if (isFetching) {
      mounted.current = false;
    }
  }, [isFetching]);

  useEffect(() => {
    if (mounted.current) {
      return;
    }

    if (isOrdersUpdated) {
      history.push('/customer/orders');
      return;
    }
  }, [isOrdersUpdated]);

  useEffect(() => {
    if (mounted.current) {
      return;
    }

    if (error?.message === 'authentication fail') {
      history.push('/');
      return;
    }
    if (error?.message === 'error.order.status.incorrect') {
      history.push('/customer/orders');
      return;
    }
  }, [error]);

  const handleDeclineAllOffers = () => {
    setShowDeclineAllOffers(!showDeclineAllOffers);
  };

  const bestOfferforNotGroup = items.find((item) => item?.isBest);

  if (!isFetching && (Object.keys(items).length === 0 && group?.offers?.length === 0)) {
    return <NotFound/>;
  }

  return (
    <>
      {isFetching ? (
        <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'}/>
      ) : (
        <div className="offers_list">
          <div className="container">

            <div className="breadcrumb-list">
              <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                <Breadcrumb.Item componentClass={Link} to="/customer/orders">
                  <span>{t('page.order.orders')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <span>{t('page.offers_list.offers_for_order')} #{orderId}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Panel bordered className="offer_number">
              <div className="flex">
                <div>
                  <h1>{t('page.offers_list.offers_for_order')} #{orderId}</h1>
                  <p style={{color: '#E02443'}}>
                    <span> {t('page.offers_list.possible_change_date')} </span>
                    {!!items.length ? <span>
                      {new Date(items[0]?.validDate)?.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}{', '}
                      {(items[0]?.validTime)?.slice(0, -3)}
                    </span> : (group && Object.keys(group).length !== 0 && <span>
                      {new Date(group?.offers[0]?.validDate)?.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}{', '}
                      {(group?.offers[0]?.validTime)?.slice(0, -3)}
                    </span>)}
                  </p>
                  <span> {t('page.offers_list.possible_change')}</span>
                </div>
                <div>
                  <Button appearance="ghost" onClick={handleDeclineAllOffers}>
                    <span> {t('page.offers_list.decline_all_offers')}</span>
                  </Button>
                </div>
              </div>
            </Panel>
            <div className="offers_list_flex">
              {group && group?.offers && group?.offers[0]?.isBest && Object.keys(group).length !== 0 ?
                <div>
                  <OfferItemGroup ref={supplierNameRef} offer={group} supplierName={t('page.offers_list.supplier')} supplierNumber={group?.offers && group?.offers[0].isBest ? numberOfSupplierName : ++numberOfSupplierName } />
                </div> :
                !!items.length && bestOfferforNotGroup &&
                <div key={bestOfferforNotGroup?.offerId}>
                  <OfferItem key={bestOfferforNotGroup?.offerId} supplierNameHeight={supplierNameHeight} group={group} offer={bestOfferforNotGroup} supplierName={t('page.offers_list.supplier')} supplierNumber={bestOfferforNotGroup?.isBest ? numberOfSupplierName : ++numberOfSupplierName } />
                </div>
              }
              {!!items.length && items.filter((item) => !item?.isBest).map((offer) => {
                return (
                  <div key={offer?.offerId}>
                    <OfferItem key={offer?.offerId} supplierNameHeight={supplierNameHeight} group={group} offer={offer} supplierName={t('page.offers_list.supplier')} supplierNumber={offer?.isBest ? numberOfSupplierName : ++numberOfSupplierName } />
                  </div>
                );
              })}
              {group && group?.offers && !group?.offers[0]?.isBest && Object.keys(group).length !== 0 ?
                <div>
                  <OfferItemGroup ref={supplierNameRef} offer={group} supplierName={t('page.offers_list.supplier')} supplierNumber={group?.offers && group?.offers[0].isBest ? numberOfSupplierName : ++numberOfSupplierName } />
                </div> : ''}
            </div>

          </div>
        </div>
      )}

      {showDeclineAllOffers && <DeclineAllOffers
        showDeclineAllOffers={showDeclineAllOffers}
        setShowDeclineAllOffers={setShowDeclineAllOffers}
      />}
    </>
  );
}
function DeclineAllOffers({ showDeclineAllOffers, setShowDeclineAllOffers }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const handlerDeclineAll = () => {
    setShowDeclineAllOffers(!showDeclineAllOffers);
    dispatch(declineAllOffersByOrderId(orderId));
  };

  const handlerModalClose = () => {
    setShowDeclineAllOffers(!showDeclineAllOffers);
  };

  return (
    <Modal
      className="decline-all-offers"
      overflow={false}
      show={showDeclineAllOffers}
      onHide={handlerModalClose}
      keyboard={true}
      autoFocus={true}>
      <Modal.Header>
        <p>{t('page.offers_list.delivery_with_delay.text.are_you_sure')}</p>
        <p>{t('page.offers_list.delivery_with_delay.text.all_of_suggested')}</p>
      </Modal.Header>
      <Modal.Body >
        <p>
          {t('page.offers_list.delivery_with_delay.text.if_you_decline_this_RFP')}
        </p>
        <div className="flex-box">
          <div><Button appearance="primary" onClick={handlerDeclineAll}><span>{t('page.offers_list.decline_all')}</span></Button></div>
          <div><Button appearance="ghost" onClick={handlerModalClose}><span>{t('page.offers_list.cancel')}</span></Button></div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

DeclineAllOffers.propTypes = {
  showDeclineAllOffers: PropTypes.bool.isRequired,
  setShowDeclineAllOffers: PropTypes.func.isRequired,
};

const ListOrdered = ({ orderedProducts }) => (
  <ul>
    {orderedProducts.map((product, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={index}>
        <OrderedProduct product={product} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  orderedProducts: PropTypes.instanceOf(Array).isRequired,
};

const OrderedProduct = ({ product }) => {
  const {
    catalogNumber,
    productName,
    totalPrice,
    price,
    orderQuantity,
    quantity,
    pictureFileName,
    outOfStock,
    alternativeProduct,
    totalMinPrice,
    totalMaxPrice,
    minPrice,
    maxPrice,
  } = product;
  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${outOfStock ? ' opacity' : ''}`}>
        <div>
          <div className="products-img">
            <img src={pictureFileName || NoImage} alt={productName} />
            {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
          </div>
          <p className="quantity">
            <span>
              x
              {quantity ? quantity : orderQuantity}
            </span>
          </p>
        </div>
        <div>
          <p><span className="product_number">{catalogNumber}</span></p>
          <p className="description">{productName}</p>
          {(!outOfStock && price) && (
            <>
              <p className="price-all"><span className="ltr">₪ {formatPrice(totalPrice || 0)}</span></p>
              <p><span className="ltr">₪ {formatPrice(price)}/{t('page.offers_list.item')}</span></p>
            </>
          )}
          {(totalMinPrice !== undefined || totalMaxPrice !== undefined) && (
            <>
              <p className="price-all"><span className="ltr">₪ {formatPrice(totalMinPrice || 0)} - ₪ {formatPrice(totalMaxPrice || 0)}</span></p>
              <p><span className="ltr">₪ {formatPrice(minPrice || 0)} - ₪ {formatPrice(maxPrice || 0)}/{t('page.offers_list.item')}</span></p>
            </>
          )}
        </div>
      </div>
      {quantity && quantity < orderQuantity && (
        <div className="warning_box">
          <p>{t('page.order.order_list_item.pay_order.info.this_product_available')}</p>
          <p><span className="red">{quantity}</span>/{orderQuantity} {t('page.order.order_list_item.pay_order.info.items_are_available')}</p>
        </div>
      )}
      {alternativeProduct && (
        <div className="replacement">
          <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
          <OrderedProduct product={alternativeProduct} />
        </div>
      )}
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

export default OffersList;
