import { createActions } from 'redux-actions';
import { addToCollection, updateCollection } from '../../helpers/collections';
import { closeNotification, showNotification } from './notification';
import cartApi from '../../api/cart';
import { putCartItemToCustomer } from './auth/smsCodeVerification';
import store from '../store';

const actions = createActions({
  addToCart: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  setCartData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetCartData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  deleteCartItem: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getAllProductsFromCartByManager: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  addDataToItems: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  addCustomerRequest: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

const setCartItemsToLocalStorage = (data) => {
  localStorage.setItem('userCart', JSON.stringify(data));
};

const setCustomerRequestToLocalStorage = (data) => {
  localStorage.setItem('customerRequest', JSON.stringify(data));
};
export const addCustomerRequest = (customerRequestItem) => async (dispatch) => {
  dispatch(actions.addCustomerRequest.request());
  try {
    dispatch(actions.addCustomerRequest.success(customerRequestItem));
    setCustomerRequestToLocalStorage(customerRequestItem);
  } catch (e) {
    dispatch(actions.addCustomerRequest.error({ error: e }));
    dispatch(showNotification(e, 'error', e));
  }
};

export const addToCart = (title, type, component, product, count) => async (dispatch, getState) => {
  const { cart: { items } } = getState();
  dispatch(actions.addToCart.request());
  const existingProduct = items.find((item) => item.productId === product.productId);

  const userStore = store.getState().auth.user;
  const isUser = Object.keys(userStore).length !== 0;

  try {
    delete product?.count;
    const newProduct = {
      count,
      ...product,
      isReadyForAlternatives: true,
    };

    const updatedProduct = {
      ...existingProduct,
      count: existingProduct?.count + count,
    };

    if (existingProduct) {
      const list = updateCollection(items, updatedProduct);
      dispatch(actions.addToCart.success({ items: list }));
      setCartItemsToLocalStorage(list);
      isUser && dispatch(putCartItemToCustomer(list));
    } else {
      const list = addToCollection(items, newProduct);
      dispatch(actions.addToCart.success({ items: list }));
      setCartItemsToLocalStorage(list);
      isUser && dispatch(putCartItemToCustomer(list));
    }
    dispatch(showNotification(title, type, component));
  } catch (e) {
    dispatch(actions.addToCart.error({ error: e }));
    dispatch(showNotification(e, 'error', e));
  }
};

export const setCartData = (id, count, type, isReadyForAlternatives) => async (dispatch, getState) => {
  const { cart: { items: cartProducts } } = getState();
  const userStore = store.getState().auth.user;
  const isUser = Object.keys(userStore).length !== 0;
  dispatch(actions.setCartData.request());
  try {
    if (type === 'checkbox') {
      const prodIndex = cartProducts.findIndex((prod) => prod.productId === id);
      cartProducts[prodIndex].isReadyForAlternatives = isReadyForAlternatives;
      const list = updateCollection(cartProducts, cartProducts[prodIndex]);
      dispatch(actions.setCartData.success({
        items: list,
      }));
      setCartItemsToLocalStorage(list);
      isUser && dispatch(putCartItemToCustomer(list));
    } else if (type === 'increment') {
      const prodIndex = cartProducts.findIndex((prod) => prod.productId === id);
      cartProducts[prodIndex].count = cartProducts[prodIndex].count + 1;
      const list = updateCollection(cartProducts, cartProducts[prodIndex]);
      dispatch(actions.setCartData.success({
        items: list,
      }));
      setCartItemsToLocalStorage(list);
      isUser && dispatch(putCartItemToCustomer(list));
    } else if (type === 'decrement') {
      const prodIndex = cartProducts.findIndex((prod) => prod.productId === id);
      cartProducts[prodIndex].count = cartProducts[prodIndex].count - 1;
      const list = updateCollection(cartProducts, cartProducts[prodIndex]);
      dispatch(actions.setCartData.success({
        items: list,
      }));
      setCartItemsToLocalStorage(list);
      isUser && dispatch(putCartItemToCustomer(list));
    } else {
      const prodIndex = cartProducts.findIndex((prod) => prod.productId === id);
      cartProducts[prodIndex].count = count;
      const list = updateCollection(cartProducts, cartProducts[prodIndex]);
      dispatch(actions.setCartData.success({
        items: list,
      }));
      setCartItemsToLocalStorage(list);
      isUser && dispatch(putCartItemToCustomer(list));
    }
  } catch (e) {
    dispatch(actions.setCartData.error({ error: e }));
  }
};

export const resetCartData = () => async (dispatch) => {
  dispatch(actions.resetCartData.request());
  try {
    dispatch(actions.resetCartData.success({ items: []}));
    setCartItemsToLocalStorage('');
  } catch (e) {
    dispatch(actions.resetCartData.error({ error: e }));
  }
};

export const deleteCartItem = (id) => async (dispatch, getState) => {
  const { cart: { items: cartProducts } } = getState();
  const userStore = store.getState().auth.user;
  const isUser = Object.keys(userStore).length !== 0;
  dispatch(actions.deleteCartItem.request());
  try {
    const list = cartProducts.filter((i) => i.productId !== id);
    dispatch(actions.deleteCartItem.success({ items: list }));
    dispatch(closeNotification());
    if (list.length) {
      setCartItemsToLocalStorage(list);
      isUser && dispatch(putCartItemToCustomer(list));
    } else {
      setCartItemsToLocalStorage('');
      isUser && dispatch(putCartItemToCustomer(''));
    }
  } catch (e) {
    dispatch(actions.deleteCartItem.error({ error: e }));
  }
};

export const getAllProductsFromCartByManager = () => async (dispatch) => {
  dispatch(actions.getAllProductsFromCartByManager.request());
  try {
    const response = await cartApi.getAllProductsFromCartByManager();
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.getAllProductsFromCartByManager.success({ items: response?.data.map((i) => {
      return { ...i, count: i.quantity};
    })}));
  } catch (e) {
    dispatch(actions.getAllProductsFromCartByManager.error({ error: e }));
  }
};

export const addDataToItems = (data) => async (dispatch) => {
  const checkEmptyString = (text) => {
    if (!!text) {
      return JSON.parse(data);
    } else return [];
  };
  const stringToObject = typeof data === 'string' ? checkEmptyString(data) : data;
  dispatch(actions.addDataToItems.request());
  try {
    dispatch(actions.addDataToItems.success({ items: stringToObject }));
  } catch (e) {
    dispatch(actions.addDataToItems.error({ error: e }));
  }
};
