import { createActions } from 'redux-actions';

const actions = createActions({
  loadAllPath: {
    success: (x) => x,
  },
});

export default actions;

export const loadAllPath = (path) => async (dispatch) => {
  const result = [];
  const pathElements = path.split('/');
  pathElements.forEach((el, i) => {
    if (i > 0 && el !== 'product') {
      result.push({ name: el, path: pathElements.slice(0, i + 1).join('/') });
    }
    if (el === 'product') {
      const prevEl = result[result.length - 1];
      prevEl.path = `${prevEl.path}/product`;
    }
  });
  dispatch(actions.loadAllPath.success({
    pathElements: result,
  }));
};

