import { put } from './';

const addTimeConstraints = (data) => put('/order/timeConstraint', data);
const addProject = (data) => put('/order/project', data);
const addAditionalServices = (data) => put('/order/additionalService', data);

const checkoutApi = {
  addTimeConstraints,
  addProject,
  addAditionalServices,
};

export default checkoutApi;
