import React from 'react';
import {
  Container,
  Header,
  Content,
  FlexboxGrid,
  Icon,
} from 'rsuite';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function UnsupportedBrowser() {
  const { t } = useTranslation();
  return (
    <div className="full-height-page">
      <Container>
        <Header>
          <div className="site-header isIE">
            <div className="info-header">
              <div className="container">
                <FlexboxGrid>
                  <FlexboxGrid.Item>
                    <div className="header-logo">
                      <Link to="/">
                        <span>logo</span>
                      </Link>
                    </div>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item>
                    <div className="contact-info">
                      <a href="tel:0723970146">
                        <span className="flex">
                          <span><Icon icon="mobile" /></span>
                          <span className="title"><span className="ltr">072 397-0146</span></span>
                        </span>
                      </a>
                    </div>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>
            </div>
          </div>
        </Header>
        <Content>
          <div className="main_container">
            <div className="unsupported_browser">
              <div className="container">
                <div className="ie_table">
                  <div>
                    <div>
                      <div className="unsupported_browser_box">
                        <h1>{t('page.unsupported_browser.unsupported_browser')}</h1>
                        <p>{t('page.unsupported_browser.you_are_using_web')}</p>
                        <p>{t('page.unsupported_browser.use_one_of_their_options')}</p>
                        <ul>
                          <li>
                            <a className="chrome" to="https://www.google.com/chrome/" target="_blank" rel="noreferrer"><span>Google Chrome</span></a>
                          </li>
                          <li>
                            <a className="firefox" to="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noreferrer"><span>Mozilla Firefox</span></a>
                          </li>
                          <li>
                            <a className="safari" to="https://support.apple.com/downloads/safari" target="_blank" rel="noreferrer"><span>Safari</span></a>
                          </li>
                          <li>
                            <a className="edge" to="https://www.microsoft.com/en-us/edge" target="_blank" rel="noreferrer"><span>Microsoft Edge</span></a>
                          </li>
                          <li>
                            <a className="opera" to="https://www.opera.com/download" target="_blank" rel="noreferrer"><span>Opera</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Container>
    </div>
  );
}

export default UnsupportedBrowser;
