import React, {useState, useEffect} from 'react';
import {
  Container,
  Header,
  Content,
  Footer,
  Loader,
} from 'rsuite';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import SiteFooter from './components/SiteFooter';
import SiteHeader from './components/SiteHeader';
import Authentication from './components/Auth/Authentication';
import SignInContextProvider from './context/signInContext';
import CartContextProvider from './context/cartContext';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser } from './redux/actions/auth/signIn';
import SiteContent from './components/SiteContent';


function App() {
  const [showCookieBar, setShowCookieBar] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user && Object.keys(user).length === 0) {
      dispatch(setCurrentUser(() => {
        setLoading(true);
      }));
    }
  }, []);

  return (
    <Router>
      <div className="full-height-page">
        <CartContextProvider>
          <SignInContextProvider>
            {loading ?<Container>
              <Header>
                <SiteHeader />
              </Header>
              <Content>
                <SiteContent />
              </Content>
              <Authentication />
              <Footer>
                <SiteFooter />
              </Footer>
            </Container> :
              <Loader backdrop center size="sm" />}
          </SignInContextProvider>
        </CartContextProvider>
      </div>
      {showCookieBar && (
        <div className="cookie-consent">
          <div className="container">
            <CookieConsent
              location="bottom"
              buttonText={i18n.language === 'heb' ? 'אשר' : 'Allow '}
              expires={150}
              disableStyles
              buttonClasses="confirm-button"
              onAccept={() => {
                setShowCookieBar(true);
              }}
            >
              <p>
                <span>
                  {t('cookie')}
                </span>
              </p>
            </CookieConsent>
          </div>
        </div>
      )}
    </Router>
  );
}

export default App;
