import { Notification } from 'rsuite';

function Notice(notificationTitle, type, Component) {
  if (type === 'close' || type === 'closeAll') {
    Notification[type]();
  } else {
    Notification[type]({
      title: notificationTitle,
      duration: 4000,
      description: Component,
    });
  }
}

export default Notice;
