import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormControl,
  Button,
  FormGroup,
  ControlLabel,
  Modal,
  Schema,
} from 'rsuite';

import { useHistory } from 'react-router-dom';
import { formatPhoneNumber } from '../../../helpers/phoneNumberHelpers';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { postLoginRestore } from '../../../redux/actions/auth/signIn';

const { StringType } = Schema.Types;
const model = (t) => Schema.Model({
  phone: StringType()
    .isRequired(t('errors.this_field_is_required'))
    .pattern(/^05\d\-\d{7}$/, t('errors.phoneInvalid')),
});

const PRODUCT_CATALOG_LINK = '/';

const LostAccessToPhoneNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showConfirmRequest, setShowConfirmRequest] = useState(false);
  const [lostAccessErrors, setLostAccessErrors] = useState({ phone: true });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlePhoneNumberChange = (value) => {
    const formattedPhoneNumber = formatPhoneNumber(value);
    setPhoneNumber(formattedPhoneNumber);
  };

  const handleConfirmRequest = () => {
    const phone = phoneNumber?.split('').filter((item) => item !== '-').join('');
    dispatch(postLoginRestore({phone: phone}, () => {
      setShowConfirmRequest(!showConfirmRequest);
    }));
  };

  return (
    <div className="lost-access-phone">
      <div>
        <Form
          fluid
          model={model(t)}
          onChange={(formValue) => setPhoneNumber({ ...formValue })}
          onCheck={(formError) => setLostAccessErrors({ ...formError })}
          checkTrigger="blur"
        >
          <div className="title-box">
            <p>
              <div>{t('auth.sign_in.if_you_lost_access_to_your_phone_number')}</div>
              <div>{t('auth.sign_in.please_use_one_of_the_options')}</div>
            </p>
            <p>
              <span>{t('auth.sign_in.Leave your alternative phone number and we')}</span>
              <span> {t('auth.sign_in.will_contact_you_shortly')}</span>
            </p>
          </div>
          <FormGroup>
            <ControlLabel>{t('auth.sign_in.phone_number')}</ControlLabel>
            <FormControl
              name="phone"
              type="text"
              placeholder="05N-XXXXXXX"
              onChange={(val) => handlePhoneNumberChange(val)}
              errorMessage={null}
              value={phoneNumber}
            />
            {lostAccessErrors.phone && <p className="registration_error">{lostAccessErrors.phone}</p>}
          </FormGroup>
          <FormGroup>
            <div><Button
              block appearance="primary"
              type="submit"
              onClick={handleConfirmRequest}
              disabled={phoneNumber.length < 11}
            >
              <span>{t('auth.sign_in.contact_me')}</span></Button></div>
          </FormGroup>
          {showConfirmRequest && <ConfirmRequestToLostAccess
            showConfirmRequest={showConfirmRequest}
            setShowConfirmRequest={setShowConfirmRequest}
            setPhoneNumber={setPhoneNumber} />}

          <div className="separate-block">
            <span>{t('auth.sign_in.or')}</span>
          </div>
          <div className="contacts">
            <p>{t('auth.sign_in.write_us_via')} <a href="https://wa.me/972524840854" target="_blank" rel="noreferrer"><span> {t('auth.sign_in.Whats‐up')}</span></a></p>
            <p>{t('auth.sign_in.Call to our support')} <a href="tel:072-3970146"><span>072-3970146</span></a></p>
          </div>
        </Form>
      </div>
    </div>
  );
};

function ConfirmRequestToLostAccess({ showConfirmRequest, setShowConfirmRequest, setPhoneNumber }) {
  const history = useHistory();
  const { t } = useTranslation();
  const handlerModalClose = () => {
    setShowConfirmRequest(!showConfirmRequest);
    setPhoneNumber('');
  };
  const handlerGoToCatalog = () => {
    history.push(PRODUCT_CATALOG_LINK);
  };
  return (
    <div>
      <Modal
        className="lost-access-phone-confirm-request"
        overflow={false}
        show={showConfirmRequest}
        onHide={handlerModalClose}
        keyboard={true}
        autoFocus={true}>
        <Modal.Header>
          <p>{t('auth.sign_in.your_request_to_restore_access_to_your')}</p>
        </Modal.Header>
        <Modal.Body >
          <p>{t('auth.sign_in.konebone_will_contact_you_to_clarify_your')}</p>
          <p>{t('auth.sign_in.account_information_and_restore_access')}</p>
          <div><Button appearance="primary" onClick={handlerGoToCatalog}><span>{t('auth.sign_in.ok_thank_you')}</span></Button></div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

ConfirmRequestToLostAccess.propTypes = {
  showConfirmRequest: PropTypes.bool.isRequired,
  setShowConfirmRequest: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
};

export default LostAccessToPhoneNumber;
