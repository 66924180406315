import actions from './constants';
import authApi from '../../../api/auth';
import { addDataToItems } from '../cart';

export const confirmRegistrationSmsCode = (code) => async (dispatch, getState) => {
  const { auth: { registrationData: { phone, status }} } = getState();
  dispatch(actions.confirmRegistrationCode.request());
  try {
    const response = await authApi.confirmRegistrationVerificationCode({ code, phone, status });
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.confirmRegistrationCode.success({ user: response?.data }));
  } catch (e) {
    console.log(e.message);
    dispatch(actions.confirmRegistrationCode.error({ error: e.message, showMessage: true }));
  }
};

export const resetRegistrationUser = () => async (dispatch) => {
  dispatch(actions.resetRegistrationUser.request());
  try {
    dispatch(actions.resetRegistrationUser.success({ user: {} }));
  } catch (e) {
    dispatch(actions.resetRegistrationUser.error({ error: e.message, showMessage: true }));
  }
};

export const putCartItemToCustomer = (data) => async (dispatch) => {
  dispatch(actions.putCartItemToCustomer.request());
  try {
    const response = await authApi.putCartItemToCustomer(JSON.stringify(data));
    if (response.statusCode !== 200) {
      throw new Error(response.message?.message);
    } else {
      const currentUser = response?.data;
      const userForLocalStorage = Object.assign({}, {
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        entityType: currentUser?.entityType,
        roles: currentUser?.roles,
        managerType: currentUser?.managerType,
        status: currentUser?.status,
        phone: currentUser?.phone,
        cart: currentUser?.cart,
      });
      dispatch(actions.putCartItemToCustomer.success({ user: userForLocalStorage }));
    }
  } catch (e) {
    dispatch(actions.putCartItemToCustomer.error({ error: e }));
  }
};

export const confirmSignInSmsCode = ({ code, isRememberMeChecked }) => async (dispatch, getState) => {
  const { auth: { signInData: { phone } } } = getState();
  dispatch(actions.confirmSignInCode.request());
  try {
    const response = await authApi.confirmLoginVerificationCode(
      { code,
        phone,
        rememberMeChecked: isRememberMeChecked,
      });
    const currentUser = response?.data;
    const userForLocalStorage = Object.assign({}, {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      entityType: currentUser?.entityType,
      roles: currentUser?.roles,
      managerType: currentUser?.managerType,
      status: currentUser?.status,
      phone: currentUser?.phone,
      cart: currentUser?.cart,
    });
    if (response.statusCode !== 200) {
      throw new Error(response.message.message);
    } else {
      dispatch(actions.confirmSignInCode.success({ user: userForLocalStorage }));
      if (userForLocalStorage?.entityType === 'CUSTOMER' || userForLocalStorage?.entityType === 'CUSTOMER_CANDIDATE') {
        const localStorageCartData = JSON.parse(localStorage.getItem('userCart')); // object
        if ( userForLocalStorage?.cart && !localStorageCartData.length ) {
          localStorage.setItem('userCart', userForLocalStorage?.cart);
          dispatch(addDataToItems(JSON.parse(userForLocalStorage?.cart)));
        }
        if ( userForLocalStorage?.cart && localStorageCartData.length ) {
          const changeCartToObject = JSON.parse(userForLocalStorage?.cart);
          const checkCartToObject = changeCartToObject.length ? (typeof changeCartToObject === 'string' ? JSON.parse(changeCartToObject) : changeCartToObject) : [];
          const combineResponseAndStorage = checkCartToObject.concat(localStorageCartData);
          const arrayUniqueByKey = [...new Map(combineResponseAndStorage.map(( item ) => [item['productId'], item])).values()];
          localStorage.setItem('userCart', JSON.stringify(arrayUniqueByKey));
          dispatch(addDataToItems(arrayUniqueByKey));
          dispatch(putCartItemToCustomer(arrayUniqueByKey));
        }
        if (!userForLocalStorage?.cart && localStorageCartData.length) {
          dispatch(putCartItemToCustomer(JSON.parse(localStorage.getItem('userCart'))));
        }
      }
    };
    dispatch(actions.resetIsCodeConfirmed());
  } catch (e) {
    console.log(e.message);
    dispatch(actions.confirmSignInCode.error({ error: e.message, showMessage: true }));
  }
};
