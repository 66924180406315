import React, { useContext, useEffect } from 'react';
import { Button } from 'rsuite';
// import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
import { SignInContext } from '../context/signInContext';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function NotAuthorized() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const checkSupplier = user?.entityType === 'SUPPLIER' || user?.entityType === 'SUPPLIER_CANDIDATE';

  const { location: { pathname } } = useHistory();
  const isSupplier = pathname.includes('supplier');
  const { handleIsModalOpen } = useContext(SignInContext);

  useEffect(() => {
    handleIsModalOpen(true);
    if (isSupplier !== checkSupplier && user?.entityType) {
      return (
        <Redirect to={{
          pathname: '/404',
          state: { referrer: pathname },
        }} />
      );
    }
  }, []);


  return (
    <div className="not_authorized">
      <div className="container">
        <div className="product_not_found_box">
          <div className="banner banner_404">
            <span />
          </div>
          <p className="header"> {t('page.not_authorized.title')}</p>
          <div className="button">
            <Button block type="button" onClick={() => handleIsModalOpen(true)} appearance="primary">
              <span>{t('page.not_authorized.button')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotAuthorized;
