import React, { useState, useRef, useEffect, createRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  Input,
  ControlLabel,
} from 'rsuite';
import { formatTime } from '../../utils';
import { confirmRegistrationSmsCode, confirmSignInSmsCode } from '../../redux/actions/auth/smsCodeVerification';
import { resetRegistrationError } from '../../redux/actions/auth/registration';
import { resetSignInData } from '../../redux/actions/auth/signIn';
import { resetGoToNextStep } from '../../redux/actions/auth/registration';
// import validationMessages from '../../helpers/validationMessages';
import { formatPhoneNumber } from '../../helpers/phoneNumberHelpers';
import { useTranslation } from 'react-i18next';

const inputNames = [
  'firstNum',
  'secondNum',
  'thirdNum',
  'fourthNum',
];

const INPUT_MAX_LENGTH = '1';

const EnterConfirmSmsCode = ({
  handleShowConfirmCode,
  handleIsModalOpen,
  editPhoneNumber,
  setIsShowConfirmCodeModal,
  isRegistrationPage,
  handleShowSignIn,
  handleShowRegistration,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [confirmCode, setConformCode] = useState({
    firstNum: '',
    secondNum: '',
    thirdNum: '',
    fourthNum: '',
  });
  const [codeValidationError, setCodeValidationError] = useState('');
  const [counter, setCounter] = React.useState(120);
  const inputNamesRef = useRef(inputNames.map(() => createRef()));
  const { t } = useTranslation();

  const order = history.location.pathname.includes('order');
  const onInputHandle = (val, evt, i) => {
    const { maxLength, name } = evt.target;
    if (val.length >= maxLength && i < inputNames.length - 1) {
      inputNamesRef.current[i + 1].current.focus();
    }
    setConformCode({ ...confirmCode, [name]: val });
  };

  const registrationData = useSelector((state) => state.auth.registrationData);
  const signInData = useSelector((state) => state.auth.signInData);
  const { error, isCodeConfirmed, user } = useSelector((state) => state.auth);

  useEffect(() => {
    let destUrl;
    if (isRegistrationPage) {
      destUrl = registrationData?.type === 'Order materials (for business)' ? '/customer_company_info' : '/supplier_company_info';
    } else if (user) {
      destUrl = user?.status === 'UNFINISHED' ?
        (user?.entityType === 'CUSTOMER' ? '/customer_company_info' : '/supplier_company_info') :
        user?.entityType === 'CUSTOMER' ? history.location.pathname : order ? history.location.pathname : '/supplier/orders/new?login';
    }

    if (isCodeConfirmed) {
      handleShowConfirmCode(false);
      handleIsModalOpen(false);
      handleShowSignIn(true);
      handleShowRegistration(true);
      history.push(destUrl);
      dispatch(resetSignInData());
    }
  }, [isCodeConfirmed]);

  useEffect(() => {
    if (`${confirmCode.firstNum}${confirmCode.secondNum}${confirmCode.thirdNum}${confirmCode.fourthNum}`.length < 4) {
      dispatch(resetRegistrationError());
    }
  }, [codeValidationError]);

  const onSubmitConfirmCode = () => {
    const code = `${confirmCode.firstNum}${confirmCode.secondNum}${confirmCode.thirdNum}${confirmCode.fourthNum}`;
    const isCodeValid = code.length === 4;
    !isCodeValid ? setCodeValidationError(t('errors.emptySmsCode')) : setCodeValidationError('');

    if (isCodeValid) {
      const confirm = isRegistrationPage ?
        confirmRegistrationSmsCode(code) :
        confirmSignInSmsCode({ code, isRememberMeChecked: signInData?.isRememberMeChecked });
      dispatch(confirm);
    }
    dispatch(resetRegistrationError());
  };

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && index > 0) {
      event.preventDefault();
      const prevIndex = index - 1;
      onInputHandle('', event, index); // Clear the previous input value
      inputNamesRef.current[prevIndex].current.focus(); // Focus on the previous input
    }
  };

  const currentPhoneNumber = registrationData?.phone ?
    formatPhoneNumber(registrationData?.phone) :
    formatPhoneNumber(signInData?.phone);

  return (
    <>
      <FormGroup>
        <p>{t('auth.enterConfirmCode.you_should_receive_the_code_within')} <strong>{t('auth.enterConfirmCode.x_minutes')}</strong> {t('auth.enterConfirmCode.on_the_number')}
          <strong> {currentPhoneNumber}</strong>
        </p>
      </FormGroup>

      <FormGroup>
        <p className="edit_phone"><a onClick={() => {
          dispatch(resetGoToNextStep());
          editPhoneNumber(true);
          setIsShowConfirmCodeModal(false);
        }}>{t('auth.enterConfirmCode.edit_phone_number')}</a></p>
      </FormGroup>
      <FormGroup>
        <ControlLabel>{t('auth.enterConfirmCode.enter_the_code_from_sms')}</ControlLabel>
        <div className="flex-container-number">
          {inputNames.map((inputName, index) => {
            return (
              <div key={inputName}>
                <Input
                  name={inputName}
                  value={confirmCode[inputName]}
                  onChange={(val, evt) => onInputHandle(val, evt, index)}
                  onKeyDown={(evt) => handleKeyDown(evt, index)} // Attach the keydown handler
                  inputRef={inputNamesRef.current[index]}
                  maxLength={INPUT_MAX_LENGTH}
                  inputMode="numeric"
                />
              </div>
            );
          })}
        </div>
        {Boolean(codeValidationError.length) && <p className="login_error">{t('errors.emptySmsCode')}</p>}
        {(error === 'codes.not.match' || error === 'authentication fail') && <p className="login_error">{t('errors.invalidSmsCode')}</p>}
        {error && error === 'bean.validation.fail' && <p className="login_error">{t('errors.incorrectSmsCode')}</p>}
      </FormGroup>

      <FormGroup className="buttons_box">
        <Button block appearance="primary" onClick={onSubmitConfirmCode}><span>{registrationData?.phone ? t('auth.enterConfirmCode.confirm') : t('auth.sign_in.sign_in')}</span></Button>
      </FormGroup>

      <p className="forgot_phone">
        {counter === 0 ? <Link to="/#" onClick={() => setCounter(120)}>{t('auth.enterConfirmCode.send_code_again')}</Link> : <>{t('auth.enterConfirmCode.The next SMS is available in')} {formatTime(counter)}</>}
      </p>
    </>
  );
};

EnterConfirmSmsCode.propTypes = {
  handleShowConfirmCode: PropTypes.func.isRequired,
  handleIsModalOpen: PropTypes.func.isRequired,
  editPhoneNumber: PropTypes.func.isRequired,
  setIsShowConfirmCodeModal: PropTypes.func.isRequired,
  handleShowSignIn: PropTypes.func.isRequired,
  handleShowRegistration: PropTypes.func.isRequired,
  isRegistrationPage: PropTypes.bool,
};

EnterConfirmSmsCode.defaultProps = {
  isRegistrationPage: false,
};

export default EnterConfirmSmsCode;
