import React, {useState} from 'react';
import {
  Button,
  Panel,
} from 'rsuite';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../utils/';

import ListOrdered from './ListOrdered';


function OfferItem({
  isSendOfferButtonDisabled,
  sendOffer,
  products,
  apt,
  street,
  city,
  deliveryCost,
  preliminaryPrice,
}) {
  const [viewProducts, setViewProducts] = useState(false);
  const { t } = useTranslation();
  const productsView = viewProducts ? t('page.offer.hide_products') : t('page.offer.view_products');

  // const subtotal = products && products.map((p) => {
  //   if (!p.outOfStock) {
  //     return (p.orderQuantity * p.supplierPrice);
  //   } else {
  //     if (p.alternativeProduct) {
  //       return (
  //         p.alternativeProduct.quantity * p.alternativeProduct.supplierPrice ?
  //           p.alternativeProduct.quantity * p.alternativeProduct.supplierPrice : 0
  //       );
  //     } else if (p.draftProduct) {
  //       return (
  //         p.draftProduct.quantity * p.draftProduct.pricePerUnit ?
  //           p.draftProduct.quantity * p.draftProduct.pricePerUnit : 0
  //       );
  //     } else return 0;
  //   }
  // }).reduce(
  //   (accumulator, currentValue) => accumulator + currentValue
  // );

  // const discount = subtotal * percentDiscount / 100;
  // const total = subtotal - discount;

  return (
    <Panel bordered >

      <div className="supplier_name">
        <p className="header">{t('page.supplier_orders_delivery_method.offer_summary')}</p>
      </div>

      <div className="order_list_item">
        <div className="offers_list">
          <div className="details_box">
            <div className="ico-item project">
              <p className="sub-header">{t('page.supplier_orders.delivery_address')}</p>
              {street && city ? <p>{city} {street}{apt ? `, ${apt}` : ''}</p> : <p>no delivery address</p>}
            </div>
          </div>
        </div>
      </div>

      <div className="grey_box">
        <p>{t('page.offer.order_filling')}</p>
        <p className="price"><span className="ltr">{t(`${products?.length > 1 ? 'page.supplier_orders_delivery_method.for_products' : 'page.supplier_orders_delivery_method.for_product'}`, { p: products?.length || 0 })}</span></p>
        <div className="toggle_hide_view">
          <Button appearance="link" onClick={() => setViewProducts(!viewProducts)}>
            <span className="flex">
              <span>{productsView}</span>
              <span>
                <i className={`rs-icon ${viewProducts ? 'open' : 'close'}`} />
              </span>
            </span>
          </Button>
        </div>
      </div>

      <div className="price_box">
        <ul>
          <li><p>{t('page.supplier_orders_delivery_method.subtotal')}</p></li>
          <li><p><span className="ltr">₪ {!isNaN(preliminaryPrice) && formatPrice(preliminaryPrice)}</span></p></li>
        </ul>

        <ul>
          <li><p>{t('page.supplier_orders_delivery_method.delivery_cost')}</p></li>
          <li><p><span className="ltr">₪ {deliveryCost ? formatPrice(deliveryCost) : '0'}</span></p></li>
        </ul>

        <ul className="total">
          <li><p>{t('page.supplier_orders_delivery_method.order_total')}</p></li>
          <li><p><span className="ltr">₪ {!isNaN(Number(preliminaryPrice) + deliveryCost) && (formatPrice(Number(preliminaryPrice) + deliveryCost))}</span></p></li>
        </ul>
      </div>

      <Button disabled={isSendOfferButtonDisabled} onClick={sendOffer} appearance="primary"><span>{t('page.supplier_orders_delivery_method.send_offer')}</span></Button>

      {viewProducts && products && (
        <ListOrdered orderedProducts={products}/>
      )}
    </Panel>
  );
}

OfferItem.propTypes = {
  isSendOfferButtonDisabled: PropTypes.bool.isRequired,
  sendOffer: PropTypes.func.isRequired,
  products: PropTypes.instanceOf(Array),
  apt: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  deliveryCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  preliminaryPrice: PropTypes.string,
};

export default OfferItem;
