import { handleActions } from 'redux-actions';
import actions from '../actions/offer';

export const initialState = {
  isFetching: false,
  error: '',
  items: [],
  group: {},
  acceptedOffers: [],
  item: {},
  pages: null,
  nextStep: false,
  isOfferUpdated: false,
  validationErrors: {},
  packingSlipList: {},
  timeRanges: [],
};

export default handleActions(
  // get offers by supplier id
  {
    [actions.getOffersBySupplierId.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),

    [actions.getOffersBySupplierId.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
      pages: payload.pages,
      totalItems: payload.totalItems,
    }),

    [actions.getOffersBySupplierId.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // get accepted offers count
    [actions.getAcceptedOffersCount.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),

    [actions.getAcceptedOffersCount.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      acceptedOffers: payload.acceptedOffers,
    }),

    [actions.getAcceptedOffersCount.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // get offers list for customer
    [actions.getOffersByCustomerId.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
      group: {},
    }),

    [actions.getOffersByCustomerId.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
      group: payload.group,
      totalItems: payload.totalItems,
    }),

    [actions.getOffersByCustomerId.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // get accepted offers count
    [actions.getOfferDetailsById.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),

    [actions.getOfferDetailsById.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
    }),

    [actions.getOfferDetailsById.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // Edit offer details ( Make offer )
    [actions.putMakeOffersById.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      nextStep: false,
      validationErrors: {},
    }),

    [actions.putMakeOffersById.success]: (state) => ({
      ...state,
      isFetching: false,
      nextStep: true,
    }),

    [actions.putMakeOffersById.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
      nextStep: false,
      validationErrors: payload.validationErrors,
    }),

    // resetOffer
    [actions.resetOffer.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
      acceptedOffers: [],
      // item: {},
      pages: null,
      nextStep: false,
      isOfferUpdated: false,
      validationErrors: {},
    }),

    [actions.resetOffer.success]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [actions.resetOffer.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // makeOffersBySupplier
    [actions.makeOffersBySupplier.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
      acceptedOffers: [],
      // item: {},
      pages: null,
      nextStep: false,
    }),

    [actions.makeOffersBySupplier.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
      isOfferUpdated: true,
    }),

    [actions.makeOffersBySupplier.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
      validationErrors: payload.validationErrors,
    }),

    [actions.getOfferItemDetails.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),
    [actions.getOfferItemDetails.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
    }),
    [actions.getOfferItemDetails.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    [actions.getOfferItemDetailsSupplier.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: {},
    }),
    [actions.getOfferItemDetailsSupplier.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
    }),
    [actions.getOfferItemDetailsSupplier.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // Cancel offer - suplier
    [actions.putCancelOffer.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
    }),

    [actions.putCancelOffer.success]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [actions.putCancelOffer.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    [actions.postOfferPackingSlip.request]: (state) => ({
      ...state,
      error: '',
    }),

    [actions.postOfferPackingSlip.success]: (state) => ({
      ...state,
    }),

    [actions.postOfferPackingSlip.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    [actions.deleteOfferPackingSlip.request]: (state) => ({
      ...state,
      error: '',
    }),

    [actions.deleteOfferPackingSlip.success]: (state, { }) => ({
      ...state,
    }),

    [actions.deleteOfferPackingSlip.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // get time range.
    [actions.getTimeRange.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      timeRanges: [],
    }),

    [actions.getTimeRange.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      timeRanges: payload.item,
    }),

    [actions.getTimeRange.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),
  },
  initialState
);
