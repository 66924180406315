import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Button,
  Icon,
  InputNumber,
} from 'rsuite';
import { setCartData } from '../redux/actions/cart';

function PurchaseCounter({
  count,
  handlePlus,
  handleMinus,
  productId,
  setCount,
  isCartComponent,
}) {
  const dispatch = useDispatch();
  const handleOnChange = (value, event) => {
    if (!isCartComponent) {
      const regexp = /^[0-9\b]+$/;
      if (regexp.test(value) && value > 0) {
        setCount(parseInt(value));
      };
    } else {
      dispatch(setCartData(productId, parseInt(value ? value : 1)));
    }
  };

  return (
    <div>
      <div className="quantity">
        <div><Button className="plus" onClick={handlePlus}><Icon icon="plus" /></Button></div>
        <div>
          <span><InputNumber type="number" min={1} value={count} scrollable={false} step={1} onChange={handleOnChange} /></span>
          {/* <span>{count}</span> */}
        </div>
        <div><Button className="minus" disabled={count === 1} onClick={handleMinus}><Icon icon="minus" /></Button></div>
      </div>
    </div>
  );
};

PurchaseCounter.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isCartComponent: PropTypes.bool,
  handlePlus: PropTypes.func.isRequired,
  handleMinus: PropTypes.func.isRequired,
  setCount: PropTypes.func,
  productId: PropTypes.number,
};

export default PurchaseCounter;
