import { createActions } from 'redux-actions';
import { stringify } from 'query-string';
import alternativeApi from '../../api/alternative';

const actions = createActions({
  searchAlternative: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

export const searchAlternative = (search) => async (dispatch) => {
  dispatch(actions.searchAlternative.request());
  const query_data = { search: search };
  try {
    const response = await alternativeApi.searchAlternative(stringify(query_data));
    dispatch(actions.searchAlternative.success({ items: response.data, result: 'Success' }));
  } catch (e) {
    dispatch(actions.searchAlternative.error({ error: e }));
  }
};
