import { handleActions } from 'redux-actions';
import actions from '../actions/notification';

export const initialState = {
  isFetching: false,
  error: '',
  showMessage: false,
};

export default handleActions(
  {
    [actions.showNotification.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      showMessage: false,
    }),

    [actions.showNotification.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      showMessage: payload.showMessage,
    }),

    [actions.showNotification.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
      showMessage: false,
    }),

    [actions.closeNotification.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      showMessage: false,
    }),

    [actions.closeNotification.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      showMessage: payload.showMessage,
    }),

    [actions.closeNotification.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
      showMessage: false,
    }),

    [actions.closeAllNotifications.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      showMessage: false,
    }),

    [actions.closeAllNotifications.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      showMessage: payload.showMessage,
    }),

    [actions.closeAllNotifications.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
      showMessage: false,
    }),
  },
  initialState
);
