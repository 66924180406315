import actions from './constants';
import authApi from '../../../api/auth';
import { updateDataObject } from '../../../helpers/collections';
import { addDataToItems } from '../cart';

export const setSignInData = (data) => async (dispatch, getState) => {
  const { auth } = getState();
  dispatch(actions.setSignInData.request());
  try {
    dispatch(actions.setSignInData.success({ signInData: updateDataObject(auth.signInData, data) }));
  } catch (e) {
    dispatch(actions.setSignInData.error({ error: e.message, showMessage: true }));
  }
};

export const resetSignInData = () => async (dispatch) => {
  dispatch(actions.setSignInData.request());
  try {
    dispatch(actions.setSignInData.success({ signInData: {} }));
  } catch (e) {
    dispatch(actions.setSignInData.error({ error: e.message, showMessage: true }));
  }
};

export const signInUser = (data) => async (dispatch) => {
  dispatch(actions.signIn.request());
  try {
    const response = await authApi.signInUser(data);
    if (response.statusCode !== 200) {
      const { message, parameters } = response?.message;
      throw new Error(`${message}${parameters && parameters[0] ? `_${parameters[0]}` : ''}`);
    }
    dispatch(actions.signIn.success({ user: response, goToNextStep: true }));
  } catch (e) {
    console.log(e.message);
    dispatch(actions.signIn.error({ error: e.message, showMessage: true }));
  }
};

export const setCurrentUser = (callBack) => async (dispatch) => {
  dispatch(actions.setCurrentUser.request());
  try {
    const response = await authApi.getCurrentUser();
    if (response.statusCode !== 200 || response?.data === null) {
      if (response.statusCode === 200 && response?.data === null) {
        if (typeof callBack === 'function') {
          callBack('NotAuth');
        }
        const localStorageCartData = JSON.parse(localStorage.getItem('userCart'));
        if (!!localStorageCartData) {
          dispatch(addDataToItems(localStorageCartData));
        } else {
          dispatch(addDataToItems([]));
        }
      }
      throw new Error(response.message?.message);
    } else {
      const currentUser = response?.data;
      const userForLocalStorage = Object.assign({}, {
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        entityType: currentUser?.entityType,
        roles: currentUser?.roles,
        managerType: currentUser?.managerType,
        status: currentUser?.status,
        phone: currentUser?.phone,
        cart: currentUser?.cart,
      });
      dispatch(actions.setCurrentUser.success({ user: userForLocalStorage }));
      if (typeof callBack === 'function') {
        callBack(userForLocalStorage?.status);
      }
      if (userForLocalStorage?.entityType === 'CUSTOMER' || userForLocalStorage?.entityType === 'CUSTOMER_CANDIDATE') {
        if ( userForLocalStorage?.cart ) {
          localStorage.setItem('userCart', userForLocalStorage?.cart);
          dispatch(addDataToItems(JSON.parse(userForLocalStorage?.cart)));
        }
      }
    };
    // const user = localStorage.getItem('user');
    // dispatch(actions.setCurrentUser.success({ user: JSON.parse(user) }));
  } catch (e) {
    console.log(e.message);
    dispatch(actions.setCurrentUser.error({ error: e.message, showMessage: true }));
  }
};

export const resetSignInError = () => async (dispatch) => {
  dispatch(actions.resetSignInError());
};

export const postLoginRestore = (data, callBack) => async (dispatch) => {
  dispatch(actions.postLoginRestore.request());
  try {
    const response = await authApi.postLoginRestore(data);
    if (response.statusCode !== 200) {
      throw new Error(response.message.message);
    } else {
      dispatch(actions.postLoginRestore.success());
      if (typeof callBack === 'function') {
        callBack();
      }
    }
  } catch (e) {
    console.log(e.message);
  }
};
