const apiBaseUrl = process.env.NODE_ENV === 'development' ? 'https://api.dev.konebone.co.il/user-service/api/v1' : process.env.REACT_APP_API_URL;
const apiUrl = (endpoint) => apiBaseUrl + endpoint;

if (process.env.NODE_ENV === 'development') {
  console.log(`Client side running in '${process.env.NODE_ENV}' mode and send requests to '${apiBaseUrl}' api url`);
}

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export const get = (endpoint, query) => {
  const getEndpoint = query ? `${endpoint}?${query}` : endpoint;
  return fetch(apiUrl(getEndpoint), {
    method: 'GET',
    headers,
    credentials: 'include',
  }).then((response) => response.json()).catch((e) => `Api Error (get method): ${e}`);
};

export const post = (endpoint, { ...args }, token) =>
  fetch(apiUrl(endpoint), {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify(args),
  }).then((response) => response.json()).catch((e) => `Api Error (post method): ${e}`);

export const postFormData = (endpoint, data, token) =>
  fetch(apiUrl(endpoint), {
    method: 'POST',
    headers: {
      'accept': 'application/json',
    },
    credentials: 'include',
    body: data,
  }).then((response) => response.json()).catch((e) => `Api Error (post method): ${e}`);

export const put = (endpoint, { ...args }, token) =>
  fetch(apiUrl(endpoint), {
    method: 'PUT',
    headers,
    credentials: 'include',
    body: JSON.stringify(args),
  }).then((response) => response.json()).catch((e) => `Api Error (put method): ${e}`);

export const putWithoutStringifyArgs = (endpoint, data, token) => {
  return (
    fetch(apiUrl(endpoint), {
      method: 'PUT',
      headers,
      credentials: 'include',
      body: data,
    }).then((response) => response.json()).catch((e) => `Api Error (put method): ${e}`)
  );
};

export const del = (endpoint) =>
  fetch(apiUrl(endpoint), {
    method: 'DELETE',
    headers,
    credentials: 'include',
  }).then((response) => response.status === 200).catch((e) => `Api Error (delete method): ${e}`);
