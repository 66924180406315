import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import {
  FlexboxGrid,
  Col,
  Button,
  Loader,
} from 'rsuite';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart as addToCartAction } from '../redux/actions/cart';
import AlertAddedToCart from '../components/AlertAddedToCart';
import PurchaseCounter from '../components/PurchaseCounter';
import PaginationComponent from '../components/PaginationComponent';

import {getProductsByCategoryName} from '../redux/actions/product';
import NoImage from '../assets/img/no_image.png';

import { deleteCartItem } from '../redux/actions/cart';
import { closeAllNotifications } from '../redux/actions/notification';
import BreadcrumbPath from '../components/BreadcrumbPath';
import { loadAllPath } from '../redux/actions/catalog';
import {Helmet} from 'react-helmet';
import categoriesApi from '../api/categories';
import NotFound from './NotFound';

function ProductList() {
  const [products, setProducts] = useState([]);
  const { categoryName, productId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activePage, setActivePage] = React.useState(1);
  const [product, setProduct] = React.useState(1);
  const { t, i18n } = useTranslation();

  const handleSelect = (eventKey) => {
    setActivePage(eventKey);
  };
  const productsItems = useSelector((state) => state.product.items);
  const cartItems = useSelector((state) => state.cart.items);
  const pages = useSelector((state) => state.product.pages);
  const totalItems = useSelector((state) => state.product.totalItems);
  const isFetching = useSelector((state) => state.product.isFetching || state.catalog.isFetching);

  useEffect(() => {
    const items = productsItems.map((prod) => {
      const cartItem = cartItems.find((i) => prod.productId === i.productId);
      if (cartItem) {
        return { ...prod, count: cartItem?.count };
      } else {
        return prod;
      }
    });
    setProducts(items);
  }, [productsItems, cartItems]);

  useEffect(() => {
    dispatch(getProductsByCategoryName(activePage, categoryName));
    dispatch(loadAllPath(location.pathname));

    if (categoryName) {
      categoriesApi.findCategoryByName(categoryName).then((item) => {
        if (item.data) {
          setProduct(item.data);
        }
      });
    }
  }, [activePage, categoryName]);

  if (productId) {
    return <></>;
  }

  const renderHtml = (text) => <p dangerouslySetInnerHTML={{ __html: text }} />;
  const searchBreak = '/n';
  const replaceBreakWith = '<br />';
  const searchItemStart = '**';
  const replaceItemStartWith = '<li class="disc">';
  const searchItemEnd = '##';
  const replaceItemEndWith = '</li>';
  const processTemplate = (text) => {
    return text.replaceAll(
      searchBreak, replaceBreakWith
    ).replaceAll(
      searchItemStart, replaceItemStartWith
    ).replaceAll(
      searchItemEnd, replaceItemEndWith
    );
  };

  return (
    <div className="product_list">
      <Helmet>
        <title>{product.metaTitle ?? 'KONEBONE'}</title>
        <meta name="description" content={product.metaDescription ?? ''} />
        <meta property="og:title" content={product.metaTitle ?? ''} />
        <meta property="og:description" content={product.metaDescription ?? ''} />
      </Helmet>
      <div className="container">

        {!isFetching && <BreadcrumbPath />}

        {isFetching && <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />}

        {products.length ? (
          <>
            <h1>
              {categoryName} <span className="light_grey">({totalItems} {t('page.product_list.results')})</span>
            </h1>
            <div className="wrapper_catalog_list_text">
              {product.pageText ? renderHtml(processTemplate(product.pageText)) : ''}
            </div>

            <div className="wrapper_product_grid">
              <FlexboxGrid className="product_item">
                <ProductsList products={products} />
              </FlexboxGrid>
            </div>

            <PaginationComponent
              pages={pages}
              activePage={activePage}
              setActivePage={handleSelect}
            />
          </>
        ) : isFetching ? null : <NotFound/>}

      </div>
    </div>
  );
}

function ProductsList({ products }) {
  return (
    products ? (
      products.map((product, index) => {
        return (
          <FlexboxGrid.Item key={`${product.productId}-${index}`} componentClass={Col} colspan={24} md={6}>
            <Product product={product} />
          </FlexboxGrid.Item>
        );
      })
    ) : null
  );
}

function Product({ product }) {
  const [count, setCount] = useState(1);
  const { url } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    // productNumber,
    catalogNumber,
    koneboneProductName,
    minKbPrice,
    maxKbPrice,
    fileName,
    productId,
  } = product;
  const { t } = useTranslation();

  const handlePlus = () => {
    setCount(count + 1);
  };

  const handleMinus = () => {
    count > 1 && setCount(count - 1);
  };

  const onCartItemDelete = (id) => {
    dispatch(deleteCartItem(id));
  };

  const closeNotifications = () => dispatch(closeAllNotifications());

  const cartItems = useSelector((state) => state.cart.items);
  const user = useSelector((state) => state.auth.user);

  const addToCart = () => dispatch(addToCartAction(
    t('cart.product_added_to_cart'),
    'open',
    <AlertAddedToCart
      history={history}
      onCartItemDelete={() => onCartItemDelete(product?.productId)}
      product={product}
      closeAllNotifications={closeNotifications}
      cartItems={cartItems}
      count={count}
    />,
    product,
    count
  ));

  return (
    <div className="products-list-flex">
      <Link to={`${url}/${productId}`} className="wrapper_products-img">
        <div className="products-img">
          <img
            src={fileName ? fileName : NoImage}
            alt={koneboneProductName}
          />
        </div>
      </Link>
      <div>
        {/* <p><span className="product_number">{productNumber}</span></p> */}
        <p><span className="product_number">{catalogNumber}</span></p>
        <p className="description"><Link to={`${url}/${productId}`}>{koneboneProductName}</Link></p>
        <p className="price-all"><span className="ltr">₪ {minKbPrice} - ₪ {maxKbPrice}</span></p>
        {!Object.keys(user).length || user?.entityType === 'CUSTOMER' ? (
          <div className="add_to_cart">
            <div>
              <PurchaseCounter
                count={count}
                handlePlus={handlePlus}
                handleMinus={handleMinus}
                setCount={setCount}
              />
            </div>
            <div>
              <Button block type="button" appearance="primary" onClick={addToCart}>
                <span>{t('page.add_to_cart')}</span>
              </Button>
            </div>
          </div>
        ) : ''}
      </div>
    </div>
  );
}

ProductsList.propTypes = {
  products: PropTypes.instanceOf(Array).isRequired,
};

Product.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

export default ProductList;
