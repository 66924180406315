import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducers from '../reducers';
import { resetUserState } from '../actions/auth/logout';

function middlewareFactory(errorHandler) {
  return function(store) {
    return function(next) {
      return function(action) {
        if (!!action.payload?.error) {
          const errorMessage = action.payload?.error?.message;
          errorHandler(errorMessage ? errorMessage : action.payload?.error, store.getState, action, store.dispatch);
        }
        return next(action);
      };
    };
  };
}

function errorHandler(error, getState, lastAction, dispatch) {
  const checkIsError401 = error && error.includes('authentication fail');
  checkIsError401 && dispatch(resetUserState(error));
}

const middleware = [thunk];
const devMiddleware = [logger];
const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const resultedAplyMiddleware = process.env.NODE_ENV === 'development' ? applyMiddleware(...[...middleware, ...devMiddleware], middlewareFactory(errorHandler)) : applyMiddleware(...[...middleware], middlewareFactory(errorHandler));
const store = createStore(
  reducers,
  composeEnhancers(resultedAplyMiddleware)
);

export default store;
