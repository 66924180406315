import { combineReducers } from 'redux';
import cart from './cart';
import notification from './notification';
import checkout from './checkout';
import product from './product';
import auth from './auth/auth';
import catalog from './catalog';
import order from './order';
import project from './project';
import offer from './offer';
import search from './search';
import alternative from './alternative';
import draft from './draft';
import packages from './packages';
import measurements from './measurements';
import payment from './payment';
import contact from './contact';

export default combineReducers({
  cart,
  notification,
  auth,
  checkout,
  product,
  catalog,
  order,
  project,
  offer,
  search,
  alternative,
  draft,
  packages,
  measurements,
  payment,
  contact,
});
