import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setCartData } from '../redux/actions/cart';

export const CartContext = React.createContext({});

const cartContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.cart.items || []);
  const customerRequest = useSelector((state) => state.cart.customerRequest || '');

  const handlePlus = (id, count) => {
    dispatch(setCartData(id, count, 'increment'));
  };

  const handleMinus = (id, count) => {
    dispatch(setCartData(id, count, 'decrement'));
  };

  const handleCheckbox = (id, count, isReadyForAlternatives) => {
    dispatch(setCartData(id, count, 'checkbox', isReadyForAlternatives));
  };

  return (
    <CartContext.Provider
      value={{
        cartItems: items,
        customerRequest,
        handlePlus,
        handleMinus,
        handleCheckbox,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

cartContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default cartContextProvider;
