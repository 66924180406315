import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleIsTimeConstraintsShown, toggleIsAdditionalServicesShown, toggleIsProjectsShown } from '../../redux/actions/order';
import PropTypes from 'prop-types';
import {
  FlexboxGrid,
  Col,
  Panel,
  Checkbox,
  Button,
  Form,
  FormGroup,
  FormControl,
} from 'rsuite';
import { setAdditionalServicesData as setAdditionalServices, addAdditionalServicesData } from '../../redux/actions/checkout';
import { useTranslation } from 'react-i18next';

function AdditionalServices({ setIsConfirmOrderShown }) {
  const dispatch = useDispatch();
  const [additionalServicesData, setAdditionalServicesData] = useState({});

  const timeConstraints = useSelector((state) => state.checkout.timeConstraints);
  const order = useSelector((state) => state.order.item);
  const additionalServices = useSelector((state) => state.checkout.additionalServices);
  const projectData = useSelector((state) => state.checkout.projectData);
  const [isCheckedStep, setIscheckedStep] = useState(false);
  const { t } = useTranslation();

  const timeConstraintsDate = timeConstraints?.date?.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' });

  useEffect(() => {
    setAdditionalServicesData(additionalServices);
    setIscheckedStep(true);
  }, [additionalServices, isCheckedStep]);

  const onHandleTextArea = (val) => {
    if (!val) {
      delete additionalServicesData.customerComment;
      return setAdditionalServicesData({ ...additionalServicesData });
    }
    setAdditionalServicesData({ ...additionalServicesData, customerComment: val });
  };
  const onHandleCheckbox = (evt, val) => setAdditionalServicesData({
    ...additionalServicesData,
    serviceUnloading: val,
  });

  const addAdditionalServiceDataAndContinue = () => {
    dispatch(setAdditionalServices(additionalServicesData, order?.orderId, isCheckedStep));
    dispatch(addAdditionalServicesData(additionalServicesData, order?.orderId, isCheckedStep));
    dispatch(toggleIsTimeConstraintsShown(false));
    dispatch(toggleIsProjectsShown(false));
    dispatch(toggleIsAdditionalServicesShown(false));
    setIsConfirmOrderShown(true);
  };
  const projectDataApt = projectData?.apt && projectData?.apt;
  const projectDataCity = projectData?.city ? `, ${projectData?.city}` : '';

  return (
    <>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>
        <Panel bordered className="panel_edit">
          <div className="flex-container">
            <div className="flex-item">
              <p className="header">
                {t('page.checkout.time_constraints.time_constraints')} <span className="light">{t('page.checkout.step_1')}</span>
              </p>
              <p>{t('page.checkout.time_constraints.specific_delivery_time')}:{' '}
                {timeConstraints?.date && timeConstraints?.time ?
                  // eslint-disable-next-line max-len
                  `${timeConstraintsDate}, ${timeConstraints?.time?.label}` :
                  t('page.checkout.time_constraints.time_of_order_confirmation')}
              </p>
            </div>
            <div className="flex-item item-edit">
              <p className="edit"><a href="#" onClick={() => {
                dispatch(toggleIsTimeConstraintsShown(true));
                dispatch(toggleIsProjectsShown(false));
                dispatch(toggleIsAdditionalServicesShown(false));
              }}><span>{t('page.checkout.edit')}</span></a></p>
            </div>
          </div>
        </Panel>

        <Panel bordered className="panel_edit">
          <div className="flex-container">
            <div className="flex-item">
              <p className="header">{t('page.checkout.project.project')} <span className="light">{t('page.checkout.step_2')}</span></p>
              <p>
                {projectData?.name}, {projectData?.street} {projectDataApt}{projectDataCity}
              </p>
            </div>
            <div className="flex-item item-edit">
              <p className="edit"><a href="#" onClick={() => {
                dispatch(toggleIsTimeConstraintsShown(false));
                dispatch(toggleIsProjectsShown(true));
                dispatch(toggleIsAdditionalServicesShown(false));
              }}><span>{t('page.checkout.edit')}</span></a></p>
            </div>
          </div>
        </Panel>

        <Panel bordered className="panel_edit">
          <p className="header">{t('page.checkout.additional_services.additional_services')} <span className="light">{t('page.checkout.step_3')}</span></p>
          <p>{t('page.checkout.additional_services.additional_services_availability_relevant_supplier')}</p>
          <Form fluid>
            <FormGroup>
              <div className="checkbox_list">
                <ul className="full-width">
                  <li>
                    <Checkbox
                      checked={additionalServicesData?.serviceUnloading}
                      onChange={(evt, val) => onHandleCheckbox(evt, val)}
                    >
                      <span className="text">{t('page.checkout.additional_services.unloading_checkbox')}</span>
                    </Checkbox>
                  </li>
                  {/* <li>
                    <Checkbox defaultChecked><span className="text">Return of leftover</span></Checkbox>
                  </li> */}
                </ul>
                {/* <ul>
                  <li>
                    <Checkbox defaultChecked><span className="text">Uploading to the flow</span></Checkbox>
                  </li>
                  <li>
                    <Checkbox defaultChecked><span className="text">Remove of garbage</span></Checkbox>
                  </li>
                </ul> */}
              </div>
            </FormGroup>
            <FormGroup>
              <FormControl
                placeholder={t('page.checkout.additional_services.comment_for_supplier')}
                rows={5} name="comment_for_supplier"
                value={additionalServicesData?.customerComment}
                componentClass="textarea"
                onChange={(val) => onHandleTextArea(val)}
              />
            </FormGroup>
            <FormGroup>
              <Button appearance="primary" onClick={addAdditionalServiceDataAndContinue}>
                <span>{t('page.checkout.continue')}</span>
              </Button>
            </FormGroup>
          </Form>
        </Panel>
      </FlexboxGrid.Item>
    </>
  );
}

AdditionalServices.propTypes = {
  setIsConfirmOrderShown: PropTypes.func?.isRequired,
};

export default AdditionalServices;
