import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  postOfferPackingSlip,
  deleteOfferPackingSlip,
} from '../../redux/actions/offer';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Modal } from 'rsuite';
import PropTypes from 'prop-types';

function UploadPackingModal({
  showUploadPackingModal, setShowUploadPackingModal, orderIdValue, offerIdValue, apirequest }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [fileData, setFileData] = useState({});
  const [errorSizeMessage, setErrorSizeMessage] = useState(false);
  const isEmptyFileData = Object.keys(fileData).length === 0;

  const attachPackingSlips = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;
    const isFile10M = file.size <= 1024 * 1024 * 10;
    if (!isFile10M) {
      setErrorSizeMessage(true);
      return;
    }
    setErrorSizeMessage(false);
    const formData = new FormData();
    formData.append('file', file);
    dispatch(postOfferPackingSlip(offerIdValue, formData, (data) => {
      setFileData(data);
    }));
  };
  const handlerRemoveFile = () => {
    !isEmptyFileData && dispatch(deleteOfferPackingSlip(fileData?.offerPackingSlipId, () => {
      setFileData({});
    }));
  };

  const handlerModalClose = () => {
    !isEmptyFileData && dispatch(deleteOfferPackingSlip(fileData?.offerPackingSlipId));
    setShowUploadPackingModal(!showUploadPackingModal);
  };

  const handlerSubmit = () => {
    apirequest();
    setShowUploadPackingModal(!showUploadPackingModal);
  };

  return (
    <Modal
      className="decline-all-offers upload-packing-modal"
      overflow={false}
      show={showUploadPackingModal}
      onHide={handlerModalClose}
      keyboard={true}
      autoFocus={true}>
      <Modal.Header>
        <p>{t('page.upload_packing.title', { order: orderIdValue})}</p>
      </Modal.Header>
      <Modal.Body >
        <p>
          <Trans
            i18nKey="page.order.order_list.upload_packing_slip"
            values={{mail: 'info@konebone.co.il'}}
            components={{ a: <a href="mailto:info@konebone.co.il"><span/></a>, bold: <strong /> }}
          />
        </p>

        {isEmptyFileData && <>
          <label htmlFor="file-upload" className="upload_label">
            <span className="title">{t('page.upload_packing.upload_file')}</span>
            <span>{t('page.upload_packing.allowed_extensions')}</span>
            <span className="choose_file">
              <strong>{t('page.upload_packing.choose_file')}</strong>
            </span>
          </label>
          <input
            id="file-upload"
            type="file"
            accept=".jpeg, .pdf, .docx"
            onChange={(e) => attachPackingSlips(e)}
          />
          {errorSizeMessage && <p className="choose_file_error">{t('page.upload_packing.error')}</p>}
        </>}

        {!isEmptyFileData && <div className="wrapper_download_file">
          <div>
            <span>{fileData?.fileName}</span>
            <div className='button_box'>
              <a href={fileData?.fileUrl} className="wrapper_download_file--icon wrapper_download_file--downloadIcon" />
              <button onClick={handlerRemoveFile} className="wrapper_download_file--icon wrapper_download_file--removeIcon" />
            </div>
          </div>
          {/* <a href={item.fileUrl}>
                  <Button variant="text" className={classes.actionButton}>
                    <DownloadIcon />
                  </Button>
                </a> */}
        </div>}

        <div className="flex-box">
          <div><Button appearance="ghost" onClick={handlerModalClose}><span>{t('page.upload_packing.cancel')}</span></Button></div>
          <div><Button appearance="primary" onClick={handlerSubmit} disabled={isEmptyFileData}><span>{t('page.upload_packing.submit')}</span></Button></div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

UploadPackingModal.propTypes = {
  showUploadPackingModal: PropTypes.bool.isRequired,
  setShowUploadPackingModal: PropTypes.func.isRequired,
  orderIdValue: PropTypes.number.isRequired,
  offerIdValue: PropTypes.number.isRequired,
  apirequest: PropTypes.func.isRequired,
};

export default UploadPackingModal;
