import { put, get, postFormData, del } from './';

const getOffersBySupplierId = (query) => get('/offer', query);
const getOfferDetailsById = (id) => get(`/offer/${id}`);
const getAcceptedOffersCount = () => get('/offer/accepted');
const putMakeOffersById = (id, query) => put(`/offer/${id}`, query);
const makeOffersBySupplier = (id, data) => put(`/offer/${id}/make`, data);
const getOffersByCustomerId = (query) => get('/offer/in-review', query);
const getOfferItemDetails = (offerId) => get(`/offer/choose/${offerId}`);
const getOfferItemDetailsSupplier = (offerId) => get(`/offer/details/${offerId}`);
const putCancelOffer = (id) => put(`/offer/${id}/cancel`);
const postOfferPackingSlip = (offerIdValue, data) => postFormData(`/offer/upload/${offerIdValue}`, data);
const deleteOfferPackingSlip = (id) => del(`/offer/${id}`);
const getTimeRange = () => get('/time-range/offer/');

const offerApi = {
  getOffersBySupplierId,
  getAcceptedOffersCount,
  getOfferDetailsById,
  putMakeOffersById,
  makeOffersBySupplier,
  getOffersByCustomerId,
  getOfferItemDetails,
  getOfferItemDetailsSupplier,
  putCancelOffer,
  postOfferPackingSlip,
  deleteOfferPackingSlip,
  getTimeRange,
};

export default offerApi;
