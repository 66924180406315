import { createActions } from 'redux-actions';
import orderApi from '../../api/order';
import { stringify } from 'query-string';
import { PRODUCTS_PER_PAGE, PRODUCTS_PER_PAGE_SEARCH_RESULT } from '../constantas/orders';

const actions = createActions({
  placeRFP: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetOrder: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getOrdersByCustomer: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getOrdersByCustomerSearch: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  // confirmOrder: {
  //   request: (x) => x,
  //   success: (x) => x,
  //   error: (x) => x,
  // },
  getDraftOrderByManager: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  confirmOrderById: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getOrderById: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getPaidOrderById: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  declineAllOffersByOrderId: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  toggleIsTimeConstraintsShown: {
    success: (x) => x,
  },
  toggleIsProjectsShown: {
    success: (x) => x,
  },
  toggleIsAdditionalServicesShown: {
    success: (x) => x,
  },
  setActivePage: {
    success: (x) => x,
  },
  getTimeRange: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

export const placeRFP = (data) => async (dispatch) => {
  dispatch(actions.placeRFP.request());
  try {
    const productsForRFP = data.map((item) => {
      return {
        productId: item?.productId,
        quantity: item?.count,
        isReadyForAlternatives: item?.isReadyForAlternatives,
      };
    });
    const response = await orderApi.placeRFP({ orderProductRequestDtos: productsForRFP });
    if (response.statusCode !== 200 || response.status === 'WAITING_FOR_REVIEW') throw new Error(response.message.message);
    dispatch(actions.placeRFP.success({ item: response?.data }));
  } catch (e) {
    dispatch(actions.placeRFP.error({ error: e }));
  }
};

export const resetOrder = () => async (dispatch) => {
  dispatch(actions.resetOrder.request());
  try {
    dispatch(actions.resetOrder.success());
  } catch (e) {
    dispatch(actions.resetOrder.error({ error: e }));
  }
};

export const confirmOrderById = (orderId, data, callback) => async (dispatch) => {
  dispatch(actions.confirmOrderById.request());
  try {
    const response = await orderApi.confirmOrderById(orderId, JSON.stringify(data));
    if (response.statusCode !== 200) {
      throw new Error(response.message?.message);
    } else {
      dispatch(actions.confirmOrderById.success({ item: response.data }));
      if (typeof callback === 'function') {
        callback();
      }
    }
  } catch (e) {
    dispatch(actions.confirmOrderById.error({ error: e }));
  }
};

export const getOrderById = (orderId) => async (dispatch) => {
  dispatch(actions.getOrderById.request());
  try {
    const response = await orderApi.getOrderById(orderId);
    if (response.statusCode !== 200) throw new Error(response.message?.message);
    dispatch(actions.getOrderById.success({ item: response.data }));
  } catch (e) {
    dispatch(actions.getOrderById.error({ error: e }));
  }
};

export const getPaidOrderById = (orderId) => async (dispatch) => {
  dispatch(actions.getPaidOrderById.request());
  try {
    const response = await orderApi.getPaidOrderById(orderId);
    if (response.statusCode !== 200) throw new Error(response.message?.message);
    response.data.offerGroup.offers.forEach((offer) => {
      const alternativeProducts = [];
      const inStockProducts = [];
      offer.products.forEach((el) => {
        if (el.alternativeProduct) {
          el.alternativeProduct.orderQuantity = el.orderQuantity;
          alternativeProducts.push(el);
        } else {
          inStockProducts.push(el);
        }
      });
      offer.alternativeProducts = alternativeProducts;
      offer.inStockProducts = inStockProducts;
    });
    dispatch(actions.getPaidOrderById.success({ item: response.data }));
  } catch (e) {
    dispatch(actions.getPaidOrderById.error({ error: e }));
  }
};

export const getOrdersByCustomer = (status, page) => async (dispatch) => {
  const query_data = { status: status, page: page - 1, size: PRODUCTS_PER_PAGE };
  dispatch(actions.getOrdersByCustomer.request());
  try {
    const response = await orderApi.getOrdersByCustomer(stringify(query_data));
    const orders = response.data.content;
    const pagesCalculation = Math.ceil(response.data.totalElements / PRODUCTS_PER_PAGE);
    const pages = pagesCalculation === 0 ? 1 : pagesCalculation;
    if (response.statusCode !== 200) throw new Error(response.message?.message);
    dispatch(actions.getOrdersByCustomer.success({
      items: orders,
      totalItems: response.data.totalElements,
      pages: pages,
    }));
  } catch (e) {
    dispatch(actions.getOrdersByCustomer.error({ error: e }));
  }
};

export const getOrdersByCustomerSearch = (status, search, page) => async (dispatch) => {
  const query_data = { status: status, search: search, page: page - 1, size: PRODUCTS_PER_PAGE_SEARCH_RESULT };
  dispatch(actions.getOrdersByCustomerSearch.request());
  try {
    const response = await orderApi.getOrdersByCustomerSearch(stringify(query_data));
    const orders = response.data.content;
    const pagesCalculation = Math.ceil(response.data.totalElements / PRODUCTS_PER_PAGE_SEARCH_RESULT);
    const pages = pagesCalculation === 0 ? 1 : pagesCalculation;
    if (response.statusCode !== 200) throw new Error(response.message?.message);
    dispatch(actions.getOrdersByCustomerSearch.success({
      items: orders,
      totalItems: response.data.totalElements,
      pages: pages,
    }));
  } catch (e) {
    dispatch(actions.getOrdersByCustomerSearch.error({ error: e }));
  }
};

export const getDraftOrderByManager = () => async (dispatch) => {
  dispatch(actions.getDraftOrderByManager.request());
  try {
    const response = await orderApi.getDraftOrderByManager();
    if (response.statusCode !== 200) throw new Error(response.message?.message);
    dispatch(actions.getDraftOrderByManager.success({ item: response.data }));
  } catch (e) {
    dispatch(actions.getDraftOrderByManager.error({ error: e }));
  }
};

export const getTimeRange = () => async (dispatch) => {
  dispatch(actions.getTimeRange.request());
  try {
    const response = await orderApi.getTimeRange();
    const resultData = response.data;

    const data = resultData.map((item) => {
      if (!item.full) {
        return { label: `${item.start.slice(0, 5)}-${item.end.slice(0, 5)}`, id: item.id };
      } else {
        return { label: 'במהלך היום', id: item.id };
      }
    });

    if (response.statusCode !== 200) throw new Error(response.message?.message);
    dispatch(actions.getTimeRange.success({ item: data }));
  } catch (e) {
    dispatch(actions.getTimeRange.error({ error: e }));
  }
};

export const declineAllOffersByOrderId = (orderId) => async (dispatch) => {
  dispatch(actions.declineAllOffersByOrderId.request());
  try {
    const response = await orderApi.declineAllOffers(orderId);
    if (response.statusCode !== 200) throw new Error(response.message?.message);
    dispatch(actions.declineAllOffersByOrderId.success());
  } catch (e) {
    dispatch(actions.declineAllOffersByOrderId.error({ error: e }));
  }
};

export const toggleIsTimeConstraintsShown = (isTimeConstraintsShown) => (dispatch) => {
  dispatch(actions.toggleIsTimeConstraintsShown.success({isTimeConstraintsShown}));
};

export const toggleIsProjectsShown = (isProjectsShown) => (dispatch) => {
  dispatch(actions.toggleIsProjectsShown.success({isProjectsShown}));
};

export const toggleIsAdditionalServicesShown = (isAdditionalServicesShown) => (dispatch) => {
  dispatch(actions.toggleIsAdditionalServicesShown.success({isAdditionalServicesShown}));
};

export const setActivePage = (activePage) => (dispatch) => {
  dispatch(actions.setActivePage.success({activePage}));
};

