import actions from './constants';
import authApi from '../../../api/auth';
import { updateDataObject } from '../../../helpers/collections';

// user registration flow
export const setRegistrationData = (data) => async (dispatch, getState) => {
  const { auth } = getState();
  dispatch(actions.setRegistrationData.request());
  try {
    dispatch(actions.setRegistrationData.success({ registrationData: updateDataObject(auth.registrationData, data) }));
  } catch (e) {
    dispatch(actions.setRegistrationData.error({ error: e.message, showMessage: true }));
  }
};

export const resetRegistrationData = () => async (dispatch) => {
  dispatch(actions.resetRegistrationData.request());
  try {
    dispatch(actions.resetRegistrationData.success({ registrationData: {} }));
  } catch (e) {
    dispatch(actions.resetRegistrationData.error({ error: e.message, showMessage: true }));
  }
};

export const registerUser = (data) => async (dispatch) => {
  dispatch(actions.registration.request());
  try {
    const registrationData = {
      status: data?.status,
      phone: data?.phone,
      type: data?.type === 'Order materials (for business)' ? 'CUSTOMER' : 'SUPPLIER_CANDIDATE',
    };
    const response = await authApi.sendPhoneForRegistration(registrationData);
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.registration.success({ user: 'Successfully registered user', goToNextStep: true }));
  } catch (e) {
    console.log(e.message);
    dispatch(actions.registration.error({ error: e.message, showMessage: true }));
  }
};

export const getSignedSupplierCandidate = (callBack) => async (dispatch) => {
  dispatch(actions.getSignedSupplierCandidate.request());
  try {
    const response = await authApi.getSignedSupplierCandidate();
    if (response.statusCode !== 200) {
      throw new Error(response.message.message);
    } else {
      const userData = Object.assign({}, {
        ...response.data,
        companyName: response.data?.name,
      });
      dispatch(actions.getSignedSupplierCandidate.success(userData));
      if (typeof callBack === 'function') {
        callBack(userData);
      }
    };
  } catch (e) {
    console.log(e.message);
    dispatch(actions.getSignedSupplierCandidate.error({ error: e.message }));
  }
};

export const getSignedCustomerCandidate = (callBack) => async (dispatch) => {
  dispatch(actions.getSignedCustomerCandidate.request());
  try {
    const response = await authApi.getSignedCustomerCandidate();
    if (response.statusCode !== 200) {
      throw new Error(response.message.message);
    } else {
      const userData = Object.assign({}, {
        ...response.data,
        companyName: response.data?.name,
      });
      dispatch(actions.getSignedCustomerCandidate.success(userData));
      if (typeof callBack === 'function') {
        callBack(userData);
      }
    };
  } catch (e) {
    console.log(e.message);
    dispatch(actions.getSignedCustomerCandidate.error({ error: e.message }));
  }
};

export const resetGoToNextStep = () => async (dispatch) => {
  dispatch(actions.resetGoToNextStep());
};

export const resetRegistrationError = () => async (dispatch) => {
  dispatch(actions.resetRegistrationError());
};
