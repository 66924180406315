import { createActions } from 'redux-actions';
import { stringify } from 'query-string';
import searchApi from '../../api/search';
import { PRODUCTS_PER_PAGE } from '../constantas/product';

const actions = createActions({
  byCategoryNameAndSearch: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

export const byCategoryNameAndSearch = (searchPhrase, categoryName, page) => async (dispatch) => {
  const query_data = { categoryName, page: page - 1, search: searchPhrase, size: PRODUCTS_PER_PAGE };
  dispatch(actions.byCategoryNameAndSearch.request());
  try {
    const response = await searchApi.byCategoryNameAndSearch(stringify(query_data));
    const products = response.data.products.content;
    const count = response.data.count;
    const pagesCalculation = response.data.products.totalPages;
    const pages = pagesCalculation === 0 ? 1 : pagesCalculation;
    dispatch(actions.byCategoryNameAndSearch.success(
      { count, products, totalItems: response.data.products.totalElements, pages }
    ));
  } catch (e) {
    dispatch(actions.byCategoryNameAndSearch.error({ error: e }));
  }
};
