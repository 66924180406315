import React from 'react';
import PropTypes from 'prop-types';
import NoImage from '../../assets/img/no_image.png';

export function OrderedProduct({ product, cartItems, count }) {
  const {
    productId,
    // productNumber,
    catalogNumber,
    koneboneProductName,
    minKbPrice,
    maxKbPrice,
    fileName,
    imageFileName,
  } = product;

  const result = cartItems.filter((i) => {
    if (i.productId === productId) {
      return i;
    }
  });

  const quantity = result.length ?
    count ? result[0]?.count + count : result[0]?.count :
    count ? count : 1;

  return (
    <div className="products-list-flex">
      <div>
        <div className="products-img">
          <img src={fileName && fileName.slice(-4) !== 'null' ? fileName : imageFileName ? imageFileName : NoImage } alt={koneboneProductName} />
        </div>
        <p className="quantity">
          <span>
            x
            {quantity}
          </span>
        </p>
      </div>
      <div>
        {/* <p><span className="product_number">{productNumber}</span></p> */}
        <p><span className="product_number">{catalogNumber}</span></p>
        <p className="description">{koneboneProductName}</p>
        <p className="price-all"><span className="ltr">₪ {minKbPrice} - ₪ {maxKbPrice}</span></p>
        <p><span className="ltr">₪{(minKbPrice * quantity).toFixed(2)} - ₪{(maxKbPrice * quantity).toFixed(2)}</span></p>
      </div>
    </div>
  );
}

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  cartItems: PropTypes.instanceOf(Array),
  count: PropTypes.number,
};
