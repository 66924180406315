import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOffersBySupplierId,
} from '../../redux/actions/offer';
import { setActivePage } from '../../redux/actions/order';
import { useTranslation } from 'react-i18next';
import { Button, Breadcrumb, Icon, /* Nav, */ Loader } from 'rsuite';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import PaginationComponent from '../../components/PaginationComponent';
import PropTypes from 'prop-types';
import { formatPrice } from '../../utils/index';
import { deliveryModeMaper } from '../../utils';
import UploadPackingModal from './UploadPackingModal';
import ModalLimitedFunctionality from './ModalLimitedFunctionality';
import NotFound from '../NotFound';

const TimeBlock = ({ time, date }) => {
  const { t } = useTranslation();
  const deliveryDate = date?.split('-');
  const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
  const deliveryTime = !time?.full ?
    `${time?.start?.slice(0, 5)}-${time?.end?.slice(0, 5)}` :
    t('page.supplier_orders.during_the_working_day');
  return (
    <div>
      <div className="details_box">
        <div className="ico-item clock">
          <p className="sub-header">{t('page.supplier_orders.expected_delivery_time')}</p>
          <p>{deliveryTime ? `${deliveryDateData} ${deliveryTime}` : 'urgent order'}
          </p>
        </div>
      </div>
    </div>
  );
};

TimeBlock.propTypes = {
  time: PropTypes.object,
  date: PropTypes.string,
};

function RequestsForProposal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const isMobileView = useMediaQuery({ query: '(max-width: 991px)' });
  const { ordersType } = useParams();
  const [typeMenu, setTypeMenu] = useState();
  const menuList = [
    {
      url: 'new',
      name: t('page.requests_for_proposal.new_orders'),
    },
    {
      url: 'review',
      name: t('page.requests_for_proposal.in_review'),
    },
    {
      url: 'awaiting_delivery',
      name: t('page.requests_for_proposal.awaiting_delivery'),
    },
    {
      url: 'delivered',
      name: t('page.requests_for_proposal.delivered'),
    },
    {
      url: 'expired',
      name: t('page.requests_for_proposal.expired'),
    },
  ];
  const pages = useSelector((state) => state.offer.pages);
  const activePage = useSelector((state) => state.order.activePage);
  const offersTypes = ['new', 'review', 'awaiting_delivery', 'delivered', 'expired'];

  const getOffersByStatus = (type) => {
    if (type === 'new') {
      dispatch(getOffersBySupplierId({ status: 'NEW_ORDER', page: activePage }));
    } else if (type === 'review') {
      dispatch(getOffersBySupplierId({ status: 'IN_REVIEW', page: activePage }));
    } else if (type === 'awaiting_delivery') {
      dispatch(getOffersBySupplierId({status: 'AWAITING_DELIVERY', page: activePage}));
    } else if (type === 'delivered') {
      dispatch(getOffersBySupplierId({status: 'DELIVERED', page: activePage}));
    } else if (type === 'accepted') {
      dispatch(getOffersBySupplierId({ status: 'ACCEPTED', page: activePage}));
    } else if (type === 'expired') {
      dispatch(getOffersBySupplierId({ status: 'EXPIRED', page: activePage}));
    }
  };

  const detectOrdersType = (param) => {
    switch (param) {
      case 'new':
      case 'review':
      case 'awaiting_delivery':
      case 'delivered':
      case 'expired':
        return param;
      default:
        return isMobileView ? '' : 'new';
    }
  };

  const offers = useSelector((state) => state.offer.items);
  const isFetching = useSelector((state) => state.offer.isFetching);

  useEffect(() => {
    getOffersByStatus(ordersType);
  }, [activePage, ordersType]);

  const handleSelect = (eventKey) => {
    dispatch(setActivePage(eventKey));
  };

  useEffect(() => {
    if (!ordersType) {
      history.push(`/supplier/orders/${detectOrdersType(ordersType)}`);
    }
    setTypeMenu(detectOrdersType(ordersType));
  }, [ordersType, isMobileView]);

  const onMenuItemClick = () => {
    dispatch(setActivePage(1));
  };

  const onOrderClick = (id) => {
    history.push(`/supplier/order/${id}`);
  };
  const onOrderClickReview = (id) => {
    history.push(`/supplier/orders/details/${id}`);
  };
  const onOrderClickAwaitingDelivery = (id, delivered) => {
    const isDelivered = delivered ? '?delivered' : '';
    history.push(`/supplier/orders/delivery-details/${id}${isDelivered}`);
  };

  const onMakeOfferClick = (id) => {
    history.push(`/supplier/make_offer/${id}`);
  };

  if ((ordersType && !offersTypes.some((item) => item === ordersType) && !offers.length && !isFetching) ||
      (history.location.search && history.location.search !== '?login')) {
    return <NotFound/>;
  }

  return (
    <>
      {isFetching ? (
        <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
      ) : (
        <div className={`checkout order_list_item supplier_orders${!typeMenu ? ' wrapper_requests_proposal' : ''}`}>
          <div className="container">

            {isMobileView && (
              typeMenu ? (
                <div className="breadcrumb-list">
                  <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                    <Breadcrumb.Item componentClass={Link} to="/supplier/orders">
                      <span>{t('header.orders')}</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      {menuList.filter((i) => i.url === typeMenu)[0].name}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              ) : (
                <div className="breadcrumb-list">
                  <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                    <Breadcrumb.Item active>
                      <span>{t('header.orders')}</span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              )
            )}

            <div className="requests_proposal">
              {!(isMobileView && typeMenu) && (<div><div className="full_height">
                <h1>{t('header.orders')}</h1>

                <ul className="orders_links_list">
                  {
                    menuList.map((i) => (
                      <li key={i.url}>
                        <Link
                          onClick={() => onMenuItemClick(i.url)}
                          className={`${i.url}${typeMenu === i.url ? ' active' : ''}`}
                          to={`/supplier/orders/${i.url}`}
                        >
                          <span>{i.name}</span>
                        </Link></li>
                    ))
                  }
                </ul>

              </div></div>)}

              {(!isMobileView || (isMobileView && typeMenu)) && (<div className={`${offers?.length && !isFetching ? '' : 'empty'} ${typeMenu}`}>

                {typeMenu === 'new' && <NewOrders offers={offers} onOrderClick={onOrderClick} onMakeOfferClick={onMakeOfferClick} isFetching={isFetching} />}
                {typeMenu === 'review' && <InReview offers={offers} onOrderClick={onOrderClickReview} isFetching={isFetching} />}
                {typeMenu === 'awaiting_delivery' && <AwaitingDelivery offers={offers} onOrderClick={onOrderClickAwaitingDelivery} isFetching={isFetching} />}
                {typeMenu === 'delivered' && <Delivered offers={offers} onOrderClick={onOrderClickAwaitingDelivery} isFetching={isFetching} />}
                {/* typeMenu === 'accepted' && <Accepted offers={offers} onOrderClick={onOrderClick} isFetching={isFetching} /> */}
                {/* {typeMenu === 'accepted' && <Accepted offers={accepted} onOrderClick={onOrderClick} isFetching={isFetching} />} */}
                {typeMenu === 'expired' && <Expired offers={offers} onOrderClick={onOrderClick} isFetching={isFetching} />}

              </div>)}
            </div>

            {(!isMobileView || (isMobileView && typeMenu)) && (pages > 1) && !isFetching && (
              <div className="requests_proposal pagination">
                <div />
                <div>
                  <PaginationComponent
                    pages={pages}
                    activePage={activePage}
                    setActivePage={handleSelect}
                  />
                </div>
              </div>
            )}

          </div>
        </div>
      )}
    </>
  );
}

const TimeBlockCreatedDate = ({ time }) => {
  const { t } = useTranslation();
  return (
    <p className='with_line_text'>{t('page.supplier_orders.order_received_on')} {''}
      {time ? `${new Date(time).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })} ,${new Date(time).toLocaleString('en-GB', { hour: 'numeric', minute: '2-digit', hour12: false })}` : 'urgent order'}
    </p>
  );
};

TimeBlockCreatedDate.propTypes = {
  time: PropTypes.string.isRequired,
};

function NewOrders({ offers, onOrderClick, onMakeOfferClick, isFetching }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isUserLogin = location.search.includes('login');
  const [showModalLimitedFunctionality, setShowModalLimitedFunctionality] = useState(isUserLogin);

  const cartItems = useSelector((state) => state.cart.items);

  return (
    <>
      {isFetching ? <Loader size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} /> : (
        offers.length ? offers.map((o) => (
          <div className="wrapper_orders_list_flex" key={o?.orderId}>
            <div className="orders_list_flex">
              <div>

                <div className="header-flex with_line">
                  <div><p className="order">{t('page.supplier_orders.order')} #{o?.orderId}</p></div>
                  <div>
                    <p>
                      <span className="orders">
                        {o?.productsQuantity === 1 ?
                          <>{o?.productsQuantity} {t('page.supplier_orders.product')}</> :
                          <>{o?.productsQuantity} {t('page.supplier_orders.products')}</>
                        }
                      </span>
                    </p>
                  </div>
                  {o?.createdTime ? <TimeBlockCreatedDate time={o?.createdTime} /> : ''}
                </div>

              </div>
              <div>

                <Button appearance='primary' onClick={() => onMakeOfferClick(o?.offerId)}>
                  <span>{t('page.supplier_orders.confirm_offer')}</span>
                </Button>

              </div>
              <div>

                <div className="offers_list">
                  <TimeBlock time={o?.deliveryTimeRange} date={o?.deliveryDate} />
                  <div>
                    <div className="details_box">
                      <div className="ico-item price">
                        <p className="sub-header">{t('page.supplier_orders.preliminary_price')}</p>
                        <p><span className="ltr">₪ {formatPrice(o?.preliminaryPrice ? o?.preliminaryPrice : 0)}</span></p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="details_box">
                      <div className="ico-item project">
                        <p className="sub-header">{t('page.supplier_orders.delivery_address')}</p>
                        {o?.street && o?.city ? <p>{o?.street}{o?.apt ? ` ${o?.apt},` : ','} {o?.city}</p> : 'no delivery address'}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div>

                <div className="wrapper_flex_timer">

                  <div className="order_details_button">
                    <Button appearance="ghost" onClick={() => onOrderClick(o?.offerId)}><span>{t('page.supplier_orders.order_details')}</span></Button>
                  </div>

                </div>

              </div>
            </div>
          </div>
        )) : (
          <div className="don_not_have_orders new">
            <div className="banner"></div>
            <p className="title">{t('page.requests_for_proposal.don_not_have_orders.new_orders.title')}</p>
            <p>{t('page.requests_for_proposal.don_not_have_orders.new_orders.text')}</p>
          </div>
        )
      )}
      {isUserLogin && cartItems.length > 0 && <ModalLimitedFunctionality
        showModalLimitedFunctionality={showModalLimitedFunctionality}
        setShowModalLimitedFunctionality={setShowModalLimitedFunctionality} />}
    </>
  );
}

NewOrders.propTypes = {
  offers: PropTypes.instanceOf(Object).isRequired,
  onOrderClick: PropTypes.func.isRequired,
  onMakeOfferClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
function InReview({ offers, onOrderClick, isFetching }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      {isFetching ? <Loader size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} /> : (
        offers.length ? offers.map((o) => (
          <div className="wrapper_orders_list_flex" key={o?.orderId}>
            <div className="orders_list_flex">
              <div>

                <div className="header-flex with_line">
                  <div><p className="order">{t('page.supplier_orders.order')} #{o?.orderId}</p></div>
                  <div>
                    <p>
                      <span className="orders">
                        {o?.productsQuantity === 1 ?
                          <>{o?.productsQuantity} {t('page.supplier_orders.product')}</> :
                          <>{o?.productsQuantity} {t('page.supplier_orders.products')}</>
                        }
                      </span>
                    </p>
                  </div>
                  {o?.createdTime ? <TimeBlockCreatedDate time={o?.createdTime} /> : ''}
                </div>

              </div>
              <div>

                <Button appearance="ghost" onClick={() => onOrderClick(o?.offerId)}>
                  <span>{t('page.supplier_orders.order_details')}</span>
                </Button>

              </div>
              <div>

                <div className="offers_list">
                  <TimeBlock time={o?.deliveryTimeRange} date={o?.deliveryDate} />
                  <div>
                    <div className="details_box">
                      <div className="ico-item price">
                        <p className="sub-header">{t('page.supplier_orders.final_price')}</p>
                        <p><span className="ltr">₪ {formatPrice(o?.preliminaryPrice ? o?.preliminaryPrice : 0)}</span></p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="details_box">
                      <div className="ico-item project">
                        <p className="sub-header">{t('page.supplier_orders.delivery_address')}</p>
                        {o?.street && o?.city ? <p>{o?.street}{o?.apt ? ` ${o?.apt},` : ','} {o?.city}</p> : 'no delivery address'}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="details_box">
                      <div className="ico-item delivery">
                        <p className="sub-header">{t('page.supplier_orders.delivery_method')}</p>
                        <p>{deliveryModeMaper(o?.deliveryMode, t)}</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        )) : (
          <div className="don_not_have_orders review">
            <div className="banner"></div>
            <p className="title">{t('page.requests_for_proposal.don_not_have_orders.in_review.title')}</p>
            <p>{t('page.requests_for_proposal.don_not_have_orders.in_review.text')}</p>
          </div>
        )
      )}
    </>
  );
}

InReview.propTypes = {
  offers: PropTypes.instanceOf(Object).isRequired,
  onOrderClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function AwaitingDelivery({ offers, onOrderClick, isFetching }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      {isFetching ? <Loader size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} /> : (
        offers.length ? offers.map((o) => (
          <div className="wrapper_orders_list_flex" key={o?.orderId}>
            <div className="orders_list_flex">
              <div>

                <div className="header-flex with_line">
                  <div><p className="order">{t('page.supplier_orders.order')} #{o?.orderId}</p></div>
                  <div>
                    <p>
                      <span className="orders">
                        {o?.productsQuantity === 1 ?
                          <>{o?.productsQuantity} {t('page.supplier_orders.product')}</> :
                          <>{o?.productsQuantity} {t('page.supplier_orders.products')}</>
                        }
                      </span>
                    </p>
                  </div>
                  {o?.createdTime ? <TimeBlockCreatedDate time={o?.createdTime} /> : ''}
                </div>

              </div>
              <div>

                <Button appearance="ghost" onClick={() => onOrderClick(o?.offerId)}>
                  <span>{t('page.supplier_orders.order_details')}</span>
                </Button>

              </div>
              <div>

                <div className="offers_list">
                  <TimeBlock time={o?.deliveryTimeRange} date={o?.deliveryDate} />
                  <div>
                    <div className="details_box">
                      <div className="ico-item price">
                        <p className="sub-header">{t('page.supplier_orders.final_price')}</p>
                        <p><span className="ltr">₪ {formatPrice(o?.preliminaryPrice ? o?.preliminaryPrice : 0)}</span></p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="details_box">
                      <div className="ico-item project">
                        <p className="sub-header">{t('page.supplier_orders.delivery_address')}</p>
                        {o?.street && o?.city ? <p>{o?.street}{o?.apt ? ` ${o?.apt},` : ','} {o?.city}</p> : 'no delivery address'}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="details_box">
                      <div className="ico-item delivery">
                        <p className="sub-header">{t('page.supplier_orders.delivery_method')}</p>
                        <p>{deliveryModeMaper(o?.deliveryMode, t)}</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        )) : (
          <div className="don_not_have_orders review">
            <div className="banner"></div>
            <p className="title">{t('page.requests_for_proposal.don_not_have_orders.awaiting_delivery.title')}</p>
            <p>{t('page.requests_for_proposal.don_not_have_orders.awaiting_delivery.text')}</p>
          </div>
        )
      )}
    </>
  );
}

AwaitingDelivery.propTypes = {
  offers: PropTypes.instanceOf(Object).isRequired,
  onOrderClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function Delivered({ offers, onOrderClick, isFetching }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showUploadPackingModal, setShowUploadPackingModal] = useState(false);
  const [orderIdValue, setOrderIdValue] = useState(null);
  const [offerIdValue, setOfferIdValue] = useState(null);
  const getItemData = () => dispatch(getOffersBySupplierId({status: 'DELIVERED', page: 1}));
  return (
    <>
      {isFetching ? <Loader size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} /> : (
        offers.length ? offers.map((o) => (
          <div className="wrapper_orders_list_flex" key={o?.orderId}>
            <div className="orders_list_flex">
              <div>

                <div className="header-flex with_line">
                  <div><p className="order">{t('page.supplier_orders.order')} #{o?.orderId}</p></div>
                  <div>
                    <p>
                      <span className="orders">
                        {o?.productsQuantity === 1 ?
                          <>{o?.productsQuantity} {t('page.supplier_orders.product')}</> :
                          <>{o?.productsQuantity} {t('page.supplier_orders.products')}</>
                        }
                      </span>
                    </p>
                  </div>
                  {o?.createdTime ? <TimeBlockCreatedDate time={o?.createdTime} /> : ''}
                </div>

              </div>
              <div>

                <Button appearance="ghost" onClick={() => onOrderClick(o?.offerId, true)}>
                  <span>{t('page.supplier_orders.order_details')}</span>
                </Button>

              </div>
              <div>

                <div className="offers_list">
                  <TimeBlock time={o?.deliveryTimeRange} date={o?.deliveryDate} />
                  <div>
                    <div className="details_box">
                      <div className="ico-item price">
                        <p className="sub-header">{t('page.supplier_orders.final_price')}</p>
                        <p><span className="ltr">₪ {formatPrice(o?.preliminaryPrice ? o?.preliminaryPrice : 0)}</span></p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="details_box">
                      <div className="ico-item project">
                        <p className="sub-header">{t('page.supplier_orders.delivery_address')}</p>
                        {o?.street && o?.city ? <p>{o?.street}{o?.apt ? ` ${o?.apt},` : ','} {o?.city}</p> : 'no delivery address'}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="details_box">
                      <div className="ico-item delivery">
                        <p className="sub-header">{t('page.supplier_orders.delivery_method')}</p>
                        <p>{deliveryModeMaper(o?.deliveryMode, t)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {o?.needPackingSlip && <div className="warning_box" style={{background: 'rgba(252, 233, 236, 0.5)'}}>
                  <p>{t('page.requests_for_proposal.delivery_needs_confirmation')}</p>
                </div>}
              </div>

              {o?.needPackingSlip && <div className="submit_button">
                <Button appearance='primary' onClick={() => {
                  setShowUploadPackingModal(true);
                  setOrderIdValue(o?.orderId);
                  setOfferIdValue(o?.offerId);
                }}>
                  <span>{t('page.requests_for_proposal.confirm_delivery')}</span>
                </Button>
              </div>}
            </div>
          </div>
        )) : (
          <div className="don_not_have_orders review">
            <div className="banner"></div>
            <p className="title">{t('page.requests_for_proposal.don_not_have_orders.delivered.title')}</p>
            <p>{t('page.requests_for_proposal.don_not_have_orders.delivered.text')}</p>
          </div>
        )
      )}
      {showUploadPackingModal && <UploadPackingModal
        showUploadPackingModal={showUploadPackingModal}
        setShowUploadPackingModal={setShowUploadPackingModal}
        orderIdValue={orderIdValue}
        offerIdValue={offerIdValue}
        apirequest={getItemData}
      />}
    </>
  );
}

Delivered.propTypes = {
  offers: PropTypes.instanceOf(Object).isRequired,
  onOrderClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function Expired({ offers, onOrderClick, isFetching }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      {isFetching ? <Loader size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} /> : (
        offers.length ? offers.map((o) => (
          <div className="wrapper_orders_list_flex" key={o?.orderId}>
            <div className="orders_list_flex">
              <div>

                <div className="header-flex with_line">
                  <div><p className="order">{t('page.supplier_orders.order')} #{o?.orderId}</p></div>
                  <div>
                    <p>
                      <span className="orders">
                        {o?.productsQuantity === 1 ?
                          <>{o?.productsQuantity} {t('page.supplier_orders.product')}</> :
                          <>{o?.productsQuantity} {t('page.supplier_orders.products')}</>
                        }
                      </span>
                    </p>
                  </div>
                  {o?.createdTime ? <TimeBlockCreatedDate time={o?.createdTime} /> : ''}
                </div>

              </div>
              <div>

                <Button appearance="ghost" onClick={() => onOrderClick(o?.offerId)}>
                  <span>
                    {t('page.supplier_orders.order_details')}
                  </span>
                </Button>

              </div>
              <div>

                <div className="offers_list">
                  <TimeBlock time={o?.deliveryTimeRange} date={o?.deliveryDate} />
                  <div>
                    <div className="details_box">
                      <div className="ico-item price">
                        <p className="sub-header">{t('page.supplier_orders.preliminary_price')}</p>
                        <p><span className="ltr">₪ {formatPrice(o?.preliminaryPrice ? o?.preliminaryPrice : 0)}</span></p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="details_box">
                      <div className="ico-item project">
                        <p className="sub-header">{t('page.supplier_orders.delivery_address')}</p>
                        {o?.street && o?.city ? <p>{o?.street}{o?.apt ? ` ${o?.apt},` : ','} {o?.city}</p> : 'no delivery address'}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        )) : (
          <div className="don_not_have_orders expired">
            <div className="banner"></div>
            <p className="title">{t('page.requests_for_proposal.don_not_have_orders.expired.title')}</p>
            <p>{t('page.requests_for_proposal.don_not_have_orders.expired.text')}</p>
          </div>
        )
      )}
    </>
  );
}

Expired.propTypes = {
  offers: PropTypes.instanceOf(Object).isRequired,
  onOrderClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default RequestsForProposal;
