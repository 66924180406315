import { createActions } from 'redux-actions';
import { stringify } from 'query-string';
import productApi from '../../api/product';
import { PRODUCTS_PER_PAGE } from '../constantas/product';

const actions = createActions({
  getProductCatalog: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getProductById: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

export const getProductsByCategoryId = (page, categoryId) => async (dispatch) => {
  // const query_data = { page, perPage: PRODUCTS_PER_PAGE };
  const query_data = { page: page - 1, size: PRODUCTS_PER_PAGE };
  dispatch(actions.getProductCatalog.request());
  try {
    const response = await productApi.getProductsByCategoryId(categoryId, stringify(query_data));
    dispatch(actions.getProductCatalog.success({ items: response.data.content, result: 'Success' }));
  } catch (e) {
    dispatch(actions.getProductCatalog.error({ error: e }));
  }
};

export const getProductsByCategoryName = (page, categoryName) => async (dispatch) => {
  // const query_data = { categoryName, page, perPage: PRODUCTS_PER_PAGE };
  const query_data = { categoryName, page: page - 1, size: PRODUCTS_PER_PAGE };
  dispatch(actions.getProductCatalog.request());
  try {
    const response = await productApi.getProductsByCategoryName(stringify(query_data));
    // const pagesCalculation = Math.round(response.data.totalElements / PRODUCTS_PER_PAGE);
    const pagesCalculation = response.data.totalPages;
    const pages = pagesCalculation === 0 ? 1 : pagesCalculation;
    const products = response.data.content.map((item) => {
      return { ...item, count: 0 };
    });
    dispatch(actions.getProductCatalog.success({ items: products, totalItems: response.data.totalElements, pages, result: 'Success' }));
  } catch (e) {
    dispatch(actions.getProductCatalog.error({ error: e }));
  }
};

export const getProductById = (productId) => async (dispatch) => {
  dispatch(actions.getProductById.request());
  try {
    const response = await productApi.getProductById(productId);
    if (response.statusCode === 200) {
      dispatch(actions.getProductById.success({item: response.data}));
    } else {
      dispatch(actions.getProductById.error({ error: e }));
    }
  } catch (e) {
    dispatch(actions.getProductById.error({ error: e }));
  }
};
