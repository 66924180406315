import { handleActions } from 'redux-actions';
import actions from '../actions/draft';

export const initialState = {
  isFetching: false,
  error: '',
  item: [],
  result: null,
  validationErrors: {},
};

export default handleActions(
  {
    [actions.postAddAlternativeDraftProduct.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: [],
      result: null,
      validationErrors: {},
    }),

    [actions.postAddAlternativeDraftProduct.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
      result: payload.result,
    }),

    [actions.postAddAlternativeDraftProduct.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
      validationErrors: payload.validationErrors,
    }),

    // resetDraft
    [actions.resetDraft.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: [],
      result: null,
      validationErrors: {},
    }),

    [actions.resetDraft.success]: (state) => ({
      ...state,
      isFetching: false,
    }),

    [actions.resetDraft.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

  },
  initialState
);
