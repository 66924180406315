import { handleActions } from 'redux-actions';
import actions from '../actions/draft';

export const initialState = {
  isFetching: false,
  error: '',
  packages: [],
};

export default handleActions(
  {
    [actions.getAllPackageTypes.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      packages: [],
    }),

    [actions.getAllPackageTypes.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      packages: payload.packages,
    }),

    [actions.getAllPackageTypes.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),
  },
  initialState
);
