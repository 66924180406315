import { handleActions } from 'redux-actions';
import actions from '../actions/alternative';

export const initialState = {
  isFetching: false,
  error: '',
  items: [],
};

export default handleActions(
  {
    [actions.searchAlternative.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
    }),

    [actions.searchAlternative.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
    }),

    [actions.searchAlternative.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),
  },
  initialState
);
