import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NotAuthorized from '../../pages/NotAuthorized';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth.user);

  const permission = rest.permission;

  const checkIsActiveCustomer = user?.entityType === 'CUSTOMER' && user?.status === 'ACTIVE' && !!permission.find((element) => element === 'CUSTOMER') || user?.entityType === 'CUSTOMER' && user?.status !== 'ACTIVE' && !!permission.find((element) => element === 'CUSTOMER_CANDIDATE');
  const checkIsPermission = user?.entityType !== 'CUSTOMER' && permission.find((element) => element === user?.entityType);

  return (
    <Route
      {...rest}
      render={
        (props) => (checkIsActiveCustomer || checkIsPermission) && user?.status !== 'UNFINISHED' ? (
          <Component {...props} />
        ) :
          (<NotAuthorized/>)}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
};

export default PrivateRoute;
