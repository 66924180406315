import React, { useContext, createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon,
  Col,
  FlexboxGrid,
  Checkbox,
  Panel,
  Button,
  Tooltip,
  Whisper,
  Modal,
  Input,
} from 'rsuite';
import Order from '../../components/Order/Order';
import PurchaseCounter from '../../components/PurchaseCounter';
import { Link } from 'react-router-dom';
import { SignInContext, navItems } from '../../context/signInContext';
import { useMediaQuery } from 'react-responsive';
import NoImage from '../../assets/img/no_image.png';
import { CartContext } from '../../context/cartContext';
import BreadcrumbPath from '../../components/BreadcrumbPath';
import {addCustomerRequest, deleteCartItem} from '../../redux/actions/cart';
import { useTranslation } from 'react-i18next';
import { RequestProducts } from '../../components/RequestProducts';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ProductRemovalTooltip = ({ handleCloseTooltip, onCartItemDelete, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Tooltip {...rest}>
      <div className="flex-box">
        <div><p>{t('page.cart.delete_product')}</p></div>
        <div><Button appearance="link" onClick={() => {
          onCartItemDelete();
          handleCloseTooltip();
        }}><span>{t('page.cart.yes')}</span></Button></div>
        <div><Button appearance="link" onClick={handleCloseTooltip}><span>{t('page.cart.no')}</span></Button></div>
      </div>
    </Tooltip>
  );
};

ProductRemovalTooltip.propTypes = {
  handleCloseTooltip: PropTypes.func.isRequired,
  onCartItemDelete: PropTypes.func.isRequired,
};

const RemoveProduct = ({ onCartItemDelete }) => {
  const triggerRef = createRef();
  const [isOpen, setIsOpen] = useState(false);
  const isMobileView = useMediaQuery({ query: '(max-width: 1199px)' });

  function handleCloseTooltip() {
    triggerRef.current.close();
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('resize', handleCloseTooltip);
    } else {
      window.removeEventListener('resize', handleCloseTooltip);
    }

    return () => {
      window.removeEventListener('resize', handleCloseTooltip);
    };
  });

  return (
    <Whisper
      placement={isMobileView ? 'autoVertical' : 'autoVerticalEnd'}
      trigger="click"
      onOpen={() => setIsOpen(true)}
      speaker={<ProductRemovalTooltip handleCloseTooltip={handleCloseTooltip} onCartItemDelete={onCartItemDelete} />}
      triggerRef={triggerRef}
    >
      <Button className="delete"><Icon icon="close" /></Button>
    </Whisper>
  );
};

RemoveProduct.propTypes = {
  onCartItemDelete: PropTypes.func.isRequired,
};

function Cart() {
  const [checkAll, setCheckAll] = useState(true);
  const [indeterminate, setIndeterminate] = useState(false);
  const [placeRfpButtonClicked, setPlaceRfpButtonClicked] = useState(false);
  const dispatch = useDispatch();
  const { isModalOpen, handleIsModalOpen, setActiveKey } = useContext(SignInContext);
  const { cartItems, customerRequest, handlePlus, handleMinus, handleCheckbox } = useContext(CartContext);
  const [cartItemsCheckedValues, setCartItemsCheckedValues] = useState(cartItems.length);
  const [showAddToRFPModal, setShowAddToRFPModal] = useState(false);
  const [showAddToRFPSection, setShowAddToRFPSection] = useState(false);
  const [showRFPInfo, setShowRFPInfo] = useState(false);
  const localStorageCustomerRequest = JSON.parse(localStorage.getItem('customerRequest'));

  const renderHtml = (text) => <p dangerouslySetInnerHTML={{ __html: text }} />;
  const searchBreak = '/n';
  const replaceBreakWith = '<br />';
  const searchItemStart = '**';
  const replaceItemStartWith = '<li class="disc">';
  const searchItemEnd = '##';
  const replaceItemEndWith = '</li>';
  const processTemplate = (text) => {
    return text.replaceAll(
      searchBreak, replaceBreakWith
    ).replaceAll(
      searchItemStart, replaceItemStartWith
    ).replaceAll(
      searchItemEnd, replaceItemEndWith
    );
  };

  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.user);

  const onCartItemDelete = (id) => {
    dispatch(deleteCartItem(id));
  };

  const isLoggedIn = Boolean(Object.keys(currentUser).length);

  useEffect(() => {
    if (localStorageCustomerRequest !== customerRequest) {
      dispatch(addCustomerRequest(localStorageCustomerRequest));
    }
  }, [localStorageCustomerRequest, customerRequest ]);

  useEffect(() => {
    if (!localStorageCustomerRequest) {
      setShowAddToRFPSection(true);
    } else setShowRFPInfo(true);
  }, [localStorageCustomerRequest]);

  useEffect(() => {
    const checkedValues = cartItems.filter((val) => val.isReadyForAlternatives).length;
    setCartItemsCheckedValues(checkedValues);
    setIndeterminate(checkedValues > 0 && checkedValues < cartItems.length);
    setCheckAll(checkedValues === cartItems.length);
  }, [cartItems]);

  const handleCheckAll = (value, checked) => {
    setCheckAll(checked);
    setIndeterminate(false);
    cartItems.forEach((p) => {
      handleCheckbox(p.productId, p.count, checked);
    });
  };

  const handleChange = (value, productId, count) => {
    handleCheckbox(productId, count, value);
  };

  const placeRfpClickedFirstTime = () => {
    setPlaceRfpButtonClicked(true);
  };

  return (
    <>
      {(cartItems?.length > 0) ? (
        <div className="checkout white_bg cart_box">
          <div className="container">
            <h1>{t('page.cart.cart')}</h1>
            <div className="checkout-flex-col">
              <FlexboxGrid className="flex-420-col">
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

                  <Panel bordered className="panel_edit gray_bg">
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checkAll}
                      onChange={handleCheckAll}>
                      <span className="text">
                        <span className="ltr"><span className="light">({cartItemsCheckedValues} of {cartItems.length})</span> {t('page.cart.ready_get_alternative_product')}</span>
                      </span>
                    </Checkbox>
                  </Panel>

                  <Panel bordered className="panel_edit cart_list">
                    <div className="cart_list_table">
                      {cartItems?.map((product) => (
                        <div key={product?.productId}>
                          <div>
                            <Checkbox
                              id={product?.productId}
                              checked={product?.isReadyForAlternatives}
                              onChange={(value, checked) => handleChange(checked, product?.productId, product?.count)}
                            />
                          </div>
                          <div>
                            <div className="img_box">
                              <img
                                src={product?.fileName ? product?.fileName :
                                  product?.imageFileName ? product?.imageFileName :
                                    NoImage}
                                alt={product?.koneboneProductName}
                              />
                            </div>
                          </div>
                          <div>
                            {/* <p><span className="product_number">{product?.productNumber}</span></p> */}
                            <p><span className="product_number">{product?.catalogNumber}</span></p>
                            <p className="description">{product?.koneboneProductName}</p>
                          </div>
                          <PurchaseCounter
                            count={product?.count}
                            productId={product?.productId}
                            handlePlus={
                              () => handlePlus(product?.productId, product?.count, product?.isReadyForAlternatives)}
                            handleMinus={
                              () => handleMinus(product?.productId, product?.count, product?.isReadyForAlternatives)}
                            isCartComponent
                          />
                          <div>
                            <p className="price-all"><span className="ltr">₪{(product?.minKbPrice * product?.count).toFixed(2)} - ₪{(product?.maxKbPrice * product?.count).toFixed(2)}</span></p>
                            <p><span className="ltr">₪{product?.minKbPrice} - ₪{product?.maxKbPrice}</span></p>
                          </div>
                          <div>
                            <RemoveProduct onCartItemDelete={() => onCartItemDelete(product?.productId)} />
                          </div>
                        </div>
                      ))}
                    </div>
                    {showRFPInfo && (
                      <div className="warning_box warning_add_rfp">
                        <div style={{ width: '80%' }}>
                          <strong> {t('page.customer_request.info_teхt')} </strong>
                          <br/>
                          <span
                            style={{
                              color: '#6E6E6E !important',
                              wordBreak: 'break-all',
                              whiteSpace: 'pre-line'}}>
                            {renderHtml(processTemplate(localStorageCustomerRequest))}
                          </span>
                        </div>
                        <div className='rfp_icon'>
                          <Link to="#" onClick={() => setShowAddToRFPModal(true)}>
                            <EditIcon style={{color: '#7C7C7C'}} />
                          </Link>
                          <Link to="#">
                            <DeleteIcon
                              style={{color: '#7C7C7C'}}
                              onClick={() => {
                                setShowAddToRFPSection(true);
                                setShowRFPInfo(false);
                                localStorage.removeItem('customerRequest');
                              }}/>
                          </Link>
                        </div>
                      </div>
                    )}
                  </Panel>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                  <Panel bordered className="gray_bg">
                    <Order
                      lite
                      placeRfpButtonClicked={placeRfpButtonClicked}
                      placeRfpClickedFirstTime={placeRfpClickedFirstTime} />
                  </Panel>
                  {placeRfpButtonClicked && (
                    <div className="warning_box">
                      <p>{t('page.order.finish_rfp_info')}</p>
                    </div>
                  )
                  }
                  {showAddToRFPSection &&
                  <RequestProducts
                    onClick={() => setShowAddToRFPModal(true)}
                  />
                  }
                  {showAddToRFPModal && <AddRFPModal
                    showAddToRFPModal={showAddToRFPModal}
                    setShowAddToRFPModal={setShowAddToRFPModal}
                    setShowRFPInfo={setShowRFPInfo}
                    setShowAddToRFPSection={setShowAddToRFPSection}
                  />}
                  {!isLoggedIn && (
                    <div className="warning_box">
                      <p><Link to="#" onClick={() => {
                        setActiveKey(navItems.REGISTRATION);
                        handleIsModalOpen(!isModalOpen);
                      }}>{t('page.order.register')}</Link> {t('page.order.or')} <Link className='card_login' to="#" onClick={() => {
                        setActiveKey(navItems.SIGN_IN);
                        handleIsModalOpen(!isModalOpen);
                      }}>{t('page.order.sign')}</Link> {t('page.order.in_to_your_account')}</p>
                    </div>
                  )
                  }
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty_cart">
          <div className="container">
            <BreadcrumbPath />
            <div className="empty_cart_box">
              <div className="banner">
                <span />
              </div>
              <p className="header">{t('page.cart.cart_empty')}</p>
              <div className="button">
                <Link to="/product_catalog">
                  <Button block type="button" appearance="primary"><span>{t('page.cart.go_to_catalog')}</span></Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function AddRFPModal({ showAddToRFPModal, setShowAddToRFPModal, setShowRFPInfo, setShowAddToRFPSection }) {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localStorageCustomerRequest = JSON.parse(localStorage.getItem('customerRequest'));

  useEffect(() => {
    setValue(localStorageCustomerRequest);
  }, []);

  const handlerModalClose = () => {
    setShowAddToRFPModal(!showAddToRFPModal);
  };

  const onChange = (val) => {
    setValue(val);
    setError('');
  };

  const onClick = () => {
    if (value.length > 400) {
      setError(t('page.customer_request.error-text'));
    } else {
      setShowAddToRFPModal(false);
      setShowRFPInfo(true);
      setShowAddToRFPSection(false);
      dispatch(addCustomerRequest(value));
    }
  };
  return (
    <Modal
      className="add-rfp"
      overflow={false}
      show={showAddToRFPModal}
      onHide={handlerModalClose}
      keyboard={true}
      autoFocus={true}
    >
      <Modal.Header>
        <p
          style={{
            fontWeight: 400,
            width: '414px',
          }}
        >
          {t('page.customer_request.modal_header')}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Input
          className={error ? 'error-textarea' : 'textarea-request-product'}
          componentClass="textarea"
          as="textarea"
          rows={4}
          value={value}
          onChange={(val) => onChange(val)}
        />
        {error && <div style={{ textAlign: 'initial', color: '#E02443', marginTop: '5px'}}> {error} </div>}
        <Button block type="button" appearance="primary" onClick={onClick}>
          <span>
            {t('page.customer_request.modal_button')}
          </span>
        </Button>
      </Modal.Body>
    </Modal>
  );
}

AddRFPModal.propTypes = {
  showAddToRFPModal: PropTypes.bool.isRequired,
  setShowAddToRFPModal: PropTypes.func.isRequired,
  setShowRFPInfo: PropTypes.func.isRequired,
  setShowAddToRFPSection: PropTypes.func.isRequired,
};

export default Cart;
