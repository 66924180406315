import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { InputPicker } from 'rsuite';
import { useTranslation } from 'react-i18next';

function Home() {
  const { i18n } = useTranslation();
  const [userLanguage, setUserLanguage] = useState(i18n.language);

  const handleChange = (value) => {
    setUserLanguage(value);
  };

  useEffect(() => {
    localStorage.setItem('userLanguage', userLanguage);
    i18n.changeLanguage(userLanguage);
    document.getElementsByTagName('html')[0].setAttribute('dir', userLanguage === 'en' ? 'ltr' : 'rtl');
  }, [userLanguage]);

  return (
    <div style={{ padding: '48px 0', width: '100%' }}>
      <div className="container">

        <InputPicker
          cleanable={false}
          value={userLanguage}
          onChange={handleChange}
          data={[{'label': 'EN', 'value': 'en'}, {'label': 'HEB', 'value': 'heb'}]}
          style={{ width: 224 }}
        />

        <hr />

        <p><Link to="/">Home</Link></p>
        <p><Link to="/cart">Cart</Link></p>
        <p><Link to="/checkout">Checkout</Link></p>

        {/* <p><Link to="/order_list">Order List</Link></p> */}
        <p><Link to="/order_list_item">Order List Item</Link></p>
        {/* <p><Link to="/order_list_item_new">Order List Item New</Link></p> */}

        <p><Link to="/supplier/orders/delivery-details/555">2.A - Supplier Orders - Accepted - Order details - Awaiting delivery</Link></p>
        <p><Link to="/order_delivered">2.С - Supplier Orders - Accepted - Delivered</Link></p>
        <p><Link to="/order_completed">5 - Supplier Orders - Accepted - Completed</Link></p>
        <p><Link to="/order_in_review">Supplier Orders - In review</Link></p>

        <p><Link to="/customer/offers_list/555">Offers list</Link></p>
        <p><Link to="/customer/successfully_accept/597">Your order has been successfully placed</Link></p>
        <p><Link to="/successfully_paid">Your order has been successfully paid</Link></p>
        <p><Link to="/payment_failed">Payment failed</Link></p>

        <p><Link to="/customer/offer_confirmation/244">Offer confirmation</Link></p>
        <p><Link to="/offer_confirmation_step_1">Offer confirmation - Step 1</Link></p>

        <p><Link to="/offer_confirmation_step_3">Payment is processing&hellip;</Link></p>
        {/* <p><Link to="/connect_buy_now">Connect buy now pay later payment</Link></p> */}

        <hr />

        <p><Link to="/offer_sent_successfully">Offer sent successfully</Link></p>
        {/* <p><Link to="/supplier_orders_order_details">Supplier Orders - Order Details (new RFP)</Link></p> */}
        {/* <p><Link to="/supplier/delivery/expected">Supplier Orders - Make Offer - Delivery details (Customer&apos;s expected delivery time)</Link></p>
        <p><Link to="/supplier/delivery/specific">Supplier Orders - Make Offer - Delivery details (Specific delivery time)</Link></p>
        <p><Link to="/supplier/delivery/without_delivery">Supplier Orders - Make Offer - Delivery details (Without delivery)</Link></p>
        <p><Link to="/supplier/make_offer">Supplier Orders - Make Offer</Link></p> */}
        {/* <p><Link to="/orders/new_orders">Supplier Orders - Requests for proposal</Link></p> */}

      </div>
    </div>
  );
}

export default Home;
