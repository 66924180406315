import { createActions } from 'redux-actions';
import contactApi from '../../api/contact';

const actions = createActions({
  postContactForm: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

export const postContactForm = (data, setIsOpenRequestSentModal, setIsSuccess) => async (dispatch) => {
  dispatch(actions.postContactForm.request());
  const query_data = {
    name: data.fullName?.val,
    contact: data.phoneOrEmail?.val,
    interest: data.fieldOfInterest?.val,
    comment: data.message?.val,
  };
  try {
    const response = await contactApi.postContactForm(query_data);

    if (response.statusCode !== 200) throw new Error(JSON.stringify(response));
    dispatch(actions.postContactForm.success({ item: response.data, result: 'Success' }));
    setIsSuccess(true);
  } catch (e) {
    const response = JSON.parse(e.message);
    dispatch(actions.postContactForm.error({
      error: response?.message?.message,
      validationErrors: response?.data?.validationErrors,
    }));
    setIsSuccess(false);
  } finally {
    setIsOpenRequestSentModal(true);
  }
};
