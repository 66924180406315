import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleIsTimeConstraintsShown, toggleIsAdditionalServicesShown, toggleIsProjectsShown } from '../../redux/actions/order';
import { addIsCheckedStep } from '../../redux/actions/checkout';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  // Checkbox,
  Button,
  Loader,
} from 'rsuite';
// import { Scrollbar } from "react-scrollbars-custom"
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { OrderedProduct } from './OrderedProduct';
import {
  placeRFP,
  // confirmOrder,
  confirmOrderById,
} from '../../redux/actions/order';
import { setCurrentUser } from '../../redux/actions/auth/signIn';
import { resetUserState } from '../../redux/actions/auth/logout';
import { resetProjectData, resetAdditionalServicesData, resetConstraintsData } from '../../redux/actions/checkout';
import { resetCartData } from '../../redux/actions/cart';
// import { showNotification } from '../../redux/actions/notification';
import { getDraftOrderByManager } from '../../redux/actions/order';
import { SignInContext } from '../../context/signInContext';
import { showNotification } from '../../redux/actions/notification';

function Order({
  lite,
  placeRfpClickedFirstTime,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [minTotalPrice, setMinTotalPrice] = useState(null);
  const [maxTotalPrice, setMaxTotalPrice] = useState(null);
  const cartItems = useSelector((state) => state.cart.items);
  const customerRequest = useSelector((state) => state.cart.customerRequest);
  const checkout = useSelector((state) => state.checkout);
  const order = useSelector((state) => state.order.item);
  const currentUser = useSelector((state) => state.auth.user);
  const isTimeConstraintsShown = useSelector((state) => state.order.isTimeConstraintsShown);
  const isProjectsShown = useSelector((state) => state.order.isProjectsShown);
  const isAdditionalServicesShown = useSelector((state) => state.order.isAdditionalServicesShown);
  const [viewProducts, setViewProducts] = useState(false);
  const { t, i18n } = useTranslation();

  const isPRFPlaced = useSelector((state) => state.order.isFetching);
  // const isOrderConfirmed = useSelector((state) => state.order.isFetching);

  const renderHtml = (text) => <p dangerouslySetInnerHTML={{ __html: text }} />;
  const searchBreak = '/n';
  const replaceBreakWith = '<br />';
  const searchItemStart = '**';
  const replaceItemStartWith = '<li class="disc">';
  const searchItemEnd = '##';
  const replaceItemEndWith = '</li>';
  const processTemplate = (text) => {
    return text?.replaceAll(
      searchBreak, replaceBreakWith
    ).replaceAll(
      searchItemStart, replaceItemStartWith
    ).replaceAll(
      searchItemEnd, replaceItemEndWith
    );
  };

  const user = useSelector((state) => state.auth.user);
  const items = useSelector((state) => state.cart.items || []);
  const isAuthorized = user && Object.keys(user).length;
  const isCheckedStep = useSelector((state) => state.checkout.isCheckedStep);
  const { handleIsModalOpen } = useContext(SignInContext);

  useEffect(() => {
    const minPrice = cartItems.reduce((acc, i) => {
      return acc + (i?.count ? i.minKbPrice * i?.count : i.minKbPrice);
    }, 0);
    setMinTotalPrice(minPrice);
    const maxPrice = cartItems.reduce((acc, i) => {
      return acc + (i?.count ? i.maxKbPrice * i?.count : i.maxKbPrice);
    }, 0);
    setMaxTotalPrice(maxPrice);
  }, [cartItems]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setCurrentUser((data) => {
      if (data !== 'ACTIVE') {
        dispatch(showNotification('Info', 'info', (<p>{t('page.order.wait_for_approve')}</p>)));
        placeRfpClickedFirstTime();
        dispatch(placeRFP(cartItems));
        return;
      }
      if (data === 'NotAuth' ) {
        dispatch(resetUserState());
        handleIsModalOpen(true);
        return;
      } else {
        dispatch(placeRFP(cartItems));
        dispatch(resetConstraintsData());
        dispatch(resetProjectData());
        dispatch(resetAdditionalServicesData());
        history.push('/checkout');
      }
    }));
  };


  useEffect(() => {
    if (order && Object.keys(order).length === 0 && isAuthorized) {
      dispatch(getDraftOrderByManager());
    }
  }, [isAuthorized]);

  const handleConfirmOrder = async () => {
    const data = items.map(({count, isReadyForAlternatives, productId}) => {
      return {
        quantity: count,
        isReadyForAlternatives,
        productId,
        consumptionQuantity: null,
      };
    });
    const sendData = {
      products: [...data],
      customerRequest: customerRequest,
    };
    dispatch(confirmOrderById(order?.orderId, sendData, () => {
      dispatch(resetCartData());
      dispatch(resetConstraintsData());
      dispatch(resetProjectData());
      dispatch(resetAdditionalServicesData());
      dispatch(toggleIsTimeConstraintsShown(true));
      dispatch(toggleIsProjectsShown(false));
      dispatch(toggleIsAdditionalServicesShown(false));
      dispatch(addIsCheckedStep(false));
      history.push('/successful_checkout');
      localStorage.removeItem('customerRequest');
    }));
  };

  const productsView = viewProducts ? t('page.order.hide_products') : t('page.order.view_products');


  const isLoggedIn = Boolean(Object.keys(currentUser).length);

  const isConfirmButtonDisabled = (checkout?.timeConstraints && Object.keys(checkout?.timeConstraints).length === 0) ||
    (checkout?.projectData && Object.keys(checkout?.projectData).length === 0);

  const isCheckoutRoute = history.location.pathname === '/checkout';

  return (
    <div className="order-component">
      <form onSubmit={(e) => handleSubmit(e)}>
        {order?.orderId && <p className="header">{t('page.order.order')} {`#${order?.orderId}`}</p>}

        {/*
          <div>
            <Checkbox><span className="text">Ready to get partial order</span></Checkbox>
          </div>
        */}

        <div className={`grey_box${lite ? ' white_bg' : ''}`}>
          <p>{t('page.order.estimated_total')}</p>
          <p className="price"><span className="ltr">₪ {minTotalPrice?.toFixed(2)} - ₪ {maxTotalPrice?.toFixed(2)}</span></p>
          <p className="vat">{t('page.order.without_vat')}</p>
          {lite ?
            (
              <div>{t('page.order.for_products', { p: cartItems?.length })}</div>
            ) : (
              <div className="toggle_hide_view">
                <Button appearance="link" onClick={() => setViewProducts(!viewProducts)}>
                  <span className="flex">
                    <span>{productsView}</span>
                    <span>
                      <i className={`rs-icon ${viewProducts ? 'open' : 'close'}`} />
                    </span>
                  </span>
                </Button>
              </div>
            )
          }
        </div>

        {!isCheckoutRoute ?
          !isPRFPlaced ? (
            <div className="submit_button">
              <Button block type="button" appearance="primary" onClick={(e) => handleSubmit(e)} disabled={!isLoggedIn}><span>{t('page.order.place_rfp')}</span></Button>
            </div>
          ) : (
            <div className="submit_button">
              <Button block type="button" appearance="primary">
                <Loader size="sm" />
                <span>{i18n.language === 'heb' ? '…טוען' : 'Loading...'}</span>
              </Button>
            </div>
          ) :
          !isPRFPlaced ? (
            <div className="submit_button">
              <Button
                block type="button"
                appearance="primary"
                onClick={(e) => handleConfirmOrder(e)}
                disabled={ isCheckedStep === undefined || isTimeConstraintsShown ||
                isProjectsShown || isAdditionalServicesShown || isConfirmButtonDisabled}>
                <span>
                  {t('page.order.confirm_order')}
                </span>
              </Button>
            </div>
          ) : (
            <div className="submit_button">
              <Button block type="button" appearance="primary">
                <Loader size="sm" />
                <span>{i18n.language === 'heb' ? '…טוען' : 'Loading...'}</span>
              </Button>
            </div>
          )}
        {/* <div className="submit_button">
          <Button block type="submit" disabled={isConfirmDissabled} appearance="primary"><span>Place RFP</span></Button>
        </div> */}

        {
          history.location.pathname === '/cart' && (
            <div className="agreement_text">
              <p>
                <Trans
                  i18nKey='page.order.terms_and_conditions'
                  components={{ Link: <Link to='/terms-and-conditions-for-customers'></Link>, Link2: <Link to='/privacy-policy'></Link> }}
                />
              </p>
            </div>
          )
        }

        {viewProducts && history.location.pathname !== '/cart' ?
          (
            <>
              <div className="products-list">
                {/* <Scrollbar style={{ width: "250px", height: "650px" }}> */}
                <ListOrdered products={cartItems} cartItems={cartItems} />
                {/* </Scrollbar> */}
              </div>
              {customerRequest && (
                <div className="warning_box warning_add_rfp">
                  <div>
                    <strong> {t('page.customer_request.info_teхt')} </strong>
                    <br/>
                    <span
                      style={{
                        color: '#6E6E6E !important',
                        wordBreak: 'break-all',
                        whiteSpace: 'pre-line'}}>
                      {renderHtml(processTemplate(customerRequest))}
                    </span>
                  </div>
                </div>
              )}
            </>
          ) : ''}
      </form>
    </div>
  );
}

const ListOrdered = ({ products, cartItems }) => (
  <ul>
    {products.map((product, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={index}>
        <OrderedProduct product={product} cartItems={cartItems} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  products: PropTypes.instanceOf(Array).isRequired,
  cartItems: PropTypes.instanceOf(Array).isRequired,
};

Order.propTypes = {
  lite: PropTypes.bool,
  isConfirmDissabled: PropTypes.bool,
  setSelectedComponent: PropTypes.func,
  confirmOrder: PropTypes.func,
  placeRfpClickedFirstTime: PropTypes.func,
};

Order.defaultProps = {
  lite: false,
  isConfirmDissabled: false,
};

export default Order;
