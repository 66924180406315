export const formatPhoneNumber = (value = '') => {
  const phoneNumber = validatePhoneInput(value);
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 10)}`;
};

export const validatePhoneInput = (value) => {
  if (!value) return value;
  return value.replace(/[^\d]/g, '');
};
