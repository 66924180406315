import { post, get } from './';

const postAddAlternativeDraftProduct = (query) => post('/draftproduct', query);
const getAllPackageTypes = () => get('/package_type');
const getAllMeasurements = () => get('/measurement');

const draftApi = {
  postAddAlternativeDraftProduct,
  getAllPackageTypes,
  getAllMeasurements,
};

export default draftApi;
