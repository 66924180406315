import actions from './constants';
import authApi from '../../../api/auth';

export const setAddressInfoData = (data) => async (dispatch) => {
  dispatch(actions.setAddressInfoData.request());
  try {
    dispatch(actions.setAddressInfoData.success({ addressInfo: data }));
  } catch (e) {
    dispatch(actions.setAddressInfoData.error({ error: e.message, showMessage: true }));
  }
};

export const resetAddressInfoData = () => async (dispatch) => {
  dispatch(actions.resetAddressInfoData.request());
  try {
    dispatch(actions.resetAddressInfoData.success({ addressInfo: {} }));
  } catch (e) {
    dispatch(actions.resetAddressInfoData.error({ error: e.message, showMessage: true }));
  }
};

export const createAddressInfoData = (address) => async (dispatch) => {
  dispatch(actions.createAddressInfo.request());
  try {
    const response = await authApi.createAddressInfoData(address);
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.createAddressInfo.success({ addressInfo: address }));
  } catch (e) {
    console.log( e.message);
    dispatch(actions.createAddressInfo.error({ error: e.message, showMessage: true }));
  }
};
