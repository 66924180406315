export const smsCodeVerification = (actions) => ({
  // confirm sign in sms code
  [actions.confirmSignInCode.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    goToNextStep: false,
    isCodeConfirmed: false,
    user: state.user,
  }),

  [actions.confirmSignInCode.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    goToNextStep: false,
    isCodeConfirmed: true,
    user: payload.user,
  }),

  [actions.confirmSignInCode.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
    goToNextStep: false,
    isCodeConfirmed: false,
    user: state.user,
  }),

  // confirm registration sms code
  [actions.confirmRegistrationCode.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    goToNextStep: false,
    isCodeConfirmed: false,
    user: state.user,
  }),

  [actions.confirmRegistrationCode.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    goToNextStep: false,
    isCodeConfirmed: true,
    user: payload.user,
  }),

  [actions.confirmRegistrationCode.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
    goToNextStep: false,
    isCodeConfirmed: false,
  }),

  // reset sms code isCodeConfirmed
  [actions.resetIsCodeConfirmed]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    goToNextStep: false,
    isCodeConfirmed: false,
  }),

  [actions.resetRegistrationUser.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    user: payload.user,
  }),

  [actions.resetRegistrationUser.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // reset registration data
  [actions.resetRegistrationUser.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    user: state.user,
    goToNextStep: false,
  }),
});
