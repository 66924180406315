import { handleActions } from 'redux-actions';
import actions from '../actions/search';

export const initialState = {
  isFetching: false,
  error: '',
  count: [],
  products: [],
  totalItems: null,
  pages: null,
};

export default handleActions(
  {
    [actions.byCategoryNameAndSearch.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      count: [],
      products: [],
    }),

    [actions.byCategoryNameAndSearch.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      count: payload.count,
      products: payload.products,
      totalItems: payload.totalItems,
      pages: payload.pages,
    }),

    [actions.byCategoryNameAndSearch.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),
  },
  initialState
);
