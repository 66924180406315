import { createActions } from 'redux-actions';
import Notice from '../../components/Notice';

const actions = createActions({
  showNotification: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  closeNotification: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  closeAllNotifications: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

export const showNotification = (title, type, component) => async (dispatch) => {
  dispatch(actions.showNotification.request());
  try {
    dispatch(actions.showNotification.success({ showMessage: true }));
    Notice(title, type, component);
  } catch (e) {
    dispatch(actions.showNotification.error({ error: e, showMessage: false }));
  }
};

export const closeNotification = () => async (dispatch) => {
  dispatch(actions.closeNotification.request());
  try {
    dispatch(actions.closeNotification.success({ showMessage: true }));
    Notice('', 'close');
  } catch (e) {
    dispatch(actions.closeNotification.error({ error: e, showMessage: false }));
  }
};

export const closeAllNotifications = () => async (dispatch) => {
  dispatch(actions.closeAllNotifications.request());
  try {
    dispatch(actions.closeAllNotifications.success({ showMessage: true }));
    Notice('', 'closeAll');
  } catch (e) {
    dispatch(actions.closeAllNotifications.error({ error: e, showMessage: false }));
  }
};
