import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal, Form, Nav } from 'rsuite';
import SignIn from './SignIn/SignIn';
import EnterConfirmSmsCode from './EnterConfirmSmsCode';
import Registration from './Registration/Registration';
import CancelRegistrationModal from './Registration/CancelRegistrationModal';
import { resetGoToNextStep } from '../../redux/actions/auth/registration';

import { SignInContext } from '../../context/signInContext';

import { useTranslation } from 'react-i18next';

const navItems = {
  SIGN_IN: 'sign_in',
  REGISTRATION: 'register',
};

function Authentication() {
  const dispatch = useDispatch();
  const {
    isModalOpen,
    handleIsModalOpen,
    onHideModal,
    cancelAbortRegistrationAction,
    abortRegistrationAction,
    handleShowRegistration,
    handleShowSignIn,
    handleSelect,
    isCancelRegistrationModalOpen,
    isShowSignIn,
    isShowRegistration,
    isShowConfirmCodeModal,
    registrationData,
    setRegistrationData,
    activeKey,
    handleIsCancelRegistrationModalOpen,
    handleShowConfirmCode,
    setIsShowSignIn,
    setIsShowConfirmCodeModal,
    setIsShowRegistration,
  } = useContext(SignInContext);
  const user = useSelector((state) => state.auth.user);
  const isAuthorized = user && Object.keys(user).length;
  const { pathname } = useLocation();
  const { t } = useTranslation();


  useEffect(() => {
    if (!isAuthorized && pathname === '/404') handleIsModalOpen(true);
  }, []);

  return (
    <div>
      <Modal
        className="authentication"
        overflow={false}
        show={isModalOpen}
        onHide={() => {
          onHideModal();
          dispatch(resetGoToNextStep());
        }}
        keyboard={true}
        autoFocus={true}>
        <Modal.Header>
          <div className="nav-list">
            <Nav activeKey={activeKey} appearance="subtle" onSelect={handleSelect}>
              <Nav.Item eventKey="sign_in">{t('auth.sign_in.sign_in')}</Nav.Item>
              <Nav.Item eventKey="register">{t('auth.register.register')}</Nav.Item>
            </Nav>
          </div>
        </Modal.Header>
        <Modal.Body className={activeKey === navItems.SIGN_IN ? 'sign_in' : 'registration'}>
          <>
            {activeKey === navItems.SIGN_IN ?
              isShowSignIn && (
                <SignIn
                  handleShowSignIn={(val) => handleShowSignIn(val)}
                  handleShowConfirmCode={() => handleShowConfirmCode(true)}
                  handleIsCancelRegistrationModalOpen={handleIsCancelRegistrationModalOpen}
                  onHideModal={onHideModal}
                />
              ) :
              isShowRegistration && (
                <Registration
                  handleShowRegistration={(val) => handleShowRegistration(val)}
                  handleShowConfirmCode={() => handleShowConfirmCode(true)}
                  isCancelRegistrationModalOpen={isCancelRegistrationModalOpen}
                  handleIsCancelRegistrationModalOpen={handleIsCancelRegistrationModalOpen}
                  registrationData={registrationData}
                  setRegistrationData={setRegistrationData}
                />
              )}
            {isShowSignIn ? (isShowConfirmCodeModal && (
              <Form fluid>
                <EnterConfirmSmsCode
                  handleShowConfirmCode={(val) => handleShowConfirmCode(val)}
                  handleIsModalOpen={(val) => handleIsModalOpen(val)}
                  editPhoneNumber={(val) => setIsShowRegistration(val)}
                  setIsShowConfirmCodeModal={(val) => setIsShowConfirmCodeModal(val)}
                  isRegistrationPage
                  handleShowSignIn={handleShowSignIn}
                  handleShowRegistration={handleShowRegistration}
                />
              </Form>
            )) : (isShowConfirmCodeModal && (
              <Form fluid>
                <EnterConfirmSmsCode
                  handleShowConfirmCode={(val) => handleShowConfirmCode(val)}
                  handleIsModalOpen={(val) => handleIsModalOpen(val)}
                  editPhoneNumber={(val) => setIsShowSignIn(val)}
                  setIsShowConfirmCodeModal={(val) => setIsShowConfirmCodeModal(val)}
                  handleShowSignIn={handleShowSignIn}
                  handleShowRegistration={handleShowRegistration}
                />
              </Form>
            ))}
          </>
        </Modal.Body>
      </Modal>
      <CancelRegistrationModal
        isModalOpen={isCancelRegistrationModalOpen}
        handleIsModalOpen={handleIsCancelRegistrationModalOpen}
        abortAction={abortRegistrationAction}
        cancelAction={cancelAbortRegistrationAction}
      />
    </div>
  );
}

export default Authentication;
