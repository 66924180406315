export const companyInfoData = (actions) => ({
  // set company info data
  [actions.setCompanyInfoData.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    companyInfo: state.companyInfo,
  }),

  [actions.setCompanyInfoData.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    companyInfo: payload.companyInfo,
  }),

  [actions.setCompanyInfoData.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // reset company info data
  [actions.resetCompanyInfoData.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
  }),

  [actions.resetCompanyInfoData.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    companyInfo: payload.companyInfo,
  }),

  [actions.resetCompanyInfoData.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // create company info
  [actions.createCompanyInfo.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
  }),

  [actions.createCompanyInfo.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    companyInfo: payload.companyInfo,
  }),

  [actions.createCompanyInfo.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),
});
