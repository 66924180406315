import React from 'react';
import { Button } from 'rsuite';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setActivePage } from '../redux/actions/order';

function OfferSentSuccessfully() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="offer_confirmation_info">
      <div className="container">
        <div className="offer_confirmation_info_box">
          <div className="banner well_done">
            <span />
          </div>
          <p className="header">
            {t('page.well_done.header')}
          </p>
          <p>
            {t('page.well_done.text')}
          </p>
          <div className="button">
            <div className="flex">
              <div>
                <Button
                  block
                  type="button"
                  appearance="primary"
                  onClick={() => {
                    history.push('/supplier/orders');
                    dispatch(setActivePage(1));
                  }
                  }><span>
                    {t('page.well_done.go_to_orders')}
                  </span></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferSentSuccessfully;
