import React, { useState, useEffect } from 'react';
import {
  Panel,
  PanelGroup,
  Breadcrumb,
  Icon,
  FlexboxGrid,
  Col,
  Button,
  Loader,
} from 'rsuite';
import PropTypes from 'prop-types';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getOfferItemDetailsSupplier } from '../redux/actions/offer';
import { formatPrice } from '../utils';
import NoImage from '../assets/img/no_image.png';
import { deliveryModeMaper, vehicleWeightTypeValue } from '../utils';
import NeedHelpComponent from '../components/NeedHelpComponent';
import UploadPackingModal from './SupplierOrders/UploadPackingModal';
import NotFound from './NotFound';

const ListOrdered = ({ orderedProducts }) => (
  <ul>
    {orderedProducts.map((product, index) => (
      <li key={index}>
        <OrderedProduct product={product} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  orderedProducts: PropTypes.instanceOf(Array).isRequired,
};

const OrderedProduct = ({ product }) => {
  const isMobileView = useMediaQuery({ query: '(max-width: 1199px)' });
  const {
    catalogNumber,
    supplierNumber,
    productName,
    quantity,
    pictureFileName,
    outOfStock,
    totalPrice,
    price,
    orderQuantity,
  } = product;
  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${outOfStock ? ' opacity' : ''}`}>

        {isMobileView ? (
          <>
            <div>
              <div className="products-img">
                <img src={pictureFileName ? pictureFileName : NoImage} alt="product_png" />
                {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
              <p className="quantity">
                <span>
                  x
                  {quantity ? quantity : orderQuantity}
                </span>
              </p>
            </div>
            <div>

              <div className="flex_box_p">
                <p><span className="product_number">{catalogNumber}</span></p>
                {supplierNumber && <p><span className="ltr">{supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
              </div>

              <p className="description">{productName}</p>
              {totalPrice && (
                <>
                  <p className="price-all"><span className="ltr">₪ {totalPrice ? totalPrice : 0}</span></p>
                  <p><span className="ltr">₪ {price ? price : 0}/{t('page.order.item')}</span></p>
                </>)
              }
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="products-img">
                <img src={pictureFileName ? pictureFileName : NoImage} alt="product_png" />
                {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
            </div>
            <div className="initial_padding">
              <div className="flex_box_p">
                <p><span className="product_number">{catalogNumber}</span></p>
                {supplierNumber && <p><span className="ltr">{supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
              </div>

              <p className="description">{productName}</p>
            </div>
            <div>
              <p className="quantity">
                <span>
                  x
                  {quantity ? quantity : orderQuantity}
                </span>
              </p>
            </div>
            <div>
              {totalPrice && (
                <>
                  <p className="price-all"><span className="ltr">₪ {totalPrice ? formatPrice(totalPrice) : 0}</span></p>
                  <p><span className="ltr">₪ {price ? price : 0}/{t('page.order.item')}</span></p>
                </>)
              }
            </div>
          </>
        )}

      </div>
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

const OrderAwaitingDelivery = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { orderId } = useParams();
  const location = useLocation();
  const isDelivered = location.search==='?delivered';

  const [showUploadPackingModal, setShowUploadPackingModal] = useState(false);
  const [orderIdValue, setOrderIdValue] = useState(null);
  const [offerIdValue, setOfferIdValue] = useState(null);

  const [orderSummary, setOrderSummary] = useState(false);
  const { item, isFetching } = useSelector((state) => state.offer);
  const { offer, project } = item;
  const deliveryDate = offer?.deliveryDetail?.deliveryDate?.split('-');
  const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
  const deliveryTimeRange = offer?.deliveryDetail?.deliveryTimeRange;

  const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
    `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
    t('page.supplier_orders.during_the_working_day');

  const getItemData = () => dispatch(getOfferItemDetailsSupplier(orderId));

  useEffect(() => {
    getItemData();
  }, []);

  if ((!isFetching && Object.keys(item).length === 0) || (!isDelivered && location.search)) {
    return <NotFound/>;
  }

  return (
    <>
      {isFetching ? (
        <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
      ) : (
        <div className="checkout offer_confirmation order_list_item">
          <div className="container">

            <div className="breadcrumb-list">
              <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                <Breadcrumb.Item componentClass={Link} to={isDelivered ? '/supplier/orders/delivered' : '/supplier/orders/awaiting_delivery'}>
                  {isDelivered ?
                    <span>{t('page.requests_for_proposal.menu.in_process')}</span> :
                    <span>{t('page.requests_for_proposal.menu.new')}</span>
                  }
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <span>{t('page.order.order')} #{item?.orderId}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="checkout-flex-col">

              <FlexboxGrid className="flex-420-col">
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

                  <Panel bordered className="panel_edit">

                    <div className="header-flex">
                      <div><p className="order">{t('page.order.order')} #{item?.orderId}</p></div>
                      <div>
                        <p>
                          <span className="orders">
                            {offer?.products?.length === 1 ?
                              <>{offer?.products?.length} {t('page.supplier_orders.product')}</> :
                              <>{offer?.products?.length} {t('page.supplier_orders.products')}</>}
                          </span>
                        </p>
                      </div>
                      {isDelivered ?
                        <div><p><span className="delivered">{t('page.requests_for_proposal.menu.in_process')}</span></p></div> :
                        <div><p><span className="awaiting-delivery">{t('page.requests_for_proposal.menu.new')}</span></p></div>
                      }
                    </div>
                    {isDelivered ?
                      <p className="upload_info">
                        <Trans
                          i18nKey="page.order.order_list.upload_packing_slip"
                          values={{mail: 'info@konebone.co.il'}}
                          components={{ a: <a href="mailto:info@konebone.co.il"><span/></a>, bold: <strong /> }}
                        />
                      </p> :
                      <p className="date">{t('page.order.order_list.customer_accepted_your_offer')}</p>
                    }
                    { offer.packingSlips.length > 0 ?
                      offer.packingSlips.map((item, index) => (
                        <div className="wrapper_download_file" key={index}>
                          <div>
                            <span>{item?.packingSlipFileName}</span>
                            <div className='button_box'>
                              <a href={item?.packingSlipFileUrl} className="wrapper_download_file--icon wrapper_download_file--downloadIcon" />
                            </div>
                          </div>
                        </div>
                      )) :
                      ''}

                    <div className="offers_list">
                      <div className="offer-box">

                        <PanelGroup accordion>
                          {offer?.inStockProducts.length > 0 && (<Panel header={<p>{t('page.order.order_filling')} <span className="count">({offer?.products?.length})</span></p>} defaultExpanded>

                            <div className="products-list">
                              <ListOrdered orderedProducts={offer?.inStockProducts} />
                            </div>

                          </Panel>)}
                        </PanelGroup>

                      </div>
                    </div>


                  </Panel>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>

                  <div className="order-component offers_list">

                    <Panel bordered>

                      <p className="header">{t('page.supplier_orders.order_details')}</p>

                      <div className="grey_box">
                        <p>{t('page.supplier_orders.final_order_price')}</p>
                        <p className="price bold"><span className="ltr">₪ {formatPrice(offer?.orderTotal || 0)}</span></p>

                        <div className="toggle_hide_view">
                          <Button appearance="link" onClick={() => setOrderSummary(!orderSummary)}>
                            <span className="flex">
                              <span>{t('page.order.order_summary')}</span>
                              <span>
                                <i className={`rs-icon ${orderSummary ? 'open' : 'close'}`} />
                              </span>
                            </span>
                          </Button>
                        </div>

                      </div>
                      {isDelivered && offer?.needPackingSlip ? <div className="submit_button">
                        <Button appearance='primary' onClick={() => {
                          setShowUploadPackingModal(true);
                          setOrderIdValue(orderId);
                          setOfferIdValue(offer?.offerId);
                        }}>
                          <span>{t('page.requests_for_proposal.confirm_delivery')}</span>
                        </Button>
                      </div> : ''}
                      {orderSummary && (
                        <div className="details_box">
                          <p className="header">{t('page.supplier_orders.summary')}</p>
                          <div className="price_box with_line">
                            <ul>
                              <li><p>{t('page.supplier_orders.make_offer.subtotal')}</p></li>
                              <li><p><span className="ltr">₪ {formatPrice(offer?.offerPrice || 0)}</span></p></li>
                            </ul>
                            {!!offer?.discountPercentage &&
                            <ul>
                              <li><p className="relative_for_badge">{t('page.offer.discount')} <span className="discount_badge"><span className="ltr">-{offer?.discountPercentage}%</span></span></p></li>
                              <li><p style={{ lineHeight: '28px' }}><span className="ltr">{!!offer?.discount && '-'}₪ {formatPrice(offer?.discount || 0)}</span></p></li>
                            </ul>}
                            <ul>
                              <li><p>{t('page.offers_list.delivery_cost')}</p></li>
                              <li><p><span className="ltr">₪ {formatPrice(offer?.deliveryDetail?.deliveryCost)}</span></p></li>
                            </ul>
                            <ul>
                              <li><p>{t('page.order.order_list_item.order_total')}</p></li>
                              <li><p><span className="ltr">₪ {formatPrice(offer?.orderTotal || 0)}</span></p></li>
                            </ul>
                          </div>
                        </div>
                      )}

                      <div className="details_box">
                        <p className="header">{t('page.order.order_list_item_new.delivery_details')}</p>

                        {offer?.deliveryDetail?.mode !== 'BY_KONEBONE' && (
                          <div className="ico-item contacts">
                            <p className="sub-header">{t('page.supplier_orders.customer_contacts')}</p>
                            <p>{project?.contactFullName}, {project?.contactPhone}</p>
                          </div>)}

                        <div className="ico-item clock">
                          <p className="sub-header">{t('page.supplier_orders.delivery_method')}</p>
                          <p>{deliveryModeMaper(offer?.deliveryDetail?.mode, t)}</p>
                        </div>

                        {offer?.deliveryDetail?.mode === 'BY_KONEBONE' && (
                          <div className="ico-item address">
                            <p className="sub-header">{t('page.supplier_orders.pickup_address')}</p>
                            <p>{`${i18n.language === 'heb' ? 'רח’' : 'st.'} ${offer?.deliveryDetail?.pickupStreet} ${offer?.deliveryDetail?.pickupApt}, 
                            ${offer?.deliveryDetail?.pickupCity}`}</p>
                          </div>)}

                        {offer?.deliveryDetail?.mode !== 'BY_KONEBONE' && (
                          <div className="ico-item delivery">
                            <p className="sub-header">{t('page.supplier_orders.delivery_time')}</p>
                            <p>{deliveryTime ?
                              `${deliveryDateData} ${deliveryTime} ` :
                              `${t('page.offers_list.delivery_time')} ${t('page.offers_list.not_specified')}`}</p>
                          </div>)}

                        <div className="ico-item project">
                          <p className="sub-header">{t('page.supplier_orders.delivery_address')}</p>
                          <p dir="auto" style={{textAlign: i18n.language === 'heb' ? 'right' : 'left'}}>
                            <span>{`"${project?.name}"`},</span>
                            <span>{`${project?.city ? ` ${project?.city}` : ''}`},</span>
                            <span>{` ${i18n.language === 'heb' ? 'רח’' : 'st.'} ${project?.street} ${project?.apt ? project?.apt : '-'}`}</span>
                          </p>
                        </div>

                        {offer?.deliveryDetail?.mode === 'BY_KONEBONE' && (
                          <div className="ico-item vehicle">
                            <p className="sub-header">{t('page.supplier_orders.delivery_vehicle')}</p>
                            <p>{vehicleWeightTypeValue(offer?.deliveryDetail?.vehicle, t)}</p>
                          </div>)}

                        {offer?.deliveryDetail?.mode !== 'BY_KONEBONE' && (
                          <div className="ico-item deliveryman">
                            <p className="sub-header">{t('page.supplier_orders.deliveryman')}</p>
                            <p>{offer?.deliveryDetail?.contactName}, {offer?.deliveryDetail?.contactPhone}</p>
                          </div>)}

                        <div className="ico-item services">
                          <p className="sub-header">{t('page.order.additional_services')}</p>
                          <p>{offer?.serviceUnloading ? t('page.order.unloading') : '-'}</p>
                        </div>

                        <div className="ico-item comment">
                          <p className="sub-header">{t('page.order.comment_supplier')}</p>
                          <p>
                            {offer.comment ? offer.comment : '-'}
                          </p>
                        </div>
                      </div>

                    </Panel>

                    <NeedHelpComponent orderId={orderId}/>

                  </div>

                </FlexboxGrid.Item>
              </FlexboxGrid>

            </div>

          </div>
        </div>
      )}
      {showUploadPackingModal && <UploadPackingModal
        showUploadPackingModal={showUploadPackingModal}
        setShowUploadPackingModal={setShowUploadPackingModal}
        orderIdValue={orderIdValue}
        offerIdValue={offerIdValue}
        apirequest={getItemData}
      />}
    </>
  );
};

export default OrderAwaitingDelivery;
