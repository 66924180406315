import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { resetGoToNextStep, resetRegistrationData } from '../redux/actions/auth/registration';
import { resetSignInData } from '../redux/actions/auth/signIn';
import { useHistory } from 'react-router-dom';
import { confirmRegistrationSmsCode, resetRegistrationUser } from '../redux/actions/auth/smsCodeVerification';
import { resetAddressInfoData } from '../redux/actions/auth/addressInfoData';
import { resetCompanyInfoData } from '../redux/actions/auth/companyInfo';
import { logout } from '../redux/actions/auth/logout';
import { resetCartData } from '../redux/actions/cart';

export const navItems = {
  SIGN_IN: 'sign_in',
  REGISTRATION: 'register',
};

export const SignInContext = React.createContext({});

const SignInContextProvider = ({ children }) => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(navItems.SIGN_IN);
  const [isCancelRegistrationModalOpen, setIsCancelRegistrationModalOpen] = useState(false);
  const [isShowSignIn, setIsShowSignIn] = useState(true);
  const [isShowRegistration, setIsShowRegistration] = useState(true);
  const [isShowConfirmCodeModal, setIsShowConfirmCodeModal] = useState(false);
  const [registrationData, setRegistrationData] = useState({});

  const handleSelect = (key) => {
    setActiveKey(key);
    if (key === 'sign_in') {
      dispatch(resetGoToNextStep());
      setIsShowSignIn(true);
      handleShowConfirmCode(false);
      dispatch(resetSignInData());
    } else {
      dispatch(resetGoToNextStep());
      setIsShowRegistration(true);
      handleShowConfirmCode(false);
    }
  };

  const handleShowSignIn = (val) => {
    setIsShowSignIn(val);
  };

  const handleShowRegistration = (val) => {
    setIsShowRegistration(val);
  };

  const handleShowConfirmCode = (val) => {
    setIsShowConfirmCodeModal(val);
  };

  const handleIsCancelRegistrationModalOpen = (val) => {
    if ((activeKey === 'register' || activeKey === 'sign_in') && !document.querySelector('.card_login') && Object.keys(registrationData).length > 0) {
      setIsCancelRegistrationModalOpen(val);
    }
  };

  const abortRegistrationAction = () => {
    setIsCancelRegistrationModalOpen(false);
    // reset stored data for registration
    dispatch(resetRegistrationData());
    dispatch(resetAddressInfoData());
    dispatch(resetCompanyInfoData());
    dispatch(resetRegistrationUser());
    setIsShowRegistration(true);
    setIsShowSignIn(true);
    handleShowConfirmCode(false);
    dispatch(logout(() => {
      history.push('/');
    }));
    dispatch(confirmRegistrationSmsCode());
    dispatch(resetCartData());
  };

  const cancelAbortRegistrationAction = () => {
    setIsCancelRegistrationModalOpen(false);
    setIsModalOpen(true);
    setIsShowRegistration(true);
  };

  const onHideModal = () => {
    dispatch(resetSignInData());
    handleIsModalOpen(false);
    handleShowConfirmCode(false);
    setIsShowRegistration(true);
    setIsShowSignIn(true);
    handleIsCancelRegistrationModalOpen(true);
  };

  const handleIsModalOpen = (val) => {
    setIsModalOpen(val);
  };

  return (
    <SignInContext.Provider
      value={{
        isModalOpen,
        handleIsModalOpen,
        onHideModal,
        cancelAbortRegistrationAction,
        abortRegistrationAction,
        handleShowRegistration,
        handleShowSignIn,
        handleSelect,
        isCancelRegistrationModalOpen,
        isShowSignIn,
        isShowRegistration,
        isShowConfirmCodeModal,
        registrationData,
        setRegistrationData,
        activeKey,
        setActiveKey,
        handleIsCancelRegistrationModalOpen,
        handleShowConfirmCode,
        setIsShowSignIn,
        setIsShowConfirmCodeModal,
        setIsShowRegistration,
      }}
    >
      {children}
    </SignInContext.Provider>
  );
};

SignInContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SignInContextProvider;
