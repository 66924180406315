import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ConnectBuyNow() {
  const { t } = useTranslation();
  return (
    <div className="connect_buy_now">
      <div className="container">
        <div className="connect_buy_now_box">

          <div className="panel">

            <div className="banner">
              <span />
            </div>

            <p className="header">
              {t('page.connect_by_now.prepare_documents')}
            </p>

            <ul className="number_list">
              <li>
                {t('page.connect_by_now.certificate_of_incorporation')}
              </li>
              <li>
                <p>{t('page.connect_by_now.latest_DNB_or_BDI_reports')}</p>
              </li>
              <li>
                <p>{t('page.connect_by_now.fill_out_this_credit_check_questionnaire')}</p>

                <ul className="list_links">
                  <li><Link to="#" download><span>{t('page.connect_by_now.credit_check_questionnaire')}</span></Link></li>
                </ul>
              </li>
              <li>
                <p>{t('page.connect_by_now.latest_financial_statements')}</p>
              </li>
              <li>
                <p>{t('page.connect_by_now.send_full_list')} <a href="mailto:info@konebone.co.il">info@konebone.co.il</a></p>
              </li>
            </ul>

            <div className="warning_box">
              <p>
                {t('page.connect_by_now.do_not_forget')}
              </p>
            </div>

          </div>

          <p>
            {t('page.connect_by_now.credit_approval_process')}
          </p>

        </div>
      </div>
    </div>
  );
}

export default ConnectBuyNow;
