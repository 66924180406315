export const addressInfo = (actions) => ({
  // set address info data
  [actions.setAddressInfoData.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    addressInfo: state.addressInfo,
  }),

  [actions.setAddressInfoData.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    addressInfo: payload.addressInfo,
  }),

  [actions.setAddressInfoData.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // reset company info data
  [actions.resetAddressInfoData.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    addressInfo: {},
  }),

  [actions.resetAddressInfoData.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    addressInfo: payload.addressInfo,
  }),

  [actions.resetAddressInfoData.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // create address info
  [actions.createAddressInfo.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
  }),

  [actions.createAddressInfo.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    addressInfo: payload.addressInfo,
  }),

  [actions.createAddressInfo.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),
});
