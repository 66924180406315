import { handleActions } from 'redux-actions';
import actions from '../actions/checkout';

export const initialState = {
  isFetching: false,
  error: '',
  checkoutData: {},
  rfp: {},
  timeConstraints: {},
  projectData: {},
  additionalServices: {
    serviceUnloading: false,
    serviceReturnOfLeftover: false,
    serviceRemoveOfGarbage: false,
    serviceUploadingToFlow: false,
  },
};

export default handleActions(
  {
    // set checkout data
    [actions.setCheckoutData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      checkoutData: state.checkoutData,
    }),

    [actions.setCheckoutData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      checkoutData: payload.checkoutData,
    }),

    [actions.setCheckoutData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // reset checkout data
    [actions.resetCheckoutData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      checkoutData: {},
    }),

    [actions.resetCheckoutData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      checkoutData: payload.checkoutData,
    }),

    [actions.resetCheckoutData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // confirm order
    [actions.confirmOrder.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: {},
    }),

    [actions.confirmOrder.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      rfp: payload.rfp,
    }),

    [actions.confirmOrder.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // set constraints data
    [actions.setConstraintsData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: {},
    }),

    [actions.setConstraintsData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      timeConstraints: payload.timeConstraints,
    }),

    [actions.setConstraintsData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // reset constraints data
    [actions.resetConstraintsData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: {},
    }),

    [actions.resetConstraintsData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      timeConstraints: payload.timeConstraints,
    }),

    [actions.resetConstraintsData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // add constraints data
    [actions.addConstraintsData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: {},
    }),

    [actions.addConstraintsData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      rfp: payload.rfp,
    }),

    [actions.addConstraintsData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // set project data
    [actions.setProjectData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: {},
    }),

    [actions.setProjectData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      projectData: payload.projectData,
    }),

    [actions.setProjectData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // reset project data
    [actions.resetProjectData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: {},
    }),

    [actions.resetProjectData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      projectData: payload.projectData,
    }),

    [actions.resetProjectData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // add project data
    [actions.addProjectData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: {},
    }),

    [actions.addProjectData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      projectData: payload.projectData,
    }),

    [actions.addProjectData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // set aditional services data
    [actions.setAdditionalServicesData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: {},
    }),

    [actions.setAdditionalServicesData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      additionalServices: payload.additionalServices,
    }),

    [actions.setAdditionalServicesData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // reset aditional services data
    [actions.resetAdditionalServicesData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: {},
    }),

    [actions.resetAdditionalServicesData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      additionalServices: payload.additionalServices,
    }),

    [actions.resetAdditionalServicesData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // add aditional services data
    [actions.addAdditionalServicesData.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: {},
    }),

    [actions.addAdditionalServicesData.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      rfp: payload.rfp,
      isCheckedStep: payload.isCheckedStep,
    }),

    [actions.addAdditionalServicesData.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    [actions.addIsCheckedStep.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      isCheckedStep: payload.isCheckedStep,
    }),
  },
  initialState,
);
