import React from 'react';
import { Button } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SuccessfullyPaid() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="offer_confirmation_info">
      <div className="container">
        <div className="offer_confirmation_info_box">
          <div className="banner successfully_paid">
            <span />
          </div>
          <p className="header">{t('page.successfully_paid.your_order_has')}</p>
          <p>
            {t('page.successfully_paid.text')}
          </p>
          <div className="button">
            <div className="flex">
              <div>
                <Button block type="button" appearance="ghost" onClick={() => history.push('/product_catalog')}>
                  <span>{t('page.successfully_paid.back_to_catalogue')}</span>
                </Button>
              </div>
              <div>
                <Button block type="button" appearance="primary" style={{maxWidth: '200px'}} onClick={() => history.push('/customer/orders?status=IN_REVIEW')}>
                  <span>{t('page.successfully_paid.btn_text')}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessfullyPaid;
