import {Button} from 'rsuite';
import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

export const RequestProducts = ({onClick}) => {
  const { t } = useTranslation();

  return (
    <div className='request-product-wrapper'>
      <span className='corner-img'/>
      <div className='request-product-header'>
        {t('page.customer_request.section_text')}
      </div>
      <div>
        <Button
          className='request-button'
          type="button"
          appearance="ghost"
          onClick={onClick}
        >
          <span> {t('page.customer_request.section_button')}</span>
        </Button>
      </div>
    </div>
  );
};

RequestProducts.propTypes = {
  onClick: PropTypes.func.isRequired,
};
