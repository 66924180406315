import React from 'react';

export const padTime = (time) => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${padTime(seconds)}`;
};

export const convertArrayToObj = (arr, idFeld) => {
  const obj = {};
  arr.forEach((el) => obj[el[idFeld]] = el);
  return obj;
};

export const splitPointsObjects = (data) => {
  return Object.keys(data).reduce(function(obj, key) {
    const val = data[key];
    key.split('.').reduce(function(step, part, index, arr) {
      if (index === arr.length - 1) {
        step[part] = val;
      } else if (step[part] === undefined) {
        step[part] = {};
      }
      return step[part];
    }, obj);
    return obj;
  }, {});
};

export const formatPrice = (data) => {
  return parseFloat(data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const validationErrorsFormat = (string, t) => {
  const array = string ? string.toString().split('|') : '';
  const errorKey = array[0] ? array[0] : '';
  const options = array[1] ? array[1].replace(/\[|\]/g, '').split(',') : [];
  switch (errorKey) {
    case 'field.validation.decimal_max':
      return t('validationErrors.field.validation.decimal_max', { value: options[0]});
    case 'field.validation.decimal_min':
      return t('validationErrors.field.validation.decimal_min', { value: options[0]});
    case 'field.validation.digits_max_integer_max_fraction':
      return t('validationErrors.field.validation.digits_max_integer_max_fraction', { integer: options[0], fraction: options[1] });
    case 'field.validation.min':
      return t('validationErrors.field.validation.min', { value: options[0]});
    case 'field.validation.not_blank':
      return t('validationErrors.field.validation.not_blank');
    case 'field.validation.not_empty':
      return t('validationErrors.field.validation.not_empty');
    case 'field.validation.not_null':
      return t('validationErrors.field.validation.not_null');
    case 'field.validation.positive':
      return t('validationErrors.field.validation.positive');
    case 'field.validation.size_min_max':
      return t('validationErrors.field.validation.size_min_max', { min: options[0], max: options[1] });
    default:
      return '';
  }
};

export const deliveryModeMaper = (deliveryMode, t) => {
  if (deliveryMode === 'BY_SUPPLIER_AS_CUSTOMER_EXPECT') {
    return t('page.supplier_orders.delivery_as_customer_expected');
  } else if (deliveryMode === 'BY_SUPPLIER_WITH_DELAY') {
    return t('page.supplier_orders.delivery_by_supplier_with_delay');
  } else if (deliveryMode === 'BY_KONEBONE') {
    return t('page.supplier_orders.delivery_by_konebone');
  }
};

export const customerOrdersStatusWithClass = (status, t) => {
  if (status === 'AWAITING_DELIVERY') {
    return <span className="awaiting-delivery">{t('page.order.status.AWAITING_DELIVERY')}</span>;
  } else if (status === 'CANCELED') {
    return <span className="canceled">{t('page.order.status.CANCELED')}</span>;
  } else if (status === 'COMPLETED') {
    return <span className="completed">{t('page.order.status.COMPLETED')}</span>;
  } else if (status === 'DECLINED') {
    return <span className="canceled">{t('page.order.status.DECLINED')}</span>;
  } else if (status === 'IN_PROGRESS') {
    return <span className="in_progress">{t('page.order.status.IN_PROGRESS')}</span>;
  } else if (status === 'IN_REVIEW') {
    return <span className="need-review">{t('page.order.status.IN_REVIEW')}</span>;
  } else if (status === 'NEW') {
    return <span className="new">{t('page.order.status.NEW')}</span>;
  } else if (status === 'WAITING_FOR_PAYMENT') {
    return <span className="waiting-payment">{t('page.order.status.WAITING_FOR_PAYMENT')}</span>;
  } else if (status === 'DELIVERED') {
    return <span className="delivered">{t('page.order.status.DELIVERED')}</span>;
  } else {
    return <span className="default">{status}</span>;
  }
};


export const vehicleWeightTypeValue = (item, t) => {
  if (item === 'WEIGHT_4T') {
    return `4 ${t('page.supplier_orders.ton')}`;
  } else if (item === 'WEIGHT_8T') {
    return `8 ${t('page.supplier_orders.ton')}`;
  } else if (item === 'WEIGHT_12T') {
    return `12 ${t('page.supplier_orders.ton')}`;
  } else if (item === 'WEIGHT_15T') {
    return `15 ${t('page.supplier_orders.ton')}`;
  } else {
    return `0 ${t('page.supplier_orders.ton')}`;
  }
};

export const reverseStringWords = (str) => str.split(' ').reverse().join(' ');

export const isValidPhoneOrEmail = (value) => {
  const re = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})|(^05[0-9]{8})+$/;
  return re.test(value);
};

