import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'rsuite';
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import categoriesApi from '../api/categories';
import { useTranslation } from 'react-i18next';
import BreadcrumbPath from '../components/BreadcrumbPath';
import { loadAllPath } from '../redux/actions/catalog';
import { useDispatch } from 'react-redux';
import {Helmet} from 'react-helmet';
import NotFound from './NotFound';

function catalogsRenger(data, parentId = null) {
  const rootCatalogs = data.filter((catalog) => catalog.parentCategoryId === parentId);

  rootCatalogs.forEach((catalog) => {
    catalog.id = catalog.categoryId;
    catalog.list = data.filter((child) => {
      if (child.parentCategoryId === catalog.id) {
        child.id = child.categoryId;
        return true;
      }
      return false;
    });
    catalog.list.forEach((catalogNext) => {
      catalogNext.list = data.filter((child) => child.parentCategoryId === catalogNext.categoryId);
    });
  });
  return rootCatalogs;
}

function LinksList({ links, parentName, preParent }) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return links.length > 5 ?
    (!show && (
      <ul>
        {links.slice(0, 4).map(({ id, name, list }) => (
          <li key={id}>
            <Link to={`/product_catalog/${preParent ? `${preParent}/` : ''}${parentName ? `${parentName}/` : ''}${name ? `${name}` : ''}${list.length ? '' : '/product'}`}><span>{name}</span></Link>
          </li>
        ))}
        <li className="show_more" onClick={() => setShow(!show)}><span>{t('page.product_catalog.show_more')}</span></li>
      </ul>
    ) || (
      <ul>
        {show &&
          links.map(({ id, name, list }) => (
            <li key={id}>
              <Link to={`/product_catalog/${preParent ? `${preParent}/` : ''}${parentName ? `${parentName}/` : ''}${name ? `${name}` : ''}${list.length ? '' : '/product'}`}><span>{name}</span></Link>
            </li>
          ))
        }
        <li className="show_more" onClick={() => setShow(!show)}><span>{t('page.product_catalog.hide_more')}</span></li>
      </ul>
    )) : (<ul>
      {links.map(({ id, name, list }) => (
        <li key={id}>
          <Link to={`/product_catalog/${preParent ? `${preParent}/` : ''}${parentName ? `${parentName}/` : ''}${name ? `${name}` : ''}${list.length ? '' : '/product'}`}><span>{name}</span></Link>
        </li>
      ))}
    </ul>);
}

function CatalogList({ catalogs, parentName }) {
  return catalogs.map(({ id, name, image, list }) => (
    id && (<div key={id}>
      <div className="catalog_item">
        <p><Link to={`/product_catalog/${parentName ? `${parentName}/` : ''}${name ? `${name}` : ''}${list.length ? '' : '/product'}`}>
          {name}</Link></p>
        <div className="links_list">
          <LinksList links={list} parentName={name} preParent={parentName} />
        </div>
        <div className="img-box">
          {image && image.slice(-4) !== 'null' && <img src={image} alt={name} />}
        </div>
      </div>
    </div>)
  ));
}

const ProductCatalog = () => {
  const [loading, setLoading] = useState(false);
  const [catalogsList, setCatalogsList] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { categoryName } = useParams();

  const allCategories = useRef([]);

  const isNotACategory = useMemo(() => pathname.slice(16).includes('/product'), [pathname]);

  useEffect(() => {
    if (isNotACategory) {
      return;
    }

    setLoading(true);
    dispatch(loadAllPath(pathname));
    if (categoryName && categoryName !== 'product') {
      categoriesApi.findCategoryByName(categoryName).then((item) => {
        if (item.data) {
          setCatalog(item.data);
          const categoryId = +item.data.categoryId;
          if (allCategories.current?.length) {
            const catalog = catalogsRenger(allCategories.current, categoryId);
            if (!catalog.length) {
              history.push(`${pathname}/product`);
            }
            setCatalogsList(catalog);
            setLoading(false);
          } else {
            categoriesApi.getAllCategories().then((items) => {
              if (items.data) {
                allCategories.current = items.data;
                const catalog = catalogsRenger(allCategories.current, categoryId);
                if (!catalog.length) {
                  history.push(`${pathname}/product`);
                }
                setCatalogsList(catalog);
                setLoading(false);
              }
            });
          }
        } else {
          setLoading(false);
        }
      });

      return;
    }

    categoriesApi.getAllCategories().then((items) => {
      if (items.data) {
        allCategories.current = items.data;
        const catalog = catalogsRenger(allCategories.current);
        setCatalogsList(catalog);
      }
      setLoading(false);
    });
  }, [categoryName]);

  if (isNotACategory) {
    return <NotFound/>;
  }

  if (loading) {
    return (
      <div style={{ position: 'fixed', top: 0, height: '100vh', width: '100vw', zIndex: 9999999 }}>
        <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
      </div>
    );
  }

  const renderHtml = (text) => <p dangerouslySetInnerHTML={{ __html: text }} />;
  const searchBreak = '/n';
  const replaceBreakWith = '<br />';
  const searchItemStart = '**';
  const replaceItemStartWith = '<li class="disc">';
  const searchItemEnd = '##';
  const replaceItemEndWith = '</li>';
  const processTemplate = (text) => {
    return text.replaceAll(
      searchBreak, replaceBreakWith
    ).replaceAll(
      searchItemStart, replaceItemStartWith
    ).replaceAll(
      searchItemEnd, replaceItemEndWith
    );
  };

  return (
    <div className="product_catalog">
      <Helmet>
        <title>{catalog.metaTitle}</title>
        <meta name="description" content={catalog.metaDescription} />
        <meta property="og:title" content={catalog.metaTitle} />
        <meta property="og:description" content={catalog.metaDescription} />
      </Helmet>
      <div className="container">

        {catalogsList.length ? (
          <>
            {pathname !== '/' && <BreadcrumbPath />}

            <h1>{categoryName || t('page.product_catalog.product_catalog')}</h1>

            <div className="wrapper_catalog_list_text">{catalog.pageText ? renderHtml(processTemplate(catalog.pageText)) : ''}</div>
            <div className="wrapper_catalog_list">
              <CatalogList catalogs={catalogsList} parentName={categoryName} />
            </div>
          </>
        ) : <NotFound/>}

      </div>
    </div>
  );
};

LinksList.propTypes = {
  links: PropTypes.instanceOf(Array).isRequired,
  parentName: PropTypes.string.isRequired,
  preParent: PropTypes.string,
};

CatalogList.propTypes = {
  catalogs: PropTypes.instanceOf(Array).isRequired,
  parentName: PropTypes.string.isRequired,
};

export default ProductCatalog;
