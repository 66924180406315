export const managerData = (actions) => ({
  // set manager info data
  [actions.setManagerData.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    managerInfo: state.managerInfo,
  }),

  [actions.setManagerData.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    managerInfo: payload.managerInfo,
  }),

  [actions.setManagerData.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // reset manager info data
  [actions.resetManagerData.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    managerInfo: {},
  }),

  [actions.resetManagerData.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    managerInfo: payload.managerInfo,
  }),

  [actions.resetManagerData.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // create manager
  [actions.createManager.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    isManagerCreated: false,
  }),

  [actions.createManager.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    managerInfo: payload.managerInfo,
    isManagerCreated: true,
    user: payload.user,
  }),

  [actions.createManager.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
    isManagerCreated: false,
  }),
});
