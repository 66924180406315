import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, Icon, Dropdown, Button, Loader } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import PaginationComponent from '../components/PaginationComponent';
import { getOrdersByCustomer, getOrdersByCustomerSearch } from '../redux/actions/order';
import { customerOrdersStatusWithClass } from '../utils/index';
import NotFound from './NotFound';

const queryString = require('query-string');

export const ordersType = {
  AWAITING_DELIVERY: 'AWAITING_DELIVERY',
  CANCELED: 'CANCELED',
  DELIVERED: 'DELIVERED',
  DECLINED: 'DECLINED',
  // DRAFT: 'DRAFT',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_REVIEW: 'IN_REVIEW',
  NEW: 'NEW',
  // TIME_IS_OUT: 'TIME_IS_OUT',
  // WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
};

const ActionButtons = ({status, orderId, canceledPaid}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const viewOffersList = (orderId) => {
    history.push(`/customer/offers_list/${orderId}`);
  };
  const handleClick = (orderId) => {
    history.push(`/customer/order/${orderId}`);
  };
  const handleClickAwaitingDelivery = (orderId) => {
    history.push(`/customer/order-paid/${orderId}`);
  };

  const PaidButton = () => {
    return <Button onClick={() => handleClickAwaitingDelivery(orderId)} block type="button" appearance="ghost"><span>{t('page.order.order_list.view_order')}</span></Button>;
  };

  return (
    <div className="wrapper_buttons">
      {status === 'IN_REVIEW' && <Button block type="button" appearance="primary" onClick={() => viewOffersList(orderId)}><span>{t('page.order.order_list.view_offers')}</span></Button>}
      {status === 'AWAITING_DELIVERY' || status === 'DELIVERED' ? <PaidButton /> :
        (status === 'CANCELED' && canceledPaid ? <PaidButton /> : <Button onClick={() => handleClick(orderId)} block type="button" appearance="ghost"><span>{t('page.order.order_list.view_order')}</span></Button>)
      }
    </div>
  );
};

ActionButtons.propTypes = {
  status: PropTypes.instanceOf(Object).isRequired,
  orderId: PropTypes.number.isRequired,
  canceledPaid: PropTypes.bool,
};

const OrderList = () => {
  const dispatch = useDispatch();
  const { page, status, search } = queryString.parse(useLocation().search);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { pages, items, isFetching } = useSelector((state) => state.order);

  const [ searchValue, setSearchValue ] = useState('');

  const statusArray = ['NEW', 'IN_PROGRESS', 'IN_REVIEW', 'AWAITING_DELIVERY', 'DELIVERED', 'DECLINED', 'CANCELED', 'ALL_ORDERS'];
  const paramsForStatus = !!status ? `status=${status}` : '';
  const paramsForSearch = !!searchValue ? `&search=${searchValue}` : '';
  const onHandleDropdown = (eventKey) => {
    history.push({
      pathname: '/customer/orders',
      search: `?status=${eventKey}${paramsForSearch}`,
    });
  };

  const handleSelect = (eventKey) => {
    history.push({
      pathname: '/customer/orders',
      search: `?page=${eventKey}${!!paramsForStatus ? '&' : ''}${paramsForStatus}${paramsForSearch}`,
    });
  };

  const handleSearch = () => {
    history.push({
      pathname: '/customer/orders',
      search: `?${paramsForStatus}${paramsForSearch}`,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const onChangeSearch = (value) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (!status && !search || status === 'ALL_ORDERS' && !search) {
      dispatch(getOrdersByCustomer(ordersType[status] || '', page ? Number(page) : 1));
    }

    if ((!!status && status !== 'ALL_ORDERS') || !!search) {
      dispatch(getOrdersByCustomerSearch(ordersType[status] || '', search || '', page ? Number(page) : 1));
    }
  }, [location.key]);

  if (status && !statusArray.some((item) => item === status)) {
    return <NotFound/>;
  }
  return (
    <>
      {isFetching ? (
        <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
      ) : (
        <div className="order_list">
          <div className="container">

            <h1>{t('page.order.orders')}</h1>

            <div className="wrapper_flex_search">
              <div>

                <div className="wrapper_dropdown">
                  <Dropdown
                    title={t(`page.order.status.${ordersType[status] || 'ALL_ORDERS'}`)}
                    onSelect={(val) => onHandleDropdown(val)}
                    activeKey={ordersType[status] || 'ALL_ORDERS'}
                  >
                    <Dropdown.Item eventKey="ALL_ORDERS">
                      <span>{t('page.order.status.ALL_ORDERS')}</span>
                    </Dropdown.Item><Dropdown.Item eventKey="NEW">
                      <span>{t('page.order.status.NEW')}</span>
                    </Dropdown.Item><Dropdown.Item eventKey="IN_PROGRESS">
                      <span>{t('page.order.status.IN_PROGRESS')}</span>
                    </Dropdown.Item><Dropdown.Item eventKey="IN_REVIEW">
                      <span>{t('page.order.status.IN_REVIEW')}</span>
                    </Dropdown.Item><Dropdown.Item eventKey="AWAITING_DELIVERY">
                      <span>{t('page.order.status.AWAITING_DELIVERY')}</span>
                    </Dropdown.Item><Dropdown.Item eventKey="DELIVERED">
                      <span>{t('page.order.status.DELIVERED')}</span>
                    </Dropdown.Item><Dropdown.Item eventKey="DECLINED">
                      <span>{t('page.order.status.DECLINED')}</span>
                    </Dropdown.Item><Dropdown.Item eventKey="CANCELED">
                      <span>{t('page.order.status.CANCELED')}</span>
                    </Dropdown.Item>
                  </Dropdown>

                  {/* <Dropdown.Item eventKey="DRAFT"><span>{t('page.order.status.DRAFT')}</span></Dropdown.Item> */}
                  {/* <Dropdown.Item eventKey="TIME_IS_OUT"><span>{t('page.order.status.TIME_IS_OUT')}</span></Dropdown.Item> */}
                  {/* <Dropdown.Item eventKey="WAITING_FOR_PAYMENT"><span>{t('page.order.status.WAITING_FOR_PAYMENT')}</span></Dropdown.Item> */}

                </div>

              </div>
              <div>

                <div className="search">
                  <InputGroup>
                    <InputGroup.Addon onClick={() => handleSearch()}>
                      <Icon icon="search" />
                    </InputGroup.Addon>
                    <Input placeholder={t('page.order.order_list.search_by_orders')} value={searchValue} onKeyDown={handleKeyDown} onChange={onChangeSearch} />
                  </InputGroup>
                </div>

              </div>
            </div>

          </div>


          {items?.length !== 0 ? (<div className="order_list_box">

            {items?.map((i, index, array) => {
              const creationDate = new Date(i?.creationDate);
              const progressStatusArray = ['NEW', 'IN_PROGRESS', 'IN_REVIEW', 'DECLINED', 'CANCELED'];
              const isInProgress = progressStatusArray.some((item) => item === i.status);


              const paidOrdersDelivery = i?.delivery?.map((el) => {
                const paidOrderDeliveryDate = el?.deliveryDate?.split('-');
                const paidOrderDeliveryDateData = paidOrderDeliveryDate ? `${paidOrderDeliveryDate?.[2]}/${paidOrderDeliveryDate?.[1]}/${paidOrderDeliveryDate?.[0]}, ` : '';
                const paidOrderDeliveryTimeRange = el?.deliveryTimeRange;
                const paidOrderDeliveryTime = paidOrderDeliveryTimeRange && !paidOrderDeliveryTimeRange?.full ?
                    // eslint-disable-next-line max-len
                    `${paidOrderDeliveryTimeRange?.start?.slice(0, 5)}-${paidOrderDeliveryTimeRange?.end?.slice(0, 5)}` :
                    t('page.supplier_orders.during_the_working_day');

                return `${paidOrderDeliveryDateData}${paidOrderDeliveryTime}`;
              });

              const deliveryDate = i?.deliveryDate?.split('-');
              const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
              const deliveryTimeRange = i?.deliveryTimeRange;

              const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
                `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
                t('page.supplier_orders.during_the_working_day');

              return (
                <div className="order_list_item" key={i.orderId}>
                  {(!array[index - 1] ||
                    creationDate.toDateString() !== new Date(array[index - 1]?.creationDate).toDateString()) ?
                    (<div className="item_date">
                      <div className="container">
                        {i?.creationDate && <p className="date">
                          {creationDate.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}
                        </p>}
                      </div>
                    </div>) : (
                      <hr className="order-list-separator"/>
                    )}
                  <div className="item_body">
                    <div className="container">

                      <div className="button_flex">
                        <div>

                          <div className="column_flex">
                            <div>
                              <div className="header-flex">
                                <div><p className="order">{t('page.order.order')} #{i.orderId}</p></div>
                                <div><p>{customerOrdersStatusWithClass(i?.status, t)}</p></div>
                              </div>
                              <p className="date">
                                {i?.creationDate ? t('page.order.order_list.placed', {
                                  date: creationDate.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                                  time: creationDate.toLocaleString('en-GB', { hour: 'numeric', minute: '2-digit', hour12: false }),
                                }) : '-'}
                              </p>
                            </div>
                            <div>
                            </div>
                            <div>
                              <p className="title">{t('page.order.order_list.project')}</p>
                              <p dir="auto" style={{textAlign: i18n.language === 'heb' ? 'right' : 'left'}}>
                                <span>{`${i?.projectName ? `"${i?.projectName}"` : ''}`},</span>
                                <span>{`${i?.city ? ` ${i?.city}` : ''}`},</span>
                                <span>{` ${i18n.language === 'heb' ? 'רח’' : 'st.'} ${i?.street} ${!!i?.apt ? ` ${i?.apt}` : ''}`}</span>
                              </p>
                            </div>
                            <div>
                              {i.urgentStatus ? (
                                <>
                                  <p className="title">{t('page.checkout.time_constraints.urgent_order')}</p>
                                  <p>{t('page.checkout.time_constraints.up_to_4_hours')}</p>
                                </>
                              ) : (
                                <>
                                  <p className="title">
                                    {
                                      isInProgress ? t('page.order.order_list.specific_delivery_time_in_progress') :
                                        t('page.order.order_list.specific_delivery_time')
                                    }
                                  </p>
                                  {isInProgress ? (
                                  <p>{deliveryTime ? `${deliveryDateData} ${deliveryTime}` : '-' }</p> ) : (
                                      <>
                                        {paidOrdersDelivery?.map((item, index) => {
                                          return <p key={index}>{item}</p>;
                                        })}
                                      </>
                                  )
                                  }
                                </>
                              )}
                            </div>
                          </div>

                        </div>
                        <ActionButtons status={i?.status} orderId={i?.orderId} canceledPaid={i?.paid} />
                      </div>

                    </div>
                  </div>
                </div>
              );
            })}

          </div>) : (
            <div className="product_not_found">
              <div className="container">
                <div className="product_not_found_box">
                  <div className="banner">
                    <span />
                  </div>

                  <p className="header">
                    {t('page.orders_not_found.orders_not_found')}
                  </p>

                  <p>
                    {t('page.orders_not_found.orders_text')}
                  </p>

                </div>
              </div>
            </div>
          )}

          {(pages > 1) && <div className="container"><PaginationComponent
            pages={pages}
            activePage={page ? Number(page) : 1}
            setActivePage={handleSelect}
          /></div>}

        </div>
      )}
    </>
  );
};

export default OrderList;
