import React, { useState, useEffect } from 'react';
import {
  Breadcrumb,
  Icon,
  Panel,
  FlexboxGrid,
  Col,
  Form,
  Radio,
  ControlLabel,
  FormControl,
  Input,
  Loader,
} from 'rsuite';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from '../../helpers/phoneNumberHelpers';
import { Schema } from 'rsuite';
import { validationErrorsFormat } from '../../utils/';
import { getOfferDetailsById, makeOffersBySupplier, resetOffer } from '../../redux/actions/offer';
import OfferItem from './OfferItem';
import OfferStatusErrorModal from './OfferStatusErrorModal';
import NotFound from '../NotFound';
const { StringType, NumberType } = Schema.Types;

const MAX_SAFE_NUMBER = 999999.99;

function ExpectedDeliveryTime() {
  const [deliveryData, setDeliveryData] = useState({});
  const [deliveryErrors, setDeliveryErrors] = useState({});
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { offerId } = useParams();
  const { item: delivery, isOfferUpdated, validationErrors, error, isFetching } = useSelector((state) => state.offer);
  const preliminaryPrice = delivery?.preliminaryPrice;

  const deliveryDate = delivery?.deliveryDetail?.deliveryDate?.split('-');
  const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
  const deliveryTimeRange = delivery?.deliveryDetail?.deliveryTimeRange;

  const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
    `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
    t('page.supplier_orders.during_the_working_day');

  const apt = delivery?.apt;
  const street = delivery?.street;
  const city = delivery?.city;

  const serviceUnloading = delivery?.serviceUnloading;

  useEffect(() => {
    dispatch(resetOffer());
    dispatch(getOfferDetailsById(offerId));
  }, []);

  useEffect(() => {
    if (delivery?.deliveryDetail) {
      setDeliveryData({
        ...deliveryData,
        deliveryTime: delivery?.deliveryDetail?.deliveryTime,
        deliveryMode: 'BY_SUPPLIER_AS_CUSTOMER_EXPECT',
        pickupStreet: street ? street : 'street',
        pickupApt: apt ? apt : 'pickupApt',
        pickupCity: city ? city : 'pickupCity',
        appropriateVehicleWeight: delivery?.deliveryDetail?.appropriateVehicleWeight ? delivery?.deliveryDetail?.appropriateVehicleWeight : 'WEIGHT_12T',
      });
    }
  }, [delivery?.deliveryDetail?.offerDeliveryDetailId]);

  useEffect(() => {
    if (isOfferUpdated) {
      dispatch(resetOffer());
      history.push('/offer_sent_successfully');
    }
  }, [isOfferUpdated]);

  const model = Schema.Model({
    contactName: StringType()
      .isRequired(t('errors.this_field_is_required')),
    contactPhone: StringType()
      .isRequired(t('errors.this_field_is_required'))
      .pattern(/^05\d\-\d{7}$/, t('errors.phoneInvalid')),
    deliveryCost: NumberType()
      .isRequired(t('errors.this_field_is_required'))
      ?.range(0, 9999999999.99, t('errors.field_size', { minSize: 0, maxSize: 9999999999.99 })),
  });

  const isSendOfferButtonDisabled = (
    Boolean(deliveryData?.contactName?.length) +
    Boolean(deliveryData?.contactPhone?.length) +
    Boolean(deliveryData?.deliveryCost?.toString().length) !== 3) ||
    (
      Boolean(deliveryErrors?.contactName?.length) +
      Boolean(deliveryErrors?.contactPhone?.length) +
      Boolean(deliveryErrors?.deliveryCost?.length)
    ) || (validationErrors && Object.keys(validationErrors).length);

  const handlePhoneNumberChange = (value) => {
    if (deliveryErrors && Object.keys(deliveryErrors).length) {
      dispatch(resetOffer());
    }
    const formattedPhoneNumber = formatPhoneNumber(value);
    setDeliveryData({ ...deliveryData, contactPhone: formattedPhoneNumber });
  };

  const sendOffer = () => {
    dispatch(makeOffersBySupplier(delivery?.offerId, deliveryData));
  };

  if (delivery && Object.keys(delivery).length === 0 && !isFetching) {
    return <NotFound/>;
  }

  return (
    <>
      {isFetching ? (
        <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
      ) : (
        <div className="checkout offer_confirmation supplier_orders">
          <div className="container">

            <div className="breadcrumb-list">
              <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                <Breadcrumb.Item componentClass={Link} to="/supplier/orders">
                  <span>{t('page.supplier_orders_delivery_method.orders')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item componentClass={Link} to={`/supplier/order/${delivery?.offerId}`}>
                  <span>{t('page.supplier_orders_delivery_method.order')} #{delivery?.orderId}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item componentClass={Link} to={`/supplier/make_offer/${delivery?.offerId}`}>
                  <span>{t('page.supplier_orders_delivery_method.confirm_offer')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <span>{t('page.supplier_orders.delivery_method')}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <h1>{t('page.supplier_orders_delivery_method.choose_delivery_method')}</h1>

            <div className="checkout-flex-col">

              <FlexboxGrid className="flex-420-col">
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

                  <Panel bordered className="panel_edit delivery_method">
                    <Form fluid>

                      <div className="grey_info_box">
                        <p>{t('page.supplier_orders_delivery_method.delivery_supplier')}</p>
                      </div>

                      <Radio onClick={() => history.push(`/supplier/delivery/expected/${offerId}`)} checked>
                        <span className="text">
                          <span className="header">{t('page.supplier_orders_delivery_method.expected_delivery_time')}</span>
                          <span>{deliveryTime ? `${deliveryDateData} ${deliveryTime}` : 'urgent order'}</span>
                        </span>
                      </Radio>

                      <div className="new_tab_option">

                        <FlexboxGrid>
                          <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
                            <ControlLabel className="required">{t('page.supplier_orders_delivery_method.delivery_contact_name')}</ControlLabel>
                            <FormControl
                              placeholder={t('page.supplier_orders_delivery_method.contact_name')}
                              value={deliveryData?.contactName || ''}
                              name="contactName"
                              errorMessage={null}
                              onChange={(val) => {
                                if (deliveryErrors && Object.keys(deliveryErrors).length) {
                                  dispatch(resetOffer());
                                }
                                setDeliveryData({ ...deliveryData, contactName: val });
                              }}
                              onBlur={() => {
                                setDeliveryErrors({
                                  ...deliveryErrors,
                                  contactName: model?.check(deliveryData)?.contactName?.errorMessage,
                                });
                              }}
                            />
                            {deliveryErrors.contactName && <p className="registration_error">{deliveryErrors.contactName}</p>}
                            {validationErrors?.contactName && <p className="registration_error">{validationErrorsFormat(validationErrors?.contactName, t)}</p>}
                          </FlexboxGrid.Item>
                          <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
                            <ControlLabel className="required">{t('page.supplier_orders_delivery_method.delivery_contact_phone')}</ControlLabel>
                            <FormControl
                              placeholder={t('page.supplier_orders_delivery_method.contact_phone')}
                              value={deliveryData?.contactPhone || ''}
                              onChange={(val) => handlePhoneNumberChange(val)}
                              name="contactPhone"
                              errorMessage={null}
                              onBlur={() => {
                                setDeliveryErrors({
                                  ...deliveryErrors,
                                  contactPhone: model?.check(deliveryData)?.contactPhone?.errorMessage,
                                });
                              }}
                            />
                            {deliveryErrors.contactPhone && <p className="registration_error">{deliveryErrors.contactPhone}</p>}
                            {validationErrors?.contactPhone && <p className="registration_error">{validationErrorsFormat(validationErrors?.contactPhone, t)}</p>}
                          </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <FlexboxGrid>
                          <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>
                            <ControlLabel className="required">{t('page.supplier_orders_delivery_method.delivery_cost')}</ControlLabel>
                            <Input
                              type="number"
                              placeholder={t('page.supplier_orders_delivery_method.delivery_cost')}
                              value={deliveryData?.deliveryCost || ''}
                              name="deliveryCost"
                              onChange={
                                (value) => {
                                  if (deliveryErrors && Object.keys(deliveryErrors).length) {
                                    dispatch(resetOffer());
                                  }
                                  if (!(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                                    if (value < 0 || isNaN(value)) {
                                      setDeliveryData({ ...deliveryData, deliveryCost: 0 });
                                    } else if (value > MAX_SAFE_NUMBER) {
                                      setDeliveryData({ ...deliveryData, deliveryCost: MAX_SAFE_NUMBER });
                                    } else {
                                      setDeliveryData({ ...deliveryData, deliveryCost: value });
                                    }
                                  }
                                }
                              }
                              onBlur={() => {
                                setDeliveryErrors({
                                  ...deliveryErrors,
                                  deliveryCost: model?.check(deliveryData)?.deliveryCost?.errorMessage,
                                });
                              }}
                            />
                            {deliveryErrors.deliveryCost && <p className="registration_error">{deliveryErrors.deliveryCost}</p>}
                            {validationErrors?.deliveryCost && <p className="registration_error">{validationErrorsFormat(validationErrors?.deliveryCost, t)}</p>}
                          </FlexboxGrid.Item>
                        </FlexboxGrid>
                      </div>

                      {serviceUnloading && (
                        <div className="warning_box">
                          <p>{t('page.supplier_orders_delivery_method.warning_message_1')}</p>
                        </div>
                      )}

                      <Radio onClick={() => history.push(`/supplier/delivery/specific/${offerId}`)}>
                        <span className="text">
                          <span className="header">{t('page.supplier_orders_delivery_method.time_proposed')}</span>
                          <span>{t('page.supplier_orders_delivery_method.select_date_time')}</span>
                        </span>
                      </Radio>

                      <div className="grey_info_box">
                        <p>{t('page.supplier_orders_delivery_method.delivery_konebone')}</p>
                      </div>

                      <Radio onClick={() => history.push(`/supplier/delivery/without_delivery/${offerId}`)}>
                        <span className="text">
                          <span className="header">{t('page.supplier_orders_delivery_method.delivery_handled_konebone')}</span>
                        </span>
                      </Radio>
                    </Form>
                  </Panel>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                  <div className="offers_list offer_summary">
                    <OfferItem
                      isSendOfferButtonDisabled={Boolean(isSendOfferButtonDisabled)}
                      sendOffer={sendOffer}
                      products={delivery?.products}
                      apt={apt}
                      street={street}
                      city={city}
                      // deliveryCost={deliveryCost}
                      deliveryCost={
                        Math.trunc(deliveryData?.deliveryCost * 100) / 100 ||
                        delivery?.deliveryDetail?.deliveryCost || 0
                      }
                      percentDiscount={delivery?.discount}
                      preliminaryPrice={preliminaryPrice}
                    />
                  </div>
                </FlexboxGrid.Item>
              </FlexboxGrid>

            </div>

          </div>
          {error && error === 'error.offer.status_incorrect' && <OfferStatusErrorModal show={true} />}
        </div>
      )}
    </>
  );
}

export default ExpectedDeliveryTime;
