import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import {
  FlexboxGrid,
  Col,
  Button,
  Loader,
} from 'rsuite';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart as addToCartAction } from '../redux/actions/cart';
import AlertAddedToCart from '../components/AlertAddedToCart';
import PurchaseCounter from '../components/PurchaseCounter';
import PaginationComponent from '../components/PaginationComponent';
import NoImage from '../assets/img/no_image.png';
import { byCategoryNameAndSearch } from '../redux/actions/search';
import { deleteCartItem } from '../redux/actions/cart';
import { closeAllNotifications } from '../redux/actions/notification';
import { loadAllPath } from '../redux/actions/catalog';


const queryString = require('query-string');

function Search() {
  const [activePage, setActivePage] = useState(1);
  const { t, i18n } = useTranslation();
  const { searchPhrase } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { count, products, pages, isFetching } = useSelector((state) => state.search);
  const { page, categoryName } = queryString.parse(useLocation().search);

  const handleSelect = (eventKey) => {
    setActivePage(eventKey);
    history.push({
      pathname: `/search/${searchPhrase}`,
      search: `?page=${eventKey}${categoryName ? '&categoryName='+categoryName : ''}`,
    });
  };

  useEffect(() => {
    setActivePage(page > 0 ? Number(page) : 1);
    searchPhrase && dispatch(byCategoryNameAndSearch(searchPhrase, categoryName, 1));
  }, [searchPhrase, categoryName]);


  useEffect(() => {
    const activePageValue = activePage !== 1 ? activePage : 0;
    if (searchPhrase) {
      dispatch(byCategoryNameAndSearch(searchPhrase, categoryName, activePageValue));
    }
  }, [activePage]);

  useEffect(() => {
    dispatch(loadAllPath(location.pathname));
  }, []);


  return (
    <>
      {isFetching ?
        (
          <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
        ) : (
          <div className="product_list search_page">
            <div className="container">

              {products.length !== 0 ? (
                <>
                  {searchPhrase && (
                    <h1>
                      {t('page.search.search_results_for')} &quot;<span className="red">{searchPhrase}</span>&quot;
                    </h1>
                  )}

                  {searchPhrase && count.length !== 0 && (
                    <div className="search_category_list">
                      <ul>
                        {count.map((i, index) => (
                          <li key={index}>
                            <p className={i.active ? 'active' : ''}>
                              <Link to={`/search/${searchPhrase}${i.categoryName === 'product_catalog.all_results' ? '' : `?categoryName=${i.categoryName}`}`}>{i.categoryName === 'product_catalog.all_results' ? t('page.search.all_results') : i.categoryName}</Link>
                              <span className="bull">&bull;</span>
                              <span>{i.productsCount}</span>
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <div className="wrapper_product_grid">
                    <FlexboxGrid className="product_item">
                      <ProductsList products={products} />
                    </FlexboxGrid>
                  </div>

                  {(pages > 1) && <PaginationComponent
                    pages={pages}
                    activePage={activePage}
                    setActivePage={handleSelect}
                  />}
                </>
              ) : (
                <div className="product_not_found">
                  <div className="container">
                    <div className="product_not_found_box">
                      <div className="banner">
                        <span />
                      </div>

                      {searchPhrase ? (
                        <p className="header">
                          {t('page.search.no_search_results_for')} &quot;<span className="red">{searchPhrase}</span>&quot;
                        </p>
                      ) : (
                        <p className="header">
                          {t('page.search.no_search_results')}
                        </p>
                      )}

                      <p>
                        {t('page.product_not_found.please_leave_us_a_message_in_Whats_up')}
                        <a href="tel:0723970146" className="ltr">{t('company_phone_number')}</a>
                        {t('page.product_not_found.and_we_will_do')}
                      </p>
                      <div className="button">
                        <Button block type="button" href="/product_catalog" appearance="primary"><span> {t('page.product_not_found.go_to_products_catalog')}</span></Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        )
      }
    </>
  );
}

function ProductsList({ products }) {
  return (
    products ? (
      products.map((product, index) => {
        return (
          <FlexboxGrid.Item key={`${product.productId}-${index}`} componentClass={Col} colspan={24} md={6}>
            <Product product={product} />
          </FlexboxGrid.Item>
        );
      })
    ) : null
  );
}

function Product({ product }) {
  const [count, setCount] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    // productNumber,
    catalogNumber,
    koneboneProductName,
    minKbPrice,
    maxKbPrice,
    fileName,
    productId,
    categoryPath,
  } = product;
  const { t } = useTranslation();

  const handlePlus = () => {
    setCount(count + 1);
  };

  const handleMinus = () => {
    count > 1 && setCount(count - 1);
  };

  const onCartItemDelete = (id) => {
    dispatch(deleteCartItem(id));
  };

  const closeNotifications = () => dispatch(closeAllNotifications());

  const cartItems = useSelector((state) => state.cart.items);
  const user = useSelector((state) => state.auth.user);

  const addToCart = () => dispatch(addToCartAction(
    t('cart.product_added_to_cart'),
    'open',
    <AlertAddedToCart
      history={history}
      onCartItemDelete={() => onCartItemDelete(product?.productId)}
      product={product}
      closeAllNotifications={closeNotifications}
      cartItems={cartItems}
      count={count}
    />,
    product,
    count
  ));

  const correctPathArr = categoryPath.split('/').reverse();
  let correctPath = '';
  correctPathArr.map((element) => {
    correctPath = correctPath + '/' + element;
  });

  return (
    <div className="products-list-flex">
      <Link to={`/product_catalog${correctPath}/product/${productId}`} className="wrapper_products-img">
        <div className="products-img">
          <img src={fileName ? fileName : NoImage} />
        </div>
      </Link>
      <div>
        <p><span className="product_number">{catalogNumber}</span></p>
        <p className="description"><Link to={`/product_catalog${correctPath}/product/${productId}`}>{koneboneProductName}</Link></p>
        <p className="price-all"><span className="ltr">₪ {minKbPrice} - ₪ {maxKbPrice}</span></p>
        {!Object.keys(user).length || user?.entityType === 'CUSTOMER' ? (
          <div className="add_to_cart">
            <div>
              <PurchaseCounter
                count={count}
                handlePlus={handlePlus}
                handleMinus={handleMinus}
                setCount={setCount}
              />
            </div>
            <div>
              <Button block type="button" appearance="primary" onClick={addToCart}>
                <span>{t('page.add_to_cart')}</span>
              </Button>
            </div>
          </div>
        ) : ''}
      </div>
    </div>
  );
}

ProductsList.propTypes = {
  products: PropTypes.instanceOf(Array).isRequired,
};

Product.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

export default Search;
