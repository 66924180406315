import { createActions } from 'redux-actions';
import { updateDataObject } from '../../helpers/collections';
import checkoutApi from '../../api/checkout';
import projectApi from '../../api/project';
import { validatePhoneInput } from '../../helpers/phoneNumberHelpers';

const actions = createActions({
  setCheckoutData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetCheckoutData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  confirmOrder: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  addConstraintsData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  setConstraintsData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetConstraintsData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  setProjectData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetProjectData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  addProjectData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  setAdditionalServicesData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetAdditionalServicesData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  addAdditionalServicesData: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  addIsCheckedStep: {
    success: (x) => x,
  },
});

export default actions;

export const setCheckoutData = (data) => async (dispatch, getState) => {
  const { checkout } = getState();
  dispatch(actions.setCheckoutData.request());
  try {
    dispatch(actions.setCheckoutData.success({ checkoutData: updateDataObject(checkout.checkoutData, data) }));
  } catch (e) {
    dispatch(actions.setCheckoutData.error({ error: e, showMessage: false }));
  }
};

export const resetCheckoutData = () => async (dispatch) => {
  dispatch(actions.resetCheckoutData.request());
  try {
    dispatch(actions.resetCheckoutData.success({ checkoutData: {} }));
  } catch (e) {
    dispatch(actions.resetCheckoutData.error({ error: e, showMessage: false }));
  }
};

export const confirOrder = (data) => async (dispatch) => {
  dispatch(actions.confirmOrder.request());
  try {
    alert(JSON.stringify(data, undefined, 2));
    dispatch(actions.confirmOrder.success({ order: data }));
  } catch (e) {
    dispatch(actions.confirmOrder.error({ error: e, showMessage: false }));
  }
};

export const setConstraintsData = (data, orderId, isUrgent) => async (dispatch) => {
  dispatch(actions.setConstraintsData.request());
  try {
    dispatch(actions.setConstraintsData.success({ timeConstraints: { ...data, orderId } }));
  } catch (e) {
    dispatch(actions.setConstraintsData.error({ error: e, showMessage: false }));
  }
};

export const resetConstraintsData = () => async (dispatch) => {
  dispatch(actions.resetConstraintsData.request());
  try {
    dispatch(actions.resetConstraintsData.success({ timeConstraints: {} }));
  } catch (e) {
    dispatch(actions.resetConstraintsData.error({ error: e, showMessage: false }));
  }
};

export const addConstraintsData = (data) => async (dispatch) => {
  dispatch(actions.addConstraintsData.request());
  try {
    const response = await checkoutApi.addTimeConstraints(data);
    dispatch(actions.addConstraintsData.success({ rfp: response.data }));
  } catch (e) {
    dispatch(actions.addConstraintsData.error({ error: e, showMessage: false }));
  }
};

export const setProjectData = (data, orderId) => async (dispatch) => {
  dispatch(actions.setProjectData.request());
  try {
    dispatch(actions.setProjectData.success({ projectData: { ...data, orderId } }));
  } catch (e) {
    dispatch(actions.setProjectData.error({ error: e, showMessage: true }));
  }
};

export const resetProjectData = () => async (dispatch) => {
  dispatch(actions.resetProjectData.request());
  try {
    dispatch(actions.resetProjectData.success({ projectData: {} }));
  } catch (e) {
    dispatch(actions.resetProjectData.error({ error: e, showMessage: false }));
  }
};

export const addProjectData = (data, isNew) => async (dispatch) => {
  dispatch(actions.addProjectData.request());
  const checkoutProjectData = {
    orderId: data?.orderId,
    projectId: data?.projectId,
  };
  const createProjectData = {
    apt: data?.apt,
    city: data?.city,
    contactFullName: data?.contactFullName,
    contactPhone: validatePhoneInput(data?.contactPhone),
    district: data?.district,
    name: data?.projectName,
    street: data?.street,
  };
  if (isNew) {
    data.name = data.projectName;
    delete data.projectName;
  }
  try {
    if (isNew) {
      const projectResponse = await projectApi.createProject(createProjectData);
      const response = await checkoutApi.addProject({
        ...checkoutProjectData,
        projectId: projectResponse?.data?.project,
      });
      dispatch(actions.addProjectData.success({ projectData: response.data.project }));
      if (!projectResponse.success) {
        dispatch(actions.addProjectData.error({ error: 'response.message.message', showMessage: true}));
      }
    } else {
      const response = await checkoutApi.addProject(data);
      dispatch(actions.addProjectData.success({ projectData: response?.data?.project }));
    };
  } catch (e) {
    dispatch(actions.addProjectData.error({ error: e, showMessage: true }));
  }
};

export const setAdditionalServicesData = (data, orderId, isCheckedStep) => async (dispatch) => {
  dispatch(actions.setAdditionalServicesData.request());
  try {
    dispatch(actions.setAdditionalServicesData.success({ additionalServices: { ...data, orderId, isCheckedStep } }));
  } catch (e) {
    dispatch(actions.setAdditionalServicesData.error({ error: e, showMessage: false }));
  }
};

export const resetAdditionalServicesData = () => async (dispatch) => {
  dispatch(actions.resetAdditionalServicesData.request());
  try {
    dispatch(actions.resetAdditionalServicesData.success({
      additionalServices: {
        serviceUnloading: false,
        serviceReturnOfLeftover: false,
        serviceRemoveOfGarbage: false,
        serviceUploadingToFlow: false,
      },
    }));
  } catch (e) {
    dispatch(actions.resetAdditionalServicesData.error({ error: e, showMessage: false }));
  }
};

export const addAdditionalServicesData = (data, orderId, isCheckedStep) => async (dispatch) => {
  dispatch(actions.addAdditionalServicesData.request());
  try {
    const response = await checkoutApi.addAditionalServices({ ...data, orderId, isCheckedStep });
    dispatch(actions.addAdditionalServicesData.success({ rfp: response.data, isCheckedStep }));
  } catch (e) {
    dispatch(actions.addAdditionalServicesData.error({ error: e, showMessage: false }));
  }
};

export const addIsCheckedStep = (isCheckedStep) => (dispatch) => {
  dispatch(actions.addIsCheckedStep.success({isCheckedStep}));
};

