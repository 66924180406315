import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleIsTimeConstraintsShown, toggleIsAdditionalServicesShown, toggleIsProjectsShown } from '../../redux/actions/order';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  FlexboxGrid,
  // Input,
  FormControl,
  Panel,
  Button,
  // InputGroup,
  Icon,
  Tooltip,
  Whisper,
} from 'rsuite';
import { setProjectData as setProject, addProjectData } from '../../redux/actions/checkout';
import { getProjects } from '../../redux/actions/project';
import { formatPhoneNumber } from '../../helpers/phoneNumberHelpers';
import { Schema } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const optionNameSaved = 'Saved';
const optionNameNew = 'New';

const { StringType } = Schema.Types;
const model = (t) => Schema.Model({
  apt: StringType().maxLength(10, t('errors.field_size', { minSize: 1, maxSize: 10 })),
  projectName: StringType().isRequired(t('errors.this_field_is_required'))
    .maxLength(100, t('errors.field_size', { minSize: 1, maxSize: 100 })),
  street: StringType().isRequired(t('errors.this_field_is_required'))
    .maxLength(100, t('errors.field_size', { minSize: 1, maxSize: 100 })),
  city: StringType().isRequired(t('errors.this_field_is_required'))
    .maxLength(100, t('errors.field_size', { minSize: 1, maxSize: 100 })),
  contactFullName: StringType().isRequired(t('errors.this_field_is_required'))
    .maxLength(200, t('errors.field_size', { minSize: 1, maxSize: 200 })),
  contactPhone: StringType()
    .isRequired(t('errors.this_field_is_required'))
    .pattern(/^05\d\-\d{7}$/, t('errors.phoneInvalid')),
});


const InfoTooltip = ({ ...rest }) => {
  const { t } = useTranslation();
  return (
    <Tooltip classPrefix="info_tooltip_checkout rs-tooltip" {...rest}>
      <p>{t('page.checkout.info')}</p>
    </Tooltip>
  );
};

const InfoButton = () => {
  const triggerRef = createRef();
  const [isOpen, setIsOpen] = useState(false);

  function handleCloseTooltip() {
    triggerRef.current.close();
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('resize', handleCloseTooltip);
    } else {
      window.removeEventListener('resize', handleCloseTooltip);
    }

    return () => {
      window.removeEventListener('resize', handleCloseTooltip);
    };
  });

  return (
    <Whisper
      placement="autoVerticalEnd"
      trigger="click"
      onOpen={() => setIsOpen(true)}
      speaker={<InfoTooltip />}
      triggerRef={triggerRef}
    >
      <Button className="info"><Icon icon="info" /></Button>
    </Whisper>
  );
};

function ProjectInfo({ setIsConfirmOrderShown }) {
  const dispatch = useDispatch();
  const [projectErrors, setProjectErrors] = useState({});
  const [projectData, setProjectData] = useState({});
  const [optionName, setOptionName] = useState('Saved');
  const history = useHistory();
  const { t } = useTranslation();

  const handleViewProject = (optionName) => {
    setOptionName(optionName);
  };

  const orderData = useSelector((state) => state.order.item);
  const { error, items } = useSelector((state) => state.project);
  const checkoutProjectData = useSelector((state) => state.checkout.projectData);
  const timeConstraints = useSelector((state) => state.checkout.timeConstraints);
  const additionalServices = useSelector((state) => state.checkout.additionalServices);
  const isCheckedStep = useSelector((state) => state.checkout.isCheckedStep);

  const timeConstraintsDate = timeConstraints?.date?.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' });

  useEffect(() => {
    dispatch(getProjects());
  }, []);


  useEffect(() => {
    if (error?.message === 'authentication fail') {
      history.push('/');
    }
  }, [error]);

  const changeCheckoutStates = () => {
    if (checkoutProjectData && Object.keys(checkoutProjectData).length === 0) {
      dispatch(toggleIsAdditionalServicesShown(true));
      dispatch(toggleIsTimeConstraintsShown(false));
      dispatch(toggleIsProjectsShown(false));
    } else {
      if (!!additionalServices.orderId) {
        dispatch(toggleIsAdditionalServicesShown(false));
        setIsConfirmOrderShown(true);
      } else {
        dispatch(toggleIsAdditionalServicesShown(true));
      }
      dispatch(toggleIsAdditionalServicesShown(false));
      dispatch(toggleIsProjectsShown(false));
    }
  };

  const addProjectDataAndContinue = () => {
    setOptionName('New');
    if (Object.keys(projectErrors).length === 0 && Object.keys(projectData).length) {
      dispatch(setProject(projectData, orderData?.orderId));
      dispatch(addProjectData({ ...projectData, orderId: orderData?.orderId }, optionName));
      changeCheckoutStates();
    }
  };

  const handlePhoneNumberChange = (value) => setProjectData({ ...projectData, contactPhone: formatPhoneNumber(value) });

  const onProjectClick = (id) => {
    setProjectData(id);
    dispatch(addProjectData({ orderId: orderData?.orderId, projectId: id }));
    setOptionName('Saved');
    changeCheckoutStates();

    if (!additionalServices.orderId) {
      dispatch(toggleIsTimeConstraintsShown(false));
      dispatch(toggleIsProjectsShown(false));
      dispatch(toggleIsAdditionalServicesShown(true));
      setIsConfirmOrderShown(false);
    }
  };

  return (
    <>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>
        <Panel bordered className="panel_edit">
          <div className="flex-container">
            <div className="flex-item">
              <p className="header">
                {t('page.checkout.time_constraints.time_constraints')} <span className="light"> {t('page.checkout.step_1')}</span>
              </p>
              <p> {t('page.checkout.time_constraints.specific_delivery_time')}:{' '}
                {timeConstraints?.date && timeConstraints?.time ?
                  // eslint-disable-next-line max-len
                  `${timeConstraintsDate}, ${timeConstraints?.time?.label}` :
                  'Up to 4 hours from the order confirmation'}
              </p>
            </div>
            <div className="flex-item item-edit">
              <p className="edit"><a href="#" onClick={() => {
                dispatch(toggleIsTimeConstraintsShown(true));
                dispatch(toggleIsProjectsShown(false));
                dispatch(toggleIsAdditionalServicesShown(false));
              }}><span>{t('page.checkout.edit')}</span></a></p>
            </div>
          </div>
        </Panel>

        <Panel bordered className="panel_edit">
          <p className="header">{t('page.checkout.project.project')} <span className="light">{t('page.checkout.step_2')}</span></p>

          <CheckoutOptions
            handleViewProject={handleViewProject}
            optionName={optionName}
          />
          {optionName === optionNameSaved ? (
            <CheckoutOptionSaved
              onProjectClick={onProjectClick}
              projects={items}
              handleViewProject={handleViewProject}
            />
          ) : (
            <CheckoutOptionNew
              addProjectDataAndContinue={addProjectDataAndContinue}
              setProjectErrors={setProjectErrors}
              projectErrors={projectErrors}
              setProjectData={setProjectData}
              projectData={projectData}
              projects={items}
              handlePhoneNumberChange={handlePhoneNumberChange}
            />
          )}

        </Panel>

        {!isCheckedStep ?
          <Panel bordered className="panel_edit opacity_50">
            <div className="flex-container info-tooltip">
              <div className="flex-item">
                <p className="header">{t('page.checkout.additional_services.additional_services')} <span className="light">{t('page.checkout.step_3')}</span></p>
              </div>
              <div className="flex-item">
                <InfoButton />
              </div>
            </div>
          </Panel> :
          <Panel bordered className="panel_edit">
            <div className="flex-container">
              <div className="flex-item">
                <p className="header">{t('page.checkout.additional_services.additional_services')} <span className="light">{t('page.checkout.step_3')}</span></p>
              </div>
              <div className="flex-item item-edit">
                <p className="edit"><a href="#" onClick={() => {
                  dispatch(toggleIsTimeConstraintsShown(false));
                  dispatch(toggleIsProjectsShown(false));
                  dispatch(toggleIsAdditionalServicesShown(true));
                }}><span>{t('page.checkout.edit')}</span></a></p>
              </div>
            </div>
          </Panel>
        }

      </FlexboxGrid.Item>

      {/*
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>

          <Panel bordered>
            <Order />
          </Panel>

        </FlexboxGrid.Item>
      */}
    </>
  );
}

function CheckoutOptions(props) {
  const { handleViewProject, optionName } = props;
  const { t } = useTranslation();

  return (
    <div className="switch_buttons">
      <div>
        <Button className={`${optionName === 'Saved' ? 'active' : ''}`} appearance="ghost" block onClick={() => handleViewProject(optionNameSaved)}><span>{t('page.checkout.project.saved.saved')}</span></Button>
      </div>
      <div>
        <Button className={`${optionName === 'New' ? 'active' : ''}`} appearance="ghost" block onClick={() => handleViewProject(optionNameNew)}><span>{t('page.checkout.project.new.new')}</span></Button>
      </div>
    </div>
  );
}

function CheckoutOptionNew({
  addProjectDataAndContinue,
  setProjectErrors,
  projectErrors,
  setProjectData,
  projectData,
  handlePhoneNumberChange,
  projects,
}) {
  const { t } = useTranslation();

  const isValidToSubmit = () => {
    return Object.keys(projectErrors).length === 0 &&
      !!projectData.projectName && !!projectData.contactFullName &&
      !!projectData.contactPhone && !!projectData.street && !!projectData.city;
  };

  const projectNameValidate = (formError) => {
    const projectExist = projects?.find((item) => item.name === projectData.projectName);
    if (projectExist) {
      setProjectErrors({valideProjectName: `${t('page.checkout.project.projectNameValidationError')}`, ...formError});
    } else setProjectErrors({...formError});
  };

  return (
    <Form
      fluid
      model={model(t)}
      onChange={(formValue) => setProjectData({ ...formValue })}
      onCheck={(formError) => projectNameValidate(formError)}
      formValue={projectData}
      checkTrigger="blur"
      className="new_tab_option"
    >
      <FlexboxGrid>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>
          <label className="required">{t('page.checkout.project.new.project_name')}</label>
          <FormControl placeholder={t('page.checkout.project.new.project_name')} name="projectName" errorMessage={null} />
          {projectErrors.projectName && <p className="registration_error">{projectErrors.projectName}</p>}
          {projectErrors.valideProjectName && <p className="registration_error">{projectErrors.valideProjectName}</p>}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <FlexboxGrid>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
          <label className="required">{t('page.checkout.project.new.contact_full_name')}</label>
          <FormControl placeholder={t('page.checkout.project.new.contact_full_name')} name="contactFullName" errorMessage={null} />
          {projectErrors.contactFullName && <p className="registration_error">{projectErrors.contactFullName}</p>}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
          <label className="required">{t('page.checkout.project.new.contact_phone')}</label>
          <FormControl placeholder="05N-XXXXXXX" name="contactPhone" errorMessage={null} onChange={(v) => handlePhoneNumberChange(v)} />
          {projectErrors.contactPhone && <p className="registration_error">{projectErrors.contactPhone}</p>}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <FlexboxGrid className="label_address">
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>
          <label className="required">{t('page.checkout.project.new.address')}</label>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <FlexboxGrid>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
          <FormControl placeholder={t('page.checkout.project.new.street', { requiredField: '*' })} name="street" errorMessage={null} />
          {projectErrors.street && <p className="registration_error">{projectErrors.street}</p>}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
          <FormControl placeholder={t('page.checkout.project.new.apt_unit')} name="apt" errorMessage={null} />
          {projectErrors.apt && <p className="registration_error">{projectErrors.apt}</p>}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <FlexboxGrid>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
          <FormControl placeholder={t('page.checkout.project.new.city', { requiredField: '*' })} name="city" errorMessage={null} />
          {projectErrors.city && <p className="registration_error">{projectErrors.city}</p>}
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <FlexboxGrid className="save_button">
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>
          <Button
            appearance="primary"
            block
            type="submit"
            disabled={!isValidToSubmit()}
            onClick={addProjectDataAndContinue}
          >
            <span>{t('page.checkout.project.new.save_continue')}</span>
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Form>
  );
}

const CheckoutOptionSaved = ({ onProjectClick, projects, handleViewProject }) => {
  const { t } = useTranslation();
  if (!projects.length) {
    return (
      <div className="no_saved_projects">
        <p className="header">{t('page.checkout.project.no_saved_projects.header')}</p>
        <p>{t('page.checkout.project.no_saved_projects.text')}</p>
        <Button appearance="primary" onClick={() => handleViewProject('New')}><span>{t('page.checkout.project.no_saved_projects.button')}</span></Button>
      </div>
    );
  }

  return (
    <>
      {/* <div className="search">
        <InputGroup>
          <InputGroup.Addon>
            <Icon icon="search" />
          </InputGroup.Addon>
          <Input placeholder={t('page.checkout.project.saved.search_project')} />
        </InputGroup>
      </div> */}
      <ProjectList projects={projects} onProjectClick={onProjectClick} />
    </>
  );
};

const Project = ({ project, onProjectClick }) => {
  const {
    apt,
    city,
    contactFullName,
    contactPhone,
    name: projectName,
    project: id,
    street,
  } = project;
  const { t } = useTranslation();

  return (
    <div className="flex-row">
      <div>
        <div className="img-box">
          <img src='' alt="" />
        </div>
      </div>
      <div>
        <p className="name">{projectName}</p>
        <p>{street}{city && `, ${city}`}{apt && `, ${apt}`}</p>
      </div>
      <div>
        <p className="name">{contactFullName}</p>
        <p>{contactPhone}</p>
      </div>
      <div>
        <Button
          onClick={() => onProjectClick(id)} appearance="primary">
          {t('page.checkout.project.saved.choose')}
        </Button>
      </div>
    </div>
  );
};

const ProjectList = ({ projects, onProjectClick }) => (
  <div className="project-list-flex">
    {projects?.map((projectItem, index) => (
      <Project key={index} project={projectItem} onProjectClick={onProjectClick} />
    ))}
  </div>
);

CheckoutOptions.propTypes = {
  handleViewProject: PropTypes.func.isRequired,
  optionName: PropTypes.string.isRequired,
};

Project.propTypes = {
  project: PropTypes.instanceOf(Object).isRequired,
  onProjectClick: PropTypes.func.isRequired,
};

ProjectList.propTypes = {
  projects: PropTypes.instanceOf(Array).isRequired,
  onProjectClick: PropTypes.func.isRequired,
};

ProjectInfo.propTypes = {
  isTimeConstraintsShown: PropTypes.bool.isRequired,
  setIsConfirmOrderShown: PropTypes.func.isRequired,
};

CheckoutOptionNew.propTypes = {
  addProjectDataAndContinue: PropTypes.func.isRequired,
  setProjectErrors: PropTypes.func.isRequired,
  projectErrors: PropTypes.instanceOf(Object).isRequired,
  setProjectData: PropTypes.func.isRequired,
  projectData: PropTypes.instanceOf(Object).isRequired,
  handlePhoneNumberChange: PropTypes.func.isRequired,
  projects: PropTypes.instanceOf(Array),
};

CheckoutOptionSaved.propTypes = {
  onProjectClick: PropTypes.func.isRequired,
  projects: PropTypes.instanceOf(Array),
  handleViewProject: PropTypes.func.isRequired,
};

export default ProjectInfo;
