import { handleActions } from 'redux-actions';
import actions from '../actions/product';

export const initialState = {
  isFetching: false,
  error: '',
  items: [],
  item: {},
  pages: null,
};

export default handleActions(
  {
    // get catalog
    [actions.getProductCatalog.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      items: [],
    }),

    [actions.getProductCatalog.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      items: payload.items,
      pages: payload.pages,
      totalItems: payload.totalItems,
    }),

    [actions.getProductCatalog.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // get product by id
    [actions.getProductById.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      item: state.item,
    }),

    [actions.getProductById.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      item: payload.item,
    }),

    [actions.getProductById.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),
  },
  initialState
);
