import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
import { Loader } from 'rsuite';
import { useTranslation } from 'react-i18next';

function TermsAndConditionsForCustomers() {
  const [numPages, setNumPages] = useState(null);
  const isMobileView = useMediaQuery({ query: '(max-width: 600px)' });
  const isTabletView = useMediaQuery({ query: '(max-width: 1200px)' });
  const path = window.location.origin.toString();
  const PDF_File = `${path}/docs/Terms%20of%20Conditions%20for%20Customers.pdf`;
  const { i18n } = useTranslation();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  return (
    <div>
      <Document
        file={PDF_File}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.log('Error while retrieving the outline! ' + error.message)}
        loading={<Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />}>
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={isTabletView ? (isMobileView ? 1 : 1.5) : 2}
              width={isMobileView ? 400 : 600}
            />
          ),
        )}
      </Document>
    </div>
  );
}

export default TermsAndConditionsForCustomers;
