import React, { useState, useEffect } from 'react';
import {
  Panel,
  Breadcrumb,
  Icon,
  FlexboxGrid,
  Col,
  Button,
  Modal,
  Loader,
} from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOfferDetailsById,
  putCancelOffer,
  // resetOffer,
} from '../redux/actions/offer';
import PropTypes from 'prop-types';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../utils/index';
import NoImage from '../assets/img/no_image.png';
import NotFound from './NotFound';

const ListOrdered = ({ products }) => (
  <ul>
    {products && products.map((product, index) => (
      <li key={index}>
        <OrderedProduct product={product} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  products: PropTypes.instanceOf(Array),
};

const OrderedProduct = ({ product }) => {
  const isMobileView = useMediaQuery({ query: '(max-width: 1199px)' });
  const { t } = useTranslation();

  const {
    catalogNumber,
    supplierNumber,
    productName,
    quantity,
    picture,
    outOfStock,
    // offerId,
    // orderId,
    // createdAt,
    // urgentStatus,
    // serviceUnloading,
    // serviceReturnOfLeftover,
    // serviceRemoveOfGarbage,
    // serviceUploadingToFlow,
    // orderDeliveryTime,
    // supplierComment,
    // cancelReason,
    totalPrice,
    supplierPrice,
  } = product;

  return (
    <>
      <div className={`products-list-flex${outOfStock ? ' opacity' : ''}`}>

        {isMobileView ? (
          <>
            <div>
              <div className="products-img">
                <img src={picture ? picture : NoImage} alt="product_png" />
                {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
              <p className="quantity">
                <span>
                  x {quantity}
                </span>
              </p>
            </div>
            <div>
              <div className="flex_box_p">
                <p><span className="product_number">{catalogNumber}</span></p>
                {supplierNumber && <p><span className="ltr">{supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
              </div>
              <p className="description">{productName}</p>
              <p className="price-all"><span className="ltr">₪ {totalPrice ? totalPrice : '0.00'}</span></p>
              {totalPrice ? <p><span className="ltr">₪ {supplierPrice ? supplierPrice : 0}/{t('page.order.item')}</span></p> : null}
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="products-img">
                <img src={picture ? picture : NoImage} alt="product_png" />
                {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
            </div>
            <div>
              <div className="flex_box_p">
                <p><span className="product_number">{catalogNumber}</span></p>
                {supplierNumber && <p><span className="ltr">{supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
              </div>
              <p className="description">{productName}</p>
            </div>
            <div>
              <p className="quantity">
                <span>
                  x {quantity}
                </span>
              </p>
            </div>
            <div>

              {/* <p><span className="ltr">₪ {preliminaryPrice}</span></p> */}
              <p className="price-all"><span className="ltr">₪ {totalPrice ? formatPrice(totalPrice) : '0.00'}</span></p>
              {totalPrice ? <p><span className="ltr">₪ {supplierPrice ? supplierPrice : 0}/{t('page.order.item')}</span></p> : null}
            </div>
          </>
        )}

      </div>
      {
        isMobileView && product.alternativeProduct ? (
          <>
            <div className="replacement">
              <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
            </div>
            <div>
              <div className="products-list-flex">
                <div>
                  <div className="products-img">
                    <img src={
                      product.alternativeProduct.fileName ? product.alternativeProduct.fileName : NoImage
                    } />
                  </div>
                  <div>
                    <p className="quantity">
                      <span>
                        x {product.quantity}
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex_box_p">
                    <p><span className="product_number">{product.alternativeProduct.catalogNumber}</span></p>
                  </div>
                  <p className="description">
                    {product.alternativeProduct.name}
                  </p>
                  <div>
                    <p className="price-all"><span className="ltr">₪ {product.totalPrice ? formatPrice(product.totalPrice) : '0.00'}</span></p>
                    {product.totalPrice ? <p><span className="ltr">₪ {supplierPrice ? supplierPrice : 0}/{t('page.order.item')}</span></p> : null}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : product.alternativeProduct ?
          (
            <>
              <div className="replacement">
                <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
              </div>
              <div>
                <div className="products-list-flex">
                  <div>
                    <div className="products-img">
                      <img src={
                        product.alternativeProduct.fileName ? product.alternativeProduct.fileName : NoImage
                      } />
                    </div>
                  </div>
                  <div>
                    <div className="flex_box_p">
                      <p><span className="product_number">{product.alternativeProduct.catalogNumber}</span></p>
                    </div>
                    <p className="description">
                      {product.alternativeProduct.name}
                    </p>
                  </div>
                  <div>
                    <p className="quantity">
                      <span>
                        x {product.quantity}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="price-all"><span className="ltr">₪ {product.totalPrice ? formatPrice(product.totalPrice) : '0.00'}</span></p>
                    {product.totalPrice ? <p><span className="ltr">₪ {supplierPrice ? supplierPrice : 0}/{t('page.order.item')}</span></p> : null}
                  </div>
                </div>
              </div>
            </>
          ): null
      }
      {
        isMobileView && product.draftProduct ? (
          <>
            <div className="replacement">
              <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
            </div>
            <div>
              <div className="products-list-flex">
                <div>
                  <div className="products-img">
                    <img src={
                      product.draftProduct.fileName ? product.draftProduct.fileName : NoImage
                    } />
                  </div>
                  <div>
                    <p className="quantity">
                      <span>
                        x {product.quantity}
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex_box_p">
                    <p><span className="product_number">{product.draftProduct.catalogNumber}</span></p>
                  </div>
                  <p className="description">
                    {product.draftProduct.name}
                  </p>
                  <div>
                    <p className="price-all"><span className="ltr">₪ {product.totalPrice ? formatPrice(product.totalPrice) : '0.00'}</span></p>
                    {product.totalPrice ? <p><span className="ltr">₪ {supplierPrice ? supplierPrice : 0}/{t('page.order.item')}</span></p> : null}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : product.draftProduct ?
          (
            <>
              <div className="replacement">
                <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
              </div>
              <div>
                <div className="products-list-flex">
                  <div>
                    <div className="products-img">
                      <img src={
                        product.draftProduct.fileName ? product.draftProduct.fileName : NoImage
                      } />
                    </div>
                  </div>
                  <div>
                    <div className="flex_box_p">
                      <p><span className="product_number">{product.draftProduct.catalogNumber}</span></p>
                    </div>
                    <p className="description">
                      {product.draftProduct.name}
                    </p>
                  </div>
                  <div>
                    <p className="quantity">
                      <span>
                        x {product.quantity}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="price-all"><span className="ltr">₪ {product.totalPrice ? formatPrice(product.totalPrice) : '0.00'}</span></p>
                    {product.totalPrice ? <p><span className="ltr">₪ {supplierPrice ? supplierPrice : 0}/{t('page.order.item')}</span></p> : null}
                  </div>
                </div>
              </div>
            </>
          ): null
      }
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};
function CancelOfferModal({ isModalOpen, cancelOffer, onHide, buttonDisable }) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        className="canceling_registration cancel_offer"
        overflow={false}
        show={isModalOpen}
        keyboard={true}
        autoFocus={true}
        onHide={onHide}
      >
        <Modal.Header>
          <p>{t('page.cancel_offer_modal.header_text_par_1')}</p>
          <p>{t('page.cancel_offer_modal.header_text_par_2')}</p>
        </Modal.Header>
        <Modal.Body >
          <p className="mb0">{t('page.cancel_offer_modal.body_text_1')}</p>
          <p>{t('page.cancel_offer_modal.body_text_2')}</p>
          <div className="flex-container">
            <div><Button appearance="primary" onClick={() => cancelOffer()} disabled={buttonDisable}><span>{t('page.cancel_offer_modal.cancel_offer')}</span></Button></div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

CancelOfferModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  cancelOffer: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  buttonDisable: PropTypes.bool.isRequired,
};

const SupplierOrdersOrderDetails = () => {
  const { t, i18n } = useTranslation();
  const [isCancelOfferModalOpen, setIsCancelOfferModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const offer = useSelector((state) => state.offer.item);
  const isFetching = useSelector((state) => state.offer.isFetching);
  const history = useHistory();

  const [buttonDisable, setButtonDisable] = useState(false);

  const deliveryDate = offer?.deliveryDetail?.deliveryDate?.split('-');
  const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
  const deliveryTimeRange = offer?.deliveryDetail?.deliveryTimeRange;

  const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
    `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
    t('page.supplier_orders.during_the_working_day');

  useEffect(() => {
    dispatch(getOfferDetailsById(orderId));
    // return dispatch(resetOffer());
  }, []);

  const cancelOffer = () => {
    setButtonDisable(true);
    dispatch(putCancelOffer(orderId, () => {
      setIsCancelOfferModalOpen(false);
      setButtonDisable(false);
      history.push('/supplier/orders/new');
    }));
  };

  const isNewOrder = offer?.status !== 'DECLINED' && offer?.status !== 'CANCELED';

  if (!isFetching && Object.keys(offer).length === 0) {
    return <NotFound/>;
  }

  return (
    isFetching ? (
      <div className="checkout offer_confirmation order_list_item supplier_orders">
        <div className="container">
          <Loader center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
        </div>
      </div>
    ) : (
      <>
        <div className="checkout offer_confirmation order_list_item supplier_orders">
          <div className="container">

            <div className="breadcrumb-list">
              <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                <Breadcrumb.Item componentClass={Link} to={isNewOrder ? '/supplier/orders/new' : '/supplier/orders/expired'}>
                  <span>{isNewOrder ? t('page.requests_for_proposal.new_orders') : t('page.requests_for_proposal.expired')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <span>{t('page.order.order')} #{offer?.orderId}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>


            <div className="checkout-flex-col">

              <FlexboxGrid className="flex-420-col">
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

                  <Panel bordered className="panel_edit">
                    <div className="header-flex">
                      <div><p className="order">{t('page.supplier_orders.order')} #{offer?.orderId}</p></div>
                      <div>
                        <p>
                          <span className="orders">
                            {offer?.products?.length === 1 ?
                              <>{offer?.products?.length} {t('page.supplier_orders.product')}</> :
                              <>{offer?.products?.length} {t('page.supplier_orders.products')}</>}
                          </span>
                        </p>
                      </div>
                    </div>
                    <p className="date">{t('page.supplier_orders.notice')}</p>

                    <div className="offers_list">
                      <div className="offer-box">

                        <div className="rs-panel-group without_collapse">
                          <div className="rs-panel">
                            <div className="rs-panel-heading">
                              <p className="rs-panel-title">{t('page.supplier_orders.order_filling')}</p>
                            </div>
                          </div>
                          <div className="rs-panel-collapse">
                            <div className="rs-panel-body">

                              <div className="products-list">
                                <ListOrdered products={offer?.products} />
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>


                  </Panel>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>

                  <div className="order-component offers_list">

                    <Panel bordered>

                      <p className="header">{t('page.supplier_orders.order_details')}</p>

                      <div className="grey_box">
                        <p>{t('page.supplier_orders.preliminary_price')}</p>
                        <p className="price"><span className="ltr">₪ {formatPrice(offer?.preliminaryPrice)}</span></p>
                        <div className="toggle_hide_view">
                          <p className="">{t('page.order.of_products', { num1: offer?.offerProductQuantity || 0, num2: offer?.orderProductQuantity || 0})}</p>
                        </div>
                      </div>

                      {isNewOrder ? <>
                        <div className="submit_button">
                          <Button block type="button" appearance="primary" onClick={() => history.push(`/supplier/make_offer/${orderId}`)}><span>{t('page.supplier_orders.confirm_offer')}</span></Button>
                        </div>

                        <div className="submit_button">
                          <Button block type="button" appearance="ghost" onClick={() => setIsCancelOfferModalOpen(true)}><span>{t('page.supplier_orders.cancel_offer')}</span></Button>
                        </div>
                      </> : ''}

                      <div className="details_box">
                        <p className="header">{t('page.supplier_orders.delivery_details')}</p>

                        <div className="ico-item clock">
                          <p className="sub-header">{t('page.supplier_orders.expected_delivery_time')}</p>
                          {deliveryTime ?
                            <p>{`${deliveryDateData} ${deliveryTime}`}</p> :
                            <p>{t('page.checkout.time_constraints.urgent_order')}</p>
                          }
                        </div>

                        <div className="ico-item project">
                          <p className="sub-header">{t('page.supplier_orders.delivery_address')}</p>
                          <p dir="auto" style={{textAlign: i18n.language === 'heb' ? 'right' : 'left'}}>
                            <span>{`${offer?.city ? ` ${offer?.city}` : ''}`},</span>
                            <span>{` ${i18n.language === 'heb' ? 'רח’' : 'st.'} ${offer?.street} ${offer?.apt ? offer?.apt : '-'}`}</span>
                          </p>
                        </div>

                        <div className="ico-item services">
                          <p className="sub-header">{t('page.supplier_orders.additional_services')}</p>
                          <p>{offer?.serviceUnloading ? t('page.order.unloading') : '-'}</p>
                        </div>

                        <div className="ico-item comment">
                          <p className="sub-header">{t('page.supplier_orders.comment_supplier.header')}</p>
                          {/* offer?.supplierComment ? <p>{offer?.supplierComment}</p> :
                            <p>-</p>
                          */}
                          {offer?.customerComment ? <p>{offer?.customerComment}</p> :
                            <p>-</p>
                          }
                        </div>
                      </div>

                    </Panel>

                  </div>

                </FlexboxGrid.Item>
              </FlexboxGrid>

            </div>

          </div>
        </div>
        <CancelOfferModal
          isModalOpen={isCancelOfferModalOpen}
          cancelOffer={() => cancelOffer()}
          onHide={() => setIsCancelOfferModalOpen(false)}
          buttonDisable={buttonDisable}
        />
      </>
    )
  );
};

export default SupplierOrdersOrderDetails;
