import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormGroup,
  ControlLabel,
  Form,
  FormControl,
} from 'rsuite';
import { setAddressInfoData, createAddressInfoData } from '../../../redux/actions/auth/addressInfoData';
import { getSignedSupplierCandidate, getSignedCustomerCandidate } from '../../../redux/actions/auth/registration';
import { Schema } from 'rsuite';
import { useTranslation } from 'react-i18next';
import CancelRegistrationModalAbortion from '../Registration/CancelRegistrationModalAbortion';
import { SignInContext } from '../../../context/signInContext';

const { StringType } = Schema.Types;
const model = (t) => (Schema.Model({
  city: StringType()
    .isRequired(t('errors.this_field_is_required'))
    .maxLength(100, t('errors.field_size', { minSize: 1, maxSize: 100 })),
  street: StringType()
    .isRequired(t('errors.this_field_is_required'))
    .maxLength(100, t('errors.field_size', { minSize: 1, maxSize: 100 })),
  unit: StringType()
    .isRequired(t('errors.this_field_is_required'))
    .maxLength(10, t('errors.field_size', { minSize: 1, maxSize: 10 })),
}));

function AddressInfo() {
  const dispatch = useDispatch();
  const history = useHistory();
  const wrapperRef = useRef(null);

  const [registrationData, setRegistrationData] = useState({});
  const [registrationErrors, setRegistrationErrors] = useState({});

  const [showCancelRegistrationModal, setShowCancelRegistrationModal] = useState(false);

  const addressInfo = useSelector((state) => state.auth.addressInfo || {});
  const stateRegistrationData = useSelector((state) => state.auth.registrationData);
  const user = useSelector((state) => state.auth.user);
  const { abortRegistrationAction } = useContext(SignInContext);
  const { t } = useTranslation();

  useEffect(() => {
    // const checkUserData = Object.keys(user).length;
    // const checkRegisterData = Object.keys(stateRegistrationData).length;
    // if (!checkUserData && !checkRegisterData || !!checkUserData && !checkRegisterData) {
    //   history.push('/');
    // }
    setRegistrationData(addressInfo);
    user?.status === 'UNFINISHED' && (user?.entityType === 'SUPPLIER_CANDIDATE' ?
      dispatch(getSignedSupplierCandidate((data) => {
        setRegistrationData(data);
      })) : dispatch(getSignedCustomerCandidate((data) => {
        setRegistrationData(data);
      })));
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowCancelRegistrationModal(true);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const isValidToSubmit = () => {
    return Object.keys(registrationErrors).length === 0 &&
      Boolean(registrationData.city) &&
      Boolean(registrationData.street) &&
      Boolean(registrationData.unit);
  };

  const goBack = () => {
    dispatch(setAddressInfoData({ ...registrationData, phone: stateRegistrationData?.phone || user?.phone }));
    history.push(user?.entityType === 'CUSTOMER' ? '/customer_company_info' : '/supplier_company_info');
  };

  const onStepSubmit = () => {
    dispatch(setAddressInfoData({ ...registrationData, phone: stateRegistrationData?.phone || user?.phone }));
    dispatch(createAddressInfoData({ ...registrationData, phone: stateRegistrationData?.phone || user?.phone }));
    history.push('/manager_info');
  };

  return (
    <div className="registration" ref={wrapperRef}>
      <div className="registration_box">
        <Form
          model={model(t)}
          onChange={(formValue) => setRegistrationData({ ...formValue })}
          onCheck={(formError) => setRegistrationErrors({ ...formError })}
          formValue={registrationData}
          checkTrigger="blur"
          onSubmit={onStepSubmit}>

          <p className="steps">{t('auth.step_2_of_3')}</p>

          <div className="registration-title">
            <p>
              <span>{t('auth.to_be_able_to_place_orders_at_konebone')}</span>
              <span>{t('auth.fill_in_your_business_address_information')}</span>
            </p>
          </div>

          <FormGroup>
            <ControlLabel className="required">{t('auth.city')}</ControlLabel>
            <FormControl name="city" type="text" placeholder={t('auth.city')} value={registrationData?.city || ''} errorMessage={null} />
            {registrationErrors.city && <p className="registration_error">{registrationErrors.city}</p>}
            <ControlLabel className="required">{t('auth.street')}</ControlLabel>
            <FormControl name="street" type="text" placeholder={t('auth.street')} value={registrationData?.street || ''} errorMessage={null} />
            {registrationErrors.street && <p className="registration_error">{registrationErrors.street}</p>}
            <ControlLabel className="required">{t('auth.apt_unit')}</ControlLabel>
            <FormControl name="unit" type="text" placeholder={t('auth.apt_unit')} value={registrationData?.unit || ''} errorMessage={null} />
            {registrationErrors.unit && <p className="registration_error">{registrationErrors.unit}</p>}
          </FormGroup>

          <div className="buttons_list">
            <FormGroup>
              <div className="flex-container">
                <div><Button appearance="primary" type="submit" disabled={!isValidToSubmit()}><span>{t('auth.continue')}</span></Button></div>
                <div><Button appearance="ghost" onClick={goBack}><span>{t('auth.back')}</span></Button></div>
              </div>
            </FormGroup>
          </div>
        </Form>
      </div>
      {showCancelRegistrationModal &&<CancelRegistrationModalAbortion
        isModalOpen={showCancelRegistrationModal}
        abortAction={abortRegistrationAction}
        cancelAction={setShowCancelRegistrationModal}
      />}
    </div>
  );
}

export default AddressInfo;
