import React, { useState, useEffect } from 'react';
import {
  Breadcrumb,
  Icon,
  Panel,
  FlexboxGrid,
  Col,
  Form,
  Radio,
  FormControl,
} from 'rsuite';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reverseStringWords, validationErrorsFormat } from '../../utils/';
import { getOfferDetailsById, makeOffersBySupplier, resetOffer } from '../../redux/actions/offer';
import OfferItem from './OfferItem';
import { Schema } from 'rsuite';
import OfferStatusErrorModal from './OfferStatusErrorModal';
const { StringType } = Schema.Types;

function WithoutDelivery() {
  const [deliveryData, setDeliveryData] = useState({});
  const [deliveryErrors, setDeliveryErrors] = useState({});
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { offerId } = useParams();
  const { item: delivery, isOfferUpdated, validationErrors, error } = useSelector((state) => state.offer);
  const preliminaryPrice = delivery?.preliminaryPrice;

  const deliveryDate = delivery?.deliveryDetail?.deliveryDate?.split('-');
  const deliveryDateData = deliveryDate ? `,${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
  const deliveryTimeRange = delivery?.deliveryDetail?.deliveryTimeRange;

  const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
    `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
    t('page.supplier_orders.during_the_working_day');

  const apt = delivery?.apt;
  const street = delivery?.street;
  const city = delivery?.city;

  useEffect(() => {
    dispatch(resetOffer());
    dispatch(getOfferDetailsById(offerId));
  }, []);

  useEffect(() => {
    if (delivery?.deliveryDetail) {
      setDeliveryData({
        ...deliveryData,
        deliveryTime: delivery?.deliveryDetail?.deliveryTime,
        deliveryMode: 'BY_KONEBONE',
        deliveryCost: delivery?.deliveryDetail?.deliveryCost,
        contactName: delivery?.deliveryDetail?.contactName,
        contactPhone: delivery?.deliveryDetail?.contactPhone,
      });
    }
  }, [delivery?.deliveryDetail?.offerDeliveryDetailId]);

  useEffect(() => {
    if (isOfferUpdated) {
      dispatch(resetOffer());
      history.push('/offer_sent_successfully');
    }
  }, [isOfferUpdated]);

  const model = Schema.Model({
    pickupStreet: StringType()
      .isRequired(t('errors.this_field_is_required')),
    pickupCity: StringType()
      .isRequired(t('errors.this_field_is_required')),
  });

  const isSendOfferButtonDisabled = (
    Boolean(deliveryData?.pickupStreet?.length) +
    Boolean(deliveryData?.pickupCity?.length) +
    Boolean(deliveryData?.appropriateVehicleWeight?.length) !== 3) &&
  (
    !Boolean(deliveryErrors?.pickupStreet?.length) +
    !Boolean(deliveryErrors?.pickupCity?.length) +
    !Boolean(deliveryErrors?.appropriateVehicleWeight?.length)
  ) || (validationErrors && Object.keys(validationErrors).length);

  const onHandleAppropriateVechicle = (val) => {
    setDeliveryData({ ...deliveryData, appropriateVehicleWeight: val });
  };

  const sendOffer = () => {
    dispatch(makeOffersBySupplier(delivery?.offerId, deliveryData));
  };

  return (
    <div className="checkout offer_confirmation supplier_orders">
      <div className="container">

        <div className="breadcrumb-list">
          <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
            <Breadcrumb.Item componentClass={Link} to="/supplier/orders">
              <span>{t('page.supplier_orders_delivery_method.orders')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item componentClass={Link} to={`/supplier/order/${delivery?.offerId}`}>
              <span>{t('page.supplier_orders_delivery_method.order')} #{delivery?.orderId}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item componentClass={Link} to={`/supplier/make_offer/${delivery?.offerId}`}>
              <span>{t('page.supplier_orders_delivery_method.confirm_offer')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <span>{t('page.supplier_orders.delivery_method')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <h1>{t('page.supplier_orders_delivery_method.choose_delivery_method')}</h1>

        <div className="checkout-flex-col">

          <FlexboxGrid className="flex-420-col">
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

              <Panel bordered className="panel_edit delivery_method">
                <Form fluid>

                  <div className="grey_info_box">
                    <p>{t('page.supplier_orders_delivery_method.delivery_supplier')}</p>
                  </div>

                  <Radio onClick={() => history.push(`/supplier/delivery/expected/${offerId}`)}>
                    <span className="text">
                      <span className="header">{t('page.supplier_orders_delivery_method.expected_delivery_time')}</span>
                      <span>{deliveryTime ? `${deliveryDateData} ${deliveryTime}` : 'urgent order'}</span>
                    </span>
                  </Radio>

                  <Radio onClick={() => history.push(`/supplier/delivery/specific/${offerId}`)}>
                    <span className="text">
                      <span className="header">{t('page.supplier_orders_delivery_method.time_proposed')}</span>
                      <span>{t('page.supplier_orders_delivery_method.select_date_time')}</span>
                    </span>
                  </Radio>

                  <div className="grey_info_box">
                    <p>{t('page.supplier_orders_delivery_method.delivery_konebone')}</p>
                  </div>

                  <Radio onClick={() => history.push(`/supplier/delivery/without_delivery/${offerId}`)} checked>
                    <span className="text">
                      <span className="header">{t('page.supplier_orders_delivery_method.delivery_handled_konebone')}</span>
                    </span>
                  </Radio>

                  <div className="new_tab_option">
                    <label className="required">{t('page.supplier_orders_delivery_method.pickup_address')}</label>
                  </div>
                  <div className="new_tab_option">
                    <FlexboxGrid>
                      <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
                        <FormControl
                          placeholder={t('page.supplier_orders_delivery_method.street')}
                          value={deliveryData?.pickupStreet || ''}
                          name="pickupStreet"
                          errorMessage={null}
                          onChange={(val) => {
                            if (deliveryErrors && Object.keys(deliveryErrors).length) {
                              dispatch(resetOffer());
                            }
                            setDeliveryData({ ...deliveryData, pickupStreet: val });
                          }}
                          onBlur={() => {
                            setDeliveryErrors({
                              ...deliveryErrors,
                              pickupStreet: model?.check(deliveryData)?.pickupStreet?.errorMessage,
                            });
                          }}
                        />
                        {deliveryErrors.pickupStreet && <p className="registration_error">{deliveryErrors.pickupStreet}</p>}
                        {validationErrors?.pickupStreet && <p className="registration_error">{validationErrorsFormat(validationErrors?.pickupStreet, t)}</p>}
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
                        <FormControl
                          placeholder={t('page.supplier_orders_delivery_method.apt_unit')}
                          value={deliveryData?.pickupApt || ''}
                          name="pickupApt"
                          errorMessage={null}
                          onChange={(val) => {
                            if (deliveryErrors && Object.keys(deliveryErrors).length) {
                              dispatch(resetOffer());
                            }
                            setDeliveryData({ ...deliveryData, pickupApt: val });
                          }}
                          onBlur={() => {
                            setDeliveryErrors({
                              ...deliveryErrors,
                            });
                          }}
                        />
                        {deliveryErrors.pickupApt && <p className="registration_error">{deliveryErrors.pickupApt}</p>}
                        {validationErrors?.pickupApt && <p className="registration_error">{validationErrorsFormat(validationErrors?.pickupApt, t)}</p>}
                      </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid>
                      <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>
                        <FormControl
                          placeholder={t('page.supplier_orders_delivery_method.city')}
                          value={deliveryData?.pickupCity || ''}
                          name="pickupCity"
                          errorMessage={null}
                          onChange={(val) => {
                            if (deliveryErrors && Object.keys(deliveryErrors).length) {
                              dispatch(resetOffer());
                            }
                            setDeliveryData({ ...deliveryData, pickupCity: val });
                          }}
                          onBlur={() => {
                            setDeliveryErrors({
                              ...deliveryErrors,
                              pickupCity: model?.check(deliveryData)?.pickupCity?.errorMessage,
                            });
                          }}
                        />
                        {deliveryErrors.pickupCity && <p className="registration_error">{deliveryErrors.pickupCity}</p>}
                        {validationErrors?.pickupCity && <p className="registration_error">{validationErrorsFormat(validationErrors?.pickupCity, t)}</p>}
                      </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid>
                      <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>
                        <label className="required">{i18n.language === 'heb' ? 'בחר הרכב המתאים' : 'Choose appropriate vehicle'}</label>
                        <div className="ton_box">
                          <ul>
                            <li onClick={() => onHandleAppropriateVechicle('WEIGHT_4T')}>
                              <input type="radio" name="truck_weight" id="deliveryTruck4" />
                              <label className="delivery-truck-4" htmlFor="deliveryTruck4">
                                <span>{reverseStringWords(t('page.supplier_orders_delivery_method.4ton'))}</span>
                              </label>
                            </li>
                            <li onClick={() => onHandleAppropriateVechicle('WEIGHT_8T')}>
                              <input type="radio" name="truck_weight" id="deliveryTruck8" />
                              <label className="delivery-truck-8" htmlFor="deliveryTruck8">
                                <span>{reverseStringWords(t('page.supplier_orders_delivery_method.8ton'))}</span>
                              </label>
                            </li>
                            <li onClick={() => onHandleAppropriateVechicle('WEIGHT_12T')}>
                              <input type="radio" name="truck_weight" id="deliveryTruck12" />
                              <label className="delivery-truck-12" htmlFor="deliveryTruck12">
                                <span>{reverseStringWords(t('page.supplier_orders_delivery_method.12ton'))}</span>
                              </label>
                            </li>
                            <li onClick={() => onHandleAppropriateVechicle('WEIGHT_15T')}>
                              <input type="radio" name="truck_weight" id="deliveryTruck15" />
                              <label className="delivery-truck-15" htmlFor="deliveryTruck15">
                                <span>{reverseStringWords(t('page.supplier_orders_delivery_method.15ton'))}</span>
                              </label>
                            </li>
                          </ul>

                        </div>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>

                  </div>
                </Form>
              </Panel>

            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
              <div className="offers_list offer_summary">
                <OfferItem
                  isSendOfferButtonDisabled={Boolean(isSendOfferButtonDisabled)}
                  sendOffer={sendOffer}
                  products={delivery?.products}
                  apt={apt}
                  street={street}
                  city={city}
                  // deliveryCost={deliveryCost}
                  deliveryCost={
                    Math.trunc(deliveryData?.deliveryCost * 100)/100 || delivery?.deliveryDetail?.deliveryCost || 0
                  }
                  percentDiscount={delivery?.discount}
                  preliminaryPrice={preliminaryPrice}
                />
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid>

        </div>

      </div>
      {error && error === 'error.offer.status_incorrect' && <OfferStatusErrorModal show={true} />}
    </div>
  );
}

export default WithoutDelivery;
