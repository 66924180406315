import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  FormGroup,
  ControlLabel,
  Dropdown,
  Checkbox,
  InputGroup,
  Input,
} from 'rsuite';
import { formatPhoneNumber } from '../../../helpers/phoneNumberHelpers';
import { registerUser, setRegistrationData as setRegData, resetRegistrationError} from '../../../redux/actions/auth/registration';
import israelFlag from '../../../assets/svg/flagOfIsrael.svg';
import { Schema } from 'rsuite';
import { useTranslation } from 'react-i18next';

const { StringType, BooleanType } = Schema.Types;
const model = (t) => Schema.Model({
  phone: StringType().isRequired(t('errors.this_field_is_required')),
  type: StringType().isRequired(t('errors.this_field_is_required')),
  conditions: BooleanType('Not'),
});
const Registration = ({
  handleShowConfirmCode,
  handleShowRegistration,
  registrationData,
  setRegistrationData,
}) => {
  const dispatch = useDispatch();
  const [registrationErrors, setRegistrationErrors] = useState({});
  const [conditionsErrors, setConditionsErrors] = useState('');
  const [checkRole, setCheckRole] = useState(true);
  const { registrationData: stateRegistrationData, error, goToNextStep } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  useEffect(() => {
    stateRegistrationData?.phone?.length ?
      setRegistrationData({ ...stateRegistrationData, phone: formatPhoneNumber(stateRegistrationData?.phone) }) :
      setRegistrationData({ ...stateRegistrationData });
  }, [stateRegistrationData]);

  useEffect(() => {
    if (goToNextStep) {
      handleShowRegistration(false);
      handleShowConfirmCode(true);
    }
  }, [goToNextStep]);

  useEffect(() => {
    if (error === 'phone.already.exists') {
      setRegistrationErrors({});
    }
  }, [error]);

  const onFormSubmit = async () => {
    if (!registrationData?.conditions) {
      setRegistrationData({ ...registrationData, conditions: false });
      setConditionsErrors('Conditions shoud be accepted');
    } else {
      setConditionsErrors('');
    }

    const phone = registrationData?.phone?.split('').filter((item) => item !== '-').join('');

    dispatch(setRegData({ ...registrationData, phone }));

    if (conditionsErrors === '' && Object.keys(registrationErrors).length === 0 && registrationData?.conditions) {
      dispatch(registerUser({ ...registrationData, phone }));
    }
  };

  const handlePhoneNumberChange = (value) => {
    if (error) {
      dispatch(resetRegistrationError());
    }
    const formattedPhoneNumber = formatPhoneNumber(value);
    setRegistrationData({ ...registrationData, phone: formattedPhoneNumber });
    dispatch(setRegData({ ...registrationData, phone: formattedPhoneNumber }));
  };

  const onHandleDropdown = (eventKey) => {
    eventKey === 'Sell materials (for suppliers)' ? setCheckRole(false) : setCheckRole(true);
    setRegistrationData({ ...registrationData, type: eventKey });
    dispatch(setRegData({ ...registrationData, type: eventKey }));
  };

  const onAcceptConditions = (evt, val) => {
    if (!val) {
      setRegistrationData({ ...registrationData, conditions: false });
      setConditionsErrors('Conditions shoud be accepted');
    } else {
      setConditionsErrors('');
    }
    setRegistrationData({ ...registrationData, conditions: val });
    dispatch(setRegData({ ...registrationData, conditions: val }));
  };

  const checkUserType = registrationData?.type === 'Sell materials (for suppliers)' ? t('auth.register.for_suppliers') : t('auth.register.for_business');

  return (
    <Form
      fluid
      model={model(t)}
      formValue={registrationData}
      onChange={(formValue) => setRegistrationData({ ...formValue })}
      onCheck={(formError) => setRegistrationErrors({ ...formError })}
      checkTrigger="change"
    >
      <FormGroup>
        <ControlLabel>{t('auth.register.i_want_to')}</ControlLabel>
        <div className="wrapper_dropdown">
          <Dropdown
            title={registrationData?.type ? checkUserType : t('auth.register.please_select')}
            name="type"
            onSelect={(val) => onHandleDropdown(val)}
            activeKey={registrationData?.type}
          >
            <Dropdown.Item eventKey="Order materials (for business)">
              <span>{t('auth.register.for_business')}</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey="Sell materials (for suppliers)">
              <span>{t('auth.register.for_suppliers')}</span>
            </Dropdown.Item>
          </Dropdown>
        </div>
        {registrationErrors.type && <p className="registration_error">{registrationErrors.type}</p>}
      </FormGroup>
      <FormGroup>
        <ControlLabel>{t('auth.register.phone_number')}</ControlLabel>
        <InputGroup className="input_group_flag">
          <InputGroup.Button>
            <img src={israelFlag} alt='israel flag' />
          </InputGroup.Button>
          <Input
            name="phone"
            type="text"
            placeholder="05N-XXXXXXX"
            value={registrationData?.phone || ''}
            errorMessage={null}
            onChange={(v) => handlePhoneNumberChange(v)}
            inputMode="numeric"
            inputType="tel"
          />
        </InputGroup>
        {registrationErrors.phone && <p className="registration_error">{registrationErrors.phone}</p>}
        {error === 'phone.already.exists' && <p className="registration_error">{t('errors.phoneAlreadyExists')}</p>}
        {error === 'bean.validation.fail' && <p className="registration_error">{t('errors.phoneInvalid')}</p>}
      </FormGroup>
      {Object.keys(registrationData).length ? <FormGroup>
        <Checkbox name="accept_conditions" onChange={(evt, val) => onAcceptConditions(evt, val)} value={registrationData?.conditions} checked={registrationData?.conditions} >
          <span className="text">{t('auth.i_accept_konebone')}
            <a href={checkRole ? '/terms-and-conditions-for-customers' : '/terms-and-conditions-for-suppliers'}
              target="_blank" rel="noreferrer">
              <span>{t('auth.terms_and_conditions')}</span>
            </a> {' '}
            {t('auth.and')} <a href="/privacy-policy" target="_blank" rel="noreferrer"><span>{t('auth.privacy_policy')}</span></a></span>
        </Checkbox>
        {conditionsErrors === 'Conditions shoud be accepted' && <p className="registration_error">{t('errors.conditions_should_be_accepted')}</p>}
      </FormGroup> : ''}
      <FormGroup className="buttons_box">
        <Button block appearance="primary" type="submit" disabled={!registrationData?.type?.length || registrationData?.phone?.length <= 10 || registrationErrors?.conditions} onClick={onFormSubmit}><span>{t('auth.register.register')}</span></Button>
      </FormGroup>
    </Form>
  );
};

Registration.propTypes = {
  handleShowConfirmCode: PropTypes.func.isRequired,
  handleShowRegistration: PropTypes.func.isRequired,
  registrationData: PropTypes.instanceOf(Object),
  setRegistrationData: PropTypes.func.isRequired,
};

export default Registration;
