import { post, get } from './';

const getCreditInfo = () => get('/credit');
const payForOfferCredit = (offerId) => post(`/credit/${offerId}`);
const getOfferPaymentInfo = (offerId) => get(`/payment/charge/${offerId}`);

const paymentApi = {
  getCreditInfo,
  payForOfferCredit,
  getOfferPaymentInfo,
};

export default paymentApi;
