import React from 'react';
import { Button } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CustomerSucceededRegistration = () => {
  const history = useHistory();
  const goToCatalog = () => history.push('/');
  const goToСonnectBuyNow = () => history.push('/connect_buy_now');
  const { t } = useTranslation();
  return (
    <div className="registration">
      <div className="registration_box success_box">
        <div className="success_icon"></div>
        <div className="registration-title">
          <p>
            <span>{t('auth.your_registration_request')}</span>
            <span>{t('auth.has_been_successfully_sent_for_processing')}</span>
          </p>
        </div>
        <p>{t('auth.register.konebone_representative_will_contact_you_soon')}</p>
        {/*
          <p>Check out the Konebone catalog so that after registration you
            will be able to quickly place your order.</p>
        */}
        <p>
          {t('auth.register.While your account is waiting for approval')}
        </p>

        <div className="buttons_list">
          <Button appearance="primary" onClick={goToСonnectBuyNow}><span>{t('auth.register.connect_the_payment')}</span></Button>
        </div>
        <div className="buttons_list">
          <Button appearance="link" onClick={goToCatalog}><span>{t('auth.register.View products catalog')}</span></Button>
        </div>
      </div>
    </div>
  );
};

export default CustomerSucceededRegistration;
