import { get } from './';

const getProductsByCategoryId = (categoryId, query) => get(`/product_catalog/${categoryId}`, query);
const getProductsByCategoryName = (query) => get('/product_catalog/byCategoryName/', query);
const getProductById = (productId) => get(`/product/${productId}`);

const productApi = {
  getProductsByCategoryId,
  getProductById,
  getProductsByCategoryName,
};

export default productApi;
