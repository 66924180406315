import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormGroup,
  ControlLabel,
  Form,
  FormControl,
  DatePicker,
} from 'rsuite';
import { setCompanyInfoData, createCompanyInfo } from '../../../redux/actions/auth/companyInfo';
import { getSignedSupplierCandidate } from '../../../redux/actions/auth/registration';
import { locales, localesHeb } from '../../../helpers/localeData';
import { Schema } from 'rsuite';
import { useTranslation } from 'react-i18next';
import CancelRegistrationModalAbortion from '../Registration/CancelRegistrationModalAbortion';
import { SignInContext } from '../../../context/signInContext';

const { StringType } = Schema.Types;
const model = (t) => Schema.Model({
  companyName:
    StringType()
      .isRequired(t('errors.this_field_is_required'))
      .maxLength(100, t('errors.field_size', { minSize: 1, maxSize: 100 })),
  taxId: StringType()
    .minLength(9, t('errors.the_tax_ID_must_contain_a_9_digit_numeric_value'))
    .maxLength(9, t('errors.the_tax_ID_must_contain_a_9_digit_numeric_value'))
    .pattern(/^[0-9]+$/i, t('errors.please_enter_numeric_characters'))
    .isRequired(t('errors.this_field_is_required')),
  activity: StringType()
    .maxLength(100, t('errors.field_size', { minSize: 0, maxSize: 100 })),
  description: StringType()
    .maxLength(500, t('errors.field_size', { minSize: 0, maxSize: 500 })),
});

function SupplierCompanyInfo() {
  const dispatch = useDispatch();
  const history = useHistory();
  const wrapperRef = useRef(null);
  const companyInfo = useSelector((state) => state.auth.companyInfo || {});
  const registerData = useSelector((state) => state.auth.registrationData);
  const user = useSelector((state) => state.auth.user);
  const [registrationData, setRegistrationData] = useState({});
  const [registrationErrors, setRegistrationErrors] = useState({});
  const [showCancelRegistrationModal, setShowCancelRegistrationModal] = useState(false);
  const { abortRegistrationAction } = useContext(SignInContext);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // const checkUserData = Object.keys(user).length;
    // const checkRegisterData = Object.keys(registerData).length;
    // if (!checkUserData && !checkRegisterData || !!checkUserData && !checkRegisterData) {
    //   history.push('/');
    // }
    setRegistrationData(companyInfo);
    user?.status === 'UNFINISHED' && dispatch(getSignedSupplierCandidate((data) => {
      setRegistrationData(data);
    }));
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !document.querySelector('.rs-calendar-table-cell-content>span')) {
        setShowCancelRegistrationModal(true);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const onStepSubmit = () => {
    dispatch(setCompanyInfoData({ ...registrationData, phone: registerData?.phone || user?.phone }));
    dispatch(createCompanyInfo({ ...registrationData, phone: registerData?.phone || user?.phone }));
    history.push('/address_info');
  };

  const isValidToSubmit = () => {
    return Object.keys(registrationErrors).length === 0 &&
      Boolean(registrationData.companyName) &&
      Boolean(registrationData.taxId);
  };

  const onHandleDate = (val) => {
    setRegistrationData({ ...registrationData, foundation: val });
  };

  const changeCalendarUi = () => {
    const getCalendarToolbar = Array.from(
      document.querySelectorAll('div.rs-calendar-header-month-toolbar')
    );
    getCalendarToolbar.forEach((element) => {
      element.classList.toggle('changeUi');
    });
    setShowCancelRegistrationModal(false);
  };


  return (
    <div className="registration">
      <div className="registration_box" ref={wrapperRef}>
        <Form
          model={model(t)}
          onChange={(formValue) => setRegistrationData({ ...formValue })}
          formValue={registrationData}
          onCheck={(formError) => setRegistrationErrors({ ...formError })}
          checkTrigger="blur"
        >
          <p className="steps">{t('auth.step_1_of_3')}</p>
          <div className="registration-title">
            <p>
              <span>{t('auth.to_be_able_to_place_orders_at_konebone')}</span>
              <span>{t('auth.fill_in_your_business_information')}</span>
            </p>
          </div>

          <FormGroup>
            <ControlLabel className="required">{t('auth.company_business_name')}</ControlLabel>
            <FormControl
              name="companyName"
              type="text"
              placeholder={t('auth.company_business_name')}
              value={registrationData?.companyName || ''}
              errorMessage={null}
            />
            {registrationErrors.companyName && <p className="registration_error">{registrationErrors.companyName}</p>}
            <ControlLabel className="required">{t('auth.tax_id')}</ControlLabel>
            <FormControl name="taxId" type="text" placeholder={t('auth.tax_id')} value={registrationData?.taxId || ''} errorMessage={null} />
            {registrationErrors.taxId && <p className="registration_error">{registrationErrors.taxId}</p>}
            <ControlLabel>{t('auth.company_foundation')}</ControlLabel>
            <DatePicker
              className="date"
              block format="DD-MM-YYYY"
              placeholder={t('pick_a_date')}
              ranges={[]}
              cleanable={false}
              locale={i18n.language === 'heb' ? localesHeb : locales}
              value={registrationData?.foundation}
              defaultValue={registrationData?.foundation}
              onChange={(val) => onHandleDate(val)}
              onOpen={() => i18n.language === 'heb' && changeCalendarUi()}
            />
            {registrationErrors.foundation && <p className="registration_error">{registrationErrors.foundation}</p>}
            <ControlLabel>{t('auth.field_of_activity')}</ControlLabel>
            <FormControl type="text" placeholder={t('auth.field_of_activity')} name="activity" value={registrationData?.activity || ''} errorMessage={null} />
            {registrationErrors.activity && <p className="registration_error">{registrationErrors.activity}</p>}
            <ControlLabel>{t('auth.company_description')}</ControlLabel>
            <FormControl rows={3} name="description" placeholder={t('auth.company_description')} componentClass="textarea" value={registrationData?.description || ''} errorMessage={null} />
            {registrationErrors.description && <p className="registration_error">{registrationErrors.description}</p>}
          </FormGroup>
          <div className="buttons_list">
            <FormGroup>
              <Button block appearance="primary" type="submit" onClick={onStepSubmit} disabled={!isValidToSubmit()}><span>{t('auth.continue')} </span></Button>
            </FormGroup>
          </div>
        </Form>
      </div>
      { showCancelRegistrationModal && <CancelRegistrationModalAbortion
        isModalOpen={showCancelRegistrationModal}
        abortAction={abortRegistrationAction}
        cancelAction={setShowCancelRegistrationModal}/>
      }
    </div >
  );
}

export default SupplierCompanyInfo;
