import React, { useState, createRef, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Panel,
  InputNumber,
  Breadcrumb,
  Icon,
  Tooltip,
  Whisper,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Dropdown,
  InputGroup,
  Input,
  Loader,
} from 'rsuite';
import {
  Link,
  useParams,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getOfferDetailsById, putMakeOffersById, resetOffer } from '../../redux/actions/offer';
import { searchAlternative } from '../../redux/actions/alternative';
import {
  getAllMeasurements,
  getAllPackageTypes,
  postAddAlternativeDraftProduct,
  resetDraft,
} from '../../redux/actions/draft';
import { useDebounce } from 'use-debounce';
import { formatPrice, validationErrorsFormat } from '../../utils/index';
import NoImage from '../../assets/img/no_image.png';
import OfferStatusErrorModal from './OfferStatusErrorModal';
import NotFound from '../NotFound';

const MAX_SAFE_NUMBER = 999999.99;

const InfoTooltip = ({ ...rest }) => {
  const { t } = useTranslation();
  return (
    <Tooltip classPrefix="info_tooltip_checkout rs-tooltip" {...rest}>
      <p>{t('page.supplier_orders.make_offer.info')}</p>
    </Tooltip>
  );
};

const InfoButton = ({
  // isAlternative,
  isChecked,
}) => {
  const { t } = useTranslation();
  const triggerRef = createRef();
  const [isOpen, setIsOpen] = useState(false);

  function handleCloseTooltip() {
    triggerRef.current.close();
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('resize', handleCloseTooltip);
    } else {
      window.removeEventListener('resize', handleCloseTooltip);
    }

    return () => {
      window.removeEventListener('resize', handleCloseTooltip);
    };
  });

  return (
    <Whisper
      placement="autoVerticalEnd"
      trigger="hover"
      onOpen={() => setIsOpen(true)}
      speaker={<InfoTooltip />}
      triggerRef={triggerRef}
    >
      <Button appearance="primary" className={`not_ready_for_alternative${(!isChecked) ? ' opacity' : ''}`}>
        <span>{t('page.supplier_orders.make_offer.alternative')}</span>
      </Button>
    </Whisper>
  );
};

InfoButton.propTypes = {
  // isAlternative: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

function AddAlternativeModal({ isModalOpen, addAlternative, addDraft, onHide, product }) {
  const { t, i18n } = useTranslation();
  const [isNotInCatalog, setIsNotInCatalog] = useState(false);
  const [autoComplete, setAutoComplete] = useState('');
  const [autoCompleteDebounce] = useDebounce(autoComplete, 500);
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.alternative.isFetching);
  const alternativeProduct = useSelector((state) => state.alternative.items);

  // const { StringType, NumberType } = Schema.Types;
  // const model = Schema.Model({
  //   catalogNumber: StringType()
  //     .isRequired(t('errors.this_field_is_required'))
  //     .maxLength(10, t('errors.field_size', { minSize: 1, maxSize: 10 })),
  //   name: StringType()
  //     .isRequired(t('errors.this_field_is_required'))
  //     .maxLength(500, t('errors.field_size', { minSize: 1, maxSize: 500 })),
  //   manufacturerName: StringType()
  //     .maxLength(500, t('errors.field_size', { minSize: 0, maxSize: 500 })),
  //   packageTypeTitle: StringType()
  //     .isRequired(t('errors.this_field_is_required'))
  //     .maxLength(100, t('errors.field_size', { minSize: 1, maxSize: 100 })),
  //   measurementTitle: StringType()
  //     .maxLength(100, t('errors.field_size', { minSize: 0, maxSize: 100 })),
  //   quantity: NumberType()
  //     .isRequired(t('errors.this_field_is_required'))
  //     .range(0.01, 9999999999.99, t('errors.field_size', { minSize: 0.01, maxSize: 9999999999.99 })),
  //   pricePerUnit: NumberType()
  //     .isRequired(t('errors.this_field_is_required'))
  //     .range(0.01, 9999999999.99, t('errors.field_size', { minSize: 0.01, maxSize: 9999999999.99 })),
  // });

  const measurements = useSelector((state) => state.measurements.measurements);
  const isFetchingMeasurements = useSelector((state) => state.measurements.isFetching);
  const packages = useSelector((state) => state.packages.packages);
  const isFetchingPackages = useSelector((state) => state.packages.isFetching);

  const [draftData, setDraftData] = useState({});
  const [draftErrors, setDraftErrors] = useState({});

  const isFetchingDraft = useSelector((state) => state.draft.isFetching);
  const draftProduct = useSelector((state) => state.draft.item);
  const error = useSelector((state) => state.draft.error);
  const validationErrors = useSelector((state) => state.draft.validationErrors || {});
  const result = useSelector((state) => state.draft.result);

  const handleChangeAutoComplete = (value) => {
    setAutoComplete(value);
  };

  useEffect(() => {
    if (isNotInCatalog) {
      dispatch(getAllMeasurements());
      dispatch(getAllPackageTypes());
    }
  }, [isNotInCatalog]);


  useEffect(() => {
    setDraftData({});
    setDraftErrors({});
    setAutoComplete('');
    setIsNotInCatalog(false);
    dispatch(resetDraft());
  }, [isModalOpen]);

  useEffect(() => {
    if (autoCompleteDebounce.trim()) {
      dispatch(searchAlternative(autoCompleteDebounce));
    }
  }, [autoCompleteDebounce]);

  useEffect(() => {
    if (result === 'Success') {
      addDraft(product.productId, draftProduct);
    }
  }, [result]);

  return (
    <Modal
      className="canceling_registration add_alternative"
      overflow={false}
      show={isModalOpen}
      keyboard={true}
      autoFocus={true}
      onHide={() => {
        // setAutoComplete('');
        onHide(product?.productId);
      }}
    >
      <Modal.Header>
        {isNotInCatalog ? <p>{t('page.add_alternative_modal.product_not_catalog')}</p> : <p>{t('page.add_alternative_modal.add_alternative_product')}</p>}
      </Modal.Header>
      <Modal.Body>

        {isNotInCatalog ? (
          <div>

            {error && <div className="warning_box make_offer">
              <p>
                {t(`errors.${error}`)}
              </p>
            </div>}

            {isFetchingMeasurements || isFetchingPackages ? (
              <Loader size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
            ) : (
              <>
                <p>{t('page.add_alternative_modal.add_description')}</p>
                <div className="registration">

                  <div className="registration_box">
                    <Form fluid>
                      <FormGroup>
                        <ControlLabel className="required">{t('page.add_alternative_modal.catalog_number_label')}</ControlLabel>
                        <FormControl
                          className={validationErrors?.catalogNumber ? 'has-error' : ''}
                          disabled={isFetchingDraft}
                          name="catalogNumber"
                          type="number"
                          placeholder={t('page.add_alternative_modal.catalog_number_placeholder')}
                          value={draftData.catalogNumber || ''}
                          errorMessage={null}
                          onChange={(val) => {
                            setDraftData({ ...draftData, catalogNumber: val });
                          }}
                          // onBlur={() => {
                          //   setDraftErrors({
                          //     ...draftErrors,
                          //     catalogNumber: model.check(draftData).catalogNumber,
                          //   });
                          // }}
                        />
                        <p className="error">{draftErrors?.catalogNumber?.errorMessage}</p>
                        {/* error && error === 'error.draft_product.supplier_catalog_number_already_exists' && <p className="error">{t('page.add_alternative_modal.errors.draft_product_exist')}</p> */}
                        {/* error && error === 'error.draft_product.exist_by_supplier_and_catalog_number' && <p className="error">{t('page.add_alternative_modal.errors.draft_product_exist')}</p> */}
                        <p className="error">{draftErrors?.catalogNumber?.errorMessage}</p>
                        <p className="error">{validationErrorsFormat(validationErrors?.catalogNumber, t)}</p>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel className="required">{t('page.add_alternative_modal.product_name')}</ControlLabel>
                        <FormControl
                          className={validationErrors?.name ? 'has-error' : ''}
                          disabled={isFetchingDraft}
                          name="name"
                          type="text"
                          placeholder={t('page.add_alternative_modal.product_name')}
                          value={draftData.name || ''}
                          errorMessage={null}
                          onChange={(val) => {
                            setDraftData({ ...draftData, name: val });
                          }}
                          // onBlur={() => {
                          //   setDraftErrors({
                          //     ...draftErrors,
                          //     name: model.check(draftData).name,
                          //   });
                          // }}
                        />
                        <p className="error">{draftErrors?.name?.errorMessage}</p>
                        <p className="error">{validationErrorsFormat(validationErrors?.name, t)}</p>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>{t('page.add_alternative_modal.manufacturer_name')}</ControlLabel>
                        <FormControl
                          className={validationErrors?.manufacturerName ? 'has-error' : ''}
                          disabled={isFetchingDraft}
                          name="manufacturerName"
                          type="text"
                          placeholder={t('page.add_alternative_modal.manufacturer_name')}
                          value={draftData.manufacturerName || ''}
                          errorMessage={null}
                          onChange={(val) => {
                            setDraftData({ ...draftData, manufacturerName: val });
                          }}
                          // onBlur={() => {
                          //   setDraftErrors({
                          //     ...draftErrors,
                          //     manufacturerName: model.check(draftData).manufacturerName,
                          //   });
                          // }}
                        />
                        <p className="error">{draftErrors?.manufacturerName?.errorMessage}</p>
                        <p className="error">{validationErrorsFormat(validationErrors?.manufacturerName, t)}</p>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel className="required">{t('page.add_alternative_modal.package_type')}</ControlLabel>
                        <div className="wrapper_dropdown">
                          <Dropdown
                            className={validationErrors?.packageTypeTitle ? 'has-error' : ''}
                            name="packageTypeTitle"
                            disabled={isFetchingDraft}
                            title={draftData.packageTypeTitle || t('page.add_alternative_modal.package_type')}
                            onSelect={(val) => {
                              setDraftData({ ...draftData, packageTypeTitle: val });
                            }}
                            activeKey={draftData.packageTypeTitle}
                            // onBlur={() => {
                            //   setDraftErrors({
                            //     ...draftErrors,
                            //     packageTypeTitle: model.check(draftData).packageTypeTitle,
                            //   });
                            // }}
                          >
                            {packages.map((i) => (
                              <Dropdown.Item key={i.packageTypeId} eventKey={i.title}>
                                <span>{i.title}</span>
                              </Dropdown.Item>
                            ))}
                          </Dropdown>
                        </div>
                        <p className="error">{draftErrors?.packageTypeTitle?.errorMessage}</p>
                        <p className="error">{validationErrorsFormat(validationErrors?.packageTypeTitle, t)}</p>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>{t('page.add_alternative_modal.price_for')}</ControlLabel>
                        <div className="wrapper_dropdown">
                          <Dropdown
                            className={validationErrors?.measurementTitle ? 'has-error' : ''}
                            name="measurementTitle"
                            disabled={isFetchingDraft}
                            title={draftData.measurementTitle || t('page.add_alternative_modal.price_for')}
                            onSelect={(val) => {
                              setDraftData({ ...draftData, measurementTitle: val });
                            }}
                            activeKey={draftData.measurementTitle}
                            // onBlur={() => {
                            //   setDraftErrors({
                            //     ...draftErrors,
                            //     measurementTitle: model.check(draftData).measurementTitle,
                            //   });
                            // }}
                          >
                            {measurements.map((i) => (
                              <Dropdown.Item key={i.measurementId} eventKey={i.title}>
                                <span>{i.title}</span>
                              </Dropdown.Item>
                            ))}
                          </Dropdown>
                        </div>
                        <p className="error">{draftErrors?.measurementTitle?.errorMessage}</p>
                        <p className="error">{validationErrorsFormat(validationErrors?.measurementTitle, t)}</p>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel className="required">{t('page.add_alternative_modal.quantity')}</ControlLabel>
                        <div className={`wrapper_number_input${validationErrors?.quantity ? ' has-error' : ''}`}>
                          <InputNumber
                            name="quantity"
                            disabled={isFetchingDraft}
                            scrollable={false}
                            type="text"
                            value={draftData.quantity}
                            onChange={(value, event) => {
                              if (!(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                                setDraftData({ ...draftData, quantity: value });
                              }
                            }}
                            // onBlur={() => {
                            //   setDraftErrors({
                            //     ...draftErrors,
                            //     quantity: model.check(draftData).quantity,
                            //   });
                            // }}
                          />
                        </div>
                        <p className="error">{draftErrors?.quantity?.errorMessage}</p>
                        <p className="error">{validationErrorsFormat(validationErrors?.quantity, t)}</p>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel className="required">{t('page.add_alternative_modal.price_per_item')}</ControlLabel>
                        <div className={`wrapper_number_input${validationErrors?.pricePerUnit ? ' has-error' : ''}`}>
                          <InputNumber
                            name="pricePerUnit"
                            disabled={isFetchingDraft}
                            scrollable={false}
                            type="text"
                            value={draftData.pricePerUnit}
                            onChange={(value, event) => {
                              if (!(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                                setDraftData({ ...draftData, pricePerUnit: value });
                              }
                            }}
                            // onBlur={() => {
                            //   setDraftErrors({
                            //     ...draftErrors,
                            //     pricePerUnit: model.check(draftData).pricePerUnit,
                            //   });
                            // }}
                            postfix={<span className="postfix"><span className="ltr">₪/{t('page.supplier_orders.make_offer.item')}</span></span>}
                          />
                        </div>
                        <p className="error">{draftErrors?.pricePerUnit?.errorMessage}</p>
                        <p className="error">{validationErrorsFormat(validationErrors?.pricePerUnit, t)}</p>
                      </FormGroup>
                    </Form>
                  </div>

                </div>

                <div className="flex-box-button">
                  <div>
                    <Button disabled={isFetchingDraft} appearance="primary" onClick={() => {
                      // setDraftErrors(model.check(draftData));
                      // const {
                      //   catalogNumber,
                      //   name,
                      //   manufacturerName,
                      //   packageTypeTitle,
                      //   measurementTitle,
                      //   quantity,
                      //   pricePerUnit,
                      // } = model.check(draftData);
                      // const hasError = catalogNumber.hasError ||
                      //   name.hasError ||
                      //   manufacturerName.hasError ||
                      //   packageTypeTitle.hasError ||
                      //   measurementTitle.hasError ||
                      //   quantity.hasError ||
                      //   pricePerUnit.hasError;
                      // if (!hasError) {
                      dispatch(postAddAlternativeDraftProduct(draftData));
                      // }
                      // addDraft(product?.productId, {});
                    }}>
                      {isFetchingDraft && <Loader size="sm" />}
                      <span>{t('page.add_alternative_modal.add_alternative')}</span>
                    </Button>
                  </div>
                  <div>
                    <p><Button appearance="link" className="dark" onClick={() => setIsNotInCatalog(!isNotInCatalog)}><span>{t('page.add_alternative_modal.add_from_my_materials')}</span></Button></p>
                  </div>
                </div>
              </>
            )}

          </div>
        ) : (
          <div>
            <p>{t('page.add_alternative_modal.use_search')}</p>

            {Object.keys(product) !== 0 && (
              <div className="products-list">
                <div className="products-list-flex">
                  <div>
                    <div className="products-img">
                      <img src={product.picture ? product.picture : NoImage} alt={product.productName} />
                    </div>
                  </div>
                  <div>
                    <div className="flex_box_p">
                      <p><span className="product_number">{product?.catalogNumber}</span></p>
                      {product?.supplierNumber && <p><span className="ltr">{product?.supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
                    </div>
                    <p className="description">{product?.productName}</p>
                  </div>
                </div>
              </div>
            )}

            <div className="auto_complete">
              <InputGroup inside>
                <InputGroup.Addon>
                  <Icon icon="search" />
                </InputGroup.Addon>
                <Input
                  type="text"
                  placeholder={t('page.add_alternative_modal.search_by_your_material')}
                  value={autoComplete}
                  onChange={(value, event) => handleChangeAutoComplete(value)}
                />
                {autoComplete && (
                  <InputGroup.Button>
                    <Icon icon="close" onClick={() => setAutoComplete('')} />
                  </InputGroup.Button>
                )}
              </InputGroup>
              <div className="products-list">

                {isFetching ? (
                  <Loader size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
                ) : (
                  <>
                    {autoComplete && alternativeProduct.length === 0 && (
                      <div className="product_not_found">
                        <div className="container">
                          <div className="product_not_found_box">
                            <div className="banner">
                              <span />
                            </div>

                            {autoComplete ? (
                              <p className="header">
                                {t('page.search.no_search_results_for')} &quot;<span className="red">{autoComplete}</span>&quot;
                              </p>
                            ) : (
                              <p className="header">
                                {t('page.search.no_search_results')}
                              </p>
                            )}

                            <p>{t('page.search.no_search_text_alternative')}</p>

                            <p><Button appearance="link" className="red" onClick={() => setIsNotInCatalog(!isNotInCatalog)}><span>{t('page.add_alternative_modal.product_not_catalog')}</span></Button></p>

                          </div>
                        </div>
                      </div>
                    )}

                    {autoComplete && alternativeProduct.length !== 0 && (
                      <ul>
                        {alternativeProduct.map((p, index) => (
                          <li key={p.productId}>
                            <div className="products-list-flex">
                              <div>
                                <div className="products-img">
                                  <img src={p?.picture ? p.picture : NoImage} alt={p.productName} />
                                </div>
                                <Button
                                  disabled={product.productId === p.productId}
                                  appearance="primary"
                                  onClick={() => {
                                    // setAutoComplete('');
                                    addAlternative(product.productId, alternativeProduct[index]);
                                  }}
                                ><span>Add</span></Button>
                              </div>
                              <div>
                                <div className="flex_box_p">
                                  <p><span className="product_number">{p.catalogNumber}</span></p>
                                  {p.supplierNumber && <p><span className="ltr">{p.supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
                                </div>
                                <p className="description">{p.productName}</p>

                                {product.productId === p.productId && (
                                  <div className="warning_box">
                                    <p>
                                      {t('page.add_alternative_modal.same_product')}
                                    </p>
                                  </div>
                                )}

                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}

              </div>
            </div>

            <p><Button appearance="link" className="red" onClick={() => setIsNotInCatalog(!isNotInCatalog)}><span>{t('page.add_alternative_modal.product_not_catalog')}</span></Button></p>
          </div>
        )}

      </Modal.Body>
    </Modal>
  );
};

AddAlternativeModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  addAlternative: PropTypes.func.isRequired,
  addDraft: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};
const AlternativeInputNumberQuantity = ({
  products,
  setProduct,
  indexOfElement,
  p,
  errorAlternativeProducts,
  setErrorAlternativeProducts,
  validationErrors,
}) => {
  const { t } = useTranslation();

  const checkErrorStatus = validationErrors[`products[${indexOfElement}].quantity`] || (!!errorAlternativeProducts[indexOfElement] && errorAlternativeProducts[indexOfElement].quantity) ? ' has-error' : '';

  return (
    <>
      <div className={`wrapper_number_input${checkErrorStatus}`}>
        <InputNumber
          scrollable={false}
          type="text"
          min={0}
          max={MAX_SAFE_NUMBER}
          value={p.alternativeProduct.quantity ? (
            p.alternativeProduct.quantity
          ) : ''}
          onChange={
            (value, event) => setProduct(products.map((i) => {
              if (i.productId === p.productId && !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                if (value < 0 || isNaN(value)) i.alternativeProduct.quantity = 0;
                else if (value > MAX_SAFE_NUMBER) {
                  i.alternativeProduct.quantity = MAX_SAFE_NUMBER;
                } else {
                  i.alternativeProduct.quantity = value;

                  if (errorAlternativeProducts[indexOfElement]?.quantity) {
                    const newArr = [...errorAlternativeProducts];
                    const check = errorAlternativeProducts[indexOfElement]?.price;
                    const statusError = {
                      quantity: false,
                      price: check ? check : false};
                    newArr[indexOfElement] = check ? statusError : undefined;
                    setErrorAlternativeProducts(newArr);
                  }
                };
                if (value === '' || value === '0') {
                  const newArr = [...errorAlternativeProducts];
                  const check = errorAlternativeProducts[indexOfElement]?.price;
                  const statusError = {
                    quantity: true, price: check ? check : false};
                  newArr[indexOfElement] = statusError;
                  setErrorAlternativeProducts(newArr);
                }
              }
              return i;
            }))
          }
          postfix={<span className="postfix"><span className="ltr">{t('page.supplier_orders.make_offer.of_items', { amount: p.amount })}</span></span>}
        />
        <p className="error">
          {validationErrorsFormat(
            validationErrors[`products[${indexOfElement}].quantity`],
            t) || (!!errorAlternativeProducts[indexOfElement] && errorAlternativeProducts[indexOfElement].quantity ? t('validationErrors.field.validation.positive') : '') }
        </p>
      </div>
    </>
  );
};
AlternativeInputNumberQuantity.propTypes = {
  setProduct: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  indexOfElement: PropTypes.number.isRequired,
  p: PropTypes.object.isRequired,
  errorAlternativeProducts: PropTypes.array.isRequired,
  setErrorAlternativeProducts: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

const AlternativeInputNumberPrice = ({
  products,
  setProduct,
  indexOfElement,
  p,
  errorAlternativeProducts,
  setErrorAlternativeProducts,
  validationErrors,
}) => {
  const { t } = useTranslation();

  const checkErrorStatus = validationErrors[`products[${indexOfElement}].quantity`] || (!!errorAlternativeProducts[indexOfElement] && errorAlternativeProducts[indexOfElement].price) ? ' has-error' : '';
  return (
    <>
      <div className={`wrapper_number_input${checkErrorStatus}`}>
        <InputNumber
          scrollable={false}
          min={0}
          max={MAX_SAFE_NUMBER}
          value={
            p.alternativeProduct.supplierPrice ? (
              p.alternativeProduct.supplierPrice
            ) : ''
          }
          onChange={
            (value, event) => setProduct(products.map((i) => {
              if (i.productId === p.productId && !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                if (value < 0 || isNaN(value)) {
                  i.alternativeProduct.supplierPrice = 0;
                } else if (value > MAX_SAFE_NUMBER) {
                  i.alternativeProduct.supplierPrice = MAX_SAFE_NUMBER;
                } else {
                  i.alternativeProduct.supplierPrice = value;
                  if (errorAlternativeProducts[indexOfElement]?.price) {
                    const newArr = [...errorAlternativeProducts];
                    const check = errorAlternativeProducts[indexOfElement]?.quantity;
                    const statusError = {
                      quantity: check ? check : false,
                      price: false};
                    newArr[indexOfElement] = check ? statusError : undefined;
                    setErrorAlternativeProducts(newArr);
                  }
                };
                if (value === '' || value === '0') {
                  const newArr = [...errorAlternativeProducts];
                  const check = errorAlternativeProducts[indexOfElement]?.quantity;
                  const statusError = {
                    quantity: check ? check : false,
                    price: true};
                  newArr[indexOfElement] = statusError;
                  setErrorAlternativeProducts(newArr);
                }
              }
              return i;
            }))
          }
          postfix={<span className="postfix"><span className="ltr">₪/{t('page.supplier_orders.make_offer.item')}</span></span>}
        />
        <p className="error">
          {validationErrorsFormat(
            validationErrors[`products[${indexOfElement}].supplierPrice`],
            t) || (!!errorAlternativeProducts[indexOfElement] && errorAlternativeProducts[indexOfElement].price ? t('validationErrors.field.validation.positive') : '') }
        </p>
      </div>
    </>
  );
};
AlternativeInputNumberPrice.propTypes = {
  setProduct: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  indexOfElement: PropTypes.number.isRequired,
  p: PropTypes.object.isRequired,
  errorAlternativeProducts: PropTypes.array.isRequired,
  setErrorAlternativeProducts: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

function MakeOffer() {
  const { t, i18n } = useTranslation();
  const isMobileView = useMediaQuery({ query: '(max-width: 767px)' });
  const [isAddAlternativeModalOpen, setIsAddAlternativeModalOpen] = useState({
    isModalOpen: false,
    product: {},
  });
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.offer.error);
  const validationErrors = useSelector((state) => state.offer.validationErrors || {});
  const { item, isFetching } = useSelector((state) => state.offer);
  const nextStep = useSelector((state) => state.offer.nextStep);
  const numberOfCompetitors = item?.numberOfCompetitors;
  const history = useHistory();
  const [products, setProduct] = useState([]);
  const [percentDiscount, setPercentDiscount] = useState(0);
  const [resultArrayAfterContinue, setResultArrayAfterContinue] = useState([]);
  const [errorAlternativeProducts, setErrorAlternativeProducts] = useState([]);

  useEffect(() => {
    dispatch(getOfferDetailsById(orderId));
    return () => dispatch(resetOffer());
  }, []);

  useEffect(() => {
    if (nextStep) {
      dispatch(resetOffer());
      history.push(`/supplier/delivery/expected/${item?.offerId}`);
    }
  }, [nextStep]);

  useEffect(() => {
    setProduct(item?.products?.length > 0 ? item.products.map((i) => {
      return { ...i,
        isAlternative: i.alternativeProduct || i.draftProduct ? true : false,
        amount: i.orderQuantity,
        inStock: i.alternativeProduct || i.draftProduct || !i.outOfStock ? true : false,
        validationErrors: null,
      };
    }) : []);
    setPercentDiscount(item?.discount ? item.discount : 0);
  }, [item]);

  const subtotal = products.filter((p) => p.inStock).length > 0 ? products.filter((p) => p.inStock).map((p) => {
    if (p.alternativeProduct) {
      return (
        p.alternativeProduct.quantity * p.alternativeProduct.supplierPrice ?
          p.alternativeProduct.quantity * p.alternativeProduct.supplierPrice : 0
      );
    }
    if (p.draftProduct) {
      return (
        p.draftProduct.quantity * p.draftProduct.pricePerUnit ?
          p.draftProduct.quantity * p.draftProduct.pricePerUnit : 0
      );
    }
    return (p.quantity * p.supplierPrice);
  }).reduce(
    (accumulator, currentValue) => accumulator + currentValue
  ) : 0;
  const discount = subtotal * percentDiscount / 100;
  const total = subtotal - discount;

  const onClickContinue = () => {
    dispatch(putMakeOffersById({
      id: orderId,
      query: {
        discount: Math.trunc(percentDiscount * 10)/10,
        products: products.filter((p) => p.inStock).map((i, index, array) => {
          setResultArrayAfterContinue(array);

          return {
            alternativeProductId: i.alternativeProduct ? i.alternativeProduct.productId : null,
            draftProductId: i.draftProduct ? i.draftProduct.draftProductId : null,
            productId: i.productId,
            quantity: Math.trunc((
              i.alternativeProduct && i.alternativeProduct.quantity ||
              i.draftProduct && i.draftProduct.quantity ||
              i.quantity) * 1000)/1000,
            supplierPrice: Math.trunc((
              i.alternativeProduct && i.alternativeProduct.supplierPrice ||
              i.draftProduct && i.draftProduct.pricePerUnit ||
              i.supplierPrice) * 100)/100,
          };
        }),
      },
    }));
  };

  // custom validation for Alternative Products
  const checkErrorAlternativeProducts = errorAlternativeProducts.filter((p) => !!p);
  const checkIsArrayEmpty = Object.keys(checkErrorAlternativeProducts).length === 0;

  if (!isFetching && Object.keys(item).length === 0 && !products.length) {
    return <NotFound/>;
  }

  return (
    <>

      {isFetching && <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />}

      <div className="checkout order_list_item supplier_orders make_offer">
        <div className="container">

          {products.length > 0 && (
            <>

              <div className="breadcrumb-list">
                <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                  <Breadcrumb.Item componentClass={Link} to="/supplier/orders">
                    <span>{t('page.order.orders')}</span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item componentClass={Link} to={`/supplier/order/${orderId}`}>
                    <span>{t('page.order.order')} #{item?.orderId}</span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    <span>{t('page.supplier_orders.confirm_offer')}</span>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>

              <Panel bordered className="panel_edit">

                {error && error !== 'error.offer.status_incorrect' && <div className="warning_box make_offer">
                  <p>
                    {t(`errors.${error}`)}
                  </p>
                </div>}

                <div className="make_offer_timer_flex">
                  <div>

                    <h1>{t('page.supplier_orders.make_offer.confirm_offer_for_order')} #{item?.orderId}</h1>
                    <p>{t('page.supplier_orders.make_offer.text_subtitle')}</p>

                  </div>
                </div>

                <div className="in_stock">
                  <Checkbox
                    indeterminate={[...new Set(products.map((p) => p.inStock))].length !== 1}
                    checked={
                      products.map((p) => p.inStock).reduce(
                        (accumulator, currentValue) => accumulator && currentValue
                      )
                    }
                    onChange={
                      (value, checked, event) => setProduct(products.map((i) => {
                        i.inStock = checked;
                        if (!checked) {
                          i.isAlternative = false;
                          i.inStock = false;
                          i.alternativeProduct = null;
                          i.draftProduct = null;
                        }
                        return i;
                      }))
                    }
                  >
                    <span className="text">
                      <span dir='auto'>
                        <span>{t('page.supplier_orders.make_offer.in_stock')}</span>
                        <span className="light">({products.filter((p) => p.inStock).length} {t('page.supplier_orders.make_offer.of')} {products.length})</span>
                      </span>
                    </span>
                  </Checkbox>
                </div>

                <div className="offers_list">
                  <div className="offer-box">
                    <div className="products-list supplier_orders_list">
                      <ul>
                        {products.map((p, index) => {
                          return (
                            <li key={p.productId}>
                              <div className="flex-checkbox">
                                <div>
                                  <Checkbox
                                    name={p.productId}
                                    id={p.productId}
                                    checked={p.inStock}
                                    onChange={
                                      (value, checked, event) => setProduct(products.map((i) => {
                                        if (i.productId === p.productId) {
                                          i.inStock = checked;
                                          if (!checked) {
                                            i.isAlternative = false;
                                            i.alternativeProduct = null;
                                            i.draftProduct = null;
                                          }
                                        }
                                        return i;
                                      }))
                                    }
                                  />
                                </div>
                                <div>
                                  <div className={`products-list-flex${(p.isAlternative || !p.inStock) ? ' opacity' : ''}`}>
                                    <div>
                                      <div className="products-img">
                                        <img src={p.picture ? p.picture : NoImage} />
                                        {(p.isAlternative || !p.inStock) && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="flex_box_p">
                                        <p><span className="product_number">{p.catalogNumber}</span></p>
                                        {p.supplierNumber && <p><span className="ltr">{p.supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
                                      </div>
                                      <p className="description">
                                        {p.productName}
                                      </p>
                                    </div>
                                    {!p.isAlternative && <>
                                      <div>

                                        <div className="input_wrapper">
                                          <div>
                                            <p>{t('page.supplier_orders.make_offer.quantity')}</p>
                                          </div>
                                          <div>
                                            <div className={`wrapper_number_input${resultArrayAfterContinue.map((e, index) => (e.productId === p.productId) && validationErrors[`products[${index}].quantity`] ? ' has-error' : '').join('')}`}>
                                              <InputNumber
                                                disabled={!p.inStock}
                                                scrollable={false}
                                                type="text"
                                                min={0}
                                                max={p.amount}
                                                value={p.quantity ? p.quantity : ''}
                                                onChange={
                                                  (value, event) => setProduct(products.map((i) => {
                                                    if (i.productId === p.productId && !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                                                      if (value < 0 || isNaN(value)) i.quantity = 0;
                                                      else if (value > p.amount) i.quantity = p.amount;
                                                      else i.quantity = value;
                                                    }
                                                    return i;
                                                  }))
                                                }
                                                postfix={<span className="postfix"><span className="ltr">{t('page.supplier_orders.make_offer.of_items', { amount: p.amount })}</span></span>}
                                              />
                                              <p className="error">
                                                {resultArrayAfterContinue.map(
                                                  // eslint-disable-next-line max-len
                                                  (e, index) => e.productId === p.productId ? validationErrorsFormat(validationErrors[`products[${index}].quantity`], t): null)
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                      <div>

                                        <div className="input_wrapper">
                                          <div>
                                            <p>{t('page.supplier_orders.make_offer.price')}</p>
                                          </div>
                                          <div>
                                            <div className={`wrapper_number_input${resultArrayAfterContinue.map((e, index) => (e.productId === p.productId) && validationErrors[`products[${index}].supplierPrice`] ? ' has-error' : '').join('')}`}>
                                              <InputNumber
                                                disabled={!p.inStock}
                                                scrollable={false}
                                                min={0}
                                                max={MAX_SAFE_NUMBER}
                                                value={p.supplierPrice ? p.supplierPrice : ''}
                                                onChange={
                                                  (value, event) => setProduct(products.map((i) => {
                                                    if (i.productId === p.productId && !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                                                      if (value < 0 || isNaN(value)) i.supplierPrice = 0;
                                                      else if (value > MAX_SAFE_NUMBER) {
                                                        i.supplierPrice = MAX_SAFE_NUMBER;
                                                      } else i.supplierPrice = value;
                                                    }
                                                    return i;
                                                  }))
                                                }
                                                postfix={<span className="postfix"><span className="ltr">₪/{t('page.supplier_orders.make_offer.item')}</span></span>}
                                              />
                                              <p className="error">
                                                {resultArrayAfterContinue.map(
                                                  // eslint-disable-next-line max-len
                                                  (e, index) => e.productId === p.productId ? validationErrorsFormat(validationErrors[`products[${index}].supplierPrice`], t): null)
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                      <div>

                                        <div className="input_wrapper">
                                          <div>
                                            <p>{t('page.supplier_orders.make_offer.sum')}</p>
                                          </div>
                                          <div>
                                            <p className="summa"><span className="ltr">₪ {p.supplierPrice ? formatPrice(p.supplierPrice * p.quantity) : 0.00}</span></p>
                                          </div>
                                        </div>

                                      </div>
                                    </>}
                                  </div>

                                  {(p.isAlternative && !isMobileView) && <>
                                    <hr className="hr" />

                                    {p.alternativeProduct && (
                                      <div className="products-list-flex">
                                        <div>
                                          <div className="products-img">
                                            <img src={
                                              p.alternativeProduct.picture ? p.alternativeProduct.picture : NoImage
                                            } />
                                          </div>
                                        </div>
                                        <div>
                                          <div className="flex_box_p">
                                            <p><span className="product_number">{p.alternativeProduct.catalogNumber}</span></p>
                                            {p.alternativeProduct.supplierNumber && <p><span className="ltr">{p.alternativeProduct.supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
                                          </div>
                                          <p className="description">
                                            {p.alternativeProduct.productName}
                                          </p>
                                        </div>
                                        <div>

                                          <div className="input_wrapper">
                                            <div>
                                              <p>{t('page.supplier_orders.make_offer.quantity')}</p>
                                            </div>
                                            <div>
                                              <AlternativeInputNumberQuantity
                                                products={products}
                                                setProduct={setProduct}
                                                indexOfElement={index} p={p}
                                                errorAlternativeProducts={errorAlternativeProducts}
                                                setErrorAlternativeProducts={setErrorAlternativeProducts}
                                                validationErrors={validationErrors}
                                              />
                                            </div>
                                          </div>

                                        </div>
                                        <div>

                                          <div className="input_wrapper">
                                            <div>
                                              <p>{t('page.supplier_orders.make_offer.price')}</p>
                                            </div>
                                            <div>
                                              <AlternativeInputNumberPrice
                                                products={products}
                                                setProduct={setProduct}
                                                indexOfElement={index} p={p}
                                                errorAlternativeProducts={errorAlternativeProducts}
                                                setErrorAlternativeProducts={setErrorAlternativeProducts}
                                                validationErrors={validationErrors}
                                              />
                                            </div>
                                          </div>

                                        </div>
                                        <div>


                                          <div className="input_wrapper">
                                            <div>
                                              <p>{t('page.supplier_orders.make_offer.sum')}</p>
                                            </div>
                                            <div>
                                              <p className="summa"><span className="ltr">₪ {p.alternativeProduct.supplierPrice ? formatPrice(p.alternativeProduct.supplierPrice * p.alternativeProduct.quantity) : 0.00}</span></p>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    )}

                                    {p.draftProduct && (
                                      <div className="products-list-flex">
                                        <div>
                                          <div className="products-img">
                                            <img src={
                                              p.draftProduct.fileName ? p.draftProduct.fileName : NoImage
                                            } />
                                          </div>
                                        </div>
                                        <div>
                                          <div className="flex_box_p">
                                            <p><span className="ltr">{p.draftProduct.catalogNumber} {t('page.supplier_orders.catalog_number')}</span></p>
                                          </div>
                                          <p className="description">
                                            {p.draftProduct.name}
                                          </p>
                                        </div>
                                        <div>

                                          <div className="input_wrapper">
                                            <div>
                                              <p>{t('page.supplier_orders.make_offer.quantity')}</p>
                                            </div>
                                            <div>
                                              <div className={`wrapper_number_input${validationErrors[
                                                `products[${index}].quantity`
                                              ] ? ' has-error' : ''}`}>
                                                <InputNumber
                                                  scrollable={false}
                                                  type="text"
                                                  min={0}
                                                  max={MAX_SAFE_NUMBER}
                                                  value={p.draftProduct.quantity ? (
                                                    p.draftProduct.quantity
                                                  ) : ''}
                                                  onChange={
                                                    (value, event) => setProduct(products.map((i) => {
                                                      if (i.productId === p.productId && !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                                                        if (value < 0 || isNaN(value)) i.draftProduct.quantity = 0;
                                                        else if (value > MAX_SAFE_NUMBER) {
                                                          i.draftProduct.quantity = MAX_SAFE_NUMBER;
                                                        } else i.draftProduct.quantity = value;
                                                        // i.quantity = value;
                                                      }
                                                      return i;
                                                    }))
                                                  }
                                                  postfix={<span className="postfix"><span className="ltr">{t('page.supplier_orders.make_offer.of_items', { amount: p.amount })}</span></span>}
                                                />
                                                <p className="error">
                                                  {validationErrorsFormat(
                                                    validationErrors[`products[${index}].quantity`],
                                                    t)}
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                        <div>

                                          <div className="input_wrapper">
                                            <div>
                                              <p>{t('page.supplier_orders.make_offer.price')}</p>
                                            </div>
                                            <div>
                                              <div className={`wrapper_number_input${validationErrors[
                                                `products[${index}].supplierPrice`
                                              ] ? ' has-error' : ''}`}>
                                                <InputNumber
                                                  scrollable={false}
                                                  min={0}
                                                  max={MAX_SAFE_NUMBER}
                                                  value={
                                                    p.draftProduct.pricePerUnit ? (
                                                      p.draftProduct.pricePerUnit
                                                    ) : ''
                                                  }
                                                  onChange={
                                                    (value, event) => setProduct(products.map((i) => {
                                                      if (i.productId === p.productId && !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                                                        if (value < 0 || isNaN(value)) i.draftProduct.pricePerUnit = 0;
                                                        else if (value > MAX_SAFE_NUMBER) {
                                                          i.draftProduct.pricePerUnit = MAX_SAFE_NUMBER;
                                                        } else i.draftProduct.pricePerUnit = value;
                                                        // i.supplierPrice = value;
                                                      }
                                                      return i;
                                                    }))
                                                  }
                                                  postfix={<span className="postfix"><span className="ltr">₪/{t('page.supplier_orders.make_offer.item')}</span></span>}
                                                />
                                                <p className="error">
                                                  {validationErrorsFormat(
                                                    validationErrors[`products[${index}].supplierPrice`],
                                                    t)}
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                        <div>


                                          <div className="input_wrapper">
                                            <div>
                                              <p>{t('page.supplier_orders.make_offer.sum')}</p>
                                            </div>
                                            <div>
                                              <p className="summa"><span className="ltr">₪ {p.draftProduct.pricePerUnit ? formatPrice(p.draftProduct.pricePerUnit * p.draftProduct.quantity) : 0.00}</span></p>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    )}

                                  </>}

                                </div>

                                <div className={p.isAlternative ? 'is_alternative' : ''}>

                                  {!p.readyForAlternatives && (
                                    <InfoButton
                                      // isAlternative={p.isAlternative ? true : false }
                                      isChecked={p.inStock}
                                    />
                                  )}

                                  {p.readyForAlternatives && (
                                    <>
                                      {p.isAlternative ? (
                                        <Button
                                          appearance="primary"
                                          onClick={() => setProduct(products.map((i) => {
                                            if (i.productId === p.productId) {
                                              i.isAlternative = false;
                                              i.alternativeProduct = null;
                                              i.draftProduct = null;
                                              i.quantity = i.orderQuantity;
                                              i.supplierPrice = i.catalogSupplierPrice;
                                            }
                                            return i;
                                          }))}
                                        ><span>{t('page.supplier_orders.make_offer.alternative')}</span></Button>
                                      ) : (
                                        <Button
                                          // disabled={!p.inStock}
                                          appearance="ghost"
                                          onClick={
                                            () => {
                                              setIsAddAlternativeModalOpen({
                                                isModalOpen: true,
                                                product: p,
                                              });
                                              setProduct(products.map((i) => {
                                                if (i.productId === p.productId) {
                                                  i.isAlternative = true;
                                                }
                                                return i;
                                              }));
                                            }
                                          }
                                        ><span>{t('page.supplier_orders.make_offer.alternative')}</span></Button>
                                      )}
                                    </>
                                  )}
                                </div>

                                {(p.isAlternative && isMobileView && p.readyForAlternatives) && <div>

                                  {p.alternativeProduct && (
                                    <div className="products-list-flex">
                                      <div>
                                        <div className="products-img">
                                          <img src={
                                            p.alternativeProduct.picture ? p.alternativeProduct.picture : NoImage
                                          } />
                                        </div>
                                      </div>
                                      <div>
                                        <div className="flex_box_p">
                                          <p><span className="product_number">{p.alternativeProduct.catalogNumber}</span></p>
                                          {p.alternativeProduct.supplierNumber && <p><span className="ltr">{p.alternativeProduct.supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
                                        </div>
                                        <p className="description">
                                          {p.alternativeProduct.productName}
                                        </p>
                                      </div>
                                      <div>

                                        <div className="input_wrapper">
                                          <div>
                                            <p>{t('page.supplier_orders.make_offer.quantity')}</p>
                                          </div>
                                          <div>
                                            <AlternativeInputNumberQuantity
                                              products={products}
                                              setProduct={setProduct}
                                              indexOfElement={index} p={p}
                                              errorAlternativeProducts={errorAlternativeProducts}
                                              setErrorAlternativeProducts={setErrorAlternativeProducts}
                                              validationErrors={validationErrors} />
                                          </div>
                                        </div>

                                      </div>
                                      <div>

                                        <div className="input_wrapper">
                                          <div>
                                            <p>{t('page.supplier_orders.make_offer.price')}</p>
                                          </div>
                                          <div>
                                            <AlternativeInputNumberPrice
                                              products={products}
                                              setProduct={setProduct}
                                              indexOfElement={index} p={p}
                                              errorAlternativeProducts={errorAlternativeProducts}
                                              setErrorAlternativeProducts={setErrorAlternativeProducts}
                                              validationErrors={validationErrors}
                                            />
                                          </div>
                                        </div>

                                      </div>
                                      <div>

                                        <div className="input_wrapper">
                                          <div>
                                            <p>{t('page.supplier_orders.make_offer.sum')}</p>
                                          </div>
                                          <div>
                                            <p className="summa"><span className="ltr">₪ {p.alternativeProduct.supplierPrice ? formatPrice(p.alternativeProduct.supplierPrice * p.alternativeProduct.quantity) : 0.00}</span></p>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  )}

                                  {p.draftProduct && (
                                    <div className="products-list-flex">
                                      <div>
                                        <div className="products-img">
                                          <img src={
                                            p.draftProduct.fileName ? p.draftProduct.fileName : NoImage
                                          } />
                                        </div>
                                      </div>
                                      <div>
                                        <div className="flex_box_p">
                                          <p><span className="ltr">{p.draftProduct.catalogNumber} {t('page.supplier_orders.catalog_number')}</span></p>
                                        </div>
                                        <p className="description">
                                          {p.draftProduct.name}
                                        </p>
                                      </div>
                                      <div>

                                        <div className="input_wrapper">
                                          <div>
                                            <p>{t('page.supplier_orders.make_offer.quantity')}</p>
                                          </div>
                                          <div>
                                            <div className={`wrapper_number_input${validationErrors[
                                              `products[${index}].quantity`
                                            ] ? ' has-error' : ''}`}>
                                              <InputNumber
                                                scrollable={false}
                                                type="text"
                                                min={0}
                                                max={MAX_SAFE_NUMBER}
                                                value={p.draftProduct.quantity ? (
                                                  p.draftProduct.quantity
                                                ) : ''}
                                                onChange={
                                                  (value, event) => setProduct(products.map((i) => {
                                                    if (i.productId === p.productId && !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                                                      if (value < 0 || isNaN(value)) i.draftProduct.quantity = 0;
                                                      else if (value > MAX_SAFE_NUMBER) {
                                                        i.draftProduct.quantity = MAX_SAFE_NUMBER;
                                                      } else i.draftProduct.quantity = value;
                                                      // i.quantity = value;
                                                    }
                                                    return i;
                                                  }))
                                                }
                                                postfix={<span className="postfix"><span className="ltr">{t('page.supplier_orders.make_offer.of_items', { amount: p.amount })}</span></span>}
                                              />
                                              <p className="error">
                                                {validationErrorsFormat(
                                                  validationErrors[`products[${index}].quantity`],
                                                  t)}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                      <div>

                                        <div className="input_wrapper">
                                          <div>
                                            <p>{t('page.supplier_orders.make_offer.price')}</p>
                                          </div>
                                          <div>
                                            <div className={`wrapper_number_input${validationErrors[`products[${index}].supplierPrice`] ? ' has-error' : ''}`}>
                                              <InputNumber
                                                scrollable={false}
                                                min={0}
                                                max={MAX_SAFE_NUMBER}
                                                value={
                                                  p.draftProduct.pricePerUnit ? (
                                                    p.draftProduct.pricePerUnit
                                                  ) : ''
                                                }
                                                onChange={
                                                  (value, event) => setProduct(products.map((i) => {
                                                    if (i.productId === p.productId && !(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2)) {
                                                      if (value < 0 || isNaN(value)) i.draftProduct.pricePerUnit = 0;
                                                      else if (value > MAX_SAFE_NUMBER) {
                                                        i.draftProduct.pricePerUnit = MAX_SAFE_NUMBER;
                                                      } else i.draftProduct.pricePerUnit = value;
                                                      // i.supplierPrice = value;
                                                    }
                                                    return i;
                                                  }))
                                                }
                                                postfix={<span className="postfix"><span className="ltr">₪/{t('page.supplier_orders.make_offer.item')}</span></span>}
                                              />
                                              <p className="error">
                                                {validationErrorsFormat(
                                                  validationErrors[`products[${index}].supplierPrice`],
                                                  t)}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                      <div>

                                        <div className="input_wrapper">
                                          <div>
                                            <p>{t('page.supplier_orders.make_offer.sum')}</p>
                                          </div>
                                          <div>
                                            <p className="summa"><span className="ltr">₪ {p.draftProduct.pricePerUnit ? formatPrice(p.draftProduct.pricePerUnit * p.draftProduct.quantity) : 0.00}</span></p>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  )}

                                </div>}

                              </div>

                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="make_offer_total">
                  <div>
                    <div className="grey_box">

                      <ul>
                        <li><p>{t('page.supplier_orders.make_offer.subtotal')}</p></li>
                        <li><p><span className="ltr">₪ {formatPrice(subtotal)}</span></p></li>
                      </ul>

                      <ul>
                        <li><p>{t('page.supplier_orders.make_offer.discount')}</p></li>
                        <li>
                          <div className="wrapper_input_number">
                            <InputNumber
                              scrollable={false}
                              step={0.1}
                              min={0}
                              max={30}
                              placeholder={0}
                              onChange={
                                (value, event) => {
                                  if (value < 0 || isNaN(value)) setPercentDiscount(0);
                                  else if (value > 30) setPercentDiscount(30);
                                  else if (!(value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 1)) {
                                    setPercentDiscount(value);
                                  }
                                }
                              }
                              value={percentDiscount}
                              postfix="%"
                            />
                          </div>
                        </li>
                        <li><p><span className="ltr">₪ {formatPrice(discount)}</span></p></li>
                      </ul>

                      {numberOfCompetitors > 0 && <div className="warning_box">
                        <p>
                          {t('page.supplier_orders.make_offer.text', {numberOfCompetitors})}
                        </p>
                      </div>}

                      <ul className="total">
                        <li><p>{t('page.supplier_orders.make_offer.total')}</p></li>
                        <li><p><span className="ltr">₪ {formatPrice(total)}</span></p></li>
                      </ul>

                    </div>

                    <Button
                      disabled={products.filter((p) => p.inStock).length === 0 || !checkIsArrayEmpty}
                      appearance="primary"
                      onClick={onClickContinue}
                    ><span>{t('page.supplier_orders.make_offer.continue')}</span></Button>
                  </div>
                </div>

              </Panel>

            </>
          )}

        </div>
      </div>

      {!isFetching && <AddAlternativeModal
        isModalOpen={isAddAlternativeModalOpen.isModalOpen}
        product={isAddAlternativeModalOpen.product}
        addAlternative={(productId, alternativeProduct) => {
          setProduct(products.map((i) => {
            if (i.productId === productId) {
              i.draftProduct = null;
              i.inStock = true;
              i.outOfStock = false;
              i.alternativeProduct = {
                productId: alternativeProduct.productId,
                productName: alternativeProduct.productName,
                catalogNumber: alternativeProduct.catalogNumber,
                supplierNumber: alternativeProduct.supplierNumber,
                picture: alternativeProduct.picture,
                // quantity: i.quantity,
                // supplierPrice: i.supplierPrice,
                quantity: i.quantity,
                supplierPrice: alternativeProduct.supplierPrice,
              };
            }
            return i;
          }));
          setIsAddAlternativeModalOpen({ isModalOpen: false, product: {} });
        }}
        addDraft={(productId, draftProduct) => {
          setProduct(products.map((i) => {
            if (i.productId === productId) {
              i.alternativeProduct = null;
              i.inStock = true;
              i.outOfStock = false;
              i.draftProduct = {
                draftProductId: draftProduct.draftProductId,
                name: draftProduct.name,
                catalogNumber: draftProduct.catalogNumber,
                supplierPn: null,
                fileName: null,
                quantity: draftProduct.quantity,
                pricePerUnit: draftProduct.pricePerUnit,
              };
            }
            return i;
          }));
          setIsAddAlternativeModalOpen({ isModalOpen: false, product: {} });
        }}
        onHide={(productId) => {
          setProduct(products.map((i) => {
            if (i.productId === productId) {
              i.isAlternative = false;
              i.alternativeProduct = null;
              i.draftProduct = null;
            }
            return i;
          }));
          setIsAddAlternativeModalOpen({ isModalOpen: false, product: {} });
        }}
      />}

      {error && error === 'error.offer.status_incorrect' && <OfferStatusErrorModal show={true} />}
    </>
  );
}

export default MakeOffer;
