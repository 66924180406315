import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'rsuite';
function PaginationComponent({ pages, maxButtons = 4, activePage, setActivePage}) {
  return (
    <div className='wrapper_pagination'>
      <Pagination
        prev={true}
        next={true}
        first={false}
        last={false}
        ellipsis={true}
        boundaryLinks={true}
        pages={pages}
        maxButtons={maxButtons}
        activePage={activePage}
        onSelect={setActivePage}
      />
    </div>
  );
};

PaginationComponent.propTypes = {
  pages: PropTypes.number,
  maxButtons: PropTypes.number,
  activePage: PropTypes.number,
  setActivePage: PropTypes.func,
};

export default PaginationComponent;
