import actions from './constants';
import authApi from '../../../api/auth';
import { putCartItemToCustomer } from './smsCodeVerification';

export const setManagerData = (data) => async (dispatch) => {
  dispatch(actions.setManagerData.request());
  try {
    dispatch(actions.setManagerData.success({ managerInfo: data }));
  } catch (e) {
    dispatch(actions.setManagerData.error({ error: e.message, showMessage: true }));
  }
};

export const resetManagerData = () => async (dispatch) => {
  dispatch(actions.resetManagerData.request());
  try {
    dispatch(actions.resetManagerData.success({ managerInfo: {} }));
  } catch (e) {
    dispatch(actions.resetManagerData.error({ error: e.message, showMessage: true }));
  }
};

export const createManager = (manager) => async (dispatch) => {
  dispatch(actions.createManager.request());

  try {
    const response = await authApi.createManager(manager);
    if (response.statusCode !== 200) {
      throw new Error(
        response.data.validationErrors.email ?
          response.data.validationErrors.email :
          response.message.message);
    }
    dispatch(actions.createManager.success({ managerInfo: {}, user: response?.data }));
    if (response?.data?.entityType === 'CUSTOMER') {
      const localStorageCartData = JSON.parse(localStorage.getItem('userCart')); // object
      if ( !response?.data?.cart && localStorageCartData.length ) {
        dispatch(putCartItemToCustomer(localStorage.getItem('userCart')));
      }
    }
  } catch (e) {
    console.log( e.message);
    dispatch(actions.createManager.error({ error: e.message, showMessage: true }));
  }
};
