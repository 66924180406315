import { handleActions } from 'redux-actions';
import actions from '../actions/catalog';

export const initialState = {
  pathElements: [],
  isFetching: true,
  error: '',
};

export default handleActions(
  {
    [actions.loadAllPath.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      pathElements: state.pathElements,
    }),

    [actions.loadAllPath.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      pathElements: [...payload.pathElements],
    }),

    [actions.loadAllPath.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),
  },
  initialState
);
