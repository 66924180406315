import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'rsuite';
import { useTranslation } from 'react-i18next';

function NeedHelpComponent({orderId}) {
  const { t } = useTranslation();

  return (
    <>
      <Panel bordered className="need_help">

        <h4>{t('page.need_help.title')}</h4>

        <p>{t('page.need_help.text', { order: orderId })}</p>
        <p><a href="mailto:info@konebone.co.il">
          <span>info@konebone.co.il</span>
        </a></p>
        <p><a href="tel:0723970146"><span className="ltr">{t('company_phone_number')}</span></a></p>

      </Panel>
    </>
  );
}
NeedHelpComponent.propTypes = {
  orderId: PropTypes.string.isRequired,
};
export default NeedHelpComponent;
