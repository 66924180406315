import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { useTranslation } from 'react-i18next';

function SuccessfulOrder() {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="successful-order">
      <div className="container">
        <div className="successful_order_box">

          <div className="banner">
            <span />
          </div>

          <div className="order-info">
            <p className="header">
              <span>{t('page.order_created.order_created')}</span>
              <span>{t('page.order_created.and_sent_successfully')}</span>
            </p>
            <p>
              {t('page.order_created.text')}
            </p>

            <div className="button">
              <Button block type="button" appearance="primary" onClick={() => history.push('/customer/orders')}><span>{t('page.order_created.go_to_my_orders')}</span></Button>
            </div>

            <div className="button">
              <Button block type="button" appearance="link" onClick={() => history.push('/product_catalog')}><span>{t('page.order_created.back_to_catalogue')}</span></Button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default SuccessfulOrder;
