export const registration = (actions) => ({
  // set registration data
  [actions.setRegistrationData.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    registrationData: state.registrationData,
    isCodeConfirmed: false,
    isManagerCreated: false,
  }),

  [actions.setRegistrationData.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    registrationData: payload.registrationData,
  }),

  [actions.setRegistrationData.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // reset registration data
  [actions.resetRegistrationData.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    registrationData: state.registrationData,
    goToNextStep: false,
  }),

  [actions.resetRegistrationData.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    registrationData: payload.registrationData,
    goToNextStep: false,
  }),

  [actions.resetRegistrationData.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
    goToNextStep: false,
  }),

  // registration
  [actions.registration.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    goToNextStep: false,
  }),

  [actions.registration.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    goToNextStep: true,
  }),

  [actions.registration.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
    goToNextStep: false,
  }),

  // getSignedSupplierCandidate
  [actions.getSignedSupplierCandidate.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
  }),

  [actions.getSignedSupplierCandidate.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
  }),

  [actions.getSignedSupplierCandidate.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // getSignedCustomerCandidate
  [actions.getSignedCustomerCandidate.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
  }),

  [actions.getSignedCustomerCandidate.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
  }),

  [actions.getSignedCustomerCandidate.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // reset registration error
  [actions.resetRegistrationError]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: '',
  }),
});
