import actions from './constants';
import authApi from '../../../api/auth';

export const setCompanyInfoData = (data) => async (dispatch) => {
  dispatch(actions.setCompanyInfoData.request());
  try {
    dispatch(actions.setCompanyInfoData.success({ companyInfoData: data }));
  } catch (e) {
    dispatch(actions.setCompanyInfoData.error({ error: e.message, showMessage: true }));
  }
};

export const resetCompanyInfoData = () => async (dispatch) => {
  dispatch(actions.resetCompanyInfoData.request());
  try {
    dispatch(actions.resetCompanyInfoData.success({ companyInfo: {} }));
  } catch (e) {
    dispatch(actions.resetCompanyInfoData.error({ error: e.message, showMessage: true }));
  }
};

export const createCompanyInfo = (company) => async (dispatch) => {
  dispatch(actions.createCompanyInfo.request());
  if (!company.description) company.description = '';
  if (!company.activity) company.activity = '';
  if (!company.foundation) company.foundation = '';
  try {
    const response = await authApi.createCompanyInfo(company);
    if (response.statusCode !== 200) {
      throw new Error(response.data.validationErrors.contractorNumber ?
        response.data.validationErrors.contractorNumber :
        response.message.message);
    }
    dispatch(actions.createCompanyInfo.success({ companyInfo: company }));
  } catch (e) {
    console.log(e.message);
    dispatch(actions.createCompanyInfo.error({ error: e.message, showMessage: true }));
  }
};
