import React, { useState, useEffect } from 'react';
import {
  Panel,
  PanelGroup,
  Breadcrumb,
  Icon,
  FlexboxGrid,
  Col,
  Button,
  Loader,
} from 'rsuite';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import NoImage from '../assets/img/no_image.png';

import {
  getOfferItemDetailsSupplier,
} from '../redux/actions/offer';
import { useDispatch, useSelector } from 'react-redux';
import { formatPrice } from '../utils';
import { deliveryModeMaper, vehicleWeightTypeValue } from '../utils';
import NeedHelpComponent from '../components/NeedHelpComponent';
import NotFound from './NotFound';


const ListOrdered = ({ orderedProducts }) => (
  <ul>
    {orderedProducts.map((product, index) => (
      <li key={index}>
        <OrderedProduct product={product} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  orderedProducts: PropTypes.instanceOf(Array).isRequired,
};

const OrderedProduct = ({ product }) => {
  const isMobileView = useMediaQuery({ query: '(max-width: 1199px)' });
  const {
    catalogNumber,
    supplierNumber,
    productName,
    quantity,
    pictureFileName,
    outOfStock,
    totalPrice,
    price,
    orderQuantity,
    alternativeProduct,
  } = product;
  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${outOfStock ? ' opacity' : ''}`}>

        {isMobileView ? (
          <>
            <div>
              <div className="products-img">
                <img src={pictureFileName ? pictureFileName : NoImage} alt="product_png" />
                {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
              <p className="quantity">
                <span>
                  x
                  {quantity ? quantity : orderQuantity}
                </span>
              </p>
            </div>
            <div>

              <div className="flex_box_p">
                <p><span className="product_number">{catalogNumber}</span></p>
                {supplierNumber && <p><span className="ltr">{supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
              </div>

              <p className="description">{productName}</p>
              {totalPrice && (
                <>
                  <p className="price-all"><span className="ltr">₪ {totalPrice ? totalPrice : 0}</span></p>
                  <p><span className="ltr">₪ {price ? price : 0}/{t('page.order.item')}</span></p>
                </>)
              }
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="products-img">
                <img src={pictureFileName ? pictureFileName : NoImage} alt="product_png" />
                {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
            </div>
            <div className="initial_padding">
              <div className="flex_box_p">
                <p><span className="product_number">{catalogNumber}</span></p>
                {supplierNumber && <p><span className="ltr">{supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
              </div>

              <p className="description">{productName}</p>
            </div>
            <div>
              <p className="quantity">
                <span>
                  x
                  {quantity ? quantity : orderQuantity}
                </span>
              </p>
            </div>
            <div>
              {totalPrice && (
                <>
                  <p className="price-all"><span className="ltr">₪ {totalPrice ? formatPrice(totalPrice) : 0}</span></p>
                  <p><span className="ltr">₪ {price ? price : 0}/{t('page.order.item')}</span></p>
                </>)
              }
            </div>
          </>
        )}

      </div>
      {alternativeProduct && (
        <div className="replacement">
          <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
          <OrderedProduct product={alternativeProduct} />
        </div>
      )}
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

const OrderInReview = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { orderId } = useParams();

  const [orderSummary, setOrderSummary] = useState(false);
  const { item, isFetching } = useSelector((state) => state.offer);
  const { offer, project } = item;

  const deliveryDate = offer?.deliveryDetail?.deliveryDate?.split('-');
  const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
  const deliveryTimeRange = offer?.deliveryDetail?.deliveryTimeRange;

  const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
    `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
    t('page.supplier_orders.during_the_working_day');

  useEffect(() => {
    dispatch(getOfferItemDetailsSupplier(orderId));
  }, []);

  if (!isFetching && Object.keys(item).length === 0) {
    return <NotFound/>;
  }

  return (
    <>
      {isFetching ? (
        <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
      ) : (
        <div className="checkout offer_confirmation order_list_item">
          <div className="container">

            <div className="breadcrumb-list">
              <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                <Breadcrumb.Item componentClass={Link} to="/supplier/orders/review">
                  <span>{t('page.requests_for_proposal.in_review')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <span>{t('page.order.order')} #{item?.orderId}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>


            <div className="checkout-flex-col">

              <FlexboxGrid className="flex-420-col">
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

                  <Panel bordered className="panel_edit">

                    <div className="header-flex">
                      <div><p className="order">{t('page.order.order')} #{item?.orderId}</p></div>
                      <div>
                        <p>
                          <span className="orders">
                            {(offer?.products?.length || offer?.outOfStockProducts?.length) &&
                              <>{`${offer?.products?.length + offer?.outOfStockProducts?.length}`} {t('page.supplier_orders.product')}</> }
                          </span>
                        </p>
                      </div>
                    </div>
                    <p className="date">{t('page.order.your_proposal_has')}</p>

                    <div className="offers_list">
                      <div className="offer-box">

                        <PanelGroup accordion>

                          {offer?.inStockProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.in_stock')} <span className="count">({offer?.inStockProducts.length})</span></p>} defaultExpanded>

                            <div className="products-list">
                              <ListOrdered orderedProducts={offer?.inStockProducts} />
                            </div>

                          </Panel>)}

                          {offer?.alternativeProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.pay_order.alternative_products')} <span className="count">({offer?.alternativeProducts.length})</span></p>} defaultExpanded>

                            <div className="products-list">
                              <ListOrdered orderedProducts={offer?.alternativeProducts} />
                            </div>

                          </Panel>)}
                          {offer?.outOfStockProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.pay_order.out_of_stock')} <span className="count">({offer?.outOfStockProducts.length})</span></p>} defaultExpanded>

                            <div className="products-list">
                              <ListOrdered orderedProducts={offer?.outOfStockProducts} />
                            </div>

                          </Panel>)}

                        </PanelGroup>

                      </div>
                    </div>


                  </Panel>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>

                  <div className="order-component offers_list">

                    <Panel bordered>

                      <p className="header">{t('page.supplier_orders.order_details')}</p>

                      <div className="grey_box">
                        <p>{t('page.supplier_orders.order_filling')}</p>
                        <p className="price">
                          <span>{t('page.order.of_products', { num1: offer?.products?.length, num2: offer?.products?.length + offer?.outOfStockProducts?.length })}</span>
                        </p>

                        <div className="toggle_hide_view">
                          <Button appearance="link" onClick={() => setOrderSummary(!orderSummary)}>
                            <span className="flex">
                              <span>{t('page.order.delivery_details')}</span>
                              <span>
                                <i className={`rs-icon ${orderSummary ? 'open' : 'close'}`} />
                              </span>
                            </span>
                          </Button>
                        </div>

                      </div>

                      {orderSummary && (
                        <div className="details_box">

                          <div className="ico-item delivery">
                            <p className="sub-header">{t('page.supplier_orders.delivery_method')}</p>
                            <p>{deliveryModeMaper(offer?.deliveryDetail?.mode, t)}</p>
                          </div>

                          {offer?.deliveryDetail?.mode === 'BY_KONEBONE' && (
                            <div className="ico-item address">
                              <p className="sub-header">{t('page.supplier_orders.pickup_address')}</p>
                              <p>{`${i18n.language === 'heb' ? 'רח’' : 'st.'} ${offer?.deliveryDetail?.pickupStreet} ${offer?.deliveryDetail?.pickupApt}, 
                              ${offer?.deliveryDetail?.pickupCity}`}</p>
                            </div>)}

                          {offer?.deliveryDetail?.mode !== 'BY_KONEBONE' && (
                            <div className="ico-item clock">
                              <p className="sub-header">{t('page.supplier_orders.delivery_time')}</p>
                              <p>{deliveryTime ?
                                `${deliveryDateData} ${deliveryTime}` :
                                `${t('page.offers_list.delivery_time')} ${t('page.offers_list.not_specified')}`}</p>
                            </div>)}

                          <div className="ico-item project">
                            <p className="sub-header">{t('page.supplier_orders.delivery_address')}</p>
                            <p dir="auto" style={{textAlign: i18n.language === 'heb' ? 'right' : 'left'}}>
                              <span>{`"${project?.name}"`},</span>
                              <span>{`${project?.city ? ` ${project?.city}` : ''}`},</span>
                              <span>{` ${i18n.language === 'heb' ? 'רח’' : 'st.'} ${project?.street} ${project?.apt ? project?.apt : '-'}`}</span>
                            </p>
                          </div>

                          {offer?.deliveryDetail?.mode === 'BY_KONEBONE' && (
                            <div className="ico-item vehicle">
                              <p className="sub-header">{t('page.supplier_orders.delivery_vehicle')}</p>
                              <p>{vehicleWeightTypeValue(offer?.deliveryDetail?.vehicle, t)}</p>
                            </div>)}

                          {offer?.deliveryDetail?.mode !== 'BY_KONEBONE' && (
                            <div className="ico-item deliveryman">
                              <p className="sub-header">{t('page.supplier_orders.deliveryman')}</p>
                              <p>{offer?.deliveryDetail?.contactName}, {offer?.deliveryDetail?.contactPhone}</p>
                            </div>)}

                          <div className="ico-item services">
                            <p className="sub-header">{t('page.order.additional_services')}</p>
                            <p>{offer?.serviceUnloading ? t('page.order.unloading') : '-'}</p>
                          </div>

                          <div className="ico-item comment">
                            <p className="sub-header">{t('page.order.comment_supplier')}</p>
                            {offer.comment ? offer.comment : '-'}
                          </div>
                        </div>
                      )}

                      <div className="details_box">
                        <p className="header">{t('page.supplier_orders.summary')}</p>
                        <div className="price_box with_line">
                          <ul>
                            <li><p>{t('page.supplier_orders.make_offer.subtotal')}</p></li>
                            <li><p><span className="ltr">₪ {formatPrice(offer?.offerPrice || 0)}</span></p></li>
                          </ul>
                          {!!offer?.discountPercentage &&
                          <ul>
                            <li><p className="relative_for_badge">{t('page.offer.discount')} <span className="discount_badge"><span className="ltr">-{offer?.discountPercentage}%</span></span></p></li>
                            <li><p style={{ lineHeight: '28px' }}><span className="ltr">{!!offer?.discount && '-'}₪ {formatPrice(offer?.discount || 0)}</span></p></li>
                          </ul>}
                          <ul>
                            <li><p>{t('page.offers_list.delivery_cost')}</p></li>
                            <li><p><span className="ltr">₪ {formatPrice(offer?.deliveryDetail?.deliveryCost || 0)}</span></p></li>
                          </ul>
                          <ul>
                            <li><p>{t('page.order.order_list_item.order_total')}</p></li>
                            <li><p><span className="ltr">₪ {formatPrice(offer?.orderTotal || 0)}</span></p></li>
                          </ul>
                        </div>
                      </div>

                    </Panel>

                    <NeedHelpComponent orderId={orderId}/>

                  </div>

                </FlexboxGrid.Item>
              </FlexboxGrid>

            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default OrderInReview;
