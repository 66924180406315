export const locales = {
  sunday: 'Su',
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  ok: 'OK',
  today: 'Today',
  yesterday: 'Yesterday',
  hours: 'Hours',
  minutes: 'Minutes',
  seconds: 'Seconds',
};
export const localesHeb = {
  sunday: 'א\'',
  monday: 'ב\'',
  tuesday: 'ג\'',
  wednesday: 'ד\'',
  thursday: 'ה\'',
  friday: 'שישי',
  saturday: 'שבת',
  ok: 'בחר',
  today: 'היום',
  yesterday: 'אתמול',
  hours: 'שעות',
  minutes: 'דקות',
  seconds: 'שניות',
};
