import { createActions } from 'redux-actions';
import { stringify } from 'query-string';
import offerApi from '../../api/offer';

const actions = createActions({
  getOffersBySupplierId: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getAcceptedOffersCount: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getOfferDetailsById: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetOffer: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  putMakeOffersById: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  makeOffersBySupplier: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getOffersByCustomerId: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getOfferItemDetails: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getOfferItemDetailsSupplier: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  putCancelOffer: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  postOfferPackingSlip: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  deleteOfferPackingSlip: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getTimeRange: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

export const getOffersBySupplierId = (data) => async (dispatch) => {
  const {
    status,
    page,
  } = data;
  dispatch(actions.getOffersBySupplierId.request());
  const query_data = { page: page - 1, status };

  try {
    const response = await offerApi.getOffersBySupplierId(stringify(query_data));
    const pagesCalculation = response?.data?.totalPages;
    const pages = pagesCalculation === 0 ? 1 : pagesCalculation;
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.getOffersBySupplierId.success({
      items: response?.data?.content,
      pages,
      totalItems: response?.data?.totalElements,
    }));
  } catch (e) {
    dispatch(actions.getOffersBySupplierId.error({ error: e }));
  }
};

export const getOffersByCustomerId = (orderId) => async (dispatch) => {
  dispatch(actions.getOffersByCustomerId.request());
  const query_data = { orderId };

  try {
    const response = await offerApi.getOffersByCustomerId(stringify(query_data));
    if (response.statusCode !== 200) throw new Error(response.message?.message);
    response?.data?.group && response?.data?.group?.offers.forEach((offer) => {
      const alternativeProducts = [];
      const inStockProducts = [];
      offer.products.forEach((el) => {
        if (el.alternativeProduct) {
          el.alternativeProduct.orderQuantity = el.orderQuantity;
          alternativeProducts.push(el);
        } else {
          inStockProducts.push(el);
        }
      });
      offer.alternativeProducts = alternativeProducts;
      offer.inStockProducts = inStockProducts;
    });
    response?.data?.offers.forEach((offer) => {
      const alternativeProducts = [];
      const inStockProducts = [];
      offer.products.forEach((el) => {
        if (el.alternativeProduct) {
          el.alternativeProduct.orderQuantity = el.orderQuantity;
          alternativeProducts.push(el);
        } else {
          inStockProducts.push(el);
        }
      });
      offer.alternativeProducts = alternativeProducts;
      offer.inStockProducts = inStockProducts;
    });
    dispatch(actions.getOffersByCustomerId.success({
      items: response?.data?.offers,
      group: response?.data?.group,
      totalItems: response?.data?.totalElements,
    }));
  } catch (e) {
    dispatch(actions.getOffersByCustomerId.error({ error: e }));
  }
};

export const putMakeOffersById = (data) => async (dispatch) => {
  dispatch(actions.putMakeOffersById.request());
  const { id, query } = data;
  try {
    const response = await offerApi.putMakeOffersById(id, query);
    if (response.statusCode !== 200) throw new Error(JSON.stringify(response));
    dispatch(actions.putMakeOffersById.success());
  } catch (e) {
    const response = JSON.parse(e.message);
    dispatch(actions.putMakeOffersById.error({
      error: response?.message?.message,
      validationErrors: response?.data?.validationErrors,
    }));
  }
};

export const putCancelOffer = (id, callBack) => async (dispatch) => {
  dispatch(actions.putCancelOffer.request());
  try {
    const response = await offerApi.putCancelOffer(id);
    if (response.statusCode !== 200) {
      throw new Error(response.message.message);
    } else {
      dispatch(actions.putCancelOffer.success());
      if (typeof callBack === 'function') {
        callBack();
      }
    };
  } catch (e) {
    dispatch(actions.putCancelOffer.error({ error: e }));
  }
};

export const getAcceptedOffersCount = () => async (dispatch) => {
  dispatch(actions.getAcceptedOffersCount.request());
  try {
    const response = await offerApi.getAcceptedOffersCount();
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.getAcceptedOffersCount.success({ acceptedOffers: response?.data }));
  } catch (e) {
    dispatch(actions.getAcceptedOffersCount.error({ error: e }));
  }
};

export const getOfferDetailsById = (id) => async (dispatch) => {
  dispatch(actions.getOfferDetailsById.request());
  try {
    const response = await offerApi.getOfferDetailsById(id);
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.getOfferDetailsById.success({ item: response?.data }));
  } catch (e) {
    dispatch(actions.getOfferDetailsById.error({ error: e }));
  }
};

// export const resetOffer = () => async (dispatch) => dispatch(actions.resetOffer());

export const resetOffer = () => async (dispatch) => {
  dispatch(actions.resetOffer.request());
  try {
    dispatch(actions.resetOffer.success());
  } catch (e) {
    dispatch(actions.resetOffer.error({ error: e }));
  }
};

export const makeOffersBySupplier = (id, data) => async (dispatch) => {
  dispatch(actions.makeOffersBySupplier.request());
  try {
    const response = await offerApi.makeOffersBySupplier(id, data);
    if (response.statusCode !== 200) throw new Error(JSON.stringify(response));
    dispatch(actions.makeOffersBySupplier.success({ item: response.data }));
  } catch (e) {
    const response = JSON.parse(e.message);
    dispatch(actions.makeOffersBySupplier.error({
      error: response?.message?.message,
      validationErrors: response?.data?.validationErrors,
    }));
  }
};

export const getOfferItemDetails = (offerId) => async (dispatch) => {
  dispatch(actions.getOfferItemDetails.request());
  try {
    const response = await offerApi.getOfferItemDetails(offerId);
    if (response.statusCode !== 200) throw new Error(response.message?.message);

    response.data.offerGroup.offers.forEach((offer) => {
      const alternativeProducts = [];
      const inStockProducts = [];
      offer.products.forEach((el) => {
        if (el.alternativeProduct) {
          el.alternativeProduct.orderQuantity = el.orderQuantity;
          alternativeProducts.push(el);
        } else {
          inStockProducts.push(el);
        }
      });
      offer.alternativeProducts = alternativeProducts;
      offer.inStockProducts = inStockProducts;
    });
    dispatch(actions.getOfferItemDetails.success({ item: response?.data }));
  } catch (e) {
    dispatch(actions.getOfferItemDetails.error({ error: e }));
  }
};

export const getOfferItemDetailsSupplier = (offerId) => async (dispatch) => {
  dispatch(actions.getOfferItemDetailsSupplier.request());
  try {
    const response = await offerApi.getOfferItemDetailsSupplier(offerId);
    if (response.statusCode !== 200) throw new Error(response.message?.message);
    const alternativeProducts = [];
    const inStockProducts = [];
    response.data.offer.products.forEach((el) => {
      if (el.alternativeProduct) {
        el.alternativeProduct.orderQuantity = el.orderQuantity;
        alternativeProducts.push(el);
      } else {
        inStockProducts.push(el);
      }
    });
    response.data.offer.alternativeProducts = alternativeProducts;
    response.data.offer.inStockProducts = inStockProducts;
    dispatch(actions.getOfferItemDetailsSupplier.success({ item: response?.data }));
  } catch (e) {
    dispatch(actions.getOfferItemDetailsSupplier.error({ error: e }));
  }
};

export const postOfferPackingSlip = (offerIdValue, formData, callBack) => async (dispatch) => {
  dispatch(actions.postOfferPackingSlip.request());

  try {
    const response = await offerApi.postOfferPackingSlip(offerIdValue, formData);
    if (response.statusCode !== 200) {
      throw new Error(response.message.message);
    } else {
      dispatch(actions.postOfferPackingSlip.success());
      if (typeof callBack === 'function') {
        callBack(response?.data);
      }
    }
  } catch (e) {
    dispatch(actions.postOfferPackingSlip.error({ error: e }));
  }
};
export const deleteOfferPackingSlip = (id, callBack) => async (dispatch) => {
  dispatch(actions.deleteOfferPackingSlip.request());
  try {
    const response = await offerApi.deleteOfferPackingSlip(id);
    if (!response) {
      throw new Error(response.message.message);
    } else {
      dispatch(actions.deleteOfferPackingSlip.success());
      if (typeof callBack === 'function') {
        callBack();
      }
    }
  } catch (e) {
    dispatch(actions.deleteOfferPackingSlip.error({ error: e }));
  }
};

export const getTimeRange = () => async (dispatch) => {
  dispatch(actions.getTimeRange.request());
  try {
    const response = await offerApi.getTimeRange();

    const resultData = response.data;

    const data = resultData.map((item) => {
      if (!item.full) {
        return { label: `${item.start.slice(0, 5)}-${item.end.slice(0, 5)}`, id: item.id };
      } else {
        return { label: 'במהלך היום', id: item.id };
      }
    });

    if (response.statusCode !== 200) throw new Error(response.message?.message);
    dispatch(actions.getTimeRange.success({ item: data }));
  } catch (e) {
    dispatch(actions.getTimeRange.error({ error: e }));
  }
};
