import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
} from 'rsuite';
import { useTranslation } from 'react-i18next';

function CancelRegistrationModalAbortion({ isModalOpen, abortAction, cancelAction }) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        className="canceling_registration"
        overflow={false}
        show={isModalOpen}
        keyboard={true}
        autoFocus={true}>
        <Modal.Header closeButton={false}>
          <p>{t('auth.register.are_you_sure_you_want_to_abort')}</p>
          <p>{t('auth.register.the_registration_process?')}</p>
        </Modal.Header>
        <Modal.Body >
          <p>{t('auth.register.interrupt_registration_process')}</p>
          <p>{t('auth.register.log_by_phone_number')}</p>
          <div className="flex-container">
            <div><Button appearance="primary" onClick={() => abortAction()}><span>{t('auth.register.abort')}</span></Button></div>
            <div><Button appearance="ghost" onClick={() => cancelAction(false)}><span>{t('auth.register.cancel')}</span></Button></div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

CancelRegistrationModalAbortion.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  abortAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
};

export default CancelRegistrationModalAbortion;
