import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const PrivateRouteNotSupplier = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={
        (props) => (user?.entityType !== 'SUPPLIER' && user?.entityType !== 'SUPPLIER_CANDIDATE')? (
          <Component {...props} />
        ) :
          (<Redirect to={{
            pathname: '/404',
            state: { referrer: location.pathname },
          }} />
          )}
    />
  );
};

PrivateRouteNotSupplier.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
};

export default PrivateRouteNotSupplier;
