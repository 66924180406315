import actions from './constants';
import authApi from '../../../api/auth';

export const logout = (callBack) => async (dispatch, getState) => {
  dispatch(actions.logout.request());
  try {
    const response = await authApi.logout();
    if (!response.success) {
      throw new Error(response.message.message);
    } else {
      dispatch(actions.logout.success());
      if (typeof callBack === 'function') {
        callBack();
      }
    }
  } catch (e) {
    dispatch(actions.logout.error({ error: e.message, showMessage: true }));
  }
};

export const resetUserState = () => async (dispatch) => {
  dispatch(actions.resetUserState());
};
