import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleIsTimeConstraintsShown, toggleIsAdditionalServicesShown, toggleIsProjectsShown } from '../../redux/actions/order';
import PropTypes from 'prop-types';
import {
  Button,
  DatePicker,
  Col,
  FlexboxGrid,
  Form,
  FormGroup,
  Panel,
  Tooltip,
  Whisper,
  Icon,
} from 'rsuite';
import { setConstraintsData as storeConstraintsData, addConstraintsData } from '../../redux/actions/checkout';
import { locales, localesHeb } from '../../helpers/localeData';
import { useTranslation } from 'react-i18next';
import { isBefore } from 'date-fns';
import {MenuItem, Select} from '@material-ui/core';
import classNames from 'classnames';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {getTimeRange} from '../../redux/actions/order';


const InfoTooltip = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <Tooltip classPrefix="info_tooltip_checkout rs-tooltip" {...rest}>
      <p>{t('page.offer.info')}</p>
    </Tooltip>
  );
};

const InfoButton = () => {
  const triggerRef = createRef();
  const [isOpen, setIsOpen] = useState(false);

  function handleCloseTooltip() {
    triggerRef.current.close();
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('resize', handleCloseTooltip);
    } else {
      window.removeEventListener('resize', handleCloseTooltip);
    }

    return () => {
      window.removeEventListener('resize', handleCloseTooltip);
    };
  });

  return (
    <Whisper
      placement="autoVerticalEnd"
      trigger="click"
      onOpen={() => setIsOpen(true)}
      speaker={<InfoTooltip />}
      triggerRef={triggerRef}
    >
      <Button className="info"><Icon icon="info" /></Button>
    </Whisper>
  );
};

function TimeConstraints({ setIsConfirmOrderShown }) {
  const dispatch = useDispatch();
  const [constraintsData, setConstraintsData] = useState({});
  const [constraintsErrors, setConstraintsErrors] = useState({});
  const [urgentStatus, setUrgentStatus] = useState(true);

  const timeConstraints = useSelector((state) => state.checkout.timeConstraints);
  const projectData = useSelector((state) => state.checkout.projectData);
  const additionalServices = useSelector((state) => state.checkout.additionalServices);
  const order = useSelector((state) => state.order.item);
  const timeRanges = useSelector((state) => state.order.timeRanges);

  const isCheckedStep = useSelector((state) => state.checkout.isCheckedStep);
  const projectDataApt = projectData?.apt && projectData?.apt;
  const projectDataCity = projectData?.city ? `, ${projectData?.city}` : '';

  useEffect(() => {
    dispatch(getTimeRange());
  }, []);

  useEffect(() => {
    setConstraintsData(timeConstraints);
    setUrgentStatus(Boolean(timeConstraints?.urgentStatus));
  }, [timeConstraints]);

  useEffect(() => {
    if (!urgentStatus && !!constraintsData?.date && !!constraintsData?.time) {
      setConstraintsErrors({ date: '', time: '' });
    }
  }, [constraintsData]);

  const onHandleDate = (val) => {
    if (!val) {
      setConstraintsErrors({ ...constraintsErrors, date: t('errors.this_field_is_required') });
    } else if (!constraintsData.time) {
      setConstraintsErrors({ ...constraintsErrors, time: t('errors.this_field_is_required') });
    } else {
      setConstraintsErrors({ ...constraintsErrors, date: '' });
    }
    setConstraintsData({ ...constraintsData, date: val });
  };

  const onHandleTime = (e) => {
    if (!constraintsData.date) {
      setConstraintsErrors({ ...constraintsErrors, date: t('errors.this_field_is_required') });
    } else {
      setConstraintsErrors({ ...constraintsErrors, date: '' });
    }
    setConstraintsData({ ...constraintsData, time: timeRanges.find((item) => item.label === e.target.value) });
  };

  const { t, i18n } = useTranslation();

  const changeCheckoutStates = () => {
    if (Object.keys(timeConstraints).length === 0) {
      dispatch(toggleIsTimeConstraintsShown(false));
      dispatch(toggleIsProjectsShown(true));
    } else {
      if (!!projectData.project && !!additionalServices.orderId) {
        dispatch(toggleIsProjectsShown(false));
        setIsConfirmOrderShown(true);
      } else {
        !!projectData.project ? dispatch(toggleIsAdditionalServicesShown(true)) : dispatch(toggleIsProjectsShown(true));
      }
      dispatch(toggleIsTimeConstraintsShown(false));
    }
  };

  const addConstraintsDataAndContinue = () => {
    if (Object.keys(constraintsErrors).length === 0 ||
      (constraintsErrors?.time?.length === 0 || constraintsErrors?.date?.length === 0)) {
      if (urgentStatus) {
        dispatch(storeConstraintsData(constraintsData, order?.orderId, urgentStatus));
        dispatch(addConstraintsData({
          deliveryTimeRangeId: null,
          orderId: order?.orderId,
          urgentStatus,
        }));
        changeCheckoutStates();
      } else {
        const deliveryTime = constraintsData?.time?.id;
        const deliveryDate = constraintsData?.date?.toISOString()?.slice(0, 10);
        if (order?.orderId) {
          dispatch(storeConstraintsData(constraintsData, order?.orderId, urgentStatus));
          dispatch(addConstraintsData({
            deliveryTimeRangeId: deliveryTime,
            deliveryDate: deliveryDate,
            orderId: order?.orderId,
            urgentStatus,
          }));
        }
        changeCheckoutStates();
      }
    }
  };

  const onDatePickerValidate = () => {
    if (!constraintsData.date) {
      setConstraintsErrors({ ...constraintsErrors, date: t('errors.this_field_is_required') });
    } else {
      setConstraintsErrors({ ...constraintsErrors, date: '' });
    }
  };

  const changeCalendarUi = () => {
    const getCalendarToolbar = Array.from(
      document.querySelectorAll('div.rs-calendar-header-month-toolbar')
    );
    getCalendarToolbar.forEach((element) => {
      element.classList.toggle('changeUi');
    });
  };

  return (
    <>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>
        <Panel bordered className="panel_edit">
          <p className="header m20">
            {t('page.checkout.time_constraints.time_constraints')} <span className="light"> {t('page.checkout.step_1')}</span>
          </p>
          <Form fluid>
            <FormGroup>
              <div>
                <div className="time_constraints">
                  <span className="text">
                    <span>{t('page.checkout.time_constraints.select_pickup_date_and_time')}</span>
                  </span>
                </div>

                {
                  !urgentStatus && (
                    <div className="checkbox_list">
                      <ul>
                        <li>
                          <p className="label">{t('page.checkout.time_constraints.date')}</p>
                          <DatePicker
                            className="date"
                            block format="DD.MM.YYYY"
                            placeholder={t('pick_a_date')}
                            ranges={[]}
                            cleanable={false}
                            locale={i18n.language === 'heb' ? localesHeb : locales}
                            value={constraintsData?.date}
                            onChange={(val) => onHandleDate(val)}
                            onOpen={() => i18n.language === 'heb' && changeCalendarUi()}
                            name="date"
                            onExited={onDatePickerValidate}
                            disabledDate={(date) => isBefore(date, new Date())}
                          />
                          {constraintsErrors.date && <p className="registration_error">{constraintsErrors.date}</p>}
                        </li>
                        <li>
                          <p className="label">{t('page.checkout.time_constraints.time')}</p>
                          <Select
                            className={classNames('time-range', !constraintsData?.time?.label && 'placeholder-time')}
                            variant='standard'
                            IconComponent = {AccessTimeIcon}
                            labelId="demo-controlled-open-select-label"
                            id="time"
                            value={constraintsData?.time?.label}
                            label="Age"
                            disableUnderline
                            required
                            onChange={onHandleTime}
                            displayEmpty
                            renderValue={(value) => (value ? value : t('page.checkout.time_constraints.pick_a_time'))}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: 'time-range-item',
                              },
                            }}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontFamily: 'Shapirit, sans-serif',
                              fontWeight: '400',
                              fontStyle: 'norma',
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {timeRanges.map((item) => (
                              <MenuItem
                                value={item?.label}
                                key={item?.id}
                                style={{
                                  fontFamily: 'Shapirit, sans-serif',
                                  fontWeight: '400',
                                  fontStyle: 'norma',
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                }}
                              >{item?.label}</MenuItem>
                            ))}
                          </Select>
                          {constraintsErrors.time && <p className="registration_error">{constraintsErrors.time}</p>}
                        </li>
                      </ul>
                    </div>
                  )
                }

              </div>
            </FormGroup>
            <div className="warning_box">
              <p>
                {t('page.checkout.time_constraints.range_of_delivery_info')}
              </p>
            </div>
            <FormGroup>
              <Button
                appearance="primary"
                onClick={addConstraintsDataAndContinue}
                disabled={urgentStatus ?
                  !urgentStatus :
                  (constraintsErrors?.time?.length !== 0 || constraintsErrors?.date?.length !== 0)}
              >
                <span>{t('page.checkout.continue')}</span>
              </Button>
            </FormGroup>
          </Form>
        </Panel>

        {!isCheckedStep ?
          <Panel bordered className="panel_edit opacity_50">
            <div className="flex-container info-tooltip">
              <div className="flex-item">
                <p className="header">{t('page.checkout.project.project')} <span className="light">{t('page.checkout.step_2')}</span></p>
              </div>
              <div className="flex-item">
                <InfoButton />
              </div>
            </div>
          </Panel> :
          <Panel bordered className="panel_edit">
            <div className="flex-container">
              <div className="flex-item">
                <p className="header">{t('page.checkout.project.project')} <span className="light">{t('page.checkout.step_2')}</span></p>
                <p>
                  {projectData?.name}, {projectData?.street} {projectDataApt}{projectDataCity}
                </p>
              </div>
              <div className="flex-item item-edit">
                <p className="edit"><a href="#" onClick={() => {
                  dispatch(toggleIsTimeConstraintsShown(false));
                  dispatch(toggleIsProjectsShown(true));
                  dispatch(toggleIsAdditionalServicesShown(false));
                }}><span>{t('page.checkout.edit')}</span></a></p>
              </div>
            </div>
          </Panel>

        }

        {!isCheckedStep ?
          <Panel bordered className="panel_edit opacity_50">
            <div className="flex-container info-tooltip">
              <div className="flex-item">
                <p className="header">{t('page.checkout.additional_services.additional_services')} <span className="light">{t('page.checkout.step_3')}</span></p>
              </div>
              <div className="flex-item">
                <InfoButton />
              </div>
            </div>
          </Panel> :
          <Panel bordered className="panel_edit">
            <div className="flex-container">
              <div className="flex-item">
                <p className="header">{t('page.checkout.additional_services.additional_services')} <span className="light">{t('page.checkout.step_3')}</span></p>
              </div>
              <div className="flex-item item-edit">
                <p className="edit"><a href="#" onClick={() => {
                  dispatch(toggleIsTimeConstraintsShown(false));
                  dispatch(toggleIsProjectsShown(false));
                  dispatch(toggleIsAdditionalServicesShown(true));
                }}><span>{t('page.checkout.edit')}</span></a></p>
              </div>
            </div>
          </Panel>
        }

      </FlexboxGrid.Item>
    </>
  );
}

TimeConstraints.propTypes = {
  setIsConfirmOrderShown: PropTypes.func.isRequired,
};

export default TimeConstraints;
