import React, {useEffect} from 'react';
import {
  Switch,
  Route,
  // useLocation,
} from 'react-router-dom';
import Home from '../pages/Home';

import ProductNotFound from '../pages/ProductNotFound';
import ProductDetails from '../pages/ProductDetails';
import ProductList from '../pages/ProductList';
import ProductCatalog from '../pages/ProductCatalog';
import Cart from '../pages/Cart/Cart';
import {
  CustomerCompanyInfo,
  SupplierCompanyInfo,
  AddressInfo,
  ManagerInfo,
  CustomerSucceededRegistration,
  SupplierSucceededRegistration,
} from './Auth/CreateProfile';

import OrderListItem from '../pages/OrderListItemExample';
import OrderListItemNew from '../pages/OrderListItemNew';

import OrderDelivered from '../pages/OrderDelivered';
import OrderCompleted from '../pages/OrderCompleted';

import NotFound from '../pages/NotFound';
import LostAccessToPhoneNumber from './Auth/SignIn/LostAccessToPhoneNumber';
import ConnectBuyNow from '../pages/ConnectBuyNow';

import OfferConfirmationStep1 from '../pages/OfferConfirmation/OfferConfirmationStep1';
import OfferConfirmationStep3 from '../pages/OfferConfirmation/OfferConfirmationStep3';

import Search from '../pages/Search';

import TermsAndConditionsForSuppliers from '../pages/TermsAndConditionsForSuppliers';
import TermsAndConditionsForCustomers from '../pages/TermsAndConditionsForCustomers';
import PrivacyPolicy from '../pages/PrivacyPolicy';

import PrivateRoute from './PrivateRoute/PrivateRoute';
import PrivateRouteNotSupplier from './PrivateRoute/PrivateRouteNotSupplier';
import PrivateRouteNotAuthorized from './PrivateRoute/PrivateRouteNotAuthorized';

import routes from '../routes';
import LandingPage from '../pages/LandingPage';

import ReactGA from 'react-ga';
const TRACKING_ID = 'G-KB353YFLGZ'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function SiteContent() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="main_container">
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/examples_page" component={Home} />
        <PrivateRouteNotSupplier exact path="/product_catalog/:parentCategory*/:categoryName/product/:productId" component={ProductDetails} />
        <PrivateRouteNotSupplier exact path="/product_catalog/:parentCategory*/:categoryName/product" component={ProductList} />
        <PrivateRouteNotSupplier exact path="/product_catalog/:parentCategory*/:categoryName" component={ProductCatalog} />
        <PrivateRouteNotSupplier exact path="/product_catalog" component={ProductCatalog} />
        <PrivateRouteNotSupplier exact path="/cart" component={Cart} />
        <Route exact path="/product_not_found" component={ProductNotFound} />

        {/* PRIVATE ROUTS */}
        {routes.map((route, item) => <PrivateRoute key={item} {...route} />)}


        <Route exact path="/customer_company_info" component={CustomerCompanyInfo} />
        <Route exact path="/supplier_company_info" component={SupplierCompanyInfo} />
        <Route exact path="/address_info" component={AddressInfo} />
        <Route exact path="/manager_info" component={ManagerInfo} />

        <Route exact path="/supplier_succeeded_registration" component={SupplierSucceededRegistration} />
        <Route exact path="/customer_succeeded_registration" component={CustomerSucceededRegistration} />

        <Route exact path="/order_list_item" component={OrderListItem} />
        <Route exact path="/order_list_item_new" component={OrderListItemNew} />

        <Route exact path="/order_delivered" component={OrderDelivered} />
        <Route exact path="/order_completed" component={OrderCompleted} />

        <PrivateRouteNotSupplier exact path="/connect_buy_now" component={ConnectBuyNow} />

        <PrivateRouteNotAuthorized exact path="/lost_access_phone_number" component={LostAccessToPhoneNumber} />

        <Route exact path="/offer_confirmation_step_1" component={OfferConfirmationStep1} />

        <Route exact path="/offer_confirmation_step_3" component={OfferConfirmationStep3} />


        <Route exact path="/search/:searchPhrase" component={Search} />
        <Route exact path="/search" component={Search} />

        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-and-conditions-for-suppliers" component={TermsAndConditionsForSuppliers} />
        <Route exact path="/terms-and-conditions-for-customers" component={TermsAndConditionsForCustomers} />
        <Route exact path="/404" component={NotFound} />
        <Route exact path="/*" component={NotFound} />
      </Switch>
    </div>
  );
}

export default SiteContent;
