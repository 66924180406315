import React, { useState, useEffect } from 'react';
import {
  Breadcrumb,
  Icon,
  Button,
  Panel,
  PanelGroup,
  FlexboxGrid,
  Col,
  Form,
  Loader,
} from 'rsuite';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NoImage from '../../assets/img/no_image.png';

import {
  getOfferItemDetails,
} from '../../redux/actions/offer';
import {
  getCreditInfo,
  payForOfferCredit,
  resetCreditInfo,
  getOfferPaymentInfo,
} from '../../redux/actions/payment';

import { useDispatch, useSelector } from 'react-redux';
import { formatPrice } from '../../utils';

const ListOrdered = ({ orderedProducts }) => (
  <ul>
    {orderedProducts.map((product, index) => (
      <li key={index}>
        <OrderedProduct product={product} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  orderedProducts: PropTypes.instanceOf(Array).isRequired,
};

const OrderedProduct = ({ product }) => {
  const {
    catalogNumber,
    productName,
    totalPrice,
    price,
    orderQuantity,
    quantity,
    pictureFileName,
    outOfStock,
    alternativeProduct,
    totalMinPrice,
    totalMaxPrice,
    minPrice,
    maxPrice,
  } = product;
  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${outOfStock ? ' opacity' : ''}`}>
        <div>
          <div className="products-img">
            <img src={pictureFileName || NoImage} alt={productName} />
            {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
          </div>
          <p className="quantity">
            <span>
              x
              {quantity ? quantity : orderQuantity}
            </span>
          </p>
        </div>
        <div>
          <p><span className="product_number">{catalogNumber}</span></p>
          <p className="description">{productName}</p>
          {(!outOfStock && price) && (
            <>
              <p className="price-all"><span className="ltr">₪ {formatPrice(totalPrice || 0)}</span></p>
              <p><span className="ltr">₪ {formatPrice(price)}/{t('page.offers_list.item')}</span></p>
            </>
          )}
          {(totalMinPrice !== undefined || totalMaxPrice !== undefined) && (
            <>
              <p className="price-all"><span className="ltr">₪ {formatPrice(totalMinPrice || 0)} - ₪ {formatPrice(totalMaxPrice || 0)}</span></p>
              <p><span className="ltr">₪ {formatPrice(minPrice || 0)} - ₪ {formatPrice(maxPrice || 0)}/{t('page.offers_list.item')}</span></p>
            </>
          )}
        </div>
      </div>
      {quantity && quantity < orderQuantity && (
        <div className="warning_box">
          <p>{t('page.order.order_list_item.pay_order.info.this_product_available')}</p>
          <p><span className="red">{quantity}</span>/{orderQuantity} {t('page.order.order_list_item.pay_order.info.items_are_available')}</p>
        </div>
      )}
      {alternativeProduct && (
        <div className="replacement">
          <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
          <OrderedProduct product={alternativeProduct} />
        </div>
      )}
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

function OfferItem({
  offer,
  total,
  vat,
  supplierName,
  onSubmitOffer,
  isSendOfferButtonDisabled,
  isSendOfferButtonLoading,
}) {
  const [viewProducts, setViewProducts] = useState(false);
  const { t } = useTranslation();
  const productsView = viewProducts ? t('page.offer.hide_products') : t('page.offer.view_products');
  const deliveryList = offer?.offers.map(({deliveryDetail}) => deliveryDetail);
  const deliveryWithDelay = offer?.offers.map(({deliveryDetail}) => deliveryDetail.withDelay);
  const countOfProductsArray = offer?.offers.map((el) => el.products?.length);
  const countOfProducts = countOfProductsArray.reduce(function(a, b) {
    return a + b;
  }, 0);

  return (
    <Panel bordered className={offer?.isBest ? 'border_red' : ''}>

      {offer?.offers[0]?.isBest && (
        <div className="best_offer">
          <span>{t('page.offers_list.best_offer')}</span>
        </div>
      )}

      <div className="supplier_name">
        <p className="header">{supplierName}</p>
        {deliveryList.map((item, index) => {
          const deliveryDate = item?.deliveryDate?.split('-');
          const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
          const deliveryTimeRange = item?.deliveryTimeRange;

          const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
            `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
            t('page.supplier_orders.during_the_working_day');

          const dateTime = `${deliveryDateData} ${deliveryTime}`;
          const deliveryText = item?.withDelay ? t('page.offers_list.delivery_with_delay.delivery_with_delay') :
            t('page.offers_list.delivery_expected');

          return (
            <p className="date" key={index}>{offer.offers.length !== 1 && `${t('page.order.order_list_item.delivery')} ${index + 1}:`} {
              deliveryTime ? (
                <>
                  <spna>{deliveryText}</spna>
                  <br/>
                  <spna>{dateTime}</spna>
                </>
              ) :
                `${t('page.offers_list.delivery_time')} ${t('page.offers_list.not_specified')}`
            }</p>
          );
        })}
      </div>

      <div className="tag_box">
        {deliveryWithDelay.find((e) => e === true) && <p><span className="with_delay">{t('page.offers_list.with_delay')}</span></p>}
        {offer.offers.length === 1 && offer.offers[0]?.isPartial && <p><span className="partial_order">{t('page.offers_list.partial_order')}</span></p>}
      </div>

      <div className="grey_box">
        <p>{t('page.offer.order_filling')}</p>
        <p className="price"><span>{t('page.order.of_products',
          {
            num1: countOfProducts,
            num2: offer?.offers[0]?.products?.length + offer?.offers[0]?.outOfStockProducts?.length,
          })}</span></p>
        <div className="toggle_hide_view">
          <Button appearance="link" onClick={() => setViewProducts(!viewProducts)}>
            <span className="flex">
              <span>{productsView}</span>
              <span>
                <i className={`rs-icon ${viewProducts ? 'open' : 'close'}`} />
              </span>
            </span>
          </Button>
        </div>
      </div>

      <div className="price_box">
        <ul>
          <li><p>{t('page.offer.price_offer')}</p></li>
          <li><p><span className="ltr">₪ {formatPrice(offer?.offerPrice || 0)}</span></p></li>
        </ul>

        <ul className="discount_price">
          <li><p><span className="flex"><span>{t('page.offer.discount')}</span><span className="percentage">-{offer?.discountPercentage}%</span></span></p></li>
          <li><p><span className="ltr">{!!offer?.discount && '-'}₪ {formatPrice(offer?.discount || 0)}</span></p></li>
        </ul>

        <ul>
          <li><p>{t('page.offer.delivery_cost')}</p></li>
          <li><p><span className="ltr">₪ {formatPrice(offer?.deliveryCost)}</span></p></li>
        </ul>

        {/* <ul>
          <li><p>{t('page.offer.unloading_cost')}</p></li>
          <li><p><span className="ltr">₪ 100</span></p></li>
        </ul> */}

        <ul>
          <li><p>{t('page.offer.subtotal_without_VAT')}</p></li>
          <li><p><span className="ltr">₪ {formatPrice(offer?.orderTotal || 0)}</span></p></li>
        </ul>
        <hr/>
        <ul>
          <li><p>{t('page.offer.vat')}</p></li>
          <li><p><span className="ltr">₪ {vat}</span></p></li>
        </ul>

        <ul className="total">
          <li><p>{t('page.offer.order_total')}</p></li>
          <li><p><span className="ltr">₪ {formatPrice(total)}</span></p></li>
        </ul>
      </div>

      <Button disabled={isSendOfferButtonDisabled} loading={isSendOfferButtonLoading} appearance="primary" onClick={onSubmitOffer}><span><span className="ltr">{t('page.offer.pay')}</span></span></Button>

      {viewProducts && (
        <div className="offer-box">

          <PanelGroup accordion>
            {offer?.offers.map((item, index) => {
              const date = item.deliveryDetail.deliveryDate?.split('-');
              const deliveryTime = !item.deliveryDetail.deliveryTimeRange?.full ?
                <span>
                  {item.deliveryDetail.deliveryTimeRange?.start?.slice(0, 5)}-
                  {item.deliveryDetail.deliveryTimeRange?.end?.slice(0, 5)}</span> :
                'during the working day';

              return (
                <React.Fragment key={index}>
                  {offer.offers.length !== 1 &&
                    <>
                      <p>{t('page.order.order_list_item.delivery')} {index + 1}: {''}
                        <span>{date[2]}/{date[1]}/{date[0]}, {deliveryTime}</span>
                      </p>
                      <br />
                    </>
                  }
                  {item?.inStockProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.in_stock')} <span className="count">({item?.inStockProducts.length})</span></p>} defaultExpanded>

                    <div className="products-list">
                      <ListOrdered orderedProducts={item?.inStockProducts} />
                    </div>

                  </Panel>)}
                  {item?.alternativeProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.pay_order.alternative_products')} <span className="count">({item?.alternativeProducts.length})</span></p>} defaultExpanded>

                    <div className="products-list">
                      <ListOrdered orderedProducts={item?.alternativeProducts} />
                    </div>

                  </Panel>)}
                  {item?.outOfStockProducts.length > 0 && (<Panel header={<p>{t('page.order.order_list_item.pay_order.out_of_stock')} <span className="count">({item?.outOfStockProducts.length})</span></p>} defaultExpanded>

                    <div className="products-list">
                      <ListOrdered orderedProducts={item?.outOfStockProducts} />
                    </div>

                  </Panel>)}
                </React.Fragment>
              );
            })}
          </PanelGroup>

        </div>
      )}
    </Panel>
  );
}

OfferItem.propTypes = {
  supplierName: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired,
  total: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  onSubmitOffer: PropTypes.func.isRequired,
  isSendOfferButtonDisabled: PropTypes.bool.isRequired,
  isSendOfferButtonLoading: PropTypes.bool.isRequired,
};

function OfferConfirmation() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { orderId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const supplierNumber = location.search.replace('?', '');

  const [optionName, setOptionName] = useState('debit');
  const [isSendOfferButtonDisabled, setIsSendOfferButtonDisabled] = useState(false);
  const [isSendOfferButtonLoading, setIsSendOfferButtonLoading] = useState(false);
  const { item, isFetching } = useSelector((state) => state.offer);
  const { creditInfo } = useSelector((state) => state.payment);
  const { offerGroup, project, total, vat } = item;


  // Check values
  const checkIsOptionBuy = optionName === 'buy';
  const checkIsOptionDebit = optionName === 'debit';
  const checkIsCreadit = Object.keys(creditInfo).length === 0;
  const checkIsCreaditLimit = creditInfo?.available ? Number(creditInfo?.available) <= Number(total) : null;
  const checkOptionName = () => {
    if (checkIsOptionBuy) {
      if (checkIsCreaditLimit || !creditInfo?.active) {
        setIsSendOfferButtonDisabled(true);
      }
    }
    if (checkIsOptionDebit) {
      setIsSendOfferButtonDisabled(false);
    }
  };


  useEffect(() => {
    setIsSendOfferButtonLoading(false);
    setIsSendOfferButtonDisabled(false);
    dispatch(getOfferItemDetails(orderId));
    dispatch(getCreditInfo());
    return () => {
      dispatch(resetCreditInfo());
    };
  }, []);


  useEffect(() => {
    checkOptionName();
  }, [optionName]);


  const onSubmitOffer = () => {
    setIsSendOfferButtonLoading(true);
    if (checkIsOptionBuy) {
      const offersIdList = offerGroup?.offers.map(({offerId}) => offerId).join();
      dispatch(payForOfferCredit(offersIdList, () => {
        history.push(`/customer/successfully_accept/${item?.orderId}`);
        setIsSendOfferButtonLoading(false);
      }));
    }
    if (checkIsOptionDebit) {
      dispatch(getOfferPaymentInfo(orderId, (data) => {
        window.location.assign(data);
        setIsSendOfferButtonLoading(false);
      }));
    }
  };


  return (
    <>
      {isFetching || Object.keys(item).length === 0 ? (
        <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
      ) : (
        <div className="checkout offer_confirmation">
          <div className="container">

            <div className="breadcrumb-list">
              <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                <Breadcrumb.Item componentClass={Link} to="/customer/orders">
                  <span>{t('page.order.orders')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item componentClass={Link} to={`/customer/offers_list/${item?.orderId}`}>
                  <span>{t('page.offers_list.offers_for_order')} {item?.orderId}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <span>{t('page.offer.contact_information.offer_confirmation')}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <Panel bordered className="offer_number">
              <h1 style={{marginBottom: '10px'}}>{t('page.offer.contact_information.offer_confirmation')}</h1>
              <p style={{color: '#E02443'}}>
                <span> {t('page.offers_list.possible_change_date')} </span>
                {offerGroup.offers[0] && Object.keys(offerGroup).length !== 0 && <span>
                  {new Date(offerGroup.offers[0]?.validDate)?.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}{', '}
                  {(offerGroup.offers[0]?.validTime)?.slice(0, -3)}
                </span>}
              </p>
              <span> {t('page.offers_list.possible_change')}</span>
            </Panel>

            <div className="checkout-flex-col">

              <FlexboxGrid className="flex-420-col">
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>
                  <Panel bordered className="panel_edit payment">
                    <Form fluid>

                      <p className="header m20">
                        <span className="step_flex">
                          <span>{t('page.offer.payment.payment')}</span>
                          {/* <span className="light">{t('page.offer.payment.step_2')}</span> */}
                        </span>

                      </p>
                      <div className="grey_box">
                        <p>
                          {t('page.offer.payment.before_payment')}
                          <span className='ltr'> 072 397-0146</span>
                        </p>
                      </div>

                      <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} colspan={24} md={11}>
                          <p className="payment_info">{t('page.order.project')}</p>
                          <p dir="auto" style={{textAlign: i18n.language === 'heb' ? 'right' : 'left'}}>
                            <span>{`"${project?.name}"`},</span>
                            <span>{`${project?.city ? ` ${project?.city}` : ''}`},</span>
                            <span>{` ${i18n.language === 'heb' ? 'רח’' : 'st.'} ${project?.street} ${project?.apt ? project?.apt : '-'}`}</span>
                          </p>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} colspan={24} md={13}>
                          <p className="payment_info">{project?.contactFullName}</p>
                          <p>{project?.contactPhone}</p>
                        </FlexboxGrid.Item>
                      </FlexboxGrid>

                      <div className="switch_buttons">
                        <div>
                          <Button className={`${checkIsOptionDebit ? 'active' : ''}`} appearance="ghost" block onClick={() => setOptionName('debit')}><span>{t('page.offer.payment.debit_credit_card.debit_or_credit_card')}</span></Button>
                        </div>
                        <div>
                          <Button className={`${checkIsOptionBuy ? 'active' : ''}`} appearance="ghost" block onClick={() => setOptionName('buy')}><span>{t('page.offer.payment.buy_now.buy_now_pay_later')}</span></Button>
                        </div>
                      </div>

                      {checkIsOptionDebit && (
                        <div className="new_tab_option">
                          <div className="warning_box">
                            <div>
                              <p>{t('page.offer.payment.if_you_want_to_pay')}</p>
                            </div>
                          </div>
                        </div>
                      )}

                      {checkIsOptionBuy && (
                        <div className="new_tab_option">

                          {!checkIsCreadit && creditInfo?.active ?
                            <>
                              <FlexboxGrid>
                                <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>

                                  <div className="grey_info_box">
                                    <p>{t('page.offer.payment.buy_now.the_order_should', {days: creditInfo?.term})}</p>
                                  </div>

                                </FlexboxGrid.Item>
                              </FlexboxGrid>
                              <FlexboxGrid>
                                <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>

                                  <div className="offers_list">
                                    <div className="price_box">
                                      <ul>
                                        <li><p>{t('page.offer.payment.buy_now.credit_limit')}</p></li>
                                        <li><p><span className="ltr">₪ {formatPrice(creditInfo?.limit)}</span></p></li>
                                      </ul>
                                      <ul>
                                        <li><p>{t('page.offer.payment.buy_now.available_limit')}</p></li>
                                        <li><p><span className="ltr">₪ {formatPrice(creditInfo?.available)}</span></p></li>
                                      </ul>
                                    </div>
                                  </div>
                                  {checkIsCreaditLimit && <div className="warning_box">
                                    <p>{t('page.offer.payment.buy_now.your_available_limit')}</p>
                                    <p>{t('page.offer.payment.buy_now.buy_now_pay')}</p>
                                    <p style={{maxWidth: 'inherit'}}>
                                      {t('page.offer.payment.buy_now.you_may_pay')}
                                    </p>
                                  </div>}

                                  {/* <div className="warning_box">
                                    <p><span className="strong">{t('page.offer.payment.buy_now.your_credit_limit_has_overdue_debt')}</span></p>
                                    <p>
                                      {t('page.offer.payment.buy_now.text_credit_limit', {days: creditInfo?.term})}
                                    </p>
                                  </div> */}

                                </FlexboxGrid.Item>
                              </FlexboxGrid>
                            </> : <div className="warning_box">
                              <p><span className="strong">{t('page.offer.payment.buy_now.your_account_is_not_eligible_for_BNPL')}</span></p>
                              <p>
                                {t('page.offer.payment.buy_now.you_may_submit_a')} {' '}
                                <Link to="/connect_buy_now">
                                  {t('page.offer.payment.buy_now.request_for_credit_approval')}
                                </Link> {' '}
                                {t('page.offer.payment.buy_now.text_BNPL')}
                              </p>
                            </div>
                          }

                        </div>
                      )}

                    </Form>
                  </Panel>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                  <div className="offers_list">
                    {offerGroup && Object.keys(offerGroup).length !== 0 && <OfferItem offer={offerGroup}
                      total={total}
                      vat={vat}
                      supplierName={`${t('page.offers_list.supplier')} ${supplierNumber}`}
                      onSubmitOffer={onSubmitOffer}
                      isSendOfferButtonDisabled={isSendOfferButtonDisabled}
                      isSendOfferButtonLoading={isSendOfferButtonLoading} />}
                  </div>
                </FlexboxGrid.Item>
              </FlexboxGrid>

            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default OfferConfirmation;
