import { handleActions } from 'redux-actions';
import actions from '../actions/payment';

export const initialState = {
  isFetching: false,
  error: '',
  creditInfo: {},
};

export default handleActions(
  {
    // get creditInfo for customer
    [actions.getCreditInfo.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
    }),

    [actions.getCreditInfo.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      creditInfo: payload.creditInfo,
    }),

    [actions.getCreditInfo.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // post payForOfferCredit
    [actions.payForOfferCredit.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
    }),

    [actions.payForOfferCredit.success]: (state,) => ({
      ...state,
      isFetching: false,
    }),

    [actions.payForOfferCredit.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    // reset creditInfo
    [actions.resetCreditInfo.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
      creditInfo: {},
    }),

    [actions.resetCreditInfo.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      creditInfo: payload.creditInfo,
    }),

    [actions.resetCreditInfo.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

    [actions.getOfferPaymentInfo.request]: (state) => ({
      ...state,
      isFetching: true,
      error: '',
    }),

    [actions.getOfferPaymentInfo.success]: (state, { payload }) => ({
      ...state,
      isFetching: false,
    }),

    [actions.getOfferPaymentInfo.error]: (state, { payload }) => ({
      ...state,
      isFetching: false,
      error: payload.error,
    }),

  },
  initialState
);
