import React from 'react';
import { Button } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function NotFound() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const checkSupplier = user?.entityType === 'SUPPLIER'|| user?.entityType === 'SUPPLIER_CANDIDATE';

  return (
    <div className="product_not_found">
      <div className="container">
        <div className="product_not_found_box">
          <div className="banner banner_404">
            <span />
          </div>
          <p className="header">404</p>
          <p>
            {t('page.page_not_found.could_not_find_page')}
          </p>
          <div className="button">
            <Button block type="button" href={checkSupplier ? '/supplier/orders/new' : '/product_catalog'} appearance="primary">
              <span>{checkSupplier ? t('header.orders') : t('page.page_not_found.go_to_products_catalog')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
