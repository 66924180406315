import { post, get } from './';

const createProject = (data) => post('/project', data);
// const getProjects = (query) => get('/project', query);
const getProjects = () => get('/project');

const projectApi = {
  createProject,
  getProjects,
};

export default projectApi;
