export const signIn = (actions) => ({
  // set sign in data
  [actions.setSignInData.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    signInData: state.signInData,
  }),

  [actions.setSignInData.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    signInData: payload.signInData,
  }),

  [actions.setSignInData.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // reset sign in data
  [actions.resetSignInData.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    signInData: {},
  }),

  [actions.resetSignInData.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    signInData: payload.signInData,
  }),

  [actions.resetSignInData.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // sign in
  [actions.signIn.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    items: {},
    goToNextStep: false,
  }),

  [actions.signIn.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    goToNextStep: true,
  }),

  [actions.signIn.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
    goToNextStep: false,
  }),

  // set current user
  [actions.setCurrentUser.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    items: {},
  }),

  [actions.setCurrentUser.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    user: payload.user,
  }),

  [actions.setCurrentUser.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // reset sign in error
  [actions.resetSignInError]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: '',
  }),

  // put cart
  [actions.putCartItemToCustomer.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    user: state.user,
  }),

  [actions.putCartItemToCustomer.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    user: payload.user,
  }),

  [actions.putCartItemToCustomer.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),

  // postLoginRestore
  [actions.postLoginRestore.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
  }),

  [actions.postLoginRestore.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    user: {},
  }),

  [actions.postLoginRestore.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),
});
