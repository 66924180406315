import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const ModalLimitedFunctionality = ({ showModalLimitedFunctionality, setShowModalLimitedFunctionality }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handlerModalClose = () => {
    setShowModalLimitedFunctionality(!showModalLimitedFunctionality);
    history.push('/supplier/orders/new');
  };
  return (
    <div>
      <Modal
        className="lost-access-phone-confirm-request"
        overflow={false}
        show={showModalLimitedFunctionality}
        onHide={handlerModalClose}
        keyboard={true}
        autoFocus={true}>
        <Modal.Header>
          <p>{t('auth.sign_in.modalLimitedFunctionality_title')}</p>
        </Modal.Header>
        <Modal.Body >
          <p>{t('auth.sign_in.modalLimitedFunctionality_descr')}</p>
          <div>
            <Button appearance="primary" onClick={handlerModalClose}>
              <span>{t('auth.sign_in.modalLimitedFunctionality_button')}</span>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

ModalLimitedFunctionality.propTypes = {
  showModalLimitedFunctionality: PropTypes.bool.isRequired,
  setShowModalLimitedFunctionality: PropTypes.func.isRequired,
};

export default ModalLimitedFunctionality;
