import React from 'react';
import {
  Panel,
  PanelGroup,
} from 'rsuite';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NoImage from '../../assets/img/no_image.png';
import { formatPrice } from '../../utils/';

const OrderedProduct = ({ product }) => {
  const {
    catalogNumber,
    productName,
    totalPrice,
    supplierPrice,
    quantity,
    picture,
    outOfStock,
    alternativeProduct,
    draftProduct,
    orderQuantity,
    supplierNumber,
  } = product;

  const isNotAvailableFull = quantity < orderQuantity;

  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${outOfStock ? ' opacity' : ''}`}>
        <div>
          <div className="products-img">
            <img src={picture ? picture : NoImage} alt="product_png" />
            {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
          </div>
          <p className="quantity">
            <span>
              x
              {orderQuantity}
            </span>
          </p>
        </div>
        <div>
          <div className='flex_box_p'>
            <p><span className="product_number">{catalogNumber}</span></p>
            {supplierNumber && <p><span className="ltr">{supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
          </div>
          <p className="description">{productName}</p>
          {!outOfStock && (
            <>
              <p className="price-all"><span className="ltr">₪ {totalPrice ? formatPrice(totalPrice) : 0}</span></p>
              <p><span className="ltr">₪ {supplierPrice ? formatPrice(supplierPrice) : 0}/{t('page.order.item')}</span></p>
            </>
          )}
        </div>
      </div>
      {isNotAvailableFull && !alternativeProduct && (
        <div className="warning_box">
          <p>{t('page.order.order_list_item.pay_order.info.this_product_available')}</p>
          <p><span className="red">{quantity}</span>/{orderQuantity} {t('page.order.order_list_item.pay_order.info.items_are_available')}</p>
        </div>
      )}
      {alternativeProduct && (
        <div className="replacement">
          <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
          <OrderedProductAlternative product={alternativeProduct} />
        </div>
      )}
      {draftProduct && (
        <div className="replacement">
          <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
          <OrderedProductDraft product={draftProduct} />
        </div>
      )}
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

const OrderedProductDraft = ({ product }) => {
  const {
    catalogNumber,
    totalPrice,
    name,
    quantity,
    pricePerUnit,
    outOfStock,
  } = product;

  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${outOfStock ? ' opacity' : ''}`}>
        <div>
          <div className="products-img">
            <img src={NoImage} alt="product_png" />
            {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
          </div>
          <p className="quantity">
            <span>
              x
              {quantity}
            </span>
          </p>
        </div>
        <div>
          <div className='flex_box_p'>
            {catalogNumber && <p><span className="ltr">{catalogNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
          </div>
          <p className="description">{name}</p>
          {!outOfStock && (
            <>
              <p className="price-all"><span className="ltr">₪ {totalPrice ? formatPrice(totalPrice) : 0}</span></p>
              <p><span className="ltr">₪ {pricePerUnit ? formatPrice(pricePerUnit) : 0}/{t('page.order.item')}</span></p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

OrderedProductDraft.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

const OrderedProductAlternative = ({ product }) => {
  const {
    catalogNumber,
    productName,
    totalPrice,
    supplierPrice,
    quantity,
    picture,
    outOfStock,
    supplierNumber,
    orderQuantity,
  } = product;

  const isNotAvailableFull = quantity < orderQuantity;

  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${outOfStock ? ' opacity' : ''}`}>
        <div>
          <div className="products-img">
            <img src={picture ? picture : NoImage} alt="product_png" />
            {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
          </div>
          <p className="quantity">
            <span>
              x
              {quantity}
            </span>
          </p>
        </div>
        <div>
          <div className='flex_box_p'>
            <p><span className="product_number">{catalogNumber}</span></p>
            {supplierNumber && <p><span className="ltr">{supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
          </div>
          <p className="description">{productName}</p>
          {!outOfStock && (
            <>
              <p className="price-all"><span className="ltr">₪ {totalPrice ? formatPrice(totalPrice) : 0}</span></p>
              <p><span className="ltr">₪ {supplierPrice ? formatPrice(supplierPrice) : 0}/{t('page.order.item')}</span></p>
            </>
          )}
        </div>
      </div>
      {isNotAvailableFull && (
        <div className="warning_box">
          <p>{t('page.order.order_list_item.pay_order.info.this_product_available')}</p>
          <p><span className="red">{quantity}</span>/{orderQuantity} {t('page.order.order_list_item.pay_order.info.items_are_available')}</p>
        </div>
      )}
    </>
  );
};

OrderedProductAlternative.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

function ListOrdered({ orderedProducts }) {
  const productInStock = [];
  const productOutOfStock = [];
  const productWithAlternatives = [];

  orderedProducts.forEach((product) => {
    if (!product.outOfStock) {
      productInStock.push(product);
    } else if (product.alternativeProduct || product.draftProduct) {
      productWithAlternatives.push(product);
    } else if (
      product.outOfStock &&
        (!product.alternativeProduct || !product.draftProduct)
    ) {
      productOutOfStock.push(product);
    }
  });
  const { t } = useTranslation();
  return (
    <div className="offer-box">
      <PanelGroup accordion>
        {!!productInStock.length && (
          <Panel header={<p>{t('page.order.order_list_item.in_stock')} <span className="count">({productInStock.length})</span></p>} defaultExpanded>
            <div className="products-list">
              <ul>
                {productInStock.map((product, index) => (
                  <li key={index}>
                    <OrderedProduct product={product}/>
                  </li>
                ))}
              </ul>
            </div>
          </Panel>
        )}
        {!!productWithAlternatives.length && (
          <Panel header={<p>{t('page.order.order_list_item.pay_order.alternative_products')} <span className="count">({productWithAlternatives.length})</span></p>} defaultExpanded>
            <div className="products-list">
              <ul>
                {productWithAlternatives.map((product, index) => (
                  <li key={index}>
                    <OrderedProduct product={product}/>
                  </li>
                ))}
              </ul>
            </div>
          </Panel>
        )}
        {!!productOutOfStock.length && (
          <Panel header={<p>{t('page.order.order_list_item.pay_order.out_of_stock')} <span className="count">({productOutOfStock.length})</span></p>} defaultExpanded>
            <div className="products-list">
              <ul>
                {productOutOfStock.map((product, index) => (
                  <li key={index}>
                    <OrderedProduct product={product}/>
                  </li>
                ))}
              </ul>
            </div>
          </Panel>
        )}
      </PanelGroup>
    </div>
  );
};

ListOrdered.propTypes = {
  orderedProducts: PropTypes.instanceOf(Array).isRequired,
};


export default ListOrdered;
