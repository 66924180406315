export const logout = (actions) => ({
  [actions.logout.request]: (state) => ({
    ...state,
    isFetching: true,
    error: '',
    user: state.user,
  }),

  [actions.logout.success]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    user: {},
  }),

  [actions.logout.error]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload.error,
  }),
});
