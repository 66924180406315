import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const PrivateRouteNotAuthorized = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={
        (props) => (!user?.entityType && user?.status !== 'UNFINISHED') ? (
          <Component {...props} />
        ) :
          (<Redirect to={{
            pathname: '/404',
            state: { referrer: location.pathname },
          }} />
          )}
    />
  );
};

PrivateRouteNotAuthorized.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
};

export default PrivateRouteNotAuthorized;
