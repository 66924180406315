import React from 'react';
import { Button } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SupplierSucceededRegistration = () => {
  const history = useHistory();
  const goToCatalog = () => history.push('/supplier/orders/new?login');
  const { t } = useTranslation();
  return (
    <div className="registration">
      <div className="registration_box success_box">
        <div className="success_icon"></div>
        <div className="registration-title">
          <p>
            <span>{t('auth.your_registration_request')}</span>
            <span>{t('auth.has_been_successfully_sent_for_processing')}</span>
          </p>
        </div>
        <p>{t('auth.sign_in.konebone_representative_will_contact_you')}</p>
        <div className="buttons_list">
          <Button appearance="primary" onClick={goToCatalog}><span>{t('header.orders')}</span></Button>
        </div>
      </div>
    </div>
  );
};

export default SupplierSucceededRegistration;
