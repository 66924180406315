import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rsuite';
import { OrderedProduct } from './Order/OrderedProduct';
import { useTranslation } from 'react-i18next';

function AlertAddedToCart({ history, onCartItemDelete, product, closeAllNotifications, cartItems, count }) {
  const { t } = useTranslation();
  const goToCart = () => {
    closeAllNotifications();
    history.push('/cart');
  };

  return (
    <>
      <OrderedProduct product={product} cartItems={cartItems} count={count} />
      <div className="submit_button">
        <Button
          block
          type="submit"
          appearance="primary"
          onClick={goToCart}
        >
          <span>{t('cart.go_to_cart')}</span>
        </Button>
      </div>
      <div className="remove_added_product">
        <p><button onClick={async () => onCartItemDelete()}>{t('cart.remove_added_to_cart')}</button></p>
      </div>
    </>
  );
}

AlertAddedToCart.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  product: PropTypes.instanceOf(Object).isRequired,
  cartItems: PropTypes.instanceOf(Array).isRequired,
  onCartItemDelete: PropTypes.func.isRequired,
  closeAllNotifications: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};

export default AlertAddedToCart;
