import React from 'react';
import PropTypes from 'prop-types';

const Accordion = ({title, content, accordionActive, setAccordionActive}) => {
  return (
    <li className="accordion-item">
      <div className={'accordion-content ' + (accordionActive === title ? 'show' : '')}>
        <p>{content}</p>
      </div>
      <div
        onClick={() => setAccordionActive(title === accordionActive ? '' : title)}
        className="accordion-heading"
      >
        <span className="title">{title}</span>
        <div className="toggle"></div>
      </div>
    </li>
  );
};

Accordion.propTypes = {
  accordionActive: PropTypes.string.isRequired,
  setAccordionActive: PropTypes.instanceOf(Function).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Accordion;
