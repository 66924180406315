import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormGroup,
  ControlLabel,
  Form,
  FormControl,
} from 'rsuite';
import {
  createManager,
  resetManagerData,
  setManagerData,
} from '../../../redux/actions/auth/managerData';
import { resetSignInData } from '../../../redux/actions/auth/signIn';
import { resetRegistrationData, resetRegistrationError } from '../../../redux/actions/auth/registration';
import { getSignedSupplierCandidate, getSignedCustomerCandidate } from '../../../redux/actions/auth/registration';
import { resetCompanyInfoData } from '../../../redux/actions/auth/companyInfo';
import { resetAddressInfoData } from '../../../redux/actions/auth/addressInfoData';
import { Schema } from 'rsuite';
import { useTranslation } from 'react-i18next';
import CancelRegistrationModalAbortion from '../Registration/CancelRegistrationModalAbortion';
import { SignInContext } from '../../../context/signInContext';

const { StringType } = Schema.Types;
const model = (t) => Schema.Model({
  firstName: StringType()
    .isRequired(t('errors.this_field_is_required'))
    .maxLength(100, t('errors.field_size', { minSize: 1, maxSize: 100 })),
  lastName: StringType()
    .isRequired(t('errors.this_field_is_required'))
    .maxLength(100, t('errors.field_size', { minSize: 1, maxSize: 100 })),
  jobTitle: StringType()
    .isRequired(t('errors.this_field_is_required'))
    .maxLength(100, t('errors.field_size', { minSize: 1, maxSize: 100 })),
  email: StringType()
    .minLength(5, t('errors.email_address_is_to_short'))
    .maxLength(255, t('errors.email_address_is_to_large'))
    .isRequired(t('errors.this_field_is_required'))
    .isEmail(t('errors.email_should_be_valid')),
});

function ManagerInfo() {
  const dispatch = useDispatch();
  const history = useHistory();
  const wrapperRef = useRef(null);

  const [registrationData, setRegistrationData] = useState({});
  const [registrationErrors, setRegistrationErrors] = useState({});

  const [showCancelRegistrationModal, setShowCancelRegistrationModal] = useState(false);

  const managerInfo = useSelector((state) => state.auth.managerInfo);
  const error = useSelector((state) => state.auth.error);
  const registerData = useSelector((state) => state.auth.registrationData);
  const user = useSelector((state) => state.auth.user);
  const { isManagerCreated } = useSelector((state) => state.auth);
  const { abortRegistrationAction } = useContext(SignInContext);
  const { t } = useTranslation();

  useEffect(() => {
    // const checkUserData = Object.keys(user).length;
    // const checkRegisterData = Object.keys(registerData).length;
    // if (!checkUserData && !checkRegisterData || !!checkUserData && !checkRegisterData) {
    //   history.push('/');
    // }

    setRegistrationData(managerInfo);
    user?.status === 'UNFINISHED' && (user?.entityType === 'SUPPLIER_CANDIDATE' ?
      dispatch(getSignedSupplierCandidate((data) => {
        setRegistrationData(data);
      })) : dispatch(getSignedCustomerCandidate((data) => {
        setRegistrationData(data);
      })));
  }, []);

  useEffect(() => {
    if (isManagerCreated) {
      const destUrl = registerData?.type === 'Order materials (for business)' ? '/customer_succeeded_registration' : '/supplier_succeeded_registration';
      history.push(destUrl);

      dispatch(resetManagerData());
      dispatch(resetAddressInfoData());
      dispatch(resetCompanyInfoData());
      dispatch(resetRegistrationData());
      dispatch(resetSignInData());
    }
  }, [isManagerCreated]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowCancelRegistrationModal(true);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const onStepSubmit = () => {
    dispatch(createManager({ ...registrationData, phone: registerData?.phone || user?.phone }));
  };

  const goBack = () => {
    dispatch(setManagerData({ ...registrationData, phone: registerData?.phone || user?.phone }));
    history.push('/address_info');
  };

  const isValidToSubmit = () => {
    return Object.keys(registrationErrors).length === 0 &&
      Boolean(registrationData.firstName) &&
      Boolean(registrationData.lastName) &&
      Boolean(registrationData.jobTitle) &&
      Boolean(registrationData.email);
  };

  return (
    <div className="registration" ref={wrapperRef}>
      <div className="registration_box">
        <Form
          model={model(t)}
          onChange={(formValue) => {
            if (error) dispatch(resetRegistrationError());
            setRegistrationData({ ...formValue });
          }}
          onCheck={(formError) => setRegistrationErrors({ ...formError })}
          checkTrigger="change"
        >
          <p className="steps">{t('auth.step_3_of_3')}</p>
          <div className="registration-title">
            <p>
              <span>{t('auth.to_be_able_to_place_orders_at_konebone')}</span>
              <span>{t('auth.fill in information about you')}</span>
            </p>
          </div>

          <FormGroup>
            <ControlLabel className="required">{t('auth.first_name')}</ControlLabel>
            <FormControl name="firstName" type="text" placeholder={t('auth.first_name')} value={registrationData?.firstName || ''} errorMessage={null} />
            {registrationErrors.firstName && <p className="registration_error">{registrationErrors.firstName}</p>}
            <ControlLabel className="required">{t('auth.last_name')}</ControlLabel>
            <FormControl name="lastName" type="text" placeholder={t('auth.last_name')} value={registrationData?.lastName || ''} errorMessage={null} />
            {registrationErrors.lastName && <p className="registration_error">{registrationErrors.lastName}</p>}
            <ControlLabel className="required">{t('auth.job_title')}</ControlLabel>
            <FormControl name="jobTitle" type="text" placeholder={t('auth.job_title')} value={registrationData?.jobTitle || ''} errorMessage={null} />
            {registrationErrors.jobTitle && <p className="registration_error">{registrationErrors.jobTitle}</p>}
            <ControlLabel className="required">{t('auth.contact_email')}</ControlLabel>
            <FormControl name="email" type="text" placeholder={t('auth.contact_email')} value={registrationData?.email || ''} errorMessage={null} />
            {registrationErrors.email && <p className="registration_error">{registrationErrors.email}</p>}
            {error && error === 'must be a well-formed email address' && <p className="registration_error">{error}</p>}
          </FormGroup>

          <div className="buttons_list">
            <FormGroup>
              <div className="flex-container">
                <div><Button appearance="primary" type="submit"
                  onClick={onStepSubmit}
                  disabled={!isValidToSubmit()}><span>{t('auth.continue')}</span></Button></div>
                <div><Button appearance="ghost" onClick={goBack}>
                  <span>{t('auth.back')}</span></Button></div>
              </div>
            </FormGroup>
          </div>

        </Form>
      </div>
      {showCancelRegistrationModal && <CancelRegistrationModalAbortion
        isModalOpen={showCancelRegistrationModal}
        abortAction={abortRegistrationAction}
        cancelAction={setShowCancelRegistrationModal}
      />}
    </div>
  );
}

export default ManagerInfo;
