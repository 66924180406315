import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TimeConstraints, ProjectInfo, AdditionalServices, ConfirmOrder, SuccessfulOrder } from './';
import { toggleIsTimeConstraintsShown, toggleIsAdditionalServicesShown, toggleIsProjectsShown } from '../../redux/actions/order';
import { addIsCheckedStep } from '../../redux/actions/checkout';
import { FlexboxGrid, Col, Panel } from 'rsuite';
import Order from '../../components/Order/Order';
import { useTranslation } from 'react-i18next';


function Checkout() {
  const [isConfirmOrderShown, setIsConfirmOrderShown] = useState(false);
  const [isSuccessfulOrderShown, setIsSuccessfulOrderShown] = useState(false);
  const isTimeConstraintsShown = useSelector((state) => state.order.isTimeConstraintsShown);
  const isProjectsShown = useSelector((state) => state.order.isProjectsShown);
  const isAdditionalServicesShown = useSelector((state) => state.order.isAdditionalServicesShown);
  const checkoutData = useSelector((state) => state.checkout.checkoutData);

  const isConfirmDissabled = Object.keys(checkoutData)?.length < 11;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addIsCheckedStep(false));
    dispatch(toggleIsTimeConstraintsShown(true));
    dispatch(toggleIsProjectsShown(false));
    dispatch(toggleIsAdditionalServicesShown(false));
  }, []);

  return (
    <div className="checkout">
      <div className="container">
        {!isSuccessfulOrderShown && <h1>{t('page.checkout.checkout')}</h1>}
        <div className="checkout-flex-col">
          <FlexboxGrid className="flex-420-col">

            {isTimeConstraintsShown && (
              <TimeConstraints setIsConfirmOrderShown={setIsConfirmOrderShown} />
            )}

            {isProjectsShown &&(
              <ProjectInfo
                isTimeConstraintsShown={isTimeConstraintsShown}
                setIsConfirmOrderShown={setIsConfirmOrderShown}
              />
            )}

            {isAdditionalServicesShown && (
              <AdditionalServices
                setIsConfirmOrderShown={setIsConfirmOrderShown}
              />
            )}


            {isConfirmOrderShown && (
              <ConfirmOrder
                setIsConfirmOrderShown={setIsConfirmOrderShown}
                setIsSuccessfulOrderShown={setIsSuccessfulOrderShown}
              />
            )}

            {isSuccessfulOrderShown && (
              <SuccessfulOrder />
            )}

            {!isSuccessfulOrderShown && (
              <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                <Panel bordered>
                  <Order
                    isConfirmDissabled={isConfirmDissabled}
                  />
                </Panel>
              </FlexboxGrid.Item>
            )}

          </FlexboxGrid>

        </div>
      </div>
    </div>
  );
}

export default Checkout;
