import React from 'react';
import { Button } from 'rsuite';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function ProductNotFound({title}) {
  const { t } = useTranslation();
  return (
    <div className="product_not_found">
      <div className="container">
        <div className="product_not_found_box">
          <div className="banner">
            <span />
          </div>
          <p className="header">
            {title ? title : t('page.product_not_found.product_not_found')}
          </p>
          <p>
            {t('page.product_not_found.please_leave_us_a_message_in_Whats_up')}
            <a href="tel:0723970146" className="ltr">{t('company_phone_number')}</a>
            {t('page.product_not_found.and_we_will_do')}
          </p>
          <div className="button">
            <Button block type="button" href="/product_catalog" appearance="primary"><span> {t('page.product_not_found.go_to_products_catalog')}</span></Button>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductNotFound.propTypes = {
  title: PropTypes.instanceOf(Object),
};

export default ProductNotFound;
