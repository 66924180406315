import React, {useContext, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-scroll';
import Slider from 'react-slick';
import ENImage from '../assets/svg/landing_page/EN.svg';
import ILImage from '../assets/svg/landing_page/IL.svg';
import AboutUs1 from '../assets/img/landing_page/about-us-1.png';
import AboutUs2 from '../assets/img/landing_page/about-us-2.png';
import mainBanner from '../assets/img/landing_page/banner-img.png';
import Done from '../assets/svg/landing_page/done.svg';
import Times from '../assets/svg/landing_page/times.svg';
import Accordion from '../components/Accordion/Accordion';
import {Button, Form, FormGroup, ControlLabel, FormControl, Input, Modal, Dropdown} from 'rsuite';
import { useMediaQuery } from 'react-responsive';
import {SignInContext} from '../context/signInContext';
import {dic} from '../i18n/locate/landingDictionary';
import {postContactForm} from '../redux/actions/contact';
import {isValidPhoneOrEmail} from '../utils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';


const LandingPage = () => {
  const isMobileView = useMediaQuery({ query: '(max-width: 575px)' });
  const [activeLanguage, setActiveLanguage] = useState(localStorage.getItem('userLanguageLanding') === 'heb');
  const [userLanguageLanding, setUserLanguageLanding] = useState(localStorage.getItem('userLanguageLanding') || 'heb');
  const [tabs, setTabs] = useState(false);
  const [contactFormData, setContactFormData] = useState({
    fullName: {
      val: '',
      isValid: -1,
      showError: false,
    },
    phoneOrEmail: {
      val: '',
      isValid: -1,
      showError: false,
    },
    fieldOfInterest: {
      val: '',
      isValid: -1,
      showError: false,
    },
    message: {
      val: '',
      isValid: 1,
      showError: false,
    },
  });
  const [isOpenRequestSentModal, setIsOpenRequestSentModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [accordionActive, setAccordionActive] = useState('I\'ve been buying from the same supplier for years and I\'m satisfied, why should I change?');
  const {isModalOpen, handleIsModalOpen} = useContext(SignInContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sliderArr = [
    {
      id: 1,
      overhead: `${dic.slider_section_preheader[userLanguageLanding]}`,
      title: `${dic.slider_section_header[userLanguageLanding]}`,
      content: `${dic.slider_section_slide_4_content[userLanguageLanding]}`,
      list: [`${dic.slider_section_slide_4_list[userLanguageLanding]}`],
    },
    {
      id: 2,
      overhead: `${dic.slider_section_preheader[userLanguageLanding]}`,
      title: `${dic.slider_section_header[userLanguageLanding]}`,
      content: `${dic.slider_section_slide_3_content[userLanguageLanding]}`,
      list: [`${dic.slider_section_slide_3_list[userLanguageLanding]}`],
    },
    {
      id: 3,
      overhead: `${dic.slider_section_preheader[userLanguageLanding]}`,
      title: `${dic.slider_section_header[userLanguageLanding]}`,
      content: `${dic.slider_section_slide_2_content[userLanguageLanding]}`,
      list: [`${dic.slider_section_slide_2_list[userLanguageLanding]}`],
    },
    {
      id: 4,
      overhead: `${dic.slider_section_preheader[userLanguageLanding]}`,
      title: `${dic.slider_section_header[userLanguageLanding]}`,
      content: `${dic.slider_section_slide_1_content[userLanguageLanding]}`,
      list: [`${dic.slider_section_slide_1_list[userLanguageLanding]}`],
    },
    {
      id: 5,
      overhead: `${dic.slider_section_preheader[userLanguageLanding]}`,
      title: `${dic.slider_section_header[userLanguageLanding]}`,
      content: `${dic.slider_section_slide_5_content[userLanguageLanding]}`,
      list: [`${dic.slider_section_slide_5_list[userLanguageLanding]}`],
    },
  ];
  const accordionArr = [
    {
      id: 1,
      title: `${dic.questions_section_accordion_list_item_1_title[userLanguageLanding]}`,
      content: `${dic.questions_section_accordion_list_item_1_content[userLanguageLanding]}`,
    },
    {
      id: 2,
      title: `${dic.questions_section_accordion_list_item_2_title[userLanguageLanding]}`,
      content: `${dic.questions_section_accordion_list_item_2_content[userLanguageLanding]}`,
    },
    {
      id: 3,
      title: `${dic.questions_section_accordion_list_item_3_title[userLanguageLanding]}`,
      content: `${dic.questions_section_accordion_list_item_3_content[userLanguageLanding]}`,
    },
    {
      id: 4,
      title: `${dic.questions_section_accordion_list_item_4_title[userLanguageLanding]}`,
      content: `${dic.questions_section_accordion_list_item_4_content[userLanguageLanding]}`,
    },
    {
      id: 5,
      title: `${dic.questions_section_accordion_list_item_5_title[userLanguageLanding]}`,
      content: `${dic.questions_section_accordion_list_item_5_content[userLanguageLanding]}`,
    },
  ];

  const {fullName, phoneOrEmail, fieldOfInterest, message} = contactFormData;

  useEffect(() => {
    document.getElementsByClassName('direction-container')[0].setAttribute('dir', localStorage.getItem('userLanguageLanding') === 'en' ? 'ltr' : 'rtl');
  }, []);

  useEffect(() => {
    localStorage.setItem('userLanguageLanding', userLanguageLanding);
    document.getElementsByClassName('direction-container')[0].setAttribute('dir', userLanguageLanding === 'en' ? 'ltr' : 'rtl');
  }, [userLanguageLanding]);

  const handleClickEn = () => {
    setUserLanguageLanding('en');
    setActiveLanguage(false);
  };

  const handleClickHeb = () => {
    setUserLanguageLanding('heb');
    setActiveLanguage(true);
  };

  const onHandleDropdown = (eventKey) => {
    if (eventKey === 'en') {
      setUserLanguageLanding('en');
      setActiveLanguage(false);
    } else {
      setUserLanguageLanding('heb');
      setActiveLanguage(true);
    }
  };

  const handleSubmit = () => {
    if (fullName?.isValid === 1 && phoneOrEmail?.isValid === 1 && fieldOfInterest?.isValid === 1) {
      dispatch(postContactForm(contactFormData, setIsOpenRequestSentModal, setIsSuccess));
      setContactFormData({
        fullName: {
          val: '',
          isValid: -1,
          showError: false,
        },
        phoneOrEmail: {
          val: '',
          isValid: -1,
          showError: false,
        },
        fieldOfInterest: {
          val: '',
          isValid: -1,
          showError: false,
        },
        message: {
          val: '',
          isValid: 1,
          showError: false,
        },
      });
    } else if (fullName?.isValid === -1 && phoneOrEmail?.isValid === -1 && fieldOfInterest?.isValid === -1) {
      setContactFormData({
        ...contactFormData,
        fullName: {
          ...fullName,
          isValid: 0,
          showError: true,
        },
        phoneOrEmail: {
          ...phoneOrEmail,
          isValid: 0,
          showError: true,
        },
        fieldOfInterest: {
          ...fieldOfInterest,
          isValid: 0,
          showError: true,
        },
      });
    } else {
      setContactFormData({
        ...contactFormData,
        fullName: {
          ...fullName,
          isValid: fullName.isValid !== 1 ? 0 : 1,
          showError: fullName.isValid !== 1,
        },
        phoneOrEmail: {
          ...phoneOrEmail,
          isValid: phoneOrEmail.isValid !== 1 ? 0 : 1,
          showError: phoneOrEmail.isValid !== 1,
        },
        fieldOfInterest: {
          ...fieldOfInterest,
          isValid: fieldOfInterest.isValid !== 1 ? 0 : 1,
          showError: fieldOfInterest.isValid !== 1,
        },
      });
    }
  };

  const settingsSlider = {
    dots: true,
    dotsClass: 'custom-dots',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name="description" content={t('meta.description')} />
        <meta property="og:title" content={t('meta.title')} />
        <meta property="og:description" content={t('meta.description')} />
      </Helmet>
      <div className={`direction-container ${activeLanguage ? 'active' : ''}`}>
        <section className="main-banner">
          <div className="container">
            {
              isMobileView ? (
                <div className="language-panel">
                  <button
                    onClick={handleClickEn}
                    className={`${!activeLanguage ? 'active' : ''}`}
                    type="button">
                    <img src={ENImage} alt="EN-flag"/>
                  </button>
                  <button
                    onClick={handleClickHeb}
                    className={`${activeLanguage ? 'active' : ''}`}
                    type="button">
                    <img src={ILImage} alt="IL-flag"/>
                  </button>
                </div>
              ) : (
                <div
                  className="wrapper_dropdown language-dropdown"
                  dir='rtl'
                  style={{transform: `translateX(${activeLanguage ? 8 : -8}px)`}}
                >
                  <Dropdown
                    title={activeLanguage ?
                      <div dir='ltr'>
                        <img
                          src={ILImage}
                          alt="IL-flag"
                          style={{margin: '0 10px 0 0'}}
                        />
                        <span>HEB</span>
                      </div> :
                      <div>
                        <img src={ENImage} alt="EN-flag"/>
                        <span>ENG</span>
                      </div>}
                    onSelect={(val) => onHandleDropdown(val)}
                    activeKey={activeLanguage ? 'heb' : 'en'}
                  >
                    <Dropdown.Item eventKey="en">
                      <span
                        className={`${!activeLanguage ? 'active' : ''}`}
                        type="button">
                        {
                          activeLanguage ? (
                            <span dir='ltr'>
                              <img src={ENImage} alt="EN-flag"/>
                              <span style={{marginRight: '-5px'}}>ENG</span>
                            </span>
                          ) : (
                            <span dir='ltr'>
                              <span>ENG</span>
                              <img src={ENImage} alt="EN-flag"/>
                            </span>
                          )
                        }
                      </span>
                    </Dropdown.Item><Dropdown.Item eventKey="heb">
                      <span
                        className={`${activeLanguage ? 'active' : ''}`}
                        type="button">
                        {
                          activeLanguage ? (
                            <span dir='ltr'>
                              <img src={ILImage} alt="IL-flag"/>
                              <span style={{marginRight: '-5px'}}>HEB</span>
                            </span>
                          ) : (
                            <span dir='ltr'>
                              <span>HEB</span>
                              <img src={ILImage} alt="IL-flag"/>
                            </span>
                          )
                        }
                      </span>
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              )
            }

            <div className="main-holder">
              <h1>{dic.main_banner_section_title[userLanguageLanding]}</h1>
              <div className="holder-text">
                <div>
                  <p>{dic.main_banner_section_content[userLanguageLanding]}</p>
                </div>
                <Link
                  className="rs-btn rs-btn-primary rs-btn-block"
                  to="contact-form"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  {dic.main_banner_section_contact_us[userLanguageLanding]}
                </Link>
              </div>
            </div>
            <div className="img-holder">
              <div className="img-content">
                {
                  activeLanguage ?
                    <img src={mainBanner} alt="main banner"/> :
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/D9frc0AhjAE" frameBorder="0"
                      allow=" autoplay; controls=0; iv_load_policy=3; gyroscope; picture-in-picture"
                      allowFullScreen=""></iframe>
                }
              </div>
            </div>
          </div>
        </section>
        <section className="tabs-btn">
          <div className="container">
            <div className="holder-margin">
              <Link
                className="tab-benefit tab-benefit-left"
                to="benefits"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
              >
                <div
                  onClick={() => setTabs(false)}
                  className={`tab-holder tab-left ${!tabs ? 'active' : ''}`}>
                  <div className="heading"><span
                    className="heading-text">{dic.tabs_btn_section_builders_preheader[userLanguageLanding]}</span>
                  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.0234 1.93979C13.9891 1.38673 13.5158 0.965903 12.9664 0.99984L4.01356 1.55289C3.46416 1.58683 3.04666 2.06268 3.08104 2.61573C3.11541 3.16879 3.58865 3.58962 4.13805 3.55568L12.0962 3.06408L12.5941 11.0752C12.6285 11.6283 13.1017 12.0491 13.6511 12.0152C14.2005 11.9813 14.618 11.5054 14.5837 10.9523L14.0234 1.93979ZM2.08336 15.9928L13.7761 2.66588L12.2813 1.33659L0.588519 14.6635L2.08336 15.9928Z"
                      fill="#28253D"></path>
                  </svg>
                  </div>
                  <h3>{dic.tabs_btn_section_builders_header[userLanguageLanding]}</h3>
                  <div className="text-holder">
                    <p>{dic.tabs_btn_section_builders_content[userLanguageLanding]}</p>
                  </div>
                </div>
              </Link>
              <Link
                className="tab-benefit tab-benefit-right"
                to="benefits"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
              >
                <div
                  onClick={() => setTabs(true)}
                  className={`tab-holder tab-right ${tabs ? 'active' : ''}`}>
                  <div className="heading"><span
                    className="heading-text">{dic.tabs_btn_section_suppliers_preheader[userLanguageLanding]}</span>
                  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.0234 1.93979C13.9891 1.38673 13.5158 0.965903 12.9664 0.99984L4.01356 1.55289C3.46416 1.58683 3.04666 2.06268 3.08104 2.61573C3.11541 3.16879 3.58865 3.58962 4.13805 3.55568L12.0962 3.06408L12.5941 11.0752C12.6285 11.6283 13.1017 12.0491 13.6511 12.0152C14.2005 11.9813 14.618 11.5054 14.5837 10.9523L14.0234 1.93979ZM2.08336 15.9928L13.7761 2.66588L12.2813 1.33659L0.588519 14.6635L2.08336 15.9928Z"
                      fill="#fff"></path>
                  </svg>
                  </div>
                  <h3>{dic.tabs_btn_section_suppliers_header[userLanguageLanding]}</h3>
                  <div className="text-holder">
                    <p>{dic.tabs_btn_section_suppliers_content[userLanguageLanding]}</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <section className="about-us">
          <div className="container">
            <div className="holder-margin">
              <div className="content-holder section-top">
                <div className="img-holder">
                  <img src={AboutUs1} alt="about-us"/>
                </div>
                <div className="text-holder">
                  <h2>{dic.about_us_section_title[userLanguageLanding]}</h2>
                  <p>{dic.about_us_section_content_paragraph_1[userLanguageLanding]}</p>
                  <p>{dic.about_us_section_content_paragraph_2[userLanguageLanding]}</p>
                  <p>{dic.about_us_section_content_paragraph_3[userLanguageLanding]}</p>
                </div>
              </div>
            </div>
            <div className="holder-margin">
              <div className="content-holder section-bottom">
                <ul className="text-holder list">
                  <li>{dic.about_us_section_list_item_1[userLanguageLanding]}</li>
                  <li>{dic.about_us_section_list_item_2[userLanguageLanding]}</li>
                  <li>{dic.about_us_section_list_item_3[userLanguageLanding]}</li>
                  <li>{dic.about_us_section_list_item_4[userLanguageLanding]}</li>
                  <li>{dic.about_us_section_list_item_5[userLanguageLanding]}</li>
                </ul>
                <div className="img-holder">
                  <img src={AboutUs2} alt="about-us"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="why-we">
          <div className="container">
            <div className="bg-holder">
              <div className="content-holder">
                <span className="preheader">{dic.why_we_section_preheader[userLanguageLanding]}</span>
                <h2>{dic.why_we_section_title[userLanguageLanding]}</h2>
                <div>
                  <p>{dic.why_we_section_content[userLanguageLanding]}</p>
                  <Button className="rs-btn rs-btn-block"
                    onClick={() => handleIsModalOpen(!isModalOpen)}>
                    {dic.why_we_section_try_it_now[userLanguageLanding]}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="how-it-work bg">
          <div className="container">
            <Slider className="carousel-wrap" {...settingsSlider}>
              {sliderArr.map((slide) => {
                const listArr = slide.list[0].split(';');
                return (
                  <div className="slide-holder" key={slide.id}>
                    <span className="preheader">{slide.overhead}</span>
                    <h3>{slide.title}</h3>
                    <div className="text-holder carousel-card-content">
                      <p>{slide.content}</p>
                      <ul className="carousel-list">
                        {listArr.map((el, index) => {
                          return (
                            <li key={index}>{el}</li>
                          );
                        })}
                      </ul>
                      <Link
                        className="rs-btn rs-btn-primary"
                        to="contact-form"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={1000}
                      >{dic.slider_section_contact_us[userLanguageLanding]}</Link>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>
        <section className="benefits">
          <div className="container" id="benefits">
            <h2 className="h1">{dic.benefits_section_title[userLanguageLanding]}</h2>
            <div className="tabs-pannel">
              <button
                onClick={() => setTabs(false)}
                className={`btn btn-left ${!tabs ? 'rs-btn-primary' : ''}`}
                type="button">{dic.benefits_section_btns_panel_btn_left[userLanguageLanding]}
              </button>
              <button
                onClick={() => setTabs(true)}
                className={`btn btn-right ${tabs ? 'rs-btn-primary' : ''}`}
                type="button">{dic.benefits_section_btns_panel_btn_right[userLanguageLanding]}
              </button>
            </div>
            <div className="holder-list">
              <ul className={`benefits-list benefits-builders ${!tabs ? 'active' : ''}`} id="list-builders">
                <li>
                  <h3>{dic.benefits_section_list_builders_item_1_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_builders_item_1_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_builders_item_2_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_builders_item_2_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_builders_item_3_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_builders_item_3_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_builders_item_4_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_builders_item_4_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_builders_item_5_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_builders_item_5_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_builders_item_6_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_builders_item_6_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_builders_item_7_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_builders_item_7_content[userLanguageLanding]}</p>
                  </div>
                </li>
              </ul>
              <ul className={`benefits-list benefits-suppliers ${tabs ? 'active' : ''}`} id="list-suppliers">
                <li>
                  <h3>{dic.benefits_section_list_suppliers_item_1_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_suppliers_item_1_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_suppliers_item_2_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_suppliers_item_2_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_suppliers_item_3_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_suppliers_item_3_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_suppliers_item_4_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_suppliers_item_4_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_suppliers_item_5_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_suppliers_item_5_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_suppliers_item_6_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_suppliers_item_6_content[userLanguageLanding]}</p>
                  </div>
                </li>
                <li>
                  <h3>{dic.benefits_section_list_suppliers_item_7_title[userLanguageLanding]}</h3>
                  <div className="content">
                    <p>{dic.benefits_section_list_suppliers_item_7_content[userLanguageLanding]}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="questions">
          <div className="container">
            <h2>{dic.questions_section_title[userLanguageLanding]}</h2>
            <ul className="accordion-list">
              {accordionArr.map((itemAc) => {
                return (
                  <Accordion
                    key={itemAc.id}
                    title={itemAc.title}
                    content={itemAc.content}
                    accordionActive={accordionActive}
                    setAccordionActive={setAccordionActive}
                  />
                );
              })}
            </ul>
          </div>
        </section>
        <section className="contact-form" id="contact-form">
          <div className="container">
            <div className="bg-holder">
              <div className="content-left">
                <h3>{dic.contact_form_section_title[userLanguageLanding]}</h3>
                <div className="text-holder">
                  {/* <p>{dic.contact_form_section_content_paragraph_1[userLanguageLanding]}</p>*/}
                  {/* <p>{dic.contact_form_section_content_paragraph_2[userLanguageLanding]}</p>*/}
                </div>
                <div className="socials-block">
                  <a href="https://www.facebook.com/koneboneisrael" target="_blank" rel="noreferrer">
                    <svg className="facebook" width="41" height="41" viewBox="0 0 41 41" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <rect className="hover" x="0.0078125" y="0.00292969" width="40" height="40" rx="3"
                        fill="#48455A"></rect>
                      <path
                        d="M11.0078 7.00293H30.0078L26.5078 37.5029L23.5078 40.0029H17.0078L12.0078 37.5029L11.0078 7.00293Z"
                        fill="#F3F3F3"></path>
                      <path className="hover"
                        d="M40.005 20.125C40.005 9.01 31.05 0 20.005 0C8.955 0.0025 0 9.01 0 20.1275C0 30.17 7.315 38.495 16.875 40.005V25.9425H11.8V20.1275H16.88V15.69C16.88 10.6475 19.8675 7.8625 24.435 7.8625C26.625 7.8625 28.9125 8.255 28.9125 8.255V13.205H26.39C23.9075 13.205 23.1325 14.7575 23.1325 16.35V20.125H28.6775L27.7925 25.94H23.13V40.0025C32.69 38.4925 40.005 30.1675 40.005 20.125Z"
                        fill="#48455A"></path>
                    </svg>
                  </a>
                  <a href="https://www.linkedin.com/company/konebone/" target="_blank" rel="noreferrer">
                    <svg className="linkedin" width="41" height="40" viewBox="0 0 41 40" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path className="hover"
                        d="M37.055 0H2.96059C2.17747 0 1.42641 0.311096 0.872662 0.864849C0.318908 1.4186 0.0078125 2.16965 0.0078125 2.95278V37.0472C0.0078125 37.8303 0.318908 38.5814 0.872662 39.1352C1.42641 39.6889 2.17747 40 2.96059 40H37.055C37.8382 40 38.5892 39.6889 39.143 39.1352C39.6967 38.5814 40.0078 37.8303 40.0078 37.0472V2.95278C40.0078 2.16965 39.6967 1.4186 39.143 0.864849C38.5892 0.311096 37.8382 0 37.055 0ZM11.93 34.075H5.91615V14.9722H11.93V34.075ZM8.91892 12.325C8.23675 12.3212 7.571 12.1153 7.0057 11.7335C6.4404 11.3516 6.00088 10.8109 5.74261 10.1795C5.48434 9.54808 5.41889 8.85432 5.55453 8.18576C5.69017 7.5172 6.02082 6.90379 6.50474 6.42297C6.98866 5.94214 7.60417 5.61544 8.27359 5.4841C8.94301 5.35276 9.63633 5.42266 10.2661 5.68498C10.8958 5.9473 11.4337 6.39028 11.8119 6.95802C12.1901 7.52576 12.3917 8.19282 12.3911 8.875C12.3976 9.33172 12.312 9.78506 12.1395 10.208C11.967 10.6309 11.7111 11.0148 11.3871 11.3368C11.0631 11.6587 10.6776 11.9121 10.2535 12.0819C9.82948 12.2517 9.3756 12.3344 8.91892 12.325ZM34.0967 34.0917H28.0856V23.6556C28.0856 20.5778 26.7773 19.6278 25.0884 19.6278C23.305 19.6278 21.555 20.9722 21.555 23.7333V34.0917H15.5411V14.9861H21.3245V17.6333H21.4023C21.9828 16.4583 24.0161 14.45 27.1189 14.45C30.4745 14.45 34.0995 16.4417 34.0995 22.275L34.0967 34.0917Z"
                        fill="#48455A"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <Form className="content-right" id="contact" fluid noValidate>
                <FormGroup>
                  <ControlLabel htmlFor="contact_name"></ControlLabel>
                  <FormControl
                    className={`${fullName.isValid !== 0 ? 'valid' : 'invalid'}`}
                    id="contact_name"
                    type="text" name="name"
                    placeholder={dic.contact_form_section_field_1[userLanguageLanding]}
                    autoComplete="off"
                    value={fullName?.val || ''}
                    onChange={(val) => {
                      setContactFormData({
                        ...contactFormData,
                        fullName: {...fullName, val, isValid: val ? 1 : 0},
                      });
                    }}
                  />
                  <p
                    className={`error${fullName?.showError ? ' error_show' : ''}`}>{dic.validationErrors_not_empty[userLanguageLanding]}</p>
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="contact_email"></ControlLabel>
                  <FormControl
                    className={`${phoneOrEmail.isValid !== 0 ? 'valid' : 'invalid'}`}
                    id="contact_email"
                    type="email"
                    name="email"
                    placeholder={dic.contact_form_section_field_2[userLanguageLanding]}
                    autoComplete="off"
                    value={phoneOrEmail?.val || ''}
                    onChange={(val) => {
                      setContactFormData({
                        ...contactFormData,
                        phoneOrEmail: {
                          ...phoneOrEmail,
                          val,
                          isValid: val && isValidPhoneOrEmail(val) ? 1 : 0,
                        },
                      });
                    }}
                  />
                  <p
                    className={`error${phoneOrEmail?.showError ? ' error_show' : ''}`}>{dic.validationErrors_phone_email[userLanguageLanding]}</p>
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="contact_interest"></ControlLabel>
                  <FormControl
                    className={`${fieldOfInterest?.isValid !== 0 ? 'valid' : 'invalid'}`}
                    id="contact_interest"
                    type="text" name="interest"
                    placeholder={dic.contact_form_section_field_3[userLanguageLanding]}
                    autoComplete="off"
                    value={fieldOfInterest?.val || ''}
                    onChange={(val) => {
                      setContactFormData({
                        ...contactFormData,
                        fieldOfInterest: {...fieldOfInterest, val, isValid: val ? 1 : 0},
                      });
                    }}
                  />
                  <p
                    className={`error${fieldOfInterest?.showError ? ' error_show' : ''}`}>{dic.validationErrors_not_empty[userLanguageLanding]}</p>
                </FormGroup>
                <FormGroup className="textarea-block">
                  <ControlLabel htmlFor="contact_message"></ControlLabel>
                  <Input
                    id="contact_message"
                    as="textarea"
                    name="message"
                    value={message?.val || ''}
                    onChange={(val) => {
                      setContactFormData({
                        ...contactFormData,
                        message: {...message, val},
                      });
                    }}
                    placeholder={dic.contact_form_section_field_4[userLanguageLanding]}>
                  </Input>
                </FormGroup>
                <div className="btn-holder" id="contact_submit">
                  <Button
                    className="rs-btn rs-btn-primary rs-btn-block"
                    onClick={handleSubmit}
                  >
                    {dic.contact_form_section_btn_submit[userLanguageLanding]}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </div>
      {
        isOpenRequestSentModal && (
          <RequestSentModal
            setIsOpenRequestSentModal={setIsOpenRequestSentModal}
            userLanguageLanding={userLanguageLanding}
            isSuccess={isSuccess}
          />
        )
      }
    </>
  );
};

LandingPage.propTypes = {
  activeIndex: PropTypes.instanceOf(Number),
  setActiveIndex: PropTypes.instanceOf(Object),
};

export default LandingPage;

function RequestSentModal({setIsOpenRequestSentModal, userLanguageLanding, isSuccess}) {
  return (
    <Modal
      className="landing-modal"
      overflow={false}
      show
      onHide={() => setIsOpenRequestSentModal(false)}
      keyboard={true}
      autoFocus={true}>
      <div className="card-success">
        <div className="container">
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <img src={isSuccess ? Done : Times} alt={`${isSuccess ? 'done' : 'error'}`}/>
            <h3>
              {isSuccess ?
                dic.request_success_title[userLanguageLanding] :
                dic.request_error_title[userLanguageLanding]}
            </h3>
            <p dir='auto'>
              {isSuccess ?
                dic.request_success_description[userLanguageLanding] :
                dic.request_error_description[userLanguageLanding]}
            </p>
            <Button
              className="rs-btn rs-btn-primary"
              onClick={() => setIsOpenRequestSentModal(false)}>
              {dic.request_success_button[userLanguageLanding]}
            </Button>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
}

RequestSentModal.propTypes = {
  setIsOpenRequestSentModal: PropTypes.func.isRequired,
  userLanguageLanding: PropTypes.string,
  isSuccess: PropTypes.bool.isRequired,
};
