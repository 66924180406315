import React, { useState, createRef, useEffect } from 'react';
import {
  Breadcrumb,
  Icon,
  Button,
  Panel,
  PanelGroup,
  FlexboxGrid,
  Col,
  Tooltip,
  Whisper,
  Input,
  Form,
  FormControl,
} from 'rsuite';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const products = {
  inStock: [
    {
      vendorСod: '365839',
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 15,005',
      priceOfOneProduct: '₪ 150,05/item',
      posterProduct: `${product_1}`,
      quantityOrdered: '100',
      isNotAvailableFull: true,
      isNotInStock: false,
      replacement: [],
    },
    {
      vendorСod: '365839',
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 15,005',
      priceOfOneProduct: '₪ 150,05/item',
      posterProduct: `${product_2}`,
      quantityOrdered: '5',
      isNotAvailableFull: false,
      isNotInStock: false,
      replacement: [],
    },
  ],
  alternativeProducts: [
    {
      vendorСod: '365839',
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 14,005',
      priceOfOneProduct: '₪ 140,05/item',
      posterProduct: `${product_2}`,
      quantityOrdered: '100',
      isNotAvailableFull: false,
      isNotInStock: true,
      replacement: [
        {
          vendorСod: '365839',
          description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
          priceOfAllProducts: '₪ 14,005',
          priceOfOneProduct: '₪ 140,05/item',
          posterProduct: `${product_2}`,
          quantityOrdered: '100',
          isNotAvailableFull: false,
          isNotInStock: false,
          replacement: [],
        },
      ],
    },
    {
      vendorСod: '365839',
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 14,005',
      priceOfOneProduct: '₪ 140,05/item',
      posterProduct: `${product_2}`,
      quantityOrdered: '100',
      isNotAvailableFull: false,
      isNotInStock: true,
      replacement: [
        {
          vendorСod: '365839',
          description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
          priceOfAllProducts: '₪ 14,005',
          priceOfOneProduct: '₪ 140,05/item',
          posterProduct: `${product_2}`,
          quantityOrdered: '100',
          isNotAvailableFull: true,
          isNotInStock: false,
          replacement: [],
        },
      ],
    },
  ],
  outOfStock: [
    {
      vendorСod: '365839',
      description: 'Wonderspeed first color to fill | Cover capacity bucket 18 liters, 162 sqm single layer',
      priceOfAllProducts: '₪ 15,005',
      priceOfOneProduct: '₪ 150,05/item',
      posterProduct: `${product_3}`,
      quantityOrdered: '5',
      isNotAvailableFull: false,
      isNotInStock: false,
      replacement: [],
    },
  ],
};

import product_1 from '../../assets/img/product_1.png';
import product_2 from '../../assets/img/product_2.png';
import product_3 from '../../assets/img/product_3.png';

const ListOrdered = ({ orderedProducts }) => (
  <ul>
    {orderedProducts.map((product, index) => (
      <li key={index}>
        <OrderedProduct product={product} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  orderedProducts: PropTypes.instanceOf(Array).isRequired,
};

const OrderedProduct = ({ product }) => {
  const {
    vendorСod,
    description,
    priceOfAllProducts,
    priceOfOneProduct,
    quantityOrdered,
    posterProduct,
    isNotAvailableFull,
    isNotInStock,
    replacement,
  } = product;
  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${isNotInStock ? ' opacity' : ''}`}>
        <div>
          <div className="products-img">
            <img src={posterProduct} alt="product_png" />
            {isNotInStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
          </div>
          <p className="quantity">
            <span>
              x
              {quantityOrdered}
            </span>
          </p>
        </div>
        <div>
          <p><span className="product_number">{vendorСod}</span></p>
          <p className="description">{description}</p>
          {!isNotInStock && (
            <>
              <p className="price-all"><span className="ltr">{priceOfAllProducts}</span></p>
              <p><span className="ltr">{priceOfOneProduct}</span></p>
            </>
          )}
        </div>
      </div>
      {isNotAvailableFull && (
        <div className="warning_box">
          <p>{t('page.order.order_list_item.pay_order.info.this_product_available')}</p>
          <p><span className="red">100</span>/200 {t('page.order.order_list_item.pay_order.info.items_are_available')}</p>
        </div>
      )}
      {replacement.length > 0 && (
        <div className="replacement">
          <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
          <ListOrdered orderedProducts={replacement} />
        </div>
      )}
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

function OfferItem({ bestOffer = false, supplierName }) {
  const [viewProducts, setViewProducts] = useState(false);
  const { t } = useTranslation();
  const productsView = viewProducts ? t('page.offer.hide_products') : t('page.offer.view_products');
  return (
    <Panel bordered className={bestOffer ? 'border_red' : ''}>

      {bestOffer && (
        <div className="best_offer">
          <span>{t('page.offers_list.best_offer')}</span>
        </div>
      )}

      <div className="supplier_name">
        <p className="header">{supplierName}</p>
        <p>{t('page.offers_list.delivery_with_delay.delivery_with_delay')} 14 Jul 2021, 14:00</p>
      </div>

      <div className="tag_box">
        <p><span className="with_delay">{t('page.offers_list.with_delay')}</span></p>
        <p><span className="partial_order">{t('page.offers_list.partial_order')}</span></p>
      </div>

      <div className="grey_box">
        <p>{t('page.order.order_filling')}</p>
        <p className="price"><span>{t('page.order.of_products', { num1: '2', num2: '4' })}</span></p>
        <div className="toggle_hide_view">
          <Button appearance="link" onClick={() => setViewProducts(!viewProducts)}>
            <span className="flex">
              <span>{productsView}</span>
              <span>
                <i className={`rs-icon ${viewProducts ? 'open' : 'close'}`} />
              </span>
            </span>
          </Button>
        </div>
      </div>

      <div className="price_box">
        <ul>
          <li><p>{t('page.offers_list.price_offer')}</p></li>
          <li><p><span className="ltr">₪ 26,500</span></p></li>
        </ul>

        <ul>
          <li><p>{t('page.offers_list.delivery_cost')}</p></li>
          <li><p><span className="ltr">₪ 1000</span></p></li>
        </ul>

        <ul>
          <li><p>{t('page.offers_list.unloading_cost')}</p></li>
          <li><p><span className="ltr">₪ 100</span></p></li>
        </ul>

        <ul className="total">
          <li><p>{t('page.offers_list.order_total')}</p></li>
          <li><p><span className="ltr">₪ 27,600</span></p></li>
        </ul>
      </div>

      <Button disabled appearance="primary"><span><span className="ltr">{t('page.offer.pay')} ₪ 27,600</span></span></Button>

      {viewProducts && (
        <div className="offer-box">

          <PanelGroup accordion>
            <Panel header={<p>{t('page.order.order_list_item.in_stock')} <span className="count">(2)</span></p>} defaultExpanded>

              <div className="products-list">
                <ListOrdered orderedProducts={products.inStock} />
              </div>

            </Panel>
            <Panel header={<p>{t('page.order.order_list_item.pay_order.alternative_products')} <span className="count">(2)</span></p>} defaultExpanded>

              <div className="products-list">
                <ListOrdered orderedProducts={products.alternativeProducts} />
              </div>

            </Panel>
            <Panel header={<p>{t('page.order.order_list_item.pay_order.out_of_stock')} <span className="count">(1)</span></p>} defaultExpanded>

              <div className="products-list">
                <ListOrdered orderedProducts={products.outOfStock} />
              </div>

            </Panel>
          </PanelGroup>

        </div>
      )}
    </Panel>
  );
}

OfferItem.propTypes = {
  bestOffer: PropTypes.bool,
  supplierName: PropTypes.string.isRequired,
};

const InfoTooltip = ({ ...rest }) => {
  const { t } = useTranslation();
  return (
    <Tooltip classPrefix="info_tooltip_checkout rs-tooltip" {...rest}>
      <p>{t('page.offer.info')}</p>
    </Tooltip>
  );
};

const InfoButton = () => {
  const triggerRef = createRef();
  const [isOpen, setIsOpen] = useState(false);

  function handleCloseTooltip() {
    triggerRef.current.close();
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('resize', handleCloseTooltip);
    } else {
      window.removeEventListener('resize', handleCloseTooltip);
    }

    return () => {
      window.removeEventListener('resize', handleCloseTooltip);
    };
  });

  return (
    <Whisper
      placement="autoVerticalEnd"
      trigger="click"
      onOpen={() => setIsOpen(true)}
      speaker={<InfoTooltip />}
      triggerRef={triggerRef}
    >
      <Button className="info"><Icon icon="info" /></Button>
    </Whisper>
  );
};

function OfferConfirmationStep1() {
  const { t } = useTranslation();
  return (
    <div className="checkout offer_confirmation">
      <div className="container">

        <div className="breadcrumb-list">
          <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
            <Breadcrumb.Item componentClass={Link} to="/">
              <span>{t('page.order.orders')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item componentClass={Link} to="/">
              <span>Offers for order #48592</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <span>Offer confirmation</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <h1>{t('page.offer.contact_information.offer_confirmation')}</h1>

        <div className="checkout-flex-col">

          <FlexboxGrid className="flex-420-col">
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

              <Panel bordered className="panel_edit">
                <Form fluid>

                  <p className="header m20">
                    <span className="step_flex">
                      <span>{t('page.offer.contact_information.contact_information')}</span>
                      <span className="light">{t('page.offer.contact_information.step_1')}</span>
                    </span>
                  </p>

                  <div className="new_tab_option">

                    <FlexboxGrid>
                      <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>

                        <div className="grey_info_box">
                          <p>{t('page.offer.contact_information.check_your_order')}</p>
                          <p>{t('page.offer.contact_information.you_cannot_change')}</p>
                        </div>

                      </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid>
                      <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>

                        <div className="project_ico_box">
                          <p className="header">{t('page.offer.contact_information.project')}</p>
                          <p>Garad, Sokolov st. 79, Holon, Israel</p>
                        </div>

                      </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid>
                      <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
                        <label className="required">{t('page.offer.contact_information.contact_full_name')}</label>
                        <Input placeholder="Contact full name" name="contact_full_name" />
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
                        <label className="required">{t('page.offer.contact_information.contact_phone')}</label>
                        <Input placeholder="05N-XXXXXXX" name="contact_full_name" />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid>
                      <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>
                        <label>{t('page.offer.contact_information.comment_for_supplier')}</label>
                        <FormControl placeholder={t('page.offer.contact_information.comment_for_supplier')} rows={5} name="comment_for_supplier" componentClass="textarea" />
                      </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid className="save_button">
                      <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>
                        <Button type="button" appearance="primary"><span>{t('page.offer.contact_information.continue')}</span></Button>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>

                  </div>

                </Form>
              </Panel>

              <Panel bordered className="panel_edit opacity_50">
                <div className="flex-container info-tooltip">
                  <div className="flex-item">
                    <p className="header">
                      <span className="step_flex">
                        <span>{t('page.offer.payment.payment')}</span>
                        <span className="light">{t('page.offer.payment.step_2')}</span>
                      </span>
                    </p>
                  </div>
                  <div className="flex-item">
                    <InfoButton />
                  </div>
                </div>
              </Panel>

            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
              <div className="offers_list">
                <OfferItem supplierName="Supplier 2" />
              </div>
            </FlexboxGrid.Item>
          </FlexboxGrid>

        </div>

      </div>
    </div>
  );
}

export default OfferConfirmationStep1;
