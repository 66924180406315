import React, { createRef, useState, useEffect } from 'react';
import {
  Panel,
  Breadcrumb,
  Icon,
  FlexboxGrid,
  Col,
  Tooltip,
  Whisper,
  Loader,
  Button,
} from 'rsuite';
import PropTypes from 'prop-types';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderById, resetOrder } from '../redux/actions/order';

import { formatPrice, customerOrdersStatusWithClass } from '../utils/index';
import { ordersType } from './OrderList';
import NoImage from '../assets/img/no_image.png';
import NeedHelpComponent from '../components/NeedHelpComponent';
import NotFound from './NotFound';

const InfoTooltip = ({ ...rest }) => {
  const { t } = useTranslation();
  return (
    <Tooltip classPrefix="info_tooltip_checkout rs-tooltip" {...rest}>
      <p>{t('page.add_alternative_modal.indicated_alternative_product')}</p>
    </Tooltip>
  );
};

const InfoButton = () => {
  const triggerRef = createRef();
  const [isOpen, setIsOpen] = useState(false);

  function handleCloseTooltip() {
    triggerRef.current.close();
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('resize', handleCloseTooltip);
    } else {
      window.removeEventListener('resize', handleCloseTooltip);
    }

    return () => {
      window.removeEventListener('resize', handleCloseTooltip);
    };
  });

  return (
    <Whisper
      placement="autoVerticalStart"
      trigger="hover"
      onOpen={() => setIsOpen(true)}
      speaker={<InfoTooltip />}
      triggerRef={triggerRef}
    >
      <span className="icon_refresh"><Icon icon="refresh" /></span>
    </Whisper>
  );
};

const ListOrdered = ({ orderedProducts }) => (
  <ul>
    {orderedProducts?.map((product, index) => (
      <li key={index}>
        <OrderedProduct product={product} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  orderedProducts: PropTypes.instanceOf(Array).isRequired,
};

const OrderedProduct = ({ product }) => {
  const isMobileView = useMediaQuery({ query: '(max-width: 1199px)' });
  const {
    fileName,
    quantity,
    isReadyForAlternatives,
    catalogNumber,
    koneboneProductName,
    maxKbPrice,
    minKbPrice,
    minKpPriceWithQuantity,
    maxKpPriceWithQuantity,
  } = product;
  const { t } = useTranslation();
  return (
    <>
      <div className='products-list-flex'>

        {isMobileView ? (
          <>
            <div>
              <div className="products-img">
                <img src={fileName ? fileName : NoImage} alt={koneboneProductName} />
              </div>
              <p className="quantity">
                <span>
                  x
                  {quantity}
                </span>
              </p>
            </div>
            <div>

              {isReadyForAlternatives ? (
                <div className="flex_box_p">
                  <p><span className="product_number">{catalogNumber}</span></p>
                  <p><InfoButton /></p>
                </div>
              ) : (
                <p>
                  <span className="product_number">{catalogNumber}</span>
                </p>
              )}

              <p className="description">{koneboneProductName}</p>
              <>
                <p className="price-all"><span className="ltr">₪ {formatPrice(minKpPriceWithQuantity || 0.00)} - ₪ {formatPrice(maxKpPriceWithQuantity || 0.00)}</span></p>
                <p><span className="ltr">₪ {formatPrice(minKbPrice || 0.00)} - ₪ {formatPrice(maxKbPrice || 0.00)}/{t('page.order.item')}</span></p>
              </>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="products-img">
                <img src={fileName ? fileName : NoImage} alt={koneboneProductName} />
              </div>
            </div>
            <div className="initial_padding">

              {isReadyForAlternatives ? (
                <div className="flex_box_p">
                  <p><span className="product_number">{catalogNumber}</span></p>
                  <p><InfoButton /></p>
                </div>
              ) : (
                <p>
                  <span className="product_number">{catalogNumber}</span>
                </p>
              )}

              <p className="description">{koneboneProductName}</p>
            </div>
            <div>
              <p className="quantity">
                <span>
                  x
                  {quantity}
                </span>
              </p>
            </div>
            <div>
              <>
                <p className="price-all"><span className="ltr">₪ {formatPrice(minKpPriceWithQuantity || 0.00)} - ₪ {formatPrice(maxKpPriceWithQuantity || 0.00)}</span></p>
                <p><span className="ltr">₪ {formatPrice(minKbPrice || 0.00)} - ₪ {formatPrice(maxKbPrice || 0.00)}/{t('page.order.item')}</span></p>
              </>
            </div>
          </>
        )}

      </div>
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

const OrderListItemNew = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { orderId } = useParams();
  const history = useHistory();
  const { item, isFetching } = useSelector((state) => state.order);
  const { project, status, totalMaxPrice, totalMinPrice } = item;

  const deliveryDate = item.deliveryDate?.split('-');
  const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
  const deliveryTimeRange = item.deliveryTimeRange;

  const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
    `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
    t('page.supplier_orders.during_the_working_day');

  const renderHtml = (text) => <p dangerouslySetInnerHTML={{ __html: text }} />;
  const searchBreak = '/n';
  const replaceBreakWith = '<br />';
  const searchItemStart = '**';
  const replaceItemStartWith = '<li class="disc">';
  const searchItemEnd = '##';
  const replaceItemEndWith = '</li>';
  const processTemplate = (text) => {
    return text.replaceAll(
      searchBreak, replaceBreakWith
    ).replaceAll(
      searchItemStart, replaceItemStartWith
    ).replaceAll(
      searchItemEnd, replaceItemEndWith
    );
  };
  useEffect(() => {
    dispatch(getOrderById(orderId));
  }, []);

  const handleClick = (orderId) => {
    history.push(`/customer/offers_list/${orderId}`);
    dispatch(resetOrder());
  };

  if (!isFetching && Object.keys(item).length === 0) {
    return <NotFound/>;
  }

  return (
    <>
      {isFetching ? (
        <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
      ) : (
        <div className="checkout offer_confirmation order_list_item">
          <div className="container">

            <div className="breadcrumb-list">
              <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                <Breadcrumb.Item componentClass={Link} to="/customer/orders">
                  <span>{t('page.order.orders')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <span>{t('page.order.order')} #{orderId}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>


            <div className="checkout-flex-col">

              <FlexboxGrid className="flex-420-col">
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

                  <Panel bordered className="panel_edit">

                    <div className="header-flex">
                      <div><p className="order">{t('page.order.order')} #{orderId}</p></div>
                      <div><p>{customerOrdersStatusWithClass(status, t)}</p></div>
                    </div>
                    <p className="date">{item?.creationDate ? t('page.order.order_list.placed', {
                      date: new Date(item?.creationDate).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                      time: new Date(item?.creationDate).toLocaleString('en-GB', { hour: 'numeric', minute: '2-digit', hour12: false }),
                    }) : '-'}</p>

                    <div className="offers_list">
                      <div className="offer-box">

                        <div className="rs-panel-group without_collapse">
                          <div className="rs-panel">
                            <div className="rs-panel-heading">
                              <p className="rs-panel-title">{t('page.order.order_filling')} <span className="count">({item?.products?.length})</span></p>
                            </div>
                          </div>
                          <div className="rs-panel-collapse">
                            <div className="rs-panel-body">

                              <div className="products-list">
                                <ListOrdered orderedProducts={item?.products} />
                              </div>

                              {item.customerRequest && (
                                <div className="warning_box warning_add_rfp">
                                  <div>
                                    <strong> {t('page.customer_request.info_teхt')} </strong>
                                    <br/>
                                    <span
                                      style={{
                                        color: '#6E6E6E !important',
                                        wordBreak: 'break-all',
                                        whiteSpace: 'pre-line'}}>
                                      {renderHtml(processTemplate(item.customerRequest))}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>


                  </Panel>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>

                  <div className="order-component offers_list">

                    <Panel bordered>

                      <p className="header">{t('page.order.order_summary')}</p>
                      {/*
                    <div>
                      <Checkbox checked><span className="text">Ready to get partial order</span></Checkbox>
                    </div>
                  */}

                      <div className="grey_box">
                        <p>{t('page.order.estimated_total')}</p>
                        <p className="price"><span className="ltr">₪ {formatPrice(totalMinPrice || 0.00)} - ₪ {formatPrice(totalMaxPrice || 0.00)}</span></p>
                        <p className="vat">{t('page.order.without_vat')}</p>
                        <div className="toggle_hide_view">
                          <p className="">{t('page.order.for_products', { p: item?.products?.length })}</p>
                        </div>
                      </div>
                      {ordersType[status] === ordersType.IN_REVIEW &&
                        <div className="submit_button">
                          <Button
                            appearance="primary"
                            type="block"
                            onClick={() => handleClick(orderId)}>
                            {t('page.order.order_list.view_offers')}
                          </Button>
                        </div>}
                      <div className="details_box">
                        <p className="header">{t('page.order.order_list_item_new.delivery_details')}</p>

                        <div className="ico-item clock">
                          <p className="sub-header">{t('page.order.specific_delivery_time')}</p>
                          <p>{deliveryTime ? `${deliveryDateData} ${deliveryTime} ` : '-'}</p>
                        </div>

                        <div className="ico-item project">
                          <p className="sub-header">{t('page.order.project')}</p>
                          <p dir="auto" style={{textAlign: i18n.language === 'heb' ? 'right' : 'left'}}>
                            <span>{`"${project?.name}"`},</span>
                            <span>{`${project?.city ? ` ${project?.city}` : ''}`},</span>
                            <span>{` ${i18n.language === 'heb' ? 'רח’' : 'st.'} ${project?.street} ${project?.apt ? project?.apt : '-'}`}</span>
                          </p>
                        </div>

                        <div className="ico-item contacts">
                          <p className="sub-header">{t('page.order.contacts')}</p>
                          <p>{`${project?.contactFullName}, ${project?.contactPhone}`}</p>
                          {/* <p>{`${manager?.firstName}, ${manager?.phone.slice(0, 3)} ${manager?.phone.slice(3)}`}</p> */}
                        </div>

                        <div className="ico-item services">
                          <p className="sub-header">{t('page.order.additional_services')}</p>
                          <p>{item.serviceUnloading ? t('page.order.unloading') : '-'}</p>
                        </div>

                        <div className="ico-item comment">
                          <p className="sub-header">{t('page.order.comment_supplier')}</p>
                          <p>
                            {item.customerComment ? item.customerComment : '-'}
                          </p>
                        </div>
                      </div>

                    </Panel>

                    {/* <Panel bordered className="documents-box">

                      <p className="header">{t('page.order.order_list_item.documents')}</p>
                      <p>{t('page.order.order_list_item.order_payment_receive')}</p>

                    </Panel> */}
                    <NeedHelpComponent orderId={orderId} />

                  </div>

                </FlexboxGrid.Item>
              </FlexboxGrid>

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderListItemNew;
