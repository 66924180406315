import React, { useState, useEffect } from 'react';
import {
  Panel,
  PanelGroup,
  Breadcrumb,
  Icon,
  FlexboxGrid,
  Col,
  Button,
  Loader,
} from 'rsuite';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPaidOrderById } from '../redux/actions/order';
import { customerOrdersStatusWithClass } from '../utils/index';
import { formatPrice } from '../utils';
import NoImage from '../assets/img/no_image.png';
import NeedHelpComponent from '../components/NeedHelpComponent';
import NotFound from './NotFound';

const ListOrdered = ({ orderedProducts }) => (
  <ul>
    {orderedProducts.map((product, index) => (
      <li key={index}>
        <OrderedProduct product={product} />
      </li>
    ))}
  </ul>
);

ListOrdered.propTypes = {
  orderedProducts: PropTypes.instanceOf(Array).isRequired,
};

const OrderedProduct = ({ product }) => {
  const isMobileView = useMediaQuery({ query: '(max-width: 1199px)' });
  const {
    catalogNumber,
    supplierNumber,
    productName,
    quantity,
    pictureFileName,
    outOfStock,
    alternativeProduct,
    totalPrice,
    price,
    orderQuantity,
  } = product;
  const { t } = useTranslation();
  return (
    <>
      <div className={`products-list-flex${outOfStock ? ' opacity' : ''}`}>
        {isMobileView ? (
          <>
            <div>
              <div className="products-img">
                <img src={pictureFileName ? pictureFileName : NoImage} alt="product_png" />
                {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
              <p className="quantity">
                <span>
                  x
                  {quantity ? quantity : orderQuantity}
                </span>
              </p>
            </div>
            <div>

              <div className="flex_box_p">
                <p><span className="product_number">{catalogNumber}</span></p>
                {supplierNumber && <p><span className="ltr">{supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
              </div>

              <p className="description">{productName}</p>

              <div>
                <p className="price-all"><span className="ltr">₪ {totalPrice ? formatPrice(totalPrice) : 0}</span></p>
                <p><span className="ltr">₪ {price ? price : 0}/{t('page.order.item')}</span></p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="products-img">
                <img src={pictureFileName ? pictureFileName : NoImage} alt="product_png" />
                {outOfStock && <p className="out_of_stock"><span>{t('page.order.order_list_item.pay_order.out_of_stock')}</span></p>}
              </div>
            </div>
            <div className="initial_padding">
              <div className="flex_box_p">
                <p><span className="product_number">{catalogNumber}</span></p>
                {supplierNumber && <p><span className="ltr">{supplierNumber} {t('page.supplier_orders.catalog_number')}</span></p>}
              </div>

              <p className="description">{productName}</p>
            </div>
            <div>
              <p className="quantity">
                <span>
                  x
                  {quantity ? quantity : orderQuantity}
                </span>
              </p>
            </div>
            <div>
              <p className="price-all"><span className="ltr">₪ {totalPrice ? formatPrice(totalPrice) : 0}</span></p>
              <p><span className="ltr">₪ {price ? price : 0}/{t('page.order.item')}</span></p>

            </div>
          </>
        )}
      </div>
      {alternativeProduct && (
        <div className="replacement">
          <div className="label"><p><span>{t('page.order.order_list_item.pay_order.replacement')}</span></p></div>
          <OrderedProduct product={alternativeProduct} />
        </div>
      )}
    </>
  );
};

OrderedProduct.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

const OrderListItem = () => {
  const dispatch = useDispatch();
  const [deliveryDetails, setDeliveryDetails] = useState(false);
  const { t, i18n } = useTranslation();
  const { orderId } = useParams();
  const { item, isFetching } = useSelector((state) => state.order);
  const {
    project,
    offerGroup,
    total,
    vat,
    orderStatus,
    term,
    dueDate,
    comment,
    invoiceLink,
    paidTotal,
    isCreditPaymentUsed,
  } = item;

  const checkCountOfOffers = offerGroup && offerGroup.offers.length !== 1;
  const deliveryList = offerGroup?.offers.map(({deliveryDetail}) => deliveryDetail);

  const countOfProductsArray = offerGroup?.offers.map((el) => el.offerProductQuantity);
  const countOfProducts = countOfProductsArray && countOfProductsArray.reduce(function(a, b) {
    return a + b;
  }, 0);

  const dueDateValue = dueDate && new Date(dueDate);

  const renderHtml = (text) => <p dangerouslySetInnerHTML={{ __html: text }} />;
  const searchBreak = '/n';
  const replaceBreakWith = '<br />';
  const searchItemStart = '**';
  const replaceItemStartWith = '<li class="disc">';
  const searchItemEnd = '##';
  const replaceItemEndWith = '</li>';
  const processTemplate = (text) => {
    return text.replaceAll(
      searchBreak, replaceBreakWith
    ).replaceAll(
      searchItemStart, replaceItemStartWith
    ).replaceAll(
      searchItemEnd, replaceItemEndWith
    );
  };

  useEffect(() => {
    dispatch(getPaidOrderById(orderId));
  }, []);

  if (!isFetching && Object.keys(item).length === 0) {
    return <NotFound/>;
  }

  return (
    <>
      {isFetching ? (
        <Loader backdrop center size="sm" content={i18n.language === 'heb' ? '…טוען' : 'Loading...'} />
      ) : (
        <div className="checkout offer_confirmation order_list_item">
          <div className="container">

            <div className="breadcrumb-list">
              <Breadcrumb maxItems={10} separator={<Icon icon="angle-right" />}>
                <Breadcrumb.Item componentClass={Link} to="/customer/orders">
                  <span>{t('page.order.orders')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <span>{t('page.order.order')} #{orderId}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="checkout-flex-col">

              <FlexboxGrid className="flex-420-col">
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={18}>

                  <Panel bordered className="panel_edit">

                    <div className="header-flex">
                      <div><p className="order">{t('page.order.order')} #{orderId}</p></div>
                      <div><p>{customerOrdersStatusWithClass(orderStatus, t)}</p></div>
                    </div>
                    {deliveryList.map((item, index) => {
                      const deliveryDate = item?.deliveryDate?.split('-');
                      const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
                      const deliveryTimeRange = item?.deliveryTimeRange;

                      const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
                        `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
                        t('page.supplier_orders.during_the_working_day');

                      const dateTime = `${deliveryDateData} ${deliveryTime}`;
                      const deliveryText = item?.withDelay ? t('page.offers_list.delivery_with_delay.delivery_with_delay') :
                        t('page.offers_list.delivery_expected');

                      return (
                        <p className="date" key={index}>{checkCountOfOffers && `${t('page.order.order_list_item.delivery')} ${index + 1}:`} {
                          deliveryTime ? (
                            <>
                              <spna>{deliveryText}</spna>
                              <br/>
                              <spna>{dateTime}</spna>
                            </>
                          ) :
                            `${t('page.offers_list.delivery_time')} ${t('page.offers_list.not_specified')}`
                        }</p>
                      );
                    })}
                    {invoiceLink && <p className='invoice_link'>{t('page.order.order_list_item.invoice_link')} <a href={invoiceLink} target="_blank" rel="noreferrer">{invoiceLink}</a></p>}
                    <div className="offers_list">
                      <div className="offer-box">
                        {offerGroup?.offers.map((item, index) => {
                          const deliveryCounter = checkCountOfOffers && <span className="count">{t('page.order.order_list_item.delivery')} {index + 1}: {''}</span>;
                          return (
                            <PanelGroup accordion key={index}>
                              {item?.inStockProducts.length > 0 && (
                                <Panel header={
                                  <p>{deliveryCounter}
                                    {t('page.order.order_list_item.in_stock')} <span className="count">({item?.inStockProducts.length})</span></p>} defaultExpanded>

                                  <div className="products-list">
                                    <ListOrdered orderedProducts={item?.inStockProducts} />
                                  </div>
                                </Panel>)}
                              {item?.alternativeProducts.length > 0 && (
                                <Panel header={
                                  <p>{deliveryCounter}
                                    {t('page.order.order_list_item.pay_order.alternative_products')} <span className="count">({item?.alternativeProducts.length})</span></p>} defaultExpanded>

                                  <div className="products-list">
                                    <ListOrdered orderedProducts={item?.alternativeProducts} />
                                  </div>

                                </Panel>)}
                              {item?.outOfStockProducts.length > 0 && (
                                <Panel header={
                                  <p>{deliveryCounter}
                                    {t('page.order.order_list_item.pay_order.out_of_stock')} <span className="count">({item?.outOfStockProducts.length})</span></p>} defaultExpanded>
                                  <div className="products-list">
                                    <ListOrdered orderedProducts={item?.outOfStockProducts} />
                                  </div>

                                </Panel>)}
                              <i></i>
                            </PanelGroup>
                          );
                        })}
                        {item.customerRequest && (
                          <div className="warning_box warning_add_rfp">
                            <div>
                              <strong> {t('page.customer_request.info_teхt')} </strong>
                              <br/>
                              <span
                                style={{
                                  color: '#6E6E6E !important',
                                  wordBreak: 'break-all',
                                  whiteSpace: 'pre-line'}}>
                                {renderHtml(processTemplate(item.customerRequest))}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>


                  </Panel>

                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>

                  <div className="offers_list">

                    <Panel bordered>

                      <div className="supplier_name">
                        <p className="header">{t('page.order.order_summary')}</p>
                      </div>

                      <div className="grey_box">
                        <p>{t('page.order.order_filling')}</p>
                        <p className="price"><span>{t('page.order.of_products',
                          {
                            num1: countOfProducts,
                            num2: offerGroup?.offers[0]?.orderProductQuantity,
                          })}</span></p>
                        <div className="toggle_hide_view">
                          <Button appearance="link" onClick={() => setDeliveryDetails(!deliveryDetails)}>
                            <span className="flex">
                              <span>{t('page.order.delivery_details')}</span>
                              <span>
                                <i className={`rs-icon ${deliveryDetails ? 'open' : 'close'}`} />
                              </span>
                            </span>
                          </Button>
                        </div>
                      </div>

                      {deliveryDetails && (
                        <div className="details_box">
                          <p className="header">{t('page.order.delivery_details')}</p>

                          <div className="ico-item clock">
                            <p className="sub-header">{t('page.order.order_list.specific_delivery_time')}</p>
                            {deliveryList.map((item, index) => {
                              const deliveryDate = item?.deliveryDate?.split('-');
                              const deliveryDateData = deliveryDate ? `${deliveryDate?.[2]}/${deliveryDate?.[1]}/${deliveryDate?.[0]}, ` : '';
                              const deliveryTimeRange = item?.deliveryTimeRange;

                              const deliveryTime = deliveryTimeRange && !deliveryTimeRange?.full ?
                                `${deliveryTimeRange?.start?.slice(0, 5)}-${deliveryTimeRange?.end?.slice(0, 5)}` :
                                t('page.supplier_orders.during_the_working_day');

                              const dateTime = `${deliveryDateData} ${deliveryTime}`;

                              return (
                                <p key={index}>
                                  {checkCountOfOffers && <>{t('page.order.order_list_item.delivery')} {index + 1}: </>}
                                  {deliveryTime ? dateTime : '-'}
                                </p>
                              );
                            })}
                          </div>

                          <div className="ico-item project">
                            <p className="sub-header">{t('page.order.project')}</p>
                            <p dir="auto"style={{textAlign: i18n.language === 'heb' ? 'right' : 'left'}}>
                              <span>{`"${project?.name}"`},</span>
                              <span>{`${project?.city ? ` ${project?.city}` : ''}`},</span>
                              <span>{` ${i18n.language === 'heb' ? 'רח’' : 'st.'} ${project?.street} ${project?.apt ? project?.apt : '-'}`}</span>
                            </p>
                          </div>

                          <div className="ico-item contacts">
                            <p className="sub-header">{t('page.order.contacts')}</p>
                            <p>{project?.contactFullName}, {project?.contactPhone}</p>
                          </div>

                          <div className="ico-item services">
                            <p className="sub-header">{t('page.order.additional_services')}</p>
                            {offerGroup?.offers.map(({serviceUnloading}, index) => {
                              return (
                                <p key={index}>
                                  {checkCountOfOffers && <>{t('page.order.order_list_item.delivery')} {index + 1}: </>}
                                  {serviceUnloading ? t('page.order.unloading') : '-'}</p>
                              );
                            })}
                          </div>

                          <div className="ico-item comment">
                            <p className="sub-header">{t('page.order.comment_supplier')}</p>
                            <p>
                              {comment ? comment : '-'}
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="details_box">
                        <p className="header">{t('page.order.contacts')}</p>
                        {deliveryList.map(({contactName, contactPhone}, index) => {
                          return (
                            <div className="ico-item delivery" key={index}>
                              <p className="sub-header">{t('page.order.order_list_item.delivery')} {checkCountOfOffers && <>{index + 1}</>}</p>
                              <p>{`${contactName ? contactName : '-'}, ${contactPhone ? contactPhone : '-'}`}</p>
                            </div>);
                        })}
                      </div>

                      {!item?.isCreditPaymentUsed ? (
                        <>
                          <div className="details_box payment_box">
                            <div className="flex-container">
                              <div>
                                <p className="header">{t('page.order.order_list_item.payment')}</p>
                              </div>
                              <div>
                                <p className="paid">{t('page.order.order_list_item.paid')}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="details_box payment_box">
                          <div className="flex-container">
                            <div>
                              <p className="header">{t('page.order.order_list_item.payment')}</p>
                            </div>
                            <div>
                              <p className="paid paid_counter">{term}+שוטף</p>
                            </div>
                          </div>
                          <p>{t('page.order.order_list_item.due_date')} - {' '}
                            {dueDateValue.toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}
                          </p>
                        </div>
                      )
                      }
                      <div className="price_box with_line">
                        <ul>
                          <li><p>{t('page.order.order_list_item.price_offer')}</p></li>
                          <li><p><span className="ltr">₪ {formatPrice(offerGroup?.offerPrice || 0)}</span></p></li>
                        </ul>

                        <ul className="discount_price">
                          <li><p><span className="flex"><span>{t('page.offer.discount')}</span><span className="percentage">-{offerGroup?.discountPercentage}%</span></span></p></li>
                          <li><p><span className="ltr">{!!offerGroup?.discount && '-'}₪ {formatPrice(offerGroup?.discount || 0)}</span></p></li>
                        </ul>

                        <ul>
                          <li><p>{t('page.order.order_list_item.delivery_cost')}</p></li>
                          <li><p><span className="ltr">₪ {formatPrice(offerGroup?.deliveryCost)}</span></p></li>
                        </ul>

                        <ul>
                          <li><p>{t('page.offer.subtotal_without_VAT')}</p></li>
                          <li><p><span className="ltr">₪ {formatPrice(offerGroup?.orderTotal || 0)}</span></p></li>
                        </ul>

                        <ul>
                          <li><p>{t('page.offer.vat')}</p></li>
                          <li><p><span className="ltr">₪ {vat}</span></p></li>
                        </ul>

                        <ul className="total">
                          <li><p>{t('page.offer.order_total')}</p></li>
                          <li><p><span className="ltr">₪ {formatPrice(total)}</span></p></li>
                        </ul>

                        {(orderStatus === 'AWAITING_DELIVERY' || orderStatus === 'DELIVERED') && !isCreditPaymentUsed && (
                          <ul className="total">
                            <li><p>{t('page.offer.paid_total')}</p></li>
                            <li><p><span className="ltr">₪ {formatPrice(paidTotal)}</span></p></li>
                          </ul>
                        )}
                      </div>
                    </Panel>

                    <NeedHelpComponent orderId={orderId}/>

                  </div>

                </FlexboxGrid.Item>
              </FlexboxGrid>

            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default OrderListItem;
