
import RequestsForProposal from '../pages/SupplierOrders/RequestsForProposal';
import SupplierOrdersOrderDetails from '../pages/SupplierOrdersOrderDetails';
import MakeOffer from '../pages/SupplierOrders/MakeOffer';
import ExpectedDeliveryTime from '../pages/SupplierOrders/ExpectedDeliveryTime';
import SpecificDeliveryTime from '../pages/SupplierOrders/SpecificDeliveryTime';
import WithoutDelivery from '../pages/SupplierOrders/WithoutDelivery';
import OfferSentSuccessfully from '../pages/OfferSentSuccessfully';
import OrderInReview from '../pages/OrderInReview';
import OrderAwaitingDelivery from '../pages/OrderAwaitingDelivery';

import { Checkout, SuccessfulOrder } from '../pages/Checkout';
import OrderList from '../pages/OrderList';
import OrderListItemNew from '../pages/OrderListItemNew';
import OrderListItem from '../pages/OrderListItem';
import OffersList from '../pages/OffersList';
import OfferConfirmation from '../pages/OfferConfirmation/OfferConfirmation';
import SuccessfullyPlaced from '../pages/SuccessfullyPlaced';
import SuccessfullyPaid from '../pages/SuccessfullyPaid';
import PaymentFailed from '../pages/PaymentFailed';
import NotFound from '../pages/NotFound';

const SUPPLIER = 'SUPPLIER';
const SUPPLIER_CANDIDATE = 'SUPPLIER_CANDIDATE';
const CUSTOMER = 'CUSTOMER';
const CUSTOMER_CANDIDATE = 'CUSTOMER_CANDIDATE';

const routes = [
  {
    component: Checkout,
    path: '/checkout',
    exact: true,
    permission: [
      CUSTOMER,
    ],
  },
  {
    component: NotFound,
    path: ['/customer/orders/*'],
    exact: true,
    permission: [
      CUSTOMER, SUPPLIER, SUPPLIER_CANDIDATE, CUSTOMER_CANDIDATE,
    ],
  },
  {
    component: SuccessfulOrder,
    path: '/successful_checkout',
    exact: true,
    permission: [
      CUSTOMER,
    ],
  },
  {
    component: OrderList,
    path: '/customer/orders/:ordersType',
    exact: true,
    permission: [
      CUSTOMER,
    ],
  },
  {
    component: OrderList,
    path: '/customer/orders',
    exact: true,
    permission: [
      CUSTOMER, CUSTOMER_CANDIDATE,
    ],
  },
  {component: OrderListItemNew,
    path: '/customer/order/:orderId',
    exact: true,
    permission: [
      CUSTOMER,
    ],
  },
  {component: OrderListItem,
    path: '/customer/order-paid/:orderId',
    exact: true,
    permission: [
      CUSTOMER,
    ],
  },
  {component: OffersList,
    path: '/customer/offers_list/:orderId',
    exact: true,
    permission: [
      CUSTOMER,
    ],
  },
  {component: OfferConfirmation,
    path: '/customer/offer_confirmation/:orderId',
    exact: true,
    permission: [
      CUSTOMER,
    ],
  },
  {component: SuccessfullyPlaced,
    path: '/customer/successfully_accept/:orderId',
    exact: true,
    permission: [
      CUSTOMER,
    ],
  },
  {component: SuccessfullyPaid,
    path: '/successfully_paid',
    exact: true,
    permission: [
      CUSTOMER,
    ],
  },
  {component: PaymentFailed,
    path: '/payment_failed',
    exact: true,
    permission: [
      CUSTOMER,
    ],
  },
  // SUPPLIER
  {
    component: RequestsForProposal,
    path: '/supplier/orders/:ordersType',
    exact: true,
    permission: [
      SUPPLIER, SUPPLIER_CANDIDATE,
    ],
  },
  {
    component: RequestsForProposal,
    path: '/supplier/orders',
    exact: true,
    permission: [
      SUPPLIER, SUPPLIER_CANDIDATE,
    ],
  },
  {
    component: SupplierOrdersOrderDetails,
    path: '/supplier/order/:orderId',
    exact: true,
    permission: [
      SUPPLIER,
    ],
  },
  {
    component: MakeOffer,
    path: '/supplier/make_offer/:orderId',
    exact: true,
    permission: [
      SUPPLIER,
    ],
  },
  {
    component: OfferSentSuccessfully,
    path: '/offer_sent_successfully',
    exact: true,
    permission: [
      SUPPLIER,
    ],
  },
  {
    component: ExpectedDeliveryTime,
    path: '/supplier/delivery/expected/:offerId',
    exact: true,
    permission: [
      SUPPLIER,
    ],
  },
  {
    component: SpecificDeliveryTime,
    path: '/supplier/delivery/specific/:offerId',
    exact: true,
    permission: [
      SUPPLIER,
    ],
  },
  {
    component: WithoutDelivery,
    path: '/supplier/delivery/without_delivery/:offerId',
    exact: true,
    permission: [
      SUPPLIER,
    ],
  },
  {
    component: OrderInReview,
    path: '/supplier/orders/details/:orderId',
    exact: true,
    permission: [
      SUPPLIER,
    ],
  },
  {
    component: OrderAwaitingDelivery,
    path: '/supplier/orders/delivery-details/:orderId',
    exact: true,
    permission: [
      SUPPLIER,
    ],
  },
];

export default routes;
