import { createActions } from 'redux-actions';
import paymentApi from '../../api/payment';

const actions = createActions({
  getCreditInfo: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  payForOfferCredit: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  resetCreditInfo: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
  getOfferPaymentInfo: {
    request: (x) => x,
    success: (x) => x,
    error: (x) => x,
  },
});

export default actions;

export const getCreditInfo = () => async (dispatch) => {
  dispatch(actions.getCreditInfo.request());
  try {
    const response = await paymentApi.getCreditInfo();
    if (response.statusCode !== 200) throw new Error(response.message.message);
    dispatch(actions.getCreditInfo.success({ creditInfo: response?.data }));
  } catch (e) {
    dispatch(actions.getCreditInfo.error({ error: e }));
  }
};

export const payForOfferCredit = (offerId, callBack) => async (dispatch) => {
  dispatch(actions.payForOfferCredit.request());
  try {
    const response = await paymentApi.payForOfferCredit(offerId);
    if (response.statusCode !== 200) {
      throw new Error(response.message.message);
    } else {
      dispatch(actions.payForOfferCredit.success());
      if (typeof callBack === 'function') {
        callBack();
      }
    };
  } catch (e) {
    dispatch(actions.payForOfferCredit.error({ error: e }));
  }
};

export const resetCreditInfo = () => async (dispatch) => {
  dispatch(actions.resetCreditInfo.request());
  try {
    dispatch(actions.resetCreditInfo.success({ creditInfo: {} }));
  } catch (e) {
    dispatch(actions.resetCreditInfo.error({ error: e.message }));
  }
};


export const getOfferPaymentInfo = (offerId, callBack) => async (dispatch) => {
  dispatch(actions.getOfferPaymentInfo.request());
  try {
    const response = await paymentApi.getOfferPaymentInfo(offerId);
    if (response.statusCode !== 200) {
      throw new Error(response.message.message);
    } else {
      dispatch(actions.getOfferPaymentInfo.success());
      if (typeof callBack === 'function') {
        const {url, sum, currencyCode, credType, lang, offerId, pdesc} = response?.data;
        const part1 = `sum=${sum}&currency=${currencyCode}&cred_type=${credType}`;
        const part2 = `lang=${lang}&offerId=${offerId}${pdesc ? `&pdesc=${pdesc}` : ''}`;
        const paymentUrl = `${url}?${part1}&${part2}`;
        callBack(paymentUrl);
      }
    }
  } catch (e) {
    dispatch(actions.getOfferPaymentInfo.error({ error: e }));
  }
};

