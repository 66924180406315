import { get } from './';

const getRootCategories = () => get('/category/root');

const getAllCategories = () => get('/category');

const getAllChildrenCategories = (categoryId) => get(`/category/children/${categoryId}`);

const getCategoryParents = (categoryId) => get(`/category/allParent/${categoryId}`);

const findCategoryById = (categoryId) => get(`/category/${categoryId}`);

const findCategoryByName = (categoryName) => get(`/category/name?name=${categoryName}`);

const categoriesApi = {
  getRootCategories,
  getAllChildrenCategories,
  getCategoryParents,
  getAllCategories,
  findCategoryById,
  findCategoryByName,
};

export default categoriesApi;
